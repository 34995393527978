// eslint-disable
import axios from 'axios'
import _ from 'lodash'

import settings from '../../../config/settings'

const apiUrl = settings.billectaURL
//const apiUrl = 'https://apitest.billecta.com';

const headers = {
  Authorization: `Basic ${process.env.VUE_APP_BILLECTA_BASIC_TOKEN}`,
  token: localStorage.getItem('feathers-jwt'),
  Accept: 'application/json',
}

//console.log(headers)

const axiosInstance = axios.create({
  baseURL: apiUrl,
  timeout: 10000,
  withCredentials: false,
  headers,
})

const state = {
  list: [],
}

const getters = {
  ProductView() {
    return {
      CreditorPublicId: '',
      ArticleNumber: null,
      ProductExternalId: null,
      Description: '',
      Units: '',
      IsActive: true,
      UnitPrice: 0,
      VAT: 25,
      BookKeepingAccount: 3000,
      BookKeepingSalesEUAccount: 3510,
      BookKeepingSalesEUVATAccount: 3610,
      BookKeepingSalesNonEUAccount: 3710,
      BookKeepingPurchaseAccount: 4000,
      BookKeepingVATAccount: null,
      BookKeepingPurchaseVATAccount: null,
      ProductType: 0,
      ConfigurationCode: null,
    }
  },

  list(state) {
    return state.list
  },
}

const actions = {
  async fetchProduct(context, id) {
    const url = `/v1/products/product/${id}`
    const response = await axiosInstance.get(url)

    return response.data
  },
  async updateProduct(context, product) {
    const url = `/v1/products/product/`
    const response = await axiosInstance.put(url, product)

    return response.data
  },
  async createProduct(context, product) {
    const url = `/v1/products/product/`
    const response = await axiosInstance.post(url, product)

    return response.data
  },
  async deleteProduct(context, id) {
    const url = `/v1/products/product/${id}`
    const response = await axiosInstance.delete(url)

    return response.data
  },

  async fetchProducts(context, args) {
    /*
    {id}	Creditor public id (guid)
    {offset}	Offset (int) - optional
    {limit}	Limit (int) - optional
    {sortingfield}	Sorting field (string) - optional
    {asc}	Ascending sorting. Default value is true (bool) - optional
     */
    const string = _.map(args, (id, key) => id && `${key}=${id}`)
      .filter(Boolean)
      .join('&')
    //const url = `/v1/creditors/creditors?=offset${offset}&limit=${limit}&sortingfield=${sortingfield}&asc=${asc}`
    const url = `/v1/products/products/${args.id}?${string}`
    const response = await axiosInstance.get(url)

    context.commit('list', response.data)

    return response.data
  },
}

const mutations = {
  list: (state, data) => (state.list = data),
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
