<template>
  <v-dialog v-model="dialogOpen" persistent max-width="750px">
    <v-card height="85vh" v-if="article">
      <v-card-title>
        <v-toolbar flat>
          <v-toolbar-title>Priser för {{ article.name }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-container class="pt-0 pb-0">
          <v-row class="pt-0 pb-0">
            <v-col class="pt-0 pb-0" cols="12" sm="12" md="6">
              <ListWithFixedHeader
                ref="priceList"
                :height="350"
                class="overflow-y-auto"
                dense
                :single="true"
                :return-index="true"
                :elevation="0"
                :items="computedPrices"
                :loading="false"
                :disabled="newPrice"
                sub-header="Prenumerationstyper"
                @selectedIndex="index => (selectedPrice = index)"
              >
                <template #item="{item}">
                  <v-list-item-content>
                    <v-list-item-title :title="item.id">{{
                      item.description
                    }} <span v-html="isInBillectaProductsHTML(item)"></span>
                    {{isInBillectaProducts(item) && isInBillectaProducts(item).ArticleNumber}}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </ListWithFixedHeader>
              <v-divider></v-divider>
              <div class="pa-1">
                <v-btn
                  v-if="newPrice"
                  text
                  dark
                  small
                  color="primary"
                  @click="cancelNewPrice"
                  :ripple="false"
                >
                  Avbryt
                </v-btn>
                <v-btn
                  v-else
                  text
                  dark
                  small
                  color="primary"
                  @click="initNewPrice"
                  :ripple="false"
                >
                  Ny prenumerationstyp
                </v-btn>
              </div>
            </v-col>

            <v-col class="pt-0 pb-0" cols="12" sm="12" md="6">
              <v-form
                :disabled="selectedPrice < 0 && !newPrice"
                ref="form"
                v-model="valid"
              >
                <v-container class="pt-0 pb-0">
                  <v-row class="pt-0 pb-0">
                    <v-col class="pt-0 pb-4" cols="12" sm="12">
                      <v-text-field
                        type="text"
                        v-model="editedPriceItem.description"
                        label="Beskrivning"
                        :readonly="!newPrice && editedPriceItem._id !== ''"
                        :hint="
                          !newPrice && editedPriceItem._id !== ''
                            ? 'Ej redigerbart'
                            : ''
                        "
                        :persistent-hint="
                          !newPrice && editedPriceItem._id !== ''
                        "
                        :hide-details="newPrice"
                      ></v-text-field>
                    </v-col>

                    <v-col class="pt-0 pb-2" cols="12" sm="12" md="6">
                      <v-text-field
                        type="number"
                        :min="0"
                        v-model.number="editedPriceItem.price"
                        label="Pris"
                      ></v-text-field>
                    </v-col>

                    <v-col class="pt-0 pb-2" cols="12" sm="12" md="6">
                      <v-text-field
                        type="number"
                        :min="1"
                        v-model.number="editedPriceItem.months"
                        hint="Antal månader som en prenumeration sträcker sig över"
                        persistent-hint
                        label="Månader"
                      ></v-text-field>
                    </v-col>

                    <v-col class="pt-0 pb-0" cols="12" sm="12">
                      <DatePickerMenu
                        v-model="editedPriceItem.pricePeriodStart"
                        label="Perioden börjar"
                        hint="(alltid den första)"
                        :min="currentPriceItem.pricePeriodStart"
                        returnFormat="YYYY-MM-DDT00:00:00.000Z"
                        displayFormat="YYYY-MM"
                      />
                    </v-col>

                    <v-col class="pt-0 pb-0" cols="12" sm="12">
                      <p class="pt-4">Valbar för</p>
                      <v-btn-toggle
                        multiple
                        class="mb-2 ml-2"
                        v-model="editedPriceItem.personType"
                      >
                        <v-btn
                          :disabled="selectedPrice < 0 && !newPrice"
                          small
                          :value="1"
                        >
                          Person
                        </v-btn>
                        <v-btn
                          :disabled="selectedPrice < 0 && !newPrice"
                          small
                          :value="2"
                        >
                          Icke-person
                        </v-btn>
                      </v-btn-toggle>
                      <v-switch
                        dense
                        v-model="editedPriceItem.noMail"
                        label="Digital produkt?"
                        persistent-hint
                        hint="Visas ej i utskicksfiler"
                      />
                      <v-switch
                        v-if="0"
                        dense
                        v-model="editedPriceItem.ignoreExtraMonth"
                        label="Ignorera extra månad"
                        hide-details
                      />
                      <v-switch
                        dense
                        v-model="editedPriceItem.wholeYear"
                        label="Helårsprenumeration"
                        hide-details
                      />
                      <v-switch
                        dense
                        v-model="editedPriceItem.active"
                        label="Aktiv"
                        hint="Om inaktiv så visas inte alternativet när en ny prenumeration skapas"
                        persistent-hint
                      />
                    </v-col>

                    <v-col cols="12" sm="12" class="d-flex justify-end">
                      <v-btn
                        v-if="editedPriceItem.description && !isInBillectaProducts(editedPriceItem)"
                        dark
                        small
                        color="primary"
                        @click="setBillectaProduct(true)"
                        :ripple="false"
                      >
                        Lägg till i Billecta
                      </v-btn>
                      <v-btn
                        v-if="editedPriceItem.description && editedPriceItem._id && isInBillectaProducts(editedPriceItem)"
                        dark
                        small
                        color="primary"
                        @click="updateBillectaProduct(true)"
                        :ripple="false"
                      >
                        Uppdatera i Billecta
                      </v-btn>
                      <v-btn
                        icon
                        dark
                        small
                        color="primary"
                        :disabled="disableSaveButton"
                        @click="savePrice"
                        :ripple="false"
                      >
                        <v-icon>mdi-content-save</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="$emit('close', false)">
          Stäng
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { models } from 'feathers-vuex'
import DatePickerMenu from '@/components/DatePickerMenu'
import ListWithFixedHeader from '@/components/ListWithFixedHeader'

import moment from 'moment'
import _ from 'lodash'
import {mapGetters} from "vuex";

export default {
  components: {
    DatePickerMenu,
    ListWithFixedHeader,
  },
  props: {
    dialogOpen: {
      default: false,
    },
    article: {
      default: null,
    },
  },
  data: () => ({
    loading: true,
    newPrice: false,
    selectedPrice: -1,
    articles: [],
    prices: [],
    price: null,
    editedPriceItem: {},
    currentPriceItem: {},
    defaultPriceItem: {},
    personsList: [],
    valid: false,
    tab: null,
    nameRules: [
      v => !!v || 'Namn är obligatoriskt',
      v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],
    emailRules: [
      v => !!v || 'E-post är obligatoriskt',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    items: [
      { tab: 'Personuppgifter', content: 'Tab 1 Content' },
      { tab: 'Prenumerationer', content: 'Tab 2 Content' },
      { tab: 'Historik', content: 'Tab 3 Content' },
    ],
  }),
  async mounted() {
    //await this.fetchArticles()
    // await this.fetchPersons()
    // await this.fetchSubscriptionPrice()
    const { Price } = models.api

    this.defaultPriceItem = { ...Price.instanceDefaults() }
    this.editedPriceItem = { ...Price.instanceDefaults() }
    this.currentPriceItem = { ...Price.instanceDefaults() }
  },
  methods: {
    isInBillectaProducts(item) {
      const p = this.billectaProducts.find(price => price.ProductPublicId === item.billecta_id)
      return p
    },
    billectaProduct(item) {
      const p = this.billectaProducts.find(price => price.ProductPublicId === item.billecta_id)
      return p
    },
    isInBillectaProductsHTML(item) {
      const p = this.billectaProducts.find(price => price.ProductPublicId === item.billecta_id)
      return item.billecta_id && p && '&#10004;' || ''
    },
    cancelNewPrice() {
      this.newPrice = false
      this.editedPriceItem = { ...this.defaultPriceItem }
    },
    initNewPrice() {
      this.selectedPrice = -1
      this.$refs.priceList.reset()
      this.newPrice = true
      this.editedPriceItem = { ...this.defaultPriceItem }
      console.log(this.editedPriceItem)

      this.editedPriceItem.pricePeriodStart = moment
        .utc()
        .add(1, 'month')
        .format('Y-MM-DDT00:00:00.0000Z')
      this.editedPriceItem.articleId = this.article._id
    },
    async savePrice() {
      if (this.priceIsDifferent || this.monthIsDifferent) delete this.editedPriceItem._id
      if (this.priceIsDifferent || this.monthIsDifferent) delete this.editedPriceItem.id

      if (!this.editedPriceItem._id) {
        this.editedPriceItem.pricePeriodStart = moment.utc().startOf('month').toISOString()
      }

      await this.setBillectaProduct()
      //console.log(this.editedPriceItem)

      const price = new models.api.Price(this.editedPriceItem)

      await price.save()


      if (!this.editedPriceItem._id)
        this.$store.commit('showMessage', {
          position: 'bottom',
          timeout: 3500,
          text: `Ny prenumerationstyp tillagt för ${this.article.name}`,
          visible: true,
        })
      else {
        if (this.priceIsDifferent)
          this.$store.commit('showMessage', {
            position: 'bottom',
            timeout: 3500,
            text: `Nytt pris tillagt för ${this.article.name}`,
            visible: true,
          })
        else
          this.$store.commit('showMessage', {
            position: 'bottom',
            timeout: 3500,
            text: `Prenumerationstypen uppdaterad för ${this.article.name}`,
            visible: true,
          })
      }

      await this.fetchPrices()
      // this.$emit('close', false)
    },
    async updateBillectaProduct() {
      const description = []

      //const article = this.articles.find(a => a._id === price.articleId)

      description.push(this.article.name)
      description.push(this.editedPriceItem.description)
      description.push(moment.utc(this.editedPriceItem.pricePeriodStart).format('Y-MM-DD'))
      const ProductView = {
        //...ProductView,
        ProductPublicId: ''+this.editedPriceItem.billecta_id,
        "CreditorPublicId": this.mainCreditorPublicId,
        "ArticleNumber": this.editedPriceItem.id || null,
        "ProductExternalId": null,
        "Description": description.join(' '),
        "Units": "st",
        "IsActive": true,
        /*"UnitPrice": this.editedPriceItem.price / 1.12,
        "VAT": 12,*/
        "UnitPrice": this.editedPriceItem.price,
        "VAT": 0,
        "BookKeepingAccount": 3000,
        "BookKeepingSalesEUAccount": 3510,
        "BookKeepingSalesEUVATAccount": 3610,
        "BookKeepingSalesNonEUAccount": 3710,
        "BookKeepingPurchaseAccount": 4000,
        "BookKeepingVATAccount": null,
        "BookKeepingPurchaseVATAccount": null,
        "ProductType": 1,
        "ConfigurationCode": null
      }

      let billecta_product = null;

      if( this.editedPriceItem.billecta_id) {
        try {
          billecta_product = await this.$store.dispatch('billecta_product/updateProduct', ProductView);

          console.log('billecta_product update', billecta_product)
        }
        catch (error) {
          this.showErrorMessage(error)
        }

      }
    },
    async setBillectaProduct(saveDb = false) {
      let billecta_product = null;

      let ProductView = _.cloneDeep(this.$store.getters['billecta_product/ProductView']);

      const description = []

      //const article = this.articles.find(a => a._id === price.articleId)

      description.push(this.article.name)
      description.push(this.editedPriceItem.description)
      description.push(moment.utc(this.editedPriceItem.pricePeriodStart).format('Y-MM-DD'))

      ProductView = {
        //...ProductView,
        "CreditorPublicId": this.mainCreditorPublicId,
        "ArticleNumber": this.editedPriceItem.id || null,
        "ProductExternalId": null,
        "Description": description.join(' '),
        "Units": "st",
        "IsActive": true,
        /*"UnitPrice": this.editedPriceItem.price / 1.12,
        "VAT": 12,*/
        "UnitPrice": this.editedPriceItem.price,
        "VAT": 0,
        "BookKeepingAccount": 3000,
        "BookKeepingSalesEUAccount": 3510,
        "BookKeepingSalesEUVATAccount": 3610,
        "BookKeepingSalesNonEUAccount": 3710,
        "BookKeepingPurchaseAccount": 4000,
        "BookKeepingVATAccount": null,
        "BookKeepingPurchaseVATAccount": null,
        "ProductType": 1,
        "ConfigurationCode": null
      }

      if(!this.editedPriceItem.billecta_id) {
        try {
          billecta_product = await this.$store.dispatch('billecta_product/createProduct', ProductView);

          console.log('billecta_product create', billecta_product)
        }
        catch (error) {
          this.showErrorMessage(error)
        }

      }
      else {
        ProductView.ProductPublicId = this.editedPriceItem.billecta_id

        billecta_product = await this.$store.dispatch('billecta_product/updateProduct', ProductView);

        console.log('billecta_product update', billecta_product)
      }

      await this.$store.dispatch('billecta_product/fetchProducts', {id: this.mainCreditorPublicId})

      if(!saveDb || !billecta_product)
        return

      this.editedPriceItem.billecta_id = billecta_product.PublicId

      const price = new models.api.Price(this.editedPriceItem)

      price.save()

      await this.updateBillectaProduct()
    },
    getList(item, queryText) {
      const search = queryText
        .toLowerCase()
        .split(' ')
        .filter(o => o)
      let count = 0
      Object.keys(item).forEach(key => {
        if (typeof item[key] === 'string' || typeof item[key] === 'number') {
          const find = search.find(
            s => ('' + item[key]).toLowerCase().indexOf(s) > -1
          )

          if (find) count += 1
        }
      })
      return count === search.length
    },
    setEndDate(date) {
      const endDate = moment
        .utc(date)
        .add(1, 'y')
        .add(-1, 'd')
        .format('YYYY-MM-DDT23:59:59.999Z')
      this.subscription.endDate = endDate
    },

    async fetchArticles() {
      const { Article } = models.api

      const data = await Article.find()

      this.articles = data
    },

    async fetchPrices() {
      const { Price } = models.api

      const articleId = this.article._id

      const data = await Price.find({
        query: {
          articleId,
        },
      })

      this.prices = data

      /* this.prices = groupByHighest.map(price => {
        return {
          ...price,
        }
      }) */
    },
    async fetchPrice(_id = '') {
      const { Price } = models.api

      const data = await Price.find({
        query: {
          _id,
        },
      })

      this.price = data[0]
    },
  },
  computed: {
    ...mapGetters({
      'mainCreditorPublicId': 'billecta_creditor/mainCreditorPublicId',
      'billectaProducts': 'billecta_product/list'
    }),
    priceIsDifferent() {
      return this.editedPriceItem.price !== this.currentPriceItem.price
    },
    monthIsDifferent() {
      return this.editedPriceItem.months !== this.currentPriceItem.months
    },
    computedPrices() {
      return _.orderBy(
        this.groupPriceByDate(this.prices, 'description', 'pricePeriodStart'),
        ['description']
      )
    },
    disableSaveButton() {
      //(selectedPrice < 0 && !newPrice)  && (editedPriceItem.price === undefined || editedPriceItem.price === 0)
      if(this.monthIsDifferent)
        return false

      if (this.selectedPrice < 0 && !this.newPrice) return true
      else if (this.editedPriceItem.description === '') return true
      else if (
        this.editedPriceItem.price === undefined ||
        this.editedPriceItem.price === 0
      )
        return true

      return false
    },
  },
  watch: {
    async article(article) {
      if (article) await this.fetchPrices()
    },
    selectedPrice(price) {
      if (this.newPrice) return

      if (price === -1) {
        this.editedPriceItem = { ...this.defaultPriceItem }
        this.currentPriceItem = { ...this.defaultPriceItem }
      } else {
        this.editedPriceItem = { ...this.computedPrices[price] }
        this.currentPriceItem = { ...this.computedPrices[price] }
      }
    },
    dialogOpen(bool) {
      if (bool) {
        console.log('')
      } else {
        setTimeout(() => {
          const { Price } = models.api

          this.prices = []
          this.newPrice = false

          this.defaultPriceItem = { ...Price.instanceDefaults() }
          this.currentPriceItem = { ...Price.instanceDefaults() }
          this.editedPriceItem = { ...Price.instanceDefaults() }
          this.selectedPrice = -1
        }, 1000)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.v-select__slot {
  input {
    position: absolute;
  }
}
</style>
