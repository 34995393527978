<template>

    <v-btn
      :color="color"
      :text="text"
      :small="small"
      :plain="plain"
      :outlined="outlined"
      ripple
      :class="[css, stepsComplete ? 'confirmation__button--complete' : '']"
      :disabled="disabled || stepsComplete"
      v-on:click="incrementStep()"
    >
      <slot
        v-if="$scopedSlots[`step_${currentStep}`]"
        :name="`step_${currentStep}`"
      >
      </slot>
      <span v-else>{{ currentMessage }}</span>
    </v-btn>

</template>

<script>
export default {
  name: 'vue-confirmation-button',
  props: {
    color: String,
    fullWidth: { type: Boolean, default: false },
    text: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    plain: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    messages: Array,
    css: {
      type: String,
      default: 'confirmation__button',
    },
  },
  data() {
    return {
      defaultSteps: ['Click to confirm', 'Are you sure?', '✔'],
      currentStep: 0,
      timeout: null,
    }
  },
  computed: {
    messageList() {
      return this.messages ? this.messages : this.defaultSteps
    },
    currentMessage() {
      return this.messageList[this.currentStep]
    },
    lastMessageIndex() {
      return this.messageList.length - 1
    },
    stepsComplete() {
      return this.currentStep === this.lastMessageIndex
    },
  },
  methods: {
    incrementStep() {
      this.currentStep++
      if (this.stepsComplete) {
        this.$emit('confirmation-success')
        clearTimeout(this.timeout)
        this.currentStep = 0
      } else {
        this.$emit('confirmation-incremented')
      }

      if (this.currentStep === this.lastMessageIndex - 1)
        this.timeout = setTimeout(() => {
          this.currentStep = 0
        }, 3000)
    },
    reset() {
      this.currentStep = 0
      this.$emit('confirmation-reset')
    },
  },
}
</script>

<style></style>
