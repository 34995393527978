<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="4" sm="12">
        <v-card flat>
          <v-card-title>Fakturor</v-card-title>

          <v-autocomplete
            v-if="0"
            :items="invoicesByDateArray"
            v-model="invoiceDate"
            label="Fakturadatum"
            no-data-text="Fakturadatumet finns inte"
            item-value="key"
            item-text="label"
            :disabled="loading"
            dense
            required
            clearable
            :return-object="false"
          >
            <template v-slot:selection="data">
              {{ `${data.item.label} [${data.item.items.length}]` }}
            </template>
            <template v-slot:item="data">
              {{ `${data.item.label} [${data.item.items.length}]` }}
            </template>
          </v-autocomplete>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" sm="12">
        <v-btn-toggle class="mb-2 ml-2" v-model="invoiceStatus">
          <v-badge
            bordered
            color="primary"
            :content="invoiceCount.all"
            overlap
            :value="invoiceCount.all"
          >
            <v-btn small value="all" :disabled="loading || !invoiceDate">
              Alla
            </v-btn>
          </v-badge>
          <v-badge
            bordered
            color="warning"
            :content="invoiceCount.nonPayed"
            overlap
            :value="invoiceCount.nonPayed"
          >
            <v-btn small value="non-payed" :disabled="loading">
              Obetalda
            </v-btn>
          </v-badge>
          <v-badge
            bordered
            color="green"
            :content="invoiceCount.payed"
            overlap
            :value="invoiceCount.payed"
          >
            <v-btn small value="payed" :disabled="loading">
              Betalda
            </v-btn>
          </v-badge>
          <v-badge
            bordered
            color="error"
            :content="invoiceCount.overdued"
            overlap
            :value="invoiceCount.overdued"
          >
            <v-btn small value="overdued" :disabled="loading">
              Förfallna
            </v-btn>
          </v-badge>
          <v-badge
            bordered
            color="black"
            :content="invoiceCount.canceled"
            overlap
            :value="invoiceCount.canceled"
          >
            <v-btn small value="canceled" :disabled="loading">
              Makulerade
            </v-btn>
          </v-badge>
        </v-btn-toggle>
      </v-col>

      <v-col cols="12" md="2" sm="12">
        <v-text-field
          label="Sök faktura-id"
          v-model.number="invoiceId"
          :full-width="false"
          @keyup.enter="fetchInvoiceById"
          :disabled="loading"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" xs="12">
        <v-list dense :elevation="0" text>
          <v-subheader>FAKTUROR</v-subheader>
          <v-list-item-group color="primary" v-model="selectedInvoice">
            <v-list-item v-for="(item, i) in invoices" :key="i">
              <v-list-item-avatar
                :color="item.color"
                :title="invoiceStatusTextual(item)"
              >
                <strong class="initials white--text">
                  {{ invoiceStatusText(item) }}
                </strong>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{
                  `Faktura #${item.id}`
                }}</v-list-item-title>
                <v-list-item-subtitle
                  v-text="item.person"
                ></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text>
                  <div>
                    {{ item.dispatchDate }}
                  </div>
                  <div>
                    {{ item.dueDate }}
                  </div>
                </v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col
        v-if="currentInvoice && $vuetify.breakpoint.smAndUp"
        cols="6"
        xs="12"
      >
        <v-btn class="mr-2" small @click="print">Skriv ut</v-btn>

        <v-btn
          class="mr-2"
          small
          v-if="!currentInvoice.payDate"
          @click="payInvoice"
          >Markera som betald</v-btn
        >

        <confirm-button
          v-if="!currentInvoice.canceled"
          color="orange lighten-1"
          :small="true"
          :plain="true"
          :messages="['Makulera', 'Säker?', '✔']"
          @confirmation-success="cancelInvoice"
          >Makulera</confirm-button>

        <div style="max-width: 400px;">
          <v-card class="mt-4">
            <InvoiceSVG
              v-if="currentInvoice"
              :scale="1"
              :invoice="currentInvoice"
            />
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import moment from 'moment'
import _ from 'lodash'

import InvoiceSVG from '@/components/invoice/InvoiceSVG'
import { models } from 'feathers-vuex'
import ConfirmButton from '@/components/ConfirmButton'

export default {
  components: { ConfirmButton, InvoiceSVG },
  data: () => ({
    search: '',
    isMobile: false,
    loading: false,
    active: true,
    invoiceDate: '',
    invoiceId: '',
    invoiceStatus: '',
    invoicesList: [],
    persons: [],
    selectedInvoice: -1,
    invoices: [],
    invoicesQuery: {
      query: {},
      $sort: {_id: -1},
      paginate: false,
    },
    statusText: ['Obetald', 'Betald', 'Förfallen', 'Makulerad'],
  }),
  async created() {
    // const today = moment().startOf('year');
    // "2018-12-05T00:00:00.00

    /* const persons = this.invoices
    await this.findInvoiceRows({
      payerId: { $in: persons.map(p => p._id) }
    }) */
    this.$store.commit('showMessage', {
      position: 'bottom',
      timeout: 5000,
      text: 'Laddar fakturadata från databasen',
      visible: true,
    })

    this.currentInvoice = null

    const { Person } = this.$FeathersVuex.api

    this.persons = await Person.find({
      query: {},
    })

    //await this.fetchInvoices()
  },
  beforeDestroy() {
    this.currentInvoice = null
  },
  methods: {
    ...mapActions('invoices', { invoicesCount: 'count' }),
    // ...mapActions('invoices', { findInvoices: 'find' }),
    ...mapActions('invoicerows', { findInvoiceRows: 'find' }),
    ...mapActions('persons', { findPersons: 'find' }),
    ...mapMutations('persons', {
      clearPersons: 'clearAll', // lets you do `this.clearTodos()` inside the component
    }),
    invoiceStatusText() {
      return ''
      /* const { nonPayed, payed, overdued, canceled } = this.invoiceStatuses
      if (canceled.find(o => o === item._id)) return 'M'
      if (overdued.find(o => o === item._id)) return 'F'
      if (nonPayed.find(o => o === item._id)) return 'O'
      if (payed.find(o => o === item._id)) return 'B' */
    },
    invoiceStatusTextual() {
      return ''
      /* const { nonPayed, payed, overdued, canceled } = this.invoiceStatuses
      if (canceled.find(o => o === item._id)) return 'Makulerad'
      if (overdued.find(o => o === item._id)) return 'Förfallen'
      if (nonPayed.find(o => o === item._id)) return 'Obetald'
      if (payed.find(o => o === item._id)) return 'Betald' */
    },
    print() {
      window.print()
    },
    async fetchInvoiceById() {
      this.selectedInvoice = -1
      this.currentInvoice = null
      this.$store.state.printInvoices = []

      this.invoicesQuery = {
        query: {
          id: this.invoiceId
        }
      }

      this.loading = true

      await this.fetchInvoices()

      this.filterInvoices()

      this.selectedInvoice = 0

    },
    async payInvoice() {
      const payment = new models.api.Payment({
        invoiceId: this.currentInvoice._id,
        amount: this.currentInvoice.amount,
        personId: this.currentInvoice.payerId,
      })
      await payment.save()

      const invoice = new models.api.Invoice({
        _id: this.currentInvoice._id,
        payDate: new Date(),
      })
      await invoice.save()

      this.$store.commit('showMessage', {
        position: 'bottom',
        timeout: 2000,
        text: `Faktura ${this.currentInvoice.id} markerad som betald`,
        visible: true,
      })

      this.filterInvoices()
      this.currentInvoice = this.invoices[this.selectedInvoice]
    },
    async cancelInvoice() {
      const invoice = new models.api.Invoice({
        _id: this.currentInvoice._id,
        canceled: new Date().toISOString(),
      })
      await invoice.save()

      this.$store.commit('showMessage', {
        position: 'bottom',
        timeout: 2000,
        text: `Faktura ${this.currentInvoice.id} är nu makulerad`,
        visible: true,
      })

      this.filterInvoices()
      this.currentInvoice = this.invoices[this.selectedInvoice]
    },
    download() {
      this.$refs.html2Pdf.generatePdf()
    },
    async fetchInvoices() {
      try {
        this.$store.commit('showMessage', {
          position: 'bottom',
          timeout: 2000,
          text: `Hämtar fakturadata`,
          visible: true,

        })
        // await this.findInvoices(this.invoicesQuery)
        const { Invoice } = this.$FeathersVuex.api

        this.invoicesList = await Invoice.find(this.invoicesQuery)
        this.invoicesList = _.orderBy(this.invoicesList, ['_id'], ['desc'])
        this.loading = false
      } catch (e) {
        console.log(e)
      }
    },
    filterInvoices() {
      //const status = this.invoiceStatus
      //const date = this.invoiceDate
      let items = []

      if (this.invoiceId) {
        items = this.invoicesList.filter(
          invoice => invoice.id === this.invoiceId
        )
        if (!items.length) {
          this.$store.commit('showMessage', {
            position: 'bottom',
            timeout: 2000,
            text: `Faktura #${this.invoiceId} finns inte`,
            visible: true,
          })
        }
      } else {
        items = this.invoicesList;
        /* items = this.invoicesByDateArray
          .find(i => i.key === date)
          .items.filter(item => {
            if (status === 'all' && item) return true
            if (status === 'non-payed' && item)
              return !item.canceled && !item.payDate
            if (status === 'payed' && item)
              return !item.canceled && item.payDate
            if (status === 'overdued' && item)
              return new Date(item.dueDate) < new Date() && !item.payDate
            if (status === 'canceled' && item) return item.canceled
          }) */
      }

      this.invoices = items.filter(o => o).map(item => {
        let person, payer;
        person = this.personsList.find(p => p._id === item.payerId)
        if(person) {
          payer = {...person}
          if (person.type === 1)
            person = `${person.id} ${person.lastname} ${person.firstname}, ${person.postal_address}`
          if (person.type === 2)
            person = `${person.id} ${person.lastname}, ${person.postal_address}`
        }

        let color = ''

        const nonPayed = !item.payDate && !item.canceled
        const payed = item.payDate && !item.canceled
        const overdued =
          new Date(item.dueDate) < new Date() && !item.payDate && !item.canceled
        const canceled = item.canceled

        if (overdued) {
          color = 'error'
        } else if (canceled) {
          color = 'black'
        } else if (nonPayed) {
          color = 'warning'
        } else if (payed) {
          color = 'green'
        } else color = ''

        return {
          ...item,
          person,
          payer,
          dispatchDate: (item && item.dispatchDate || '').substr(0, 10),
          dueDate: (item && item.dueDate || '').substr(0, 10),
          payerId: person && person.id || '',
          nonPayed,
          payed,
          overdued,
          canceled,
          color,
        }
      })
    },
  },
  computed: {
    //...mapGetters('invoices', { invoicesList: 'list' }),
    ...mapState('invoices', { loadingSubscriptions: 'isFindPending' }),
    ...mapGetters('persons', { personsList: 'list' }),
    ...mapState('persons', { loadingPersons: 'isFindPending' }),
    currentInvoice: {
      get() {
        return this.$store.state.currentInvoice
      },
      set(invoice) {
        this.$store.state.currentInvoice = invoice
      },
    },
    invoiceCount() {
      const invoiceArray = this.invoicesByDateArray.find(
        i => i.key === this.invoiceDate
      )
      if (!invoiceArray)
        return {
          all: 0,
          nonPayed: 0,
          payed: 0,
          overdued: 0,
          canceled: 0,
        }

      const invoices = invoiceArray.items

      let all = invoices.length
      const overduedIds = invoices
        .filter(
          item =>
            new Date(item.dueDate) < new Date() &&
            !item.payDate &&
            !item.canceled
        )
        .map(o => o._id)
      let overdued = invoices.filter(
        item =>
          new Date(item.dueDate) < new Date() && !item.payDate && !item.canceled
      ).length
      let nonPayed = invoices.filter(
        item =>
          !overduedIds.includes(item._id) && !item.payDate && !item.canceled
      ).length
      let payed = invoices.filter(
        item =>
          !overduedIds.includes(item._id) && item.payDate && !item.canceled
      ).length
      let canceled = invoices.filter(item => item.canceled).length

      return {
        all,
        nonPayed,
        payed,
        overdued,
        canceled,
      }
    },
    invoiceStatuses() {
      const invoiceArray = this.invoicesByDateArray.find(
        i => i.key === this.invoiceDate
      )
      if (!invoiceArray)
        return {
          all: 0,
          nonPayed: 0,
          payed: 0,
          overdued: 0,
          canceled: 0,
        }

      const invoices = invoiceArray.items

      let all = invoices
      let nonPayed = invoices
        .filter(item => !item.payDate && !item.canceled)
        .map(o => o._id)

      let payed = invoices
        .filter(item => item.payDate && !item.canceled)
        .map(o => o._id)
      let overdued = invoices
        .filter(item => new Date(item.dueDate) < new Date() && !item.payDate)
        .map(o => o._id)
      let canceled = invoices.filter(item => item.canceled).map(o => o._id)

      return {
        all,
        nonPayed,
        payed,
        overdued,
        canceled,
      }
    },
    invoicesByDate() {
      if (!this.invoicesList.length) return null

      const key = 'dispatchDate'
      const result = this.invoicesList.reduce(function(r, a) {
        r[a[key]] = r[a[key]] || []
        r[a[key]].push(a)
        return r
      }, Object.create(null))

      return result
    },
    invoicesByDateArray() {
      if (!this.invoicesByDate) return []

      return Object.entries(this.invoicesByDate)
        .map(([key, value]) => {
          return {
            key,
            label: moment.utc(key).format('YYYY-MM-DD'),
            items: value,
          }
        })
        .sort((a, b) => new Date(b.key) - new Date(a.key))
    },
  },
  watch: {
    currentInvoice(invoice) {
      if (invoice) this.$store.state.printInvoices = [_.cloneDeep(invoice)]
      else this.$store.state.printInvoices = []
    },
    async invoiceStatus(status) {
      this.selectedInvoice = -1
      this.invoicesList = []
      this.invoices = [];
      this.currentInvoice = null
      this.$store.state.printInvoices = []

      //

      if(status === 'all') {
        this.invoicesQuery = {
          query: {}
        }
      }

      if(status === 'non-payed') {
        this.invoicesQuery = {
          query: {
            canceled: '',
            payDate: null,
            dueDate: {$gt: new Date()}
          }
        }
      }

      if(status === 'overdued') {
        this.invoicesQuery = {
          query: {
            canceled: '',
            payDate: null,
            dueDate: {$lte: new Date()}
          }
        }
      }

      if(status === 'payed'){
        this.invoicesQuery = {
          query: {
            canceled: '',
            payDate: {$ne: ''}
          },
          $limit: 100
        }
      }

      if(status === 'canceled'){
        this.invoicesQuery = {
          query: {
            canceled: {$ne: ''}
          }
        }
      }

      this.invoicesQuery.query = {
        ...this.invoicesQuery.query,
        billecta_id: '',
        payerId: {$ne: ''},
        id: {$lt: 9160}
      }

      this.loading = true

      await this.fetchInvoices()

      this.filterInvoices()

      return
    },
    invoiceDate() {
      this.currentInvoice = null
      this.selectedInvoice = -1
      this.invoiceId = ''
      // this.filterInvoices()
    },
    selectedInvoice(index) {
      if (index === -1) return
      this.currentInvoice = this.invoices[index]
    },
  },
}
</script>
