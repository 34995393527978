<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12" xs="12">
        <v-data-table
          :loading="loadingUsers"
          :headers="headers"
          :items="filteredUsers"
          :hide-default-header="isMobile"
          :hide-default-footer="true"
          :footer-props="footerProps"
          item-key="_id"
          class="elevation-1"
          :sort-by="['name']"
          :sort-desc="[false]"
          multi-sort
          @click:row="handleClick"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Användare</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" persistent max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Ny användare
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12">
                          <v-text-field
                            type="text"
                            v-model="editedItem.name"
                            label="Användarnamn"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12">
                          <v-text-field
                            type="email"
                            v-model="editedItem.email"
                            label="E-postadress"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            v-model="editedItem.first_name"
                            label="Förnamn"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-text-field
                            v-model="editedItem.last_name"
                            label="Efternamn"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="6">
                          <v-select
                            :items="[
                              { value: 1, text: 'Användare' },
                              { value: 2, text: 'Admin' },
                            ]"
                            item-text="text"
                            item-value="value"
                            label="Nivå"
                            :return-object="false"
                            v-model.number="editedItem.level"
                            single-line
                          />
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            type="password"
                            v-model="editedItem.password"
                            label="Lösenord"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Avbryt
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      {{ buttonText }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline"
                    >Är du säker på att du vill ta bort denna
                    användare?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Avbryt</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
              Reset
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-fab-transition v-if="0">
      <v-btn v-show="true" color="pink" fab dark small absolute top right>
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-container>
</template>

<script>
import { models } from 'feathers-vuex'
import { mapActions } from 'vuex'

export default {
  data: () => ({
    search: '',
    isMobile: false,
    loadingUsers: false,
    active: 'active',
    footerProps: {
      options: {
        itemsPerPage: 20,
        sortBy: ['firstname'],
        sortDesc: [false],
      },
      pagination: {
        rowsPerPage: 20,
        sortBy: 'lastname',
        ascending: true,
      },
      itemsPerPageOptions: [
        5,
        10,
        15,
        20,
        25,
        30,
        40,
        { text: 'Alla', value: -1 },
      ],
      itemsPerPageText: 'Användare per sida',
    },
    usersList: [],

    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    editedItem: {
      email: '',
      first_name: '',
      last_name: '',
      level: 1,
    },
    defaultItem: {
      email: '',
      first_name: '',
      last_name: '',
      level: 1,
    },
  }),
  async created() {
    this.loadingUsers = true
    const { User } = models.api

    this.defaultItem = { ...User.instanceDefaults() }

    await this.fetchUsers()

    this.loadingUsers = false
  },
  methods: {
    ...mapActions('users', { updateUser: 'updateUser' }),
    handleClick() {},
    async fetchUsers() {
      this.usersList = []

      const { User } = models.api

      const data = await User.find({})

      this.usersList = this.multiSortArray(data, {})
    },
    initialize() {
      this.editedItem = { ...this.defaultItem }
    },
    editItem(item) {
      this.editedIndex = this.usersList.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.usersList.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      const user = new models.api.User(this.editedItem)
      await user.remove()
      this.usersList.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 1000)
    },

    closeDelete() {
      this.dialogDelete = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 1000)
    },

    async save() {
      const user = new models.api.User(this.editedItem)
      if (this.editedIndex > -1) {
        const item = await user.update()
        Object.assign(this.usersList[this.editedIndex], item)
      } else {
        const item = await user.save()
        this.usersList.push(item)
      }
      this.close()
    },
  },
  computed: {
    headers() {
      return [
        {
          text: 'Användarnamn',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Förnamn', value: 'first_name' },
        { text: 'Efternamn', value: 'last_name' },
        { text: 'E-postadress', value: 'email' },
        { text: 'Nivå', value: 'level' },
        { text: '', value: 'actions', sortable: false },
      ]
    },
    formTitle() {
      return this.editedIndex === -1 ? 'Ny användare' : 'Redigera användare'
    },
    buttonText() {
      return this.editedIndex === -1 ? 'Lägg till' : 'Uppdatera'
    },
    filteredUsers() {
      if (!this.search) return this.usersList

      const search = this.search
        .toLowerCase()
        .split(' ')
        .filter(o => o)
      const result = this.persons.filter(o => {
        let count = []
        Object.keys(o).forEach(key => {
          if (typeof o[key] === 'string' || typeof o[key] === 'number') {
            const string = '' + o[key]
            const index = search.findIndex(
              s =>
                string
                  .trim()
                  .toLowerCase()
                  .indexOf(s) > -1
            )

            if (index > -1 && !count.includes(index)) {
              count.push(index)
            }
          }
        })
        return count.length === search.length
      })
      return result
    },
  },
  watch: {},
}
</script>
