<template>
  <v-container class="mb-6">
    <v-row>
      <v-col cols="12" md="4" sm="12">
        <v-card flat>
          <v-card-title>Skicka fakturor</v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-progress-linear v-if="loading" value="1" indeterminate />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4" xs="12">
        <v-select
          class="mt-6"
          :items="invoiceTitles"
          v-model="invoiceDate"
          label="Fakturadatum"
          no-data-text="Inga fakturadatum tillgängliga"
          item-text="date"
          item-value="key"
          dense
          required
        >
          <template v-slot:selection="data">
            {{ `${data.item.date}` }}
          </template>
          <template v-slot:item="data">
            {{ `${data.item.date}` }}
          </template>
        </v-select>

        <v-switch
          v-model="ignorePrintStatus"
          hint="Om detta är valt så hämtas även utskrivna fakturor för valt datum"
          persistent-hint
          label="Ignorera utskriftsstatus"
        />

        <ListWithFixedHeader
          v-if="0"
          :height="300"
          class="overflow-y-auto"
          dense
          :elevation="0"
          :items="subscriptionTypes"
          sub-header="Visa prenumerationstyper"
          @activeItems="items => (activeArticles = items)"
        >
          <template #item="{item}">
            <v-list-item-content>
              <v-list-item-title>{{ `${item.description}` }}</v-list-item-title>
              <v-list-item-subtitle
                v-text="item.articleTitle"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </ListWithFixedHeader>

        <v-select
          class="mt-4"
          :items="attachmentItems"
          v-model="attachment"
          label="Använd bilagor"
          no-data-text="Inga bilagor"
          item-value="value"
          item-text="text"
          dense
          chips
          clearable
          :return-object="false"
        >
        </v-select>

        <FileReader classes="mb-8" @load="addAttachment" />

        <v-text-field
          v-model.number="invoicesPerPrint"
          label="Antal fakturor/utskrift"
          hint="Tänk på att det blir tyngre för datorn om antalet sidor ökar och att om en bilaga skickas med så dubbleras antalet sidor"
          persistent-hint
        />

        <v-textarea class="mt-4" v-model="infoText" label="Informationstext" />

        <v-btn class="primary" @click="fetchInvoices" :disabled="loading"
          >Visa fakturor för valt datum</v-btn
        >
      </v-col>
      <v-col cols="12" md="6" xs="12">
        <div>
          <template v-for="(item, i) in invoices">
            <v-card
              class="mb-4"
              style="width: 500px"
              :key="`svga_${invoices._id || i}`"
            >
              <Invoice
                :scale="0.6"
                :invoice="item"
                :info-text="infoText"
                :attachment="attachment"
                :key="`svg_${invoices._id || i}`"
              />

            </v-card>

            <v-card
              v-if="attachment || attachmentSVG"
              class="mb-4"
              style="width: 500px"
              :key="`svgb_${invoices._id || i}`"
            >
              <InvoiceAttachment
                :scale="1"
                :invoice="item"
                :attachment="attachment"
                :attachmentSVG="attachmentSVG"
                :key="`svg_attachment_${invoices._id || i}`"
              />
            </v-card>
          </template>
        </div>

        <v-btn v-if="0" @click="download">Ladda ner</v-btn>
      </v-col>
    </v-row>
    <v-fab-transition v-if="0">
      <v-btn v-show="true" color="pink" fab dark small absolute top right>
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-bottom-navigation
      fixed
      hide-on-scroll
      horizontal
      scroll-target="#hide-on-scroll-example"
    >
      <v-btn color="deep-purple accent-4" @click="fetchInvoices" text>
        <span>Visa fakturor</span>
      </v-btn>
      <v-btn
        color="deep-purple accent-4"
        :disabled="!invoices.length"
        text
        @click="print"
      >
        <span>Skriv ut</span>
      </v-btn>
      <confirm-button
        color="deep-purple accent-4"
        :small="true"
        :plain="false"
        :text="true"
        :disabled="!invoices.length"
        :messages="[`Markera som utskrivna`, 'Bekräfta', '✔']"
        @confirmation-success="setPrintedInvoices"
      >
      </confirm-button>
    </v-bottom-navigation>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import ListWithFixedHeader from '@/components/ListWithFixedHeader'
import ConfirmButton from '@/components/ConfirmButton'
import InvoiceAttachment from '@/components/invoice/InvoiceAttachment'
import FileReader from '@/components/FileReader'

import moment from 'moment'
import _ from 'lodash'
import { models } from 'feathers-vuex'
import Invoice from "@/components/invoice/Invoice";

export default {
  components: {
    Invoice,
    InvoiceAttachment,
    ListWithFixedHeader,
    ConfirmButton,
    FileReader,
  },
  data: () => ({
    search: '',
    isMobile: false,
    loading: false,
    active: true,
    ignorePrintStatus: false,
    infoText: '',
    activeArticles: [],
    invoicesList: [],
    subscriptionTypes: [],
    attachmentItems: [],
    invoicesPerPrint: 25,
    invoiceDate: '',
    invoiceStatus: 'all',
    invoiceTitles: [],
    persons: [],
    invoices: [],
    selectedInvoice: -1,
    invoicesQuery: {
      query: {
        printed: null,
        canceled: '',
        payDate: null,
      },
      paginate: true,
    },
    articleList: [],
  }),
  async created() {
    // const today = moment().startOf('year');
    // "2018-12-05T00:00:00.00

    /* const persons = this.invoices
    await this.findInvoiceRows({
      payerId: { $in: persons.map(p => p._id) }
    }) */

    this.loading = true

    this.$store.commit('showMessage', {
      position: 'bottom',
      timeout: 2000,
      text: 'Laddar fakturadata från databasen',
      visible: true,
    })

    this.infoText =
      'Ordinarie 590kr (ny pren 350kr), Till Liv pdf 390kr, Fullpris 700kr\n' +
      'Taltidning 590kr (ny pren 350kr), Student 290kr\n' +
      'Begrunda, 2 nr/år (bilaga i Till Liv) \n' +
      'För Till Liv-pren 120kr/år, Endast Begrunda 150kr\n' +
      '\n' +
      'prenumeration@tillliv.se'

    this.attachmentItems = [
      {
        value: '@/assets/images/bilagor/bilaga_2014-11-11.svg',
        text: 'Bilaga 2014-11-11',
      },
    ]

    this.currentInvoice = null

    const { Person } = this.$FeathersVuex.api

    this.persons = await Person.find({
      query: {},
    })

    await this.fetchInvoiceDates()

    await this.fetchSubscriptionTypes()

    this.loading = false

    // await this.fetchInvoices()
  },
  beforeDestroy() {
    this.currentInvoice = null
    this.attachment = null
  },
  methods: {
    ...mapActions('invoices', { invoicesCount: 'count' }),
    ...mapActions('invoices', { findInvoices: 'find' }),
    ...mapActions('invoicerows', { findInvoiceRows: 'find' }),
    ...mapActions('persons', { findPersons: 'find' }),
    ...mapMutations('persons', {
      clearPersons: 'clearAll', // lets you do `this.clearTodos()` inside the component
    }),
    addAttachment(svg) {
      this.attachmentSVG = svg
    },
    print() {
      window.print()
    },
    async setPrintedInvoices() {
      for await (let invoice of this.invoices) {
        await this.setInvoicePrinted(invoice)
      }

      this.$store.commit('showMessage', {
        position: 'bottom',
        timeout: 5000,
        text: `${this.invoices.length} fakturor markerades som utskrivna`,
        color: 'success',
        visible: true,
      })
    },
    async setInvoicePrinted(invoiceObject = {}) {
      const invoice = new models.api.Invoice({
        _id: invoiceObject._id,
        printed: new Date(),
      })
      await invoice.save()
      this.invoicesList = this.invoicesList.filter(i => i._id !== invoiceObject._id)

      this.$store.commit('showMessage', {
        position: 'bottom',
        timeout: 2000,

        text: `Faktura ${invoiceObject.id} markerad som utskriven`,
        visible: true,
      })

      this.filterInvoices()
      this.currentInvoice = this.invoices[this.selectedInvoice]
    },
    download() {
      this.$refs.html2Pdf.generatePdf()
    },
    async fetchSubscriptionTypes() {
      try {
        const { Article, Price } = this.$FeathersVuex.api

        const articles = await Article.find({ query: {} })

        const result = await Price.find({ query: {} })

        const prices = result.map(price => {
          return {
            ...price,
            articleTitle:
              articles.find(a => a._id === price.articleId).name || '',
          }
        })

        this.subscriptionTypes = _.orderBy(
          this.groupPriceByDate(prices, 'description', 'pricePeriodStart'),
          ['articleId', 'description'],
          ['asc', 'asc']
        )
      } catch (e) {
        console.log(e)
      }
    },
    async fetchInvoiceDates() {
      try {
        const { Invoice } = this.$FeathersVuex.api

        const invoiceQuery = {
          query: {
            $select: ['printed', 'dispatchDate'],
            canceled: '',
          },
          paginate: false,
        }

        if (!this.ignorePrintStatus) invoiceQuery.query.printed = null

        let result = await Invoice.find(invoiceQuery)
        // console.log(invoiceQuery, result)
        if (!this.ignorePrintStatus) result = result.filter(r => !r.printed)
        // console.log(invoiceQuery, result)

        this.setInvoiceDates(result)

        this.invoices = []
      } catch (e) {
        console.log(e)
      }
    },
    setInvoiceDates(result) {
      const dates = [
        ...new Set(result.map(r => r.dispatchDate.substr(0, 10))),
      ].sort((a, b) => new Date(b) - new Date(a))

      this.invoiceTitles = dates.map(date => {
        return {
          key: new Date(date).toISOString(),
          date,
        }
      })
    },
    async fetchInvoices() {
      this.loading = true
      this.invoices = []

      this.invoicesQuery = {
        query: {
          dispatchDate: this.invoiceDate,
          canceled: '',
        },
        paginate: false,
      }

      if (!this.ignorePrintStatus) this.invoicesQuery.query.printed = null

      const { Invoice } = this.$FeathersVuex.api

      try {
        const invoices = await Invoice.find(this.invoicesQuery)
        this.invoicesList = invoices

        let invoicesList

        if (this.invoicesPerPrint)
          invoicesList = this.invoicesList.filter(
            (o, i) => i < this.invoicesPerPrint
          )

        invoicesList = invoicesList.map(item => {
          let person
          person = this.personsList.find(p => p._id === item.payerId)
          const payerId = person.id
          const payer = { ...person }
          if (person.type === 1)
            person = `${person.id} ${person.lastname} ${person.firstname}, ${person.postal_address}`
          if (person.type === 2)
            person = `${person.id} ${person.lastname}, ${person.postal_address}`
          return {
            ...item,
            person,
            payer,
            payerId,
            infoText: this.infoText,
          }
        })

        this.invoices = invoicesList

        this.$store.commit('showMessage', {
          position: 'bottom',
          timeout: 2000,
          text: `Hämtade ${this.invoices.length} faktur${
            this.invoices.length === 1 ? 'a' : 'or'
          }`,
          visible: true,
        })
      } catch (e) {
        console.log(e)
      }

      this.loading = false
    },
    async filterInvoices() {
      const status = this.invoiceStatus
      const date = this.invoiceDate

      await this.fetchInvoiceDates()

      if(!this.invoicesByDateArray.length)
        return


      const items = this.invoicesByDateArray
        .find(i => i.key === date)
        .items.filter(item => {
          if (status === 'all' && item) return true
          if (status === 'non-payed' && item) return !item.payDate
          if (status === 'payed' && item) return item.payDate !== ''
          if (status === 'canceled' && item) return item.canceled
        })

      this.invoices = items.map(item => {
        let person
        person = this.personsList.find(p => p._id === item.payerId)
        const payer = { ...person }
        if (person.type === 1)
          person = `${person.id} ${person.lastname} ${person.firstname}, ${person.postal_address}`
        if (person.type === 2)
          person = `${person.id} ${person.lastname}, ${person.postal_address}`
        return {
          ...item,
          person,
          payer,
          payerId: person.id,
        }
      })

    },
  },
  computed: {
    ...mapState('invoices', { loadingSubscriptions: 'isFindPending' }),
    ...mapGetters('persons', { personsList: 'list' }),
    ...mapState('persons', { loadingPersons: 'isFindPending' }),
    currentInvoice: {
      get() {
        return this.$store.state.currentInvoice
      },
      set(invoice) {
        this.$store.state.currentInvoice = invoice
      },
    },
    attachment: {
      get() {
        return this.$store.state.invoiceAttachment
      },
      set(attachment) {
        this.$store.state.invoiceAttachment = attachment
      },
    },
    attachmentSVG: {
      get() {
        return this.$store.state.invoiceAttachmentSVG
      },
      set(attachment) {
        this.$store.state.invoiceAttachmentSVG = attachment
      },
    },
    invoiceCount() {
      const invoiceArray = this.invoicesByDateArray.find(
        i => i.key === this.invoiceDate
      )
      if (!invoiceArray)
        return {
          all: 0,
          nonPayed: 0,
          payed: 0,
          overdued: 0,
          canceled: 0,
        }

      const invoices = invoiceArray.items

      let all = invoices.length
      let nonPayed = invoices.filter(item => !item.payDate).length
      let payed = invoices.filter(item => item.payDate).length
      let overdued = invoices.filter(item => !item.payDate).length
      let canceled = invoices.filter(item => item.canceled).length

      return {
        all,
        nonPayed,
        payed,
        overdued,
        canceled,
      }
    },
    invoicesByDate() {
      if (!this.invoicesList.length) return null

      const key = 'dispatchDate'
      const result = this.invoicesList.reduce(function(r, a) {
        r[a[key]] = r[a[key]] || []
        r[a[key]].push(a)
        return r
      }, Object.create(null))

      return result
    },
    invoicesByDateArray() {
      if (!this.invoicesByDate) return []

      return Object.entries(this.invoicesByDate)
        .map(([key, value]) => {
          return {
            key,
            label: moment.utc(key).format('YYYY-MM-DD'),
            items: value,
          }
        })
        .sort((a, b) => new Date(b.key) - new Date(a.key))
    },
  },
  watch: {
    invoices(invoices) {
      this.$store.state.printInvoices = _.cloneDeep(invoices)
    },
    async ignorePrintStatus() {
      this.loading = true
      this.currentInvoice = null
      this.selectedInvoice = -1

      await this.fetchInvoiceDates()
      this.loading = false
    },
    invoiceStatus(status) {
      console.log(status)

      this.filterInvoices()

      // if(status === 'all') {}

      // if(status === 'non-payed') {}

      // if(status === 'payed'){}

      // if(status === 'canceled'){}

      return
      /* if(status === 'non-payed')
        this.invoicesQuery =  {
          query: {
            dispatchDate: this.invoiceDate,
            payDate: {"$not": {"$size": 0}}
          },
          paginate: true
        }

      if(status === 'payed')
        this.invoicesQuery =  {
          query: {
            dispatchDate: this.invoiceDate,
            payDate: {"$not": {"$size": 0}}
          },
          paginate: true
        }

      if(status === 'canceled')
        this.invoicesQuery =  {
          query: {
            dispatchDate: this.invoiceDate,
            payDate: {"$not": {"$size": 0}}
          },
          paginate: true
        } */

      // this.fetchInvoices()
    },
    invoiceDate() {
      this.currentInvoice = null
      this.selectedInvoice = -1
    },
    selectedInvoice(index) {
      this.currentInvoice = { ...this.invoices[index] }
    },
  },
}
</script>
