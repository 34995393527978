<template>
  <v-container class="mb-6">
    <v-row>
      <v-col cols="12" md="4" sm="12">
        <v-card flat>
          <v-card-title>Skapa adressfil</v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="0">
      <v-col cols="12" md="4" sm="12">
        <v-btn-toggle class="mb-2 ml-2" v-model="invoiceStatus">
          <v-badge
            bordered
            color="green"
            :content="invoiceCount.all"
            overlap
            :value="invoiceCount.all"
          >
            <v-btn small value="all">
              Alla
            </v-btn>
          </v-badge>
          <v-badge
            bordered
            color="green"
            :content="invoiceCount.nonPayed"
            overlap
            :value="invoiceCount.nonPayed"
          >
            <v-btn small value="non-payed">
              Obetalda
            </v-btn>
          </v-badge>
          <v-badge
            bordered
            color="green"
            :content="invoiceCount.payed"
            overlap
            :value="invoiceCount.payed"
          >
            <v-btn small value="payed">
              Betalda
            </v-btn>
          </v-badge>
          <v-badge
            bordered
            color="green"
            :content="invoiceCount.overdued"
            overlap
            :value="invoiceCount.overdued"
          >
            <v-btn small value="overdued">
              Förfallna
            </v-btn>
          </v-badge>
          <v-badge
            bordered
            color="green"
            :content="invoiceCount.canceled"
            overlap
            :value="invoiceCount.canceled"
          >
            <v-btn small value="canceled">
              Makulerade
            </v-btn>
          </v-badge>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-progress-linear v-if="loading" value="1" indeterminate/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4" xs="12">
        <ListWithFixedHeader
          :height="250"
          class="overflow-y-auto"
          dense
          :elevation="0"
          :items="subscribableArticlesList"
          :loading="articlesListLoading"
          sub-header="Skapa filen för"
          @activeItems="items => (activeArticles = items)"
        >
          <template #item="{item}">
            <v-list-item-content>
              <v-list-item-title>{{ `${item.name}` }}</v-list-item-title>
            </v-list-item-content>
          </template>
        </ListWithFixedHeader>

        <ListWithFixedHeader
          :height="150"
          class="overflow-y-auto"
          dense
          :elevation="0"
          :items="nonSubscribableArticlesList"
          :loading="articlesListLoading"
          sub-header="Välj övriga artiklar som ska inkluderas i filen"
          @activeItems="items => (activeNonsubscribableArticles = items)"
        >
          <template #item="{item}">
            <v-list-item-content>
              <v-list-item-title>{{ `${item.name}` }}</v-list-item-title>
            </v-list-item-content>
          </template>
        </ListWithFixedHeader>

        <DatePickerMenu
          class="mt"
          v-model="sendDate"
          label="Välj den sista dagen i utskicksmånaden"
          hint="(alltid den sista)"
          returnFormat="YYYY-MM-DDT23:59:59.999Z"
          displayFormat="YYYY-MM"
        />

        <v-text-field
          v-if="0"
          class="mt-4"
          type="number"
          v-model.number="extraMonth"
          :label="`Extra månader`"
          min="0"
          :hint="
            formatExtraMonth
              ? `inkluderar även prenumerationer som gick ut ${formatExtraMonth}`
              : ''
          "
          :persistent-hint="extraMonth > 0"
        ></v-text-field>

        <v-switch
          v-model="ignoreInvoiceStatus"
          :label="`Ignorera fakturastatus (betald/obetald/förfallen)`"
        ></v-switch>

        <v-switch
          v-if="0"
          v-model="ignorePersonStatus"
          :label="`Ignorera personstatus (aktiv/inaktiv)`"
        ></v-switch>

        <v-btn
          class="primary"
          small
          @click="fetchSubscriptions"
          :disabled="disableFetchButton"
        >Läs in prenumerationer från databasen
        </v-btn>
      </v-col>
      <v-col cols="12" md="6" xs="12">
        <v-list :elevation="0" text>
          <v-text-field
            v-model="search"
            label="Sök"
            class="ma-4 mt-6"
            hint="Alla fält är sökbara"
            persistent-hint
          ></v-text-field>
          <v-subheader
          >PERSONER SOM SKA FÅ [{{ filteredAddressFileList.length }}], ANTAL EX [{{ totalNumberOfCopiesToBeSent }}]
          </v-subheader>


          <v-list-item-group color="primary" v-model="selectedInvoice">
            <v-list-item
              v-for="(item, i) in filteredAddressFileList"
              :key="i"
              :class="item.color"
              :title="item._id"
              @click="
                currentIndex === i ? (currentIndex = -1) : (currentIndex = i)
              "
            >
              <v-list-item-content>
                <v-list-item-title :title="item.personObject._id">{{ item.person }}</v-list-item-title>
                <v-list-item-subtitle>
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="item.subs.length">
                  <template v-for="(sub, subindex) in item.subs">
                    {{
                      (subindex ? ', ' : '') +
                      (sub.articleObject && sub.articleObject.name)
                    }}: {{ sub.articleObject && sub.subscriptionCount }}
                  </template>
                </v-list-item-subtitle>
                <v-list-item-subtitle></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text>
                  <!--div>
                    {{ formatDate(item.subs[0].startDate, 'Y-MM') }}
                  </div>
                  <div>
                    {{ formatDate(item.subs[0].endDate, 'Y-MM') }}
                  </div>
                  <div>
                    <v-btn
                      class="secondary"
                      x-small
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </div-->
                </v-list-item-action-text>
              </v-list-item-action>

              <!--v-bottom-sheet
                v-model="showBottomSheet"
                inset
              >
                <v-sheet
                  class="text-center pa-2 mb-4"
                  v-if="clickedPerson"
                >
                  <v-btn
                    class="mt-6"
                    text
                    color="error"
                    @click="closeSheet(item)"
                  >
                    stäng
                  </v-btn>
                  <div class="my-3">
                    {{clickedPerson.invoice.payerName}}
                  </div>
                  <div class="my-3">
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                        <tr>
                          <th class="text-left">
                            Artikel
                          </th>
                          <th class="text-left">
                            Pris
                          </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                          v-for="item in clickedInvoice.items"
                          :key="item.text"
                          @click=""
                        >
                          <td class="text-left" v-html="item.text.replace(/\n/, '<br>')"></td>
                          <td class="text-left">{{ item.price * item.quantity }}</td>
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>

                </v-sheet>

              </v-bottom-sheet-->

              <v-scroll-x-transition v-if="0">
                <v-card class="mt-4" v-show="currentIndex === i">
                  <v-card-title>Prenumerationsinfo</v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row
                        v-for="(sub, subindex) in item.allsubs"
                        :key="item._id + subindex"
                      >
                        <v-col cols="12" md="12" sm="12">
                          Pren #{{ sub.id }}
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-scroll-x-transition>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <v-bottom-sheet v-if="0"
                        :value="selectedSubscription"
                        inset
        >
          <v-sheet
            class="text-center pa-2 mb-4"
            height="200px"
            v-if="selectedSubscription"
          >
            <v-btn
              class="mt-6"
              text
              color="error"
              @click="selectedInvoiceSheet = false"
            >
              stäng
            </v-btn>
            <div class="my-3">
              <v-card class="mt-4">
                <v-card-title>{{ selectedSubscription.person }}</v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row
                      v-for="(sub, subindex) in selectedSubscription.subs"
                      :key="subindex"
                    >
                      <v-col cols="12" md="12" sm="12">
                        {{ sub.articleObject.name }}, {{ sub.ex }}ex
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>

          </v-sheet>
        </v-bottom-sheet>
      </v-col>


    </v-row>
    <v-fab-transition v-if="0">
      <v-btn v-show="true" color="pink" fab dark small absolute top right>
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-bottom-navigation fixed horizontal>
      <v-btn
        color="deep-purple accent-4"
        text
        :disabled="disableFetchButton"
        @click="fetchSubscriptions"
      >
        <span>Läs in prenumerationer</span>
        <v-icon>mdi-history</v-icon>
      </v-btn>
      <v-btn
        color="deep-purple accent-4"
        text
        v-if="filteredAddressFileList.length"
        @click="downloadXLSX"
      >
        <span>Ladda ner adressfil</span>
        <v-icon>mdi-history</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'

import ListWithFixedHeader from '@/components/ListWithFixedHeader'

import DatePickerMenu from '@/components/DatePickerMenu'

import XLSX from 'xlsx'

import moment from 'moment'
import _ from 'lodash'

export default {
  components: {ListWithFixedHeader, DatePickerMenu},
  data: () => ({
    subscriptionQuery: {},
    search: '',
    isMobile: false,
    loading: false,
    active: true,
    extraMonth: 0,
    currentIndex: -1,
    ignoreInvoiceStatus: true,
    ignorePersonStatus: false,
    infoText: '',
    sendDate: '',
    subs: [],
    subscriptionsList: [],
    invoicesList: [],
    articlesList: [],
    articlesListLoading: false,
    activeArticles: [],
    activeNonsubscribableArticles: [],
    pricesList: [],
    subscriptionTypes: [],
    attachmentItems: [],
    attachments: [],
    invoicesPerPrint: 25,
    invoiceDate: '',
    invoiceStatus: 'all',
    invoiceTitles: [],
    persons: [],
    invoices: [],
    invoicesrows: [],
    selectedInvoice: -1,
    selectedInvoiceSheet: false,
    invoicesQuery: {
      query: {
        printed: null,
        payDate: null,
      },
      paginate: true,
    },

    showBottomSheet: false,
    clickedPerson: null,
  }),
  async created() {
    // const today = moment().startOf('year');
    // "2018-12-05T00:00:00.00

    /* const persons = this.invoices
    await this.findInvoiceRows({
      payerId: { $in: persons.map(p => p._id) }
    }) */

    this.infoText =
      'Ordinarie 590kr (ny pren 350kr), Till Liv pdf 390kr, Fullpris 700kr\n' +
      'Taltidning 590kr (ny pren 350kr), Student 290kr\n' +
      'Begrunda, 2 nr/år (bilaga i Till Liv) \n' +
      'För Till Liv-pren 120kr/år, Endast Begrunda 150kr\n' +
      '\n' +
      'prenumeration@tillliv.se'

    this.attachmentItems = [
      {
        value: '@/assets/images/bilagor/bilaga_2014-11-11.svg',
        text: 'Bilaga 2014-11-11',
      },
    ]

    this.sendDate = moment
      .utc()
      .endOf('month')
      .toISOString()

    this.loading = true

    await this.fetchArticles()

    this.loading = false
    this.currentInvoice = null
  },
  beforeDestroy() {
    this.currentInvoice = null
  },
  methods: {
    ...mapActions('invoices', {invoicesCount: 'count'}),
    ...mapActions('invoices', {findInvoices: 'find'}),
    ...mapActions('invoicerows', {findInvoiceRows: 'find'}),
    ...mapActions('persons', {findPersons: 'find'}),
    ...mapMutations('persons', {
      clearPersons: 'clearAll', // lets you do `this.clearTodos()` inside the component
    }),
    closeSheet() {
      this.showBottomSheet = false;
      this.clickedPerson = false;
    },
    downloadXLSX() {
      /*const formatColumn = (worksheet, col, fmt) => {
        const range = XLSX.utils.decode_range(worksheet['!ref'])
        // note: range.s.r + 1 skips the header row
        for (let row = range.s.r + 1; row <= range.e.r; ++row) {
          const ref = XLSX.utils.encode_cell({ r: row, c: col })
          if (worksheet[ref] && worksheet[ref].t === 'n') {
            worksheet[ref].z = fmt
          }
        }
      }*/

      const exportFileName = `Utskicksfil ${moment().format('Y-MM-DD')}`

      const rows = [
        ...this.downloadFileData,
        // eslint-disable-next-line no-unused-vars
        ...this.computedTitles.map(_ => ''),
      ]
      /* make the worksheet */
      const ws = XLSX.utils.json_to_sheet(rows, {skipHeader: true})

      /* add to workbook */
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Utskick')

      /* write workbook */
      const buffer = XLSX.write(wb, {bookType: 'xlsx', type: 'base64'})

      const pre =
        'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,'

      const a = document.createElement('a')
      a.href = pre + buffer
      a.target = '_blank'
      a.download = `${exportFileName}.xlsx`
      document.body.appendChild(a)
      a.click()
      a.parentElement.removeChild(a)
    },
    downloadFile() {
      const csv = [...this.downloadFileDataCSV].join('\r\n')

      let encodedUri = encodeURI(csv)
      let link = document.createElement('a')
      link.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,%EF%BB%BF' + encodedUri
      )
      link.setAttribute(
        'download',
        `Utskicksfil ${moment().format('Y-MM-DD')}.csv`
      )
      document.body.appendChild(link) // Required for FF

      link.click()
    },

    async fetchArticles() {
      try {
        const {Article, Price} = this.$FeathersVuex.api

        this.articlesListLoading = true
        const articles = await Article.find({query: {}})
        this.articlesListLoading = false

        const prices = await Price.find({
          query: {
            noMail: false,
          },
        })

        this.pricesList = prices.sort(
          (a, b) => new Date(b.pricePeriodStart) - new Date(a.pricePeriodStart)
        )

        this.articlesList = articles.map(article => {
          const prices = this.pricesList.filter(
            p => p.articleId === article._id
          )
          return {
            ...article,
            prices,
            prices_ids: prices.map(p => p._id),
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    filterPrices(articleId) {
      return this.groupPriceByDate(
        this.pricesList.filter(price => price.articleId === articleId),
        'description',
        'pricePeriodStart'
      )
    },
    async fetchSubscriptions() {
      this.loading = true

      this.$store.commit('showMessage', {
        position: 'bottom',
        timeout: 22000,
        text: 'Laddar prenumerationsdata från databasen',
        visible: true,
      })

      this.invoicesrows = []
      this.invoices = []
      this.persons = []

      this.subscriptionsList = []

      const {
        Subscription,
        Person,
        Invoice,
        Invoicerow,
      } = this.$FeathersVuex.api

      this.subscriptionQuery = {
        query: {
          articleId: {$in: this.selectedPriceIds},
          pdf: false,
          startDate: {
            $lte: new Date(this.sendDate),
          },
          endDate: {
            $gte: moment
              .utc(this.sendDate)
              .add(0 - this.extraMonth, 'months')
              .format(),
          },
        },
        paginate: false,
      }


      let subscriptions = await Subscription.find(this.subscriptionQuery)

      this.subs = subscriptions

      const subIds = subscriptions
        .filter(sub => sub.invoiceRowId)
        .map(sub => sub.invoiceRowId)

      // console.log(subIds)

      if (!this.ignoreInvoiceStatus) {
        const invoiceRowsQuery = {
          query: {
            _id: {
              $in: subIds,
            },
          },
          paginate: false,
        }

        const invoiceRows = await Invoicerow.find(invoiceRowsQuery)

        console.log(invoiceRows)

        const invoiceQuery = {
          query: {
            canceled: '',
            _id: {$in: invoiceRows.map(sub => sub.invoiceId)},
          },
          paginate: false,
        }
        let invoices = await Invoice.find(invoiceQuery)

        invoices = invoices.filter(i => !i.payDate)
        console.log(invoices)
        //const wronginvoices = invoices.filter(i => i.payDate)

        this.invoices = invoices
        const invoiceIds = invoices.map(i => i._id)
        let invoiceRowIds = invoiceRows
          .filter(i => invoiceIds.includes(i.invoiceId))
          .map(row => row._id)

        this.subscriptionQuery.query.invoiceRowId = {
          $in: invoiceRowIds,
        }

        // console.log(invoiceRowIds)

        //const filteredOut = subscriptions.filter(s => !invoiceRowIds.includes(s.invoiceRowId))

        // console.log(filteredOut)

        subscriptions = subscriptions.filter(s => invoiceRowIds.includes(s.invoiceRowId))

        //subscriptions = await Subscription.find(this.subscriptionQuery)
      } else {
        //console.log('hej')
      }

      // console.log(subscriptions)

      //const unique = arr => arr.filter((item, i, s) => s.lastIndexOf(item) == i)
      //const personIds = unique(subscriptions.map(p => p.personId))
      // const personIds = (subscriptions)
      // console.log(personIds)
      const personsQuery = {
        query: {
          /*_id: { $in: personIds },
          active: true,*/
          active: true,
        },
        paginate: false,
      }
      //if (this.ignorePersonStatus) delete personsQuery.query.active

      const persons = await Person.find(personsQuery)

      const invoiceRowsQuery = {
        query: {
          _id: {$in: subscriptions.map(p => p.invoiceRowId).filter(o => o)},
        },
        paginate: false,
      }
      // console.log(invoiceRowsQuery)
      const invoicerows = await Invoicerow.find(invoiceRowsQuery)

      const invoiceQuery = {
        query: {
          $select: ['id'],
          _id: {$in: invoicerows.map(p => p.invoiceId).filter(o => o)},
        },
        paginate: false,
      }

      const invoices = await Invoice.find(invoiceQuery)

      this.invoicesrows = invoicerows
      this.invoices = invoices
      this.persons = _.orderBy(
        persons,
        ['type', 'lastname', 'firstname'],
        ['desc', 'asc', 'asc']
      )

      //this.subscriptionsList = subscriptions

      // console.log(this.pricesList)
      // TODO Fixa så att flera prenumerationer kan komma med, men inte med samma personid och description
      const mapSubs = (_subs) => {
        console.log(_subs.filter(o => o.personId === '6021cac2c22a3014f6f00b25'))
        const renewedIds = []
        return _subs.reverse().reduce((r, o) => {
          const priceObject = this.pricesList.reverse().find(a => a._id == o.articleId)
          if(priceObject) {
            //const articleObjectId = priceObject.articleId
            //const priceIds = this.pricesList.reverse().filter(a => a.articleId == articleObjectId).map(o => o._id).join('.')

            if(!renewedIds.includes(o._id)) {
              r.set(`${o.personId}.${o.payerId}.${priceObject._id}.${priceObject.description}`, o)
              renewedIds.push(o.renewedId)
            }
          }
          return r
        }, new Map())
      }

      //filter older subscription if person has a new sub
      const map = mapSubs(subscriptions)
      //console.log(map.values())
      this.subscriptionsList = Array.from(map.values());
      //console.log(this.subscriptionsList)
      // const fSubs = subscriptions.filter(s => s.personId === '6021cac3c22a3014f6f00e06')
      // console.log(fSubs)
      // console.log(mapSubs(fSubs))

      this.$store.commit('showMessage', {
        visible: false,
      })

      this.loading = false
    },
    filterInvoices() {
      const status = this.invoiceStatus
      const date = this.invoiceDate
      const items = this.invoicesByDateArray
        .find(i => i.key === date)
        .items.filter(item => {
          if (status === 'all' && item) return true
          if (status === 'non-payed' && item) return !item.payDate
          if (status === 'payed' && item) return item.payDate !== ''
          if (status === 'canceled' && item) return item.canceled
        })
      this.invoices = items.map(item => {
        let person
        person = this.personsList.find(p => p._id === item.payerId)
        const payer = {...person}
        if (person.type === 1)
          person = `${person.id} ${person.lastname} ${person.firstname}, ${person.postal_address}`
        if (person.type === 2)
          person = `${person.id} ${person.lastname}, ${person.postal_address}`
        return {
          ...item,
          person,
          payer,
          payerId: person.id,
        }
      })
    },
  },
  computed: {
    ...mapState('invoices', {loadingSubscriptions: 'isFindPending'}),
    ...mapGetters('persons', {personsList: 'list'}),
    ...mapState('persons', {loadingPersons: 'isFindPending'}),
    selectedSubscription() {
      if (this.selectedInvoice < 0)
        return null

      return this.filteredAddressFileList[this.selectedInvoice]
    },
    formatExtraMonth() {
      if (!this.extraMonth) return ''

      return [
        ...Array(+this.extraMonth)
          .fill(0)
          .map((_, i) =>
            this.formatDate(
              moment(this.sendDate)
                .utc()
                .add(-1 - i, 'months')
                .format('Y-MM-DD'),
              'Y-MM'
            )
          ),
      ].join(', ')
    },
    computedTitles() {
      const activeArticles = this.articlesList.filter(list =>
        this.selectedArticles.includes(list._id)
      )

      const titles = [
        'PrenId',
        'PersonId',
        'BetalarId',
        'FakturaId',
        'FakturapostId',
        'Förnamn',
        'Efternamn',
        'Careof',
        'Gatuadress',
        'Postnummer',
        'Postadress',
        'Land',
        ...activeArticles.map(article => article.name),
      ]

      return titles
    },
    downloadFileData() {
      /*ArtikelId," ArtikelNamn"," PersonId"," FakturaId"," FakturapostId"," Förnamn"," Efternamn"," Careof"," Gatuadress"," Postnummer"," Postadress"," Land"," Antal",Begrunda
       */

      /*
      const activeExtraArticles = this.nonSubscribableArticlesList
        .filter((list) => this.activeNonsubscribableArticles.map(_ => _._id).includes(list._id))
      const titles = [
        "ArtikelId",
        "ArtikelNamn",
        "PrenId",
        "PersonId",
        "FakturaId",
        "FakturapostId",
        "Förnamn",
        "Efternamn",
        "Careof",
        "Gatuadress",
        "Postnummer",
        "Postadress",
        "Land",
        "Antal",
        ...activeExtraArticles
          .map(article => article.name)
      ].join(',')



      const data = this.filteredAddressFileList.map(item => {
        return [
          item.articleObject.id,
          item.articleObject.name,
          item.id,
          item.personObject.id,
          item.invoiceObject.id,
          item.invoiceRowObject.id,
          item.personObject.firstname,
          item.personObject.lastname,
          item.personObject.careof,
          item.personObject.street_address,
          item.personObject.postal_number,
          item.personObject.postal_address,
          item.personObject.country,
          item.ex,
          ...activeExtraArticles.map((article) => {
            const subs = item.allsubs.filter(sub => sub.priceObject.articleId === article._id)
            return ''+ subs.reduce((a,b) => a + b.ex, 0)//
          })
        ].join(',')
      })*/

      const activeArticles = this.articlesList.filter(list =>
        this.selectedArticles.includes(list._id)
      )

      const titles = this.computedTitles

      const data = this.filteredAddressFileList
        .filter(o => o)
        .map(item => {
          return [
            (item !== undefined && item.subs[0].id) || '-',
            (item !== undefined && item.personObject.id) || '-',
            (item.subs[0].payerObject && item.subs[0].payerObject.id) || '',
            (item.subs[0].invoiceObject && item.subs[0].invoiceObject.id) || '–',
            (item.subs[0].invoiceRowObject && item.subs[0].invoiceRowObject.id) || '–',
            item.personObject.firstname,
            item.personObject.lastname,
            item.personObject.careof,
            item.personObject.street_address,
            item.personObject.postal_number,
            item.personObject.postal_address,
            item.personObject.country,
            ...activeArticles.map(article => {
              const subs = item.subs.find(
                sub => sub.articleObject._id === article._id && sub.subscriptionCount
              )
              return subs ? subs.subscriptionCount : ''
            }),
          ]
        })

      const autoSumRow = [
        ...new Array(12).fill(''),

        ...activeArticles.map((_, index) => {
          const letterACharCode = 65
          const startRangeLetterCharCode = letterACharCode + 12 + index
          const endRangeLetterCharCode = letterACharCode + 12 + index
          const startRangeRow = 2
          const endRangeRow = 2 + data.length - 1

          const startRange = `${String.fromCharCode(
            startRangeLetterCharCode
          )}${startRangeRow}`
          const endRange = `${String.fromCharCode(
            endRangeLetterCharCode
          )}${endRangeRow}`
          return `=SUMMA(${startRange}:${endRange})`
        }),
      ]

      return [titles, ...data, autoSumRow]
    },
    downloadFileDataCSV() {
      const data = this.downloadFileData
      return [data[0].join(','), ...data.slice(1).map(item => item.join(','))]
    },
    disableFetchButton() {
      return this.loading || !this.selectedPriceIds.length
    },
    subscribableArticlesList() {
      return this.articlesList.filter(s => s.subscribable)
    },
    nonSubscribableArticlesList() {
      return this.articlesList.filter(s => !s.subscribable)
    },
    selectedArticles() {
      return [
        ...this.activeArticles.map(a => a._id),
        ...this.activeNonsubscribableArticles.map(a => a._id),
      ].flat()
    },
    selectedPriceIds() {
      return [
        ...this.activeArticles.map(a => a.prices_ids),
        ...this.activeNonsubscribableArticles.map(a => a.prices_ids),
      ].flat()
    },
    personIds() {
      return [...new Set(this.subscriptionsList.map(o => o.personId))]
    },
    currentInvoice: {
      get() {
        return this.$store.state.currentInvoice
      },
      set(invoice) {
        this.$store.state.currentInvoice = invoice
      },
    },
    subscriptions() {
      const subscriptions = this.subscriptionsList

      return subscriptions
    },
    totalNumberOfCopiesToBeSent() {
      let count = 0

      this.filteredAddressFileList
        .filter(o => o)
        .forEach(item => {
          this.selectedArticles.map(articleId => {
            const subs = item.subs.find(sub => sub.articleObject._id === articleId)

            if (item && subs)
              count += subs.subscriptionCount
          })
        })

      return count
    },
    filteredAddressFileList() {
      if (!this.search) return this.addressFileList

      const search = this.search
        .toLowerCase()
        .split(' ')
        .filter(o => o)

      return this.addressFileList.filter(item => {
        let count = []
        Object.keys(item).forEach(key => {
          if (typeof item[key] === 'string' || typeof item[key] === 'number') {
            const string = '' + item[key]
            const index = search.findIndex(
              s =>
                string
                  .trim()
                  .toLowerCase()
                  .indexOf(s) > -1
            )

            if (index > -1 && !count.includes(index)) {
              count.push(index)
            }
          }
        })
        return count.length === search.length
      })
    },
    addressFileList() {
      let list = []

      // const unique = (arr, key) => arr.filter((item, i, s) => s.lastIndexOf(item) == i);

      /* const articleIds = this.articlesList
        .filter(a => a.subscribable)
        .map(a => {
          return this.pricesList.filter(price => price.articleId === a._id)
        })
        .flat()
        .map(a => a._id)

      const personIds = this.persons.map(p => p._id) */

      const getPersonsSubscriptions = personId => {
        let subs = this.subscriptions
          .filter(s => personId === s.personId)
          .map(item => {
            let priceObject
            let articleObject

            priceObject = this.pricesList.find(
              price => price._id === item.articleId
            )
            if (priceObject && this.articlesList)
              articleObject = this.articlesList.find(
                _article => _article._id === priceObject.articleId
              )

            let invoiceId, invoiceRowObject, invoiceObject
            invoiceRowObject = this.invoicesrows.find(
              invoicerow => invoicerow._id === item.invoiceRowId
            )
            if (invoiceRowObject) {
              invoiceId = invoiceRowObject.invoiceId
              invoiceObject = this.invoices.find(
                invoice => invoice._id === invoiceId
              )
            }

            let person, personObject
            person = this.persons.find(p => p._id === item.personId)
            personObject = _.cloneDeep(person)
            // const payer = { ...person }
            if (person) {
              if (person.type === 1)
                person = `${person.id} ${person.lastname} ${person.firstname}, ${person.postal_address}`
              if (person.type === 2)
                person = `${person.id} ${person.lastname}, ${person.postal_address}`
            } else {
              person = ''
            }

            let payerObject
            payerObject = this.persons.find(p => p._id === item.payerId)

            return {
              ...item,
              articleObject,
              priceObject,
              person,
              personObject,
              payerObject,
              invoiceObject,
              invoiceRowObject,
              invoiceId,
              ...personObject,
            }
          })

        subs = _.orderBy(
          subs,
          ['startDate'],
          ['desc']
        )

        return subs//_.uniqBy(subs, a => a.articleObject._id)
      }

      const personIds = [...new Set(this.persons.map(p => p._id))]

      /*const subscriptions = allsubs.filter(
              s => s.priceObject.articleId === sub.priceObject.articleId
            )*/

      list = personIds
        .map(personId => {
          let allsubs = getPersonsSubscriptions(personId)

          //allsubs = Array.from(allsubs.reverse().reduce((r, o) => r.set(o.priceObject.articleId, o), new Map()).values());

          /*allsubs = _.uniqWith(
            allsubs.reverse(),
            (a, b) => a.priceObject.articleId !== b.priceObject.articleId
          )*/

          let subs = allsubs.reduce((arr, sub) => {
            const index = arr.findIndex(s => s.articleObject._id === sub.articleObject._id)
            if (index > -1) {
              arr[index].subscriptionCount += sub.ex
            } else {
              const newSub = {
                articleObject: sub.articleObject,
                subscriptionCount: sub.ex
              }
              arr.push(newSub)
            }

            return arr
          }, [])

          let person, personObject
          person = this.persons.find(p => p._id === personId)
          personObject = _.cloneDeep(person)
          // const payer = { ...person }
          if (person) {
            if (person.type === 1)
              person = `${person.id} ${person.lastname} ${person.firstname}, ${person.postal_address}`
            if (person.type === 2)
              person = `${person.id} ${person.lastname}, ${person.postal_address}`
          } else {
            person = ''
          }
          return {
            person,
            personObject,
            subs,
            allsubs,
          }
        })
        .filter(o => o.subs.length)
        .filter(l => l.personObject && l.personObject.active)

      // console.log(articleIds)

      list = _.orderBy(
        list,
        ['person.type', 'person.lastname', 'person.firstname'],
        ['desc', 'asc', 'asc']
      )

      return list

      list = this.subscriptions
        /* .filter(
          s => personIds.includes(s.personId) && personIds.includes(s.payerId)
        )
        .filter(s => articleIds.includes(s.articleId)) */
        .map(item => {
          let priceObject
          let articleObject

          priceObject = this.pricesList.find(
            price => price._id === item.articleId
          )
          if (priceObject && this.articlesList)
            articleObject = this.articlesList.find(
              _article => _article._id === priceObject.articleId
            )

          let invoiceId, invoiceRowObject, invoiceObject
          invoiceRowObject = this.invoicesrows.find(
            invoicerow => invoicerow._id === item.invoiceRowId
          )
          if (invoiceRowObject) {
            invoiceId = invoiceRowObject.invoiceId
            invoiceObject = this.invoices.find(
              invoice => invoice._id === invoiceId
            )
          }

          let person, personObject
          person = this.persons.find(p => p._id === item.personId)
          personObject = _.cloneDeep(person)
          // const payer = { ...person }
          if (person) {
            if (person.type === 1)
              person = `${person.id} ${person.lastname} ${person.firstname}, ${person.postal_address}`
            if (person.type === 2)
              person = `${person.id} ${person.lastname}, ${person.postal_address}`
          } else {
            person = ''
          }

          let payerObject
          payerObject = this.persons.find(p => p._id === item.payerId)

          return {
            ...item,
            articleObject,
            priceObject,
            person,
            personObject,
            payerObject,
            invoiceObject,
            invoiceRowObject,
            invoiceId,
            ...personObject,
          }
        })

      list = list.filter(l => l.personObject && l.personObject.active)

      list = list.map(item => {
        //_.uniqWith(allsubs, o => o.articleObject._id && o.personId)
        const allsubs = this.subscriptions
          .filter(o => o.personId === item.personId)
          .map(sub => {
            let priceObject
            let articleObject

            priceObject = this.pricesList.find(
              price => price._id === sub.articleId
            )
            if (priceObject && this.articlesList)
              articleObject = this.articlesList.find(
                _article => _article._id === priceObject.articleId
              )

            return {
              ...sub,
              articleObject,
              priceObject,
            }
          })

        console.log(item, item.person)
        const subs = _.uniqWith(
          allsubs,
          (a, b) => a.articleObject._id !== b.articleObject._id
        ).map(sub => {
          const subscriptions = allsubs.filter(
            s => s.priceObject.articleId === sub.priceObject.articleId
          )
          const sum = subscriptions.reduce((a, b) => a + b.ex, 0) //
          return {
            ...sub,
            sum,
            subscriptions,
          }
        })

        return {
          ...item,
          subs,
          allsubs,
        }
      })

      list = _.orderBy(
        list,
        ['person.type', 'person.lastname', 'person.firstname'],
        ['desc', 'asc', 'asc']
      )

      return _.uniqBy(list, 'personId')
    },
  },
  watch: {
    subscriptions() {
      //console.log(subs.filter(s => s.personId === '6021cac3c22a3014f6f00e06'))
    },
    selectedInvoice(index) {
      this.currentInvoice = {...this.invoices[index]}
      //this.selectedInvoiceSheet = index > 0
    },
    selectedArticles() {
      this.invoicesrows = []
      this.invoices = []
      this.persons = []

      this.subscriptionsList = []
    },
  },
}
</script>
