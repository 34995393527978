// eslint-disable
import axios from 'axios'
import _ from 'lodash'
import countries from '@/json/countries.json'

import settings from '../../../config/settings'

const apiUrl = settings.billectaURL
//const apiUrl = 'https://apitest.billecta.com';

const headers = {
  Authorization: `Basic ${process.env.VUE_APP_BILLECTA_BASIC_TOKEN}`,
  Accept: 'application/json',
  token: localStorage.getItem('feathers-jwt'),
}

//console.log(headers)

const axiosInstance = axios.create({
  baseURL: apiUrl,
  timeout: 10000,
  withCredentials: false,
  headers,
})

const state = {
  list: [],
}

const getters = {
  DeptorView() {
    return {
      DebtorExternalId: null,
      OrgNo: null,
      Name: '',
      Attention: null,
      CareOf: null,
      Address: '',
      Address2: null,
      ZipCode: '',
      City: '',
      CountryCode: '',
      CitizenshipCountryCode: '',
      Phone: null,
      Email: '',
      ContactName: '',
      ContactEmail: '',
      VatNumber: '',
      DebtorNo: null,
      GLN: null,
      IsActive: null,
      ProtectedIdentity: false,
      DebtorType: 1,
      Intermediator: null,
      EInvoiceBank: null,
      Notes: null,
      DebtorSelfInvoiceInfo: null,
      DefaultActionConfig: null,
      Autogiro: {
        Active: false,
        AccountNo: '',
        ClearingNo: '',
        Stage: 1,
        PayerNumber: '',
        PaymentServiceSupplier: '',
        AutogiroFirstWithdrawalDate: '',
      },
      CreditCards: null,
      Created: '',
    }
  },
}

const actions = {
  async fetchDebtor(context, id) {
    const url = `/v1/debtors/debtor/${id}`
    const response = await axiosInstance.get(url)

    return response.data
  },
  async updateDebtor(context, debtor) {
    const url = `/v1/debtors/debtor/`
    const response = await axiosInstance.put(url, debtor)

    return response.data
  },
  async createDebtor(context, debtor) {
    const url = `/v1/debtors/debtor/`
    const response = await axiosInstance.post(url, debtor)

    return response.data
  },
  async deleteDebtor(context, id) {
    const url = `/v1/debtors/debtor/${id}`
    const response = await axiosInstance.delete(url)

    return response.data
  },

  async fetchDebtors(context, args) {
    /*
    {id}	Creditor public id (guid)
    {offset}	Offset (int) - optional
    {limit}	Limit (int) - optional
    {sortingfield}	Sorting field (string) - optional
    {asc}	Ascending sorting. Default value is true (bool) - optional
     */
    const string = _.map(args, (id, key) => id && `${key}=${id}`)
      .filter(Boolean)
      .join('&')
    //const url = `/v1/creditors/creditors?=offset${offset}&limit=${limit}&sortingfield=${sortingfield}&asc=${asc}`
    const url = `/v1/debtors/debtors/${args.id}?${string}`
    const response = await axiosInstance.get(url)

    context.commit('list', response.data)

    return response.data
  },

  localPersonToBillectaCustomer(context, data) {
    let DeptorView = _.cloneDeep(context.getters.DeptorView)

    // console.log(data)

    const mainCreditorPublicId =
      context.rootGetters['billecta_creditor/mainCreditorPublicId']

    // eslint-disable-next-line no-unused-vars
    const {
      id,
      firstname,
      lastname,
      careof,
      street_address,
      postal_number,
      postal_address,
      country,
      phone,
      // cell_phone,
      email_address,
      type,
    } = data

    let countryName = country
    if (!countryName) countryName = 'Sverige'

    const getCountryCode = (string = 'Sverige') => {
      // console.log('country', string)
      const country = countries.find(c => c.name == string)
      // console.log(country)
      if (country) return country.alpha2
    }
    const CountryCode = getCountryCode(countryName)

    let name = '',
      DebtorType = -1
    if (firstname || lastname) {
      if (type === 1) {
        name = [firstname, lastname].filter(o => o).join(' ')
        DebtorType = 0
      }
      if (type === 2) {
        name = [lastname].filter(o => o).join(' ')
        DebtorType = 1
      }
    }

    const newView = {
      DebtorPublicId: '8cc0f00c-3f85-446a-9663-6418a115c3f8',
      CreditorPublicId: mainCreditorPublicId,
      DebtorExternalId: null,
      OrgNo: null,
      Name: name || '',
      Attention: null,
      CareOf: careof || null,
      Address: street_address || '',
      Address2: null,
      ZipCode: postal_number || '',
      City: postal_address || '',
      CountryCode: (CountryCode || 'SE').toUpperCase(),
      CitizenshipCountryCode: (CountryCode || 'SE').toUpperCase(),
      Phone: phone || null,
      Email: email_address || '',
      ContactName: name || '',
      ContactEmail: email_address || '',
      VatNumber: '',
      DebtorNo: '' + id || '',
      GLN: null,
      IsActive: true,
      ProtectedIdentity: false,
      DebtorType: DebtorType,
      Intermediator: null,
      EInvoiceBank: null,
      Notes: null,
      DebtorSelfInvoiceInfo: null,
      DefaultActionConfig: null,
      Autogiro: {
        Active: false,
        AccountNo: '123456789',
        ClearingNo: '1234',
        Stage: 1,
        PayerNumber: '5568885645',
        PaymentServiceSupplier: 'SEB',
        AutogiroFirstWithdrawalDate: '2021-03-28T09:56:24.9250476+00:00',
      },
      CreditCards: null,
      Created: '0001-01-01T00:00:00',
    }

    DeptorView = {
      ...DeptorView,
      ...newView,
    }

    //console.log(DeptorView)

    return DeptorView
  },
}

const mutations = {
  list: (state, data) => (state.list = data),
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
