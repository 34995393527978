require('dotenv').config()

module.exports = {
  secret: 'lfSE54GyOoKnop_EDySJtP7KAhc4sF-s2KNsIXEt3-zVazl3',
  tokenLife: '7d',

  refreshTokenSecret:'WdZvVT8XZwRlqAEtdaD_O0c2AQCmfswjAns',
  refreshTokenLife: '7d',
  corsDomain: process.env.CORS_DOMAIN || '*',
  billectaURL:
    process.env.NODE_ENV === 'production'
      ? process.env.VUE_APP_BILLECTA_API_URI
      : process.env.VUE_APP_DEV_BILLECTA_API_URI,
  development: {
    frontend: {
      host: 'localhost',
      url: '0.0.0.0',
      port: 9999
    },
    backend: {
      host: 'https://api.prenumerationtillliv.se',
      host1: 'http://localhost:3040',
      port: 3040
    },
    fileUrl: 'http://localhost:3300'
  },
  production: {
    frontend: {
      host1: 'https://prenumerationtillliv.se',
      host: 'localhost',
      url: 'http://prenumerationtillliv.se',
      port: 9999
    },
    backend: {
      host: 'https://api.prenumerationtillliv.se',
      host1: 'http://localhost:3040',
      port: 80
    },
    backendlive: {
      url: 'https://api.prenumerationtillliv.se',
      host: 'http://localhost',
      port: 3040
    },
    fileUrl: 'https://prenumerationtillliv.se'
  }
};
