// eslint-disable
import axios from 'axios'
import _ from 'lodash'

// eslint-disable-next-line no-unused-vars
import settings from '../../../config/settings'

const apiUrl = settings.billectaURL
//const apiUrl = '/billecta';
//const apiUrl = 'https://apitest.billecta.com';

const headers = {
  Authorization: `Basic ${process.env.VUE_APP_BILLECTA_BASIC_TOKEN}`,
  Accept: 'application/json',
  token: localStorage.getItem('feathers-jwt'),
}



//console.log(headers)

const axiosInstance = axios.create({
  baseURL: apiUrl,
  timeout: 10000,
  withCredentials: false,
  headers,
})

//delete axiosInstance.defaults.headers.common['Access-Control-Allow-Origin'];

const state = {
  list: [],
  subs: [],
}

const getters = {
  mainCreditor: state => {
    if (state.list.length) return state.list[0]

    return null
  },
  mainCreditorPublicId: state => {
    if (state.subs.length) return state.subs[0].CreditorPublicId

    return ''
  },
}

const actions = {
  // eslint-disable-next-line no-unused-vars
  /* async auth(context, _) {
    //const url = `/v1/creditors/creditors?=offset${offset}&limit=${limit}&sortingfield=${sortingfield}&asc=${asc}`
    const url = `/v1/authentication/apiauthenticate`
    const response = await axiosInstance.get(url)

    context.commit('list', {})

    return response.data
  }, */
  async fetchCreditors(context, args) {
    const string = _.map(args, (id, key) => id && `${key}=${id}`)
      .filter(Boolean)
      .join('&')
    //const url = `/v1/creditors/creditors?=offset${offset}&limit=${limit}&sortingfield=${sortingfield}&asc=${asc}`
    const url = `/v1/creditors/creditors?${string}`
    const response = await axiosInstance.get(url)

    context.commit('list', response.data)

    return response.data
  },
  async fetchSubCreditors(context) {
    //const url = `/v1/creditors/creditors?=offset${offset}&limit=${limit}&sortingfield=${sortingfield}&asc=${asc}`
    const url = `/v1/creditors/creditorsubs`
    const response = await axiosInstance.get(url)

    context.commit('subs', response.data)

    return response.data
  },
}

const mutations = {
  list: (state, data) => (state.list = data),
  subs: (state, data) => (state.subs = data),
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
