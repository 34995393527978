import feathers from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import auth from '@feathersjs/authentication-client'
import io from 'socket.io-client'
import { iff, discard } from 'feathers-hooks-common'
import feathersVuex from 'feathers-vuex'

import settings from '../config/settings'

const host =
  process.env.NODE_ENV === 'production'
    ? settings.production.backend.host
    : settings.development.backend.host

const socket = io(host, { transports: ['websocket'] })

const feathersClient = feathers()
  .configure(
    socketio(socket, {
      timeout: 30000,
    })
  )
  .configure(auth({ storage: window.localStorage }))
  .hooks({
    before: {
      all: [
        iff(
          context => ['create', 'update', 'patch'].includes(context.method),
          discard('__id', '__isTemp')
        ),
      ],
    },
  })

export default feathersClient

// Setting up feathers-vuex
const {
  makeServicePlugin,
  makeAuthPlugin,
  BaseModel,
  models,
  FeathersVuex,
} = feathersVuex(feathersClient, {
  serverAlias: 'api', // optional for working with multiple APIs (this is the default value)
  idField: '_id', // Must match the id field in your database table/collection
  whitelist: ['$regex', '$options', '$exists', '$or', '$nin', '$in', '$not', '$ne', '$eq', '$aggregate', '$gt', '$lt', '$gte', '$lte'],
})

export { makeAuthPlugin, makeServicePlugin, BaseModel, models, FeathersVuex }
