<template>
  <v-container fluid class="login pa-0">
    <v-layout
      flex
      align-center
      justify-center
      class="loginOverlay"
      @click.self="$emit('loginOverlayClicked')"
    >
      <v-flex xs12 sm8 md4 ma-5 elevation-0>
        <v-container
          flex
          column
          align-center
          justify-center
          class="header-login pa-3"
        >
          <v-flex lg12 align-center>
            <v-toolbar-title style="color: rgb(128,113,93);"
              ><h4>Välkommen</h4></v-toolbar-title
            >
            <v-img
              src="/static/images/elida_square_cmyk.svg"
              height="120px"
              contain
              alt=""
            ></v-img>
          </v-flex>
        </v-container>
        <v-card>
          <v-card-text class="pt-4">
            <div v-if="$route.name === 'forgot'">
              <div v-if="showLoginButton">
                <v-layout justify-space-between>
                  <v-btn to="/login">Logga in</v-btn>
                </v-layout>
              </div>
              <v-form
                v-else-if="passwordTokenExists"
                v-model="valid"
                ref="form"
              >
                <v-text-field
                  v-model="password"
                  :append-icon="showIcon1 ? 'visibility_off' : 'visibility'"
                  @click:append="() => (showIcon1 = !showIcon1)"
                  :type="showIcon1 ? 'text' : 'password'"
                  label="Nytt lösenord"
                ></v-text-field>
                <v-text-field
                  v-model="password1"
                  :append-icon="showIcon2 ? 'visibility_off' : 'visibility'"
                  @click:append="() => (showIcon2 = !showIcon2)"
                  :type="showIcon2 ? 'text' : 'password'"
                  label="Uppera nytt lösenord"
                ></v-text-field>
                <v-layout justify-space-between>
                  <v-spacer />
                  <v-btn
                    @click="submitNewPassword"
                    :disabled="disableSubmit"
                    :class="{
                      'blue darken-4 white--text': valid,
                      disabled: !valid,
                    }"
                    >Skapa nytt löseord</v-btn
                  >
                </v-layout>
              </v-form>
              <div v-else>
                {{ message }}
              </div>
            </div>
            <div v-else>
              <v-form v-if="!forgot" v-model="valid" id="form" ref="form">
                <v-text-field
                  type="email"
                  id="email"
                  label="E-postadress"
                  placeholder="Fyll i din e-postadress"
                  @keyup.enter="submit"
                  v-model="email"
                  :rules="emailRules"
                  required
                />
                <v-text-field
                  label="Lösenord"
                  id="password"
                  placeholder="Fyll i ditt lösenord"
                  v-model="password"
                  min="8"
                  @keyup.enter="submit"
                  :append-icon="e1 ? 'mdi-eye-on' : 'mdi-eye-off'"
                  @click:append="() => (e1 = !e1)"
                  :type="e1 ? 'password' : 'text'"
                  :rules="passwordRules"
                  counter
                  required
                />
                <v-layout v-if="message" justify-space-between>
                  {{ message }}
                </v-layout>
                <v-layout v-if="error" justify-space-between>
                  {{ error.message }}
                </v-layout>
                <v-layout justify-space-between>
                  <v-btn text @click="forgot = true">Glömt lösenord</v-btn>
                  <v-btn
                    @click="submit"
                    id="submit"
                    :class="{
                      'blue darken-4 white--text': valid,
                      disabled: !valid,
                    }"
                    >Logga in</v-btn
                  >
                </v-layout>
              </v-form>
              <v-form v-else v-model="valid" ref="form">
                <v-text-field
                  type="email"
                  label="E-postadress"
                  placeholder="Fyll i din e-postadress"
                  v-model="email"
                  :rules="emailRules"
                  required
                />
                <v-layout v-if="message" justify-space-between>
                  {{ message }}
                </v-layout>
                <v-layout justify-space-between>
                  <v-btn text @click="forgot = false">Inloggning</v-btn>
                  <v-btn @click="submitForgot">Skicka</v-btn>
                </v-layout>
              </v-form>
            </div>
            <div
              v-if="msg.error"
              class="red--text subtitle font-weight-medium pa-2"
            >
              {{ msg.error }}
            </div>
            <div
              v-if="msg.success"
              class="green--text subtitle font-weight-medium pa-2"
            >
              {{ msg.success }}
            </div>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from 'axios'

import { mapState } from 'vuex'

export default {
  name: 'Login',
  components: {},
  data() {
    return {
      msg: {
        error: '',
        success: '',
      },
      message: '',
      forgot: false,
      valid: true,
      e1: true,
      error: null,
      disableSubmit: false,
      showLoginButton: false,
      passwordTokenExists: false,
      password: '',
      password1: '',
      showIcon1: false,
      showIcon2: false,
      passwordRules: [v => !!v || 'Lösenord är obligatoriskt'],
      email: '',
      emailRules: [
        v => !!v || 'E-postadress är obligatorisk   ',
        v =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          'E-postadressen är inte rätt formaterad',
      ],
    }
  },
  async created() {
    if (this.$route.name === 'forgot' && this.$route.params.token) {
      const token = this.$route.params.token

      try {
        let res = await axios.post(
          `${this.$store.state.apiURI}/auth/passwordtokencheck`,
          {
            token,
          }
        )

        // console.log(res)

        let success = res.data.success
        if (success) {
          this.passwordTokenExists = true
        } else {
          this.message = 'Det finns ingen användare med denna token.'
          this.passwordTokenExists = false
        }
      } catch (e) {
        // console.log(e)
        this.message = 'Det finns ingen användare med denna token.'
        this.passwordTokenExists = false
      }
    }
  },
  methods: {
    async submitNewPassword() {
      const validate = this.$refs.form.validate()
      if (validate) {
        //this.$refs.form.$el.submit()

        const { password } = this
        const token = this.$route.params.token

        try {
          let res = await axios.post(
            `${this.$store.state.apiURI}/auth/resetpassword`,
            {
              password,
              token,
            }
          )

          let success = res.data.success
          if (success) {
            this.message = 'Ditt nya lösenord har sparats.'
            this.showLoginButton = true
          } else {
            this.message = 'Det finns ingen användare med denna token.'
          }
        } catch (e) {
          console.log(e)
        }
      } else {
        console.log(validate)
      }
    },
    async submit() {
      const validate = this.$refs.form.validate()
      if (validate) {
        //this.$refs.form.$el.submit()

        this.disableSubmit = true

        const { email, password } = this

        this.$store
          .dispatch('auth/authenticate', { strategy: 'local', email, password })
          // Just use the returned error instead of mapping it from the store.
          .catch(err => {
            // Convert the error to a plain object and add a message.
            let type = err.className
            err = Object.assign({}, err)
            err.message =
              type === 'not-authenticated'
                ? 'Incorrect email or password.'
                : 'An error prevented login.'
            this.error = err
          })
      } else {
        console.log(validate)
      }
    },
    async submitForgot() {
      const validate = this.$refs.form.validate()
      if (validate) {
        //this.$refs.form.$el.submit()

        const { email } = this

        try {
          await axios.post(`${this.$store.state.apiURI}/auth/forgot`, {
            email,
          })

          this.message =
            'Förfrågan om nytt lösenord har skickats. Om ett konto om e-postadress finns så har det skickats ett mail dit med en länk för att nollställa ditt lösenord.'

          setTimeout(() => {
            this.message = ''
          }, 10000)
        } catch (e) {
          console.log(e)
        }
      } else {
        console.log(validate)
      }
    },
    clear() {
      this.$refs.form.reset()
    },
  },
  computed: {
    ...mapState(['rules']),
    passwordConfirmationRule() {
      return this.password === this.password1 || 'Lösenorden måste matcha'
    },
    backgroundStyle() {
      let style = {
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        backgroundColor: '#001527',
        background: 'url(https://source.unsplash.com/1920x1080/?sea&q=20)',
        backgroundBlendMode: 'luminosity',
      }

      return style
    },
    toolbarStyle() {
      return {
        justifyContent: 'space-between',
      }
    },
    users() {
      return this.$store.getters.users
    },
    userId() {
      if (!this.$route.params.userId) return null

      return this.$route.params.userId
    },
  },
}
</script>

<style scoped>
body {
  font-family: 'Roboto', sans-serif;
}
#app {
  background-image: url('https://images.unsplash.com/photo-1497733942558-e74c87ef89db?dpr=1&auto=compress,format&fit=crop&w=1650&h=&q=80&cs=tinysrgb&crop=');
  background-size: cover;
  overflow: hidden;
  background-color: #001527;
  background-position: center;
  background-blend-mode: luminosity;
}
.login {
  display: flex;
  background: url(//unsplash.it/1680/945/?random);
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-color: #001527;
  background-position: center;
  background-blend-mode: luminosity;
}
.header-login {
  background: black;
  display: flex;
  flex-flow: column;
}
.loginOverlay {
  width: 100%;
}
.photoCredit {
  position: absolute;
  bottom: 15px;
  right: 15px;
}
.align-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
</style>
