<template>
  <v-main class="grey lighten-3">
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-card>
            <Users />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import Users from '@/components/Users'
export default {
  components: { Users },
  data: () => ({}),
}
</script>
