import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '../../feathers-client'

class Subscription extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'Subscription'
  // Define default properties here
  static instanceDefaults() {
    return {
      billecta_id: '',
      articleId: '',
      personId: '',
      payerId: '',
      invoiceRowId: '',
      pdf: false,
      ex: 1,
      price: 0,
      startDate: '',
      endDate: '',
      payedDatum: '',
      renewed: false,
      renewAuto: false,
      renewedId: '',
      extraNumber: false,
      status: 'active',
    }
  }
}
const servicePath = 'subscriptions'
const servicePlugin = makeServicePlugin({
  Model: Subscription,
  service: feathersClient.service(servicePath),
  servicePath,
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
})

export default servicePlugin
