<template>
  <svg
    v-if="localInvoice"
    version="1.2"
    :style="{ scale }"
    id="invoice-svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 793.8 1122.96"
    enable-background="new 0 0 793.8 1122.96"
    xml:space="preserve"
  >
    <rect
      v-if="0"
      width="793.8"
      height="1122.96"
      style="fill:rgba(0,0,255, 0);stroke-width:1;stroke:rgb(0,0,0)"
    />

    <image
      href="@/assets/images/TillLivloggasvart.svg"
      :height="logoStyle.height"
      :width="logoStyle.width"
      :x="logoStyle.x"
      :y="logoStyle.y"
    />

    <text
      :x="returnAddressStyle.x"
      :y="returnAddressStyle.y"
      :style="returnAddressStyle"
    >
      <tspan
        v-for="(text, index) in [
          'Returadress:',
          'Rebecka Carlsson, Hanaskogsvägen 590, 291 95 Färlöv',
        ]"
        :key="index"
        :dy="index * 12"
        :x="mmToPx(18)"
      >
        {{ text }}
      </tspan>
    </text>

    <text
      :x="invoiceTitleStyle.x"
      :y="invoiceTitleStyle.y"
      :style="invoiceTitleStyle"
    >
      FAKTURA {{ localInvoice.id || 'x' }}
    </text>

    <text
      :x="invoiceDatesStyle.x"
      :y="invoiceDatesStyle.y"
      :style="invoiceDatesStyle"
    >
      <tspan
        v-for="(text, index) in [
          'Fakturadatum',
          localInvoice.dispatchDate,
          '&#160;',
          'Plusgiro',
          '607 98 94-9',
        ]"
        :key="index"
        :dy="14"
        :x="invoiceDatesStyle.x"
        v-html="text"
      >

      </tspan>
    </text>

    <text :x="dueDatesStyle.x" :y="dueDatesStyle.y" :style="dueDatesStyle">
      <tspan
        v-for="(text, index) in ['Förfallodatum', localInvoice.dueDate]"
        :key="index"
        :dy="14"
        :x="dueDatesStyle.x"
      >
        {{ text }}
      </tspan>
    </text>

    <text
      :x="contactDataStyle.x"
      :y="contactDataStyle.y"
      :style="contactDataStyle"
    >
      <tspan
        v-for="(text, index) in [
          'Till Liv, Sandgatan 14b, 223 50 Lund',
          'Org.nr: 802403-0705',
        ]"
        :key="index"
        :dy="14"
        :x="contactDataStyle.x"
      >
        {{ text }}
      </tspan>
    </text>

    <text :x="ibanDataStyle.x" :y="ibanDataStyle.y" :style="ibanDataStyle">
      <tspan
        v-for="(text, index) in [
          'IBAN: SE33 9500 0099 6026 6079 8949',
          'BIC: NDEASESS',
        ]"
        :key="index"
        :dy="14"
        :x="ibanDataStyle.x"
      >
        {{ text }}
      </tspan>
    </text>

    <text
      :x="personBlockStyle.x"
      :y="personBlockStyle.y"
      :style="personBlockStyle.style"
    >
      <template v-for="(subrow, subindex) in nameRows">
        <tspan
          :x="personBlockStyle.x"
          :dy="subindex ? 20 : 0"
          :key="subindex"
          v-html="subrow"
        ></tspan>
      </template>
    </text>

    <rect
      :x="invoiceRowsRectStyle.x"
      :y="invoiceRowsRectStyle.y"
      :style="invoiceRowsRectStyle.style"
    />

    <template v-for="(row, index) in invoiceRows">
      <text
        :x="invoiceRowsStyle.x + 10"
        :y="
          invoiceRowsStyle.y +
            25 +
            (index ? index * 5 : 0) +
            (row.rowsBeforeLength - row.rowsLength) * 20
        "
        :key="index"
        :style="invoiceRowsStyle.style"
      >
        <tspan
          v-if="row.quantity !== null"
          :x="invoiceRowsStyle.x + 560"
          dy="0"
          v-html="row.quantity"
          text-anchor="end"
        ></tspan>
        <tspan
          v-if="row.price !== null"
          :x="invoiceRowsStyle.x + 635"
          dy="0"
          v-html="row.price"
          text-anchor="end"
        ></tspan>
        <template v-for="(subrow, subindex) in row.rows">
          <tspan
            :x="invoiceRowsStyle.x + 10"
            :dy="subindex * 20"
            :key="subindex"
            v-html="subrow"
          ></tspan>
        </template>
      </text>
    </template>

    <text
      :x="invoiceRowsStyle.x + 635"
      :y="
        invoiceRowsStyle.y +
          25 +
          invoiceRows.length * 20 +
          invoiceTotalRows * 20
      "
      :style="invoiceRowsStyle.style"
      text-anchor="end"
    >
      SUMMA: {{ invoiceTotal }}
    </text>

    <text
      v-if="invoice.infoText"
      :x="invoiceRowsStyle.x + 10"
      :y="
        invoiceRowsStyle.y +
          25 +
          invoiceRows.length * 20 +
          (invoiceTotalRows + 1.5) * 20
      "
      :style="invoiceRowsStyle.style"
    >
      <template
        v-for="(subrow, subindex) in (invoice.infoText || '').split('\n')"
      >
        <tspan
          :x="invoiceRowsStyle.x + 10"
          :dy="20"
          :key="subindex"
          v-html="subrow"
        ></tspan>
      </template>
    </text>

    <text
      :x="paymentCardCustomerNumberStyle.x"
      :y="paymentCardCustomerNumberStyle.y"
      :style="paymentCardCustomerNumberStyle.style"
    >
      <tspan :x="paymentCardCustomerNumberStyle.x" dy="0">
        Kundnummer: {{ localInvoice.payer ? localInvoice.payer.id : '' }}
      </tspan>
      <tspan :x="paymentCardCustomerNumberStyle.x" dy="25">
        Fakturanummer:
        {{ localInvoice ? localInvoice.id : 'x' }}
      </tspan>
    </text>

    <text
      :x="paymentCardPlusgiroNumberStyle.x"
      :y="paymentCardPlusgiroNumberStyle.y"
      :style="paymentCardPlusgiroNumberStyle.style"
    >
      607 98 94-9
    </text>

    <text
      :x="paymentCardTilllivTitleStyle.x"
      :y="paymentCardTilllivTitleStyle.y"
      :style="paymentCardTilllivTitleStyle.style"
    >
      Till Liv
    </text>

    <text
      :x="paymentCardPersonBlockStyle.x"
      :y="paymentCardPersonBlockStyle.y"
      :style="paymentCardPersonBlockStyle.style"
      alignment-baseline="middle"
    >
      <template v-for="(subrow, subindex) in nameRows">
        <tspan
          :x="paymentCardPersonBlockStyle.x"
          :dy="subindex ? 17 : 0"
          :key="subindex"
          v-html="subrow"
        ></tspan>
      </template>
    </text>

    <text
      v-if="invoice.canceled"
      :x="canceledStyle.x"
      :y="canceledStyle.y"
      :style="canceledStyle.style"
      text-anchor="middle"
      dominant-baseline="central"
    >
      Makulerad
    </text>

    <text
      v-if="attachment"
      :x="attachmentStyle.x"
      :y="attachmentStyle.y"
      :style="attachmentStyle.style"
      text-anchor="middle"
      dominant-baseline="central"
    >
      {{ invoice.id }}
    </text>

    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="11px"
      y="752.96px"
      width="793.8"
      height="374.32"
      viewBox="0 0 960 462.635"
      enable-background="new 0 0 960 462.635"
      xml:space="preserve"
    >
      <path
        fill="#010202"
        d="M70.008,24.779c0,6.64-5.383,9.831-12.022,9.831c-6.638,0-12.022-3.191-12.022-9.831
      c0-6.639,5.383-9.836,12.022-9.836C64.624,14.945,70.008,18.14,70.008,24.779"
      />
      <path
        fill="#FFFFFF"
        d="M56.307,24.055c-0.494,0.334-1.145,0.334-1.676,0.334h-4.33v-3.387h4.183c0.744,0,1.552,0.013,2.087,0.543
      c0.384,0.386,0.437,0.848,0.437,1.14C57.008,23.326,56.702,23.805,56.307,24.055 M62.174,24.389v1.127h4.132
      c-0.079,0.548-0.193,1.024-0.742,1.622c-0.886,0.961-2.207,1.235-3.095,1.235c-0.613,0-2.579-0.135-3.598-1.711
      c-0.279-0.426-0.639-1.209-0.639-2.182c0-2.364,1.964-3.664,4.274-3.664c1.077,0,2.166,0.334,2.964,1.024
      c0.292,0.255,0.424,0.449,0.598,0.707l1.313-0.4c-0.2-0.331-0.398-0.707-0.916-1.142c-0.531-0.448-1.896-1.342-4.064-1.342
      c-1.85,0-3.371,0.686-4.324,1.667c-0.135-0.235-0.308-0.459-0.537-0.656c-0.714-0.613-1.617-0.786-2.853-0.786h-5.725v9.376h1.354
      v-3.744h4.502c0.592,0,1.356-0.008,2.043-0.282c0.324,2.371,2.35,4.254,5.314,4.254c1.595,0,2.896-0.516,3.574-1.039
      c0.198-0.16,0.345-0.306,0.518-0.463v1.286h1.155v-4.883L62.174,24.389L62.174,24.389z"
      />
      <path
        fill="#010202"
        d="M90.332,19.733c-0.898-1.127-2.345-1.15-3.656-1.15h-7.399v5.862h7.493c1.448,0,2.804-0.043,3.678-1.357
      c0.344-0.507,0.438-1.055,0.438-1.679C90.883,20.789,90.722,20.215,90.332,19.733 M91.685,25.272
      c-1.31,1.055-2.987,1.102-4.595,1.102h-7.791v6.504h-2.345V16.653h10.157c2.069,0,3.955,0.274,5.332,2.025
      c0.623,0.777,0.943,1.924,0.943,2.919C93.388,23.062,92.86,24.329,91.685,25.272z"
      />
      <rect x="96.1" y="16.653" fill="#010202" width="2.159" height="16.227" />
      <path
        fill="#010202"
        d="M113.161,32.878v-1.426c-1.54,1.311-2.851,1.814-4.898,1.814c-2.067,0-4.688-0.595-5.652-2.643
      c-0.392-0.829-0.438-1.839-0.438-2.756v-6.209h2.161v5.653c0,0.669,0,1.498,0.255,2.117c0.621,1.513,2.415,1.906,3.885,1.906
      c1.472,0,3.606-0.595,4.343-2.022c0.251-0.501,0.298-1.011,0.298-1.56v-6.071h2.184V32.87h-2.137"
      />
      <path
        fill="#010202"
        d="M125.917,33.294c-2.595,0-5.677-0.527-7.675-2.347l1.882-1.264c1.587,1.31,3.632,1.771,5.657,1.771
      c1.124,0,3.998-0.158,3.998-1.787c0-3.108-10.481,0.276-10.481-4.874c0-3.125,3.837-3.562,6.208-3.562
      c1.882,0,3.652,0.3,5.309,1.223c0.46,0.248,0.87,0.503,1.242,0.869l-1.911,1.055c-1.401-0.942-3.058-1.31-4.735-1.31
      c-1.011,0-3.701,0.095-3.701,1.54c0,0.418,0.298,0.711,0.623,0.919c0.78,0.482,5.354,0.667,6.643,0.848
      c1.747,0.276,3.124,1.174,3.124,3.103C132.098,32.647,128.4,33.294,125.917,33.294"
      />
      <path
        fill="#010202"
        d="M151.465,32.878v-2.232c-1.863,1.887-4.48,2.598-7.08,2.598c-1.492,0-3.011-0.275-4.365-0.895
      c-3.035-1.404-4.919-4.273-4.919-7.628c0-5.423,4.688-8.46,9.676-8.46c2.596,0,5.885,0.873,7.654,2.895
      c0.39,0.439,0.666,0.876,0.942,1.379l-2.251,0.69c-1.308-2.046-3.815-2.988-6.159-2.988c-3.864,0-7.401,2.161-7.401,6.364
      c0,0.872,0.162,1.771,0.506,2.577c1.218,2.873,3.816,4.135,6.803,4.135c2.001,0,4.067-0.71,5.449-2.205
      c0.298-0.323,0.598-0.737,0.78-1.15c0.255-0.555,0.345-1.106,0.437-1.705h-7.149v-1.927h9.08v8.549L151.465,32.878"
      />
      <path
        fill="#010202"
        d="M159.94,32.878h-2.184V21.637h2.184V32.878z M159.895,19.778h-2.161v-2.205h2.161V19.778z"
      />
      <path
        fill="#010202"
        d="M170.011,23.411c-1.542,0-3.151,0.457-3.701,2.048c-0.162,0.437-0.162,0.892-0.162,1.354v6.067h-2.184
      V21.642h2.16v1.286c0.184-0.158,0.369-0.344,0.574-0.479c0.987-0.737,2.504-1.106,3.722-1.106c0.463,0,0.876,0.068,1.337,0.182
      v2.139C171.184,23.499,170.608,23.411,170.011,23.411"
      />
      <path
        fill="#010202"
        d="M179.927,23.179c-2.549,0-5.077,1.31-5.077,4.161s2.756,4.069,5.241,4.069c2.435,0,5.012-1.403,5.012-4.117
      C185.103,24.422,182.456,23.179,179.927,23.179 M179.997,33.225c-3.586,0-7.354-1.637-7.354-5.746c0-3.998,3.561-6.088,7.192-6.088
      c3.7,0,7.444,1.722,7.444,5.906C187.281,31.41,183.63,33.225,179.997,33.225z"
      />
      <path
        fill="#010202"
        d="M193.029,23.131v6.186c0,1.219,0.205,1.792,1.564,1.792c0.527,0,1.056-0.069,1.583-0.165v1.93
      c-0.713,0.094-1.446,0.163-2.163,0.163c-2.25,0-3.124-0.739-3.124-2.922v-6.986h-1.908v-1.494h1.957v-3.864h2.158v3.864h3.127v1.494
      h-3.195"
      />
      <g enable-background="new">
        <path fill="#010202" d="M515.871,30.399h-2.64V17.682h2.64V30.399z" />
        <path
          fill="#010202"
          d="M518.311,17.682h2.787l5.048,8.866v-8.866h2.477v12.717h-2.655l-5.179-9.024v9.024h-2.476V17.682H518.311z"
        />
        <path
          fill="#010202"
          d="M540.6,19.036c0.391,0.54,0.586,1.187,0.586,1.942c0,0.775-0.198,1.399-0.589,1.871
        c-0.221,0.266-0.545,0.506-0.971,0.726c0.648,0.235,1.137,0.609,1.468,1.121c0.33,0.511,0.496,1.132,0.496,1.863
        c0,0.755-0.188,1.431-0.565,2.029c-0.242,0.397-0.542,0.729-0.901,1c-0.407,0.31-0.886,0.522-1.439,0.639
        c-0.553,0.116-1.151,0.172-1.796,0.172h-5.73V17.68h6.146C538.855,17.706,539.953,18.156,540.6,19.036z M533.696,19.891v2.804h3.09
        c0.555,0,1.001-0.105,1.345-0.314c0.347-0.209,0.516-0.582,0.516-1.118c0-0.592-0.227-0.982-0.682-1.174
        c-0.392-0.132-0.894-0.198-1.505-0.198H533.696z M533.696,24.8v3.392h3.086c0.552,0,0.982-0.076,1.289-0.226
        c0.558-0.275,0.837-0.805,0.837-1.586c0-0.662-0.27-1.115-0.812-1.363c-0.301-0.139-0.724-0.211-1.269-0.216H533.696z"
        />
        <path
          fill="#010202"
          d="M553.28,19.935h-6.729v2.701h6.178v2.208h-6.178v3.269h7.039v2.287h-9.638V17.682h9.326v2.253H553.28z"
        />
      </g>
      <g enable-background="new">
        <path
          fill="#010202"
          d="M564.899,17.682v2.251h-3.804v10.465h-2.674V19.933H554.6v-2.251H564.899z"
        />
      </g>
      <g enable-background="new">
        <path
          fill="#010202"
          d="M568.535,17.682h3.006l4.499,12.717h-2.882l-0.84-2.614h-4.682l-0.863,2.614h-2.78L568.535,17.682z
         M568.359,25.595h3.257l-1.605-5.005L568.359,25.595z"
        />
        <path
          fill="#010202"
          d="M577.658,17.682h2.656v10.431h6.351v2.287h-9.007V17.682z"
        />
        <path
          fill="#010202"
          d="M588.408,17.682h2.786l5.05,8.866v-8.866h2.476v12.717h-2.656l-5.177-9.024v9.024h-2.478L588.408,17.682
        L588.408,17.682z"
        />
        <path fill="#010202" d="M603.637,30.399h-2.641V17.682h2.641V30.399z" />
        <path
          fill="#010202"
          d="M606.076,17.682h2.789l5.047,8.866v-8.866h2.478v12.717h-2.657l-5.177-9.024v9.024h-2.478V17.682H606.076z"
        />
      </g>
      <g enable-background="new">
        <path
          fill="#010202"
          d="M627.434,21.65c-0.202-0.868-0.693-1.476-1.476-1.821c-0.438-0.188-0.925-0.286-1.458-0.286
        c-1.024,0-1.866,0.386-2.523,1.16c-0.658,0.772-0.986,1.932-0.986,3.482c0,1.562,0.356,2.667,1.068,3.315
        c0.714,0.648,1.523,0.974,2.435,0.974c0.893,0,1.623-0.255,2.19-0.768s0.921-1.181,1.053-2.011h-2.941v-2.121h5.296v6.823h-1.759
        l-0.268-1.587c-0.514,0.602-0.975,1.026-1.381,1.271c-0.701,0.431-1.564,0.644-2.587,0.644c-1.686,0-3.065-0.582-4.142-1.75
        c-1.12-1.174-1.682-2.777-1.682-4.811c0-2.058,0.567-3.708,1.699-4.949c1.133-1.242,2.633-1.861,4.495-1.861
        c1.617,0,2.916,0.409,3.895,1.229c0.98,0.821,1.543,1.843,1.688,3.067h-2.617V21.65z"
        />
        <path
          fill="#010202"
          d="M635.24,17.293h2.048l-4.95,13.106h-2.029L635.24,17.293z"
        />
      </g>
      <g enable-background="new">
        <path
          fill="#010202"
          d="M648.914,21.65c-0.201-0.868-0.693-1.476-1.476-1.821c-0.437-0.188-0.924-0.286-1.458-0.286
        c-1.023,0-1.865,0.386-2.523,1.16c-0.658,0.772-0.985,1.932-0.985,3.482c0,1.562,0.356,2.667,1.067,3.315
        c0.715,0.648,1.524,0.974,2.436,0.974c0.892,0,1.622-0.255,2.189-0.768c0.568-0.513,0.921-1.181,1.054-2.011h-2.941v-2.121h5.296
        v6.823h-1.76l-0.268-1.587c-0.513,0.602-0.974,1.026-1.381,1.271c-0.701,0.431-1.563,0.644-2.586,0.644
        c-1.686,0-3.066-0.582-4.142-1.75c-1.121-1.174-1.683-2.777-1.683-4.811c0-2.058,0.568-3.708,1.7-4.949s2.632-1.861,4.494-1.861
        c1.618,0,2.916,0.409,3.896,1.229c0.98,0.821,1.543,1.843,1.688,3.067h-2.618V21.65z"
        />
        <path fill="#010202" d="M656.531,30.399h-2.642V17.682h2.642V30.399z" />
        <path
          fill="#010202"
          d="M667.167,18.01c0.468,0.202,0.864,0.499,1.19,0.889c0.268,0.323,0.481,0.679,0.638,1.071
        c0.157,0.391,0.236,0.837,0.236,1.337c0,0.603-0.153,1.197-0.458,1.781c-0.304,0.583-0.807,0.997-1.51,1.238
        c0.585,0.235,1.001,0.571,1.246,1.005c0.242,0.436,0.366,1.098,0.366,1.988v0.855c0,0.581,0.024,0.974,0.071,1.18
        c0.071,0.328,0.233,0.571,0.49,0.724v0.319h-2.932c-0.082-0.282-0.139-0.508-0.173-0.682c-0.069-0.356-0.106-0.721-0.111-1.097
        l-0.018-1.181c-0.012-0.811-0.151-1.351-0.421-1.622s-0.772-0.405-1.513-0.405h-2.595v4.986h-2.597V17.68h6.083
        C666.029,17.699,666.697,17.809,667.167,18.01z M661.674,19.891v3.417h2.859c0.567,0,0.993-0.069,1.279-0.208
        c0.501-0.24,0.753-0.718,0.753-1.432c0-0.771-0.242-1.289-0.729-1.553c-0.272-0.15-0.682-0.226-1.23-0.226h-2.932V19.891z"
        />
        <path
          fill="#010202"
          d="M681.181,19.935h-6.728v2.701h6.177v2.208h-6.177v3.269h7.039v2.287h-9.639V17.682h9.326L681.181,19.935
        L681.181,19.935z"
        />
        <path
          fill="#010202"
          d="M691.714,18.01c0.468,0.202,0.864,0.499,1.189,0.889c0.268,0.323,0.482,0.679,0.639,1.071
        c0.156,0.391,0.235,0.837,0.235,1.337c0,0.603-0.152,1.197-0.458,1.781c-0.303,0.583-0.806,0.997-1.509,1.238
        c0.585,0.235,1.001,0.571,1.246,1.005c0.242,0.436,0.366,1.098,0.366,1.988v0.855c0,0.581,0.024,0.974,0.071,1.18
        c0.07,0.328,0.233,0.571,0.49,0.724v0.319h-2.932c-0.083-0.282-0.139-0.508-0.173-0.682c-0.069-0.356-0.106-0.721-0.111-1.097
        l-0.018-1.181c-0.012-0.811-0.151-1.351-0.421-1.622s-0.772-0.405-1.513-0.405h-2.595v4.986h-2.597V17.68h6.083
        C690.576,17.699,691.244,17.809,691.714,18.01z M686.221,19.891v3.417h2.859c0.567,0,0.993-0.069,1.278-0.208
        c0.502-0.24,0.754-0.718,0.754-1.432c0-0.771-0.242-1.289-0.729-1.553c-0.272-0.15-0.682-0.226-1.23-0.226h-2.932V19.891z"
        />
        <path fill="#010202" d="M698.746,30.399h-2.64V17.682h2.64V30.399z" />
        <path
          fill="#010202"
          d="M701.188,17.682h2.789l5.048,8.866v-8.866h2.477v12.717h-2.657l-5.177-9.024v9.024h-2.478V17.682H701.188z"
        />
        <path
          fill="#010202"
          d="M722.543,21.65c-0.201-0.868-0.693-1.476-1.476-1.821c-0.437-0.188-0.924-0.286-1.458-0.286
        c-1.023,0-1.865,0.386-2.523,1.16c-0.658,0.772-0.985,1.932-0.985,3.482c0,1.562,0.356,2.667,1.067,3.315
        c0.715,0.648,1.524,0.974,2.436,0.974c0.892,0,1.622-0.255,2.189-0.768c0.568-0.513,0.921-1.181,1.054-2.011h-2.943v-2.121h5.296
        v6.823h-1.76l-0.268-1.587c-0.513,0.602-0.974,1.026-1.38,1.271c-0.702,0.431-1.564,0.644-2.587,0.644
        c-1.686,0-3.065-0.582-4.142-1.75c-1.121-1.174-1.682-2.777-1.682-4.811c0-2.058,0.567-3.708,1.699-4.949s2.632-1.861,4.495-1.861
        c1.617,0,2.915,0.409,3.895,1.229c0.98,0.821,1.543,1.843,1.688,3.067h-2.616V21.65z"
        />
        <path
          fill="#010202"
          d="M740.544,17.682h3.006l4.5,12.717h-2.882l-0.841-2.614h-4.682l-0.862,2.614h-2.78L740.544,17.682z
         M740.368,25.595h3.258l-1.606-5.005L740.368,25.595z"
        />
      </g>
      <g enable-background="new">
        <path
          fill="#010202"
          d="M888.676,20.826h1.295v4.794l4.899-4.794h1.853l-4.183,3.953l4.271,5.841h-1.737l-3.51-4.922l-1.595,1.509
        v3.413h-1.295v-9.794H888.676z"
        />
      </g>
      <g enable-background="new">
        <path
          fill="#010202"
          d="M902.235,24.193c0.63,0.613,0.946,1.513,0.946,2.705c0,1.15-0.279,2.102-0.835,2.851
        c-0.557,0.751-1.42,1.126-2.587,1.126c-0.976,0-1.752-0.333-2.324-0.995c-0.572-0.665-0.859-1.556-0.859-2.675
        c0-1.198,0.303-2.153,0.906-2.863s1.416-1.066,2.436-1.066C900.83,23.272,901.603,23.581,902.235,24.193z M901.488,28.938
        c0.294-0.602,0.438-1.271,0.438-2.008c0-0.666-0.104-1.208-0.315-1.625c-0.334-0.656-0.91-0.984-1.726-0.984
        c-0.726,0-1.253,0.279-1.582,0.839c-0.329,0.561-0.495,1.235-0.495,2.025c0,0.76,0.166,1.39,0.495,1.898
        c0.328,0.505,0.852,0.76,1.568,0.76C900.656,29.839,901.196,29.54,901.488,28.938z"
        />
        <path
          fill="#010202"
          d="M908.578,23.7c0.213,0.134,0.454,0.368,0.724,0.7v-3.606h1.153v9.828h-1.079v-0.993
        c-0.28,0.44-0.609,0.758-0.99,0.955c-0.381,0.195-0.817,0.293-1.31,0.293c-0.792,0-1.477-0.334-2.058-1.003
        c-0.581-0.669-0.871-1.56-0.871-2.671c0-1.04,0.265-1.94,0.794-2.703c0.528-0.763,1.285-1.144,2.268-1.144
        C907.754,23.353,908.21,23.468,908.578,23.7z M905.897,29.053c0.324,0.516,0.84,0.772,1.553,0.772c0.554,0,1.009-0.237,1.364-0.716
        c0.356-0.477,0.533-1.164,0.533-2.056c0-0.903-0.183-1.571-0.551-2.003c-0.367-0.432-0.82-0.65-1.359-0.65
        c-0.602,0-1.09,0.232-1.464,0.695c-0.371,0.461-0.56,1.142-0.56,2.04C905.415,27.896,905.576,28.538,905.897,29.053z"
        />
        <path
          fill="#010202"
          d="M916.469,23.86v-0.921c0.866-0.084,1.471-0.226,1.812-0.423c0.342-0.198,0.597-0.666,0.768-1.404h0.947
        v9.508h-1.281v-6.759h-2.246V23.86z"
        />
      </g>
      <g enable-background="new">
        <path
          fill="#010202"
          d="M30.088,204.621c-0.401,0.331-0.89,0.495-1.464,0.495v-0.811c0.4-0.034,0.69-0.108,0.871-0.226
        c0.324-0.202,0.488-0.564,0.488-1.09c0-0.41-0.109-0.737-0.329-0.984c-0.218-0.247-0.5-0.369-0.844-0.369
        c-0.427,0-0.724,0.129-0.894,0.389c-0.169,0.26-0.254,0.623-0.254,1.085c0,0.052,0,0.103,0.001,0.158
        c0.001,0.055,0.005,0.106,0.006,0.163h-0.686c0.008-0.081,0.015-0.15,0.018-0.203c0.003-0.053,0.005-0.113,0.005-0.176
        c0-0.291-0.047-0.529-0.139-0.716c-0.162-0.327-0.449-0.492-0.863-0.492c-0.307,0-0.545,0.108-0.711,0.328
        c-0.166,0.219-0.25,0.474-0.25,0.764c0,0.516,0.173,0.875,0.518,1.074c0.189,0.108,0.46,0.169,0.812,0.186v0.768
        c-0.46,0-0.852-0.092-1.174-0.276c-0.574-0.316-0.863-0.873-0.863-1.669c0-0.631,0.141-1.118,0.421-1.463
        c0.281-0.345,0.687-0.518,1.219-0.518c0.379,0,0.687,0.103,0.922,0.306c0.147,0.127,0.261,0.292,0.345,0.494
        c0.09-0.326,0.261-0.579,0.516-0.763c0.253-0.183,0.566-0.274,0.934-0.274c0.589,0,1.069,0.193,1.44,0.582
        c0.371,0.387,0.558,0.939,0.558,1.651C30.689,203.761,30.488,204.292,30.088,204.621z"
        />
        <path
          fill="#010202"
          d="M30.52,197.481h-1.511v2.709H28.25l-3.925-2.832v-0.655h4.006v-0.91h0.677v0.91h1.511v0.777H30.52z
         M28.333,197.493h-2.805l2.805,1.98V197.493z"
        />
        <path
          fill="#010202"
          d="M26.146,194.654H25.55c-0.053-0.561-0.145-0.953-0.272-1.174c-0.129-0.221-0.432-0.387-0.908-0.497v-0.613
        h6.151v0.829h-4.374V194.654z"
        />
        <path
          fill="#010202"
          d="M26.146,167.65H25.55c-0.053-0.561-0.145-0.951-0.272-1.174c-0.129-0.223-0.432-0.386-0.908-0.497v-0.613
        h6.151v0.829h-4.374V167.65z"
        />
        <path
          fill="#010202"
          d="M28.946,162.489c0.442-0.051,0.75-0.258,0.919-0.618c0.085-0.182,0.129-0.396,0.129-0.639
        c0-0.459-0.146-0.801-0.44-1.022c-0.293-0.222-0.618-0.332-0.974-0.332c-0.432,0-0.765,0.131-1,0.395
        c-0.235,0.261-0.354,0.578-0.354,0.945c0,0.268,0.051,0.498,0.156,0.69c0.103,0.19,0.247,0.354,0.432,0.489l-0.039,0.674
        l-3.326-0.471v-3.208h0.752v2.627l1.716,0.261c-0.108-0.144-0.19-0.28-0.245-0.41c-0.095-0.23-0.142-0.495-0.142-0.796
        c0-0.568,0.182-1.047,0.547-1.443c0.366-0.394,0.829-0.589,1.388-0.589c0.584,0,1.1,0.179,1.545,0.541
        c0.445,0.361,0.669,0.937,0.669,1.727c0,0.503-0.142,0.948-0.426,1.335c-0.284,0.387-0.718,0.603-1.306,0.648v-0.805H28.946z"
        />
        <path
          fill="#010202"
          d="M26.146,157.822H25.55c-0.053-0.561-0.145-0.952-0.272-1.172c-0.129-0.223-0.432-0.387-0.908-0.499v-0.609
        h6.151v0.829h-4.374V157.822z"
        />
      </g>
      <g enable-background="new">
        <path
          fill="#010202"
          d="M35.564,352.494v-2.74c0-0.746,0.223-1.277,0.665-1.592c0.261-0.186,0.564-0.279,0.906-0.279
        c0.4,0,0.728,0.113,0.984,0.342c0.135,0.115,0.258,0.287,0.369,0.508c0.124-0.326,0.263-0.57,0.419-0.729
        c0.276-0.287,0.656-0.428,1.144-0.428c0.408,0,0.777,0.127,1.109,0.385c0.495,0.385,0.742,0.996,0.742,1.838v2.695H35.564z
         M38.243,350.076c0-0.369-0.05-0.652-0.151-0.855c-0.158-0.318-0.442-0.48-0.853-0.48c-0.415,0-0.694,0.17-0.837,0.51
        c-0.082,0.191-0.121,0.477-0.121,0.854v1.549h1.962V350.076z M41.168,349.783c0-0.529-0.151-0.908-0.456-1.135
        c-0.192-0.145-0.426-0.215-0.7-0.215c-0.459,0-0.772,0.209-0.94,0.625c-0.089,0.221-0.134,0.51-0.134,0.873v1.721h2.23V349.783z"
        />
        <path fill="#010202" d="M35.564,346.662v-0.777h6.338v0.777H35.564z" />
        <path
          fill="#010202"
          d="M40.514,342.23c-0.393-0.193-0.753-0.564-1.074-1.119l-0.479-0.83c-0.216-0.369-0.4-0.631-0.553-0.779
        c-0.239-0.236-0.513-0.355-0.821-0.355c-0.361,0-0.646,0.107-0.858,0.324c-0.213,0.217-0.318,0.502-0.318,0.863
        c0,0.531,0.202,0.9,0.606,1.104c0.216,0.109,0.515,0.17,0.9,0.182v0.789c-0.537-0.01-0.976-0.107-1.314-0.299
        c-0.598-0.338-0.898-0.93-0.898-1.781c0-0.707,0.19-1.225,0.572-1.551s0.808-0.488,1.277-0.488c0.495,0,0.916,0.172,1.268,0.52
        c0.205,0.201,0.452,0.562,0.742,1.082l0.329,0.592c0.155,0.281,0.303,0.504,0.443,0.666c0.25,0.287,0.527,0.467,0.832,0.541v-3.373
        h0.734v4.24C41.37,342.529,40.907,342.42,40.514,342.23z"
        />
        <path
          fill="#010202"
          d="M36.713,333.797c0.508-0.277,1.206-0.414,2.092-0.414c0.84,0,1.535,0.123,2.083,0.373
        c0.789,0.365,1.182,0.957,1.182,1.779c0,0.742-0.323,1.295-0.968,1.656c-0.537,0.303-1.259,0.451-2.166,0.451
        c-0.701,0-1.305-0.09-1.808-0.271c-0.937-0.34-1.405-0.951-1.405-1.842C35.726,334.732,36.055,334.154,36.713,333.797z
         M40.829,334.578c-0.356-0.238-1.021-0.359-1.994-0.359c-0.701,0-1.279,0.086-1.73,0.26c-0.453,0.17-0.68,0.508-0.68,1.006
        c0,0.457,0.216,0.793,0.645,1.004c0.429,0.211,1.062,0.318,1.899,0.318c0.631,0,1.137-0.068,1.52-0.203
        c0.582-0.209,0.875-0.562,0.875-1.062C41.364,335.139,41.185,334.818,40.829,334.578z"
        />
        <path
          fill="#010202"
          d="M40.514,332.402c-0.393-0.191-0.753-0.564-1.074-1.119l-0.479-0.83c-0.216-0.369-0.4-0.631-0.553-0.779
        c-0.239-0.236-0.513-0.355-0.821-0.355c-0.361,0-0.646,0.107-0.858,0.324c-0.213,0.217-0.318,0.504-0.318,0.863
        c0,0.531,0.202,0.9,0.606,1.104c0.216,0.109,0.515,0.17,0.9,0.182v0.791c-0.537-0.01-0.976-0.109-1.314-0.301
        c-0.598-0.338-0.898-0.93-0.898-1.781c0-0.705,0.19-1.223,0.572-1.551c0.382-0.326,0.808-0.488,1.277-0.488
        c0.495,0,0.916,0.172,1.268,0.521c0.205,0.199,0.452,0.562,0.742,1.082l0.329,0.592c0.155,0.279,0.303,0.502,0.443,0.664
        c0.25,0.287,0.527,0.467,0.832,0.541v-3.373h0.734v4.24C41.37,332.701,40.907,332.592,40.514,332.402z"
        />
        <path
          fill="#010202"
          d="M37.529,327.248h-0.597c-0.053-0.561-0.145-0.951-0.272-1.174c-0.129-0.223-0.432-0.387-0.908-0.496v-0.613
        h6.151v0.828h-4.374V327.248z"
        />
        <path fill="#010202" d="M40.962,322.426v-0.902h0.94v0.902H40.962z" />
        <path
          fill="#010202"
          d="M36.713,316.604c0.508-0.279,1.206-0.416,2.092-0.416c0.84,0,1.535,0.125,2.083,0.375
        c0.789,0.363,1.182,0.957,1.182,1.779c0,0.74-0.323,1.295-0.968,1.656c-0.537,0.303-1.259,0.451-2.166,0.451
        c-0.701,0-1.305-0.09-1.808-0.273c-0.937-0.338-1.405-0.951-1.405-1.842C35.726,317.537,36.055,316.959,36.713,316.604z
         M40.829,317.383c-0.356-0.238-1.021-0.359-1.994-0.359c-0.701,0-1.279,0.088-1.73,0.26c-0.453,0.17-0.68,0.508-0.68,1.006
        c0,0.459,0.216,0.793,0.645,1.006c0.429,0.211,1.062,0.316,1.899,0.316c0.631,0,1.137-0.066,1.52-0.203
        c0.582-0.207,0.875-0.562,0.875-1.062C41.364,317.943,41.185,317.623,40.829,317.383z"
        />
        <path
          fill="#010202"
          d="M41.902,312.879h-1.511v2.709h-0.759l-3.925-2.83v-0.654h4.006v-0.91h0.677v0.91h1.511v0.775H41.902z
         M39.714,312.893H36.91l2.804,1.98V312.893z"
        />
        <path
          fill="#010202"
          d="M39.857,307.211c0.36-0.02,0.65-0.102,0.876-0.244c0.419-0.273,0.63-0.758,0.63-1.449
        c0-0.311-0.047-0.592-0.139-0.848c-0.177-0.494-0.499-0.74-0.958-0.74c-0.345,0-0.59,0.104-0.739,0.312
        c-0.143,0.213-0.268,0.545-0.374,0.994l-0.193,0.832c-0.126,0.545-0.266,0.93-0.419,1.154c-0.264,0.391-0.66,0.584-1.185,0.584
        c-0.571,0-1.037-0.189-1.401-0.572c-0.366-0.383-0.548-0.926-0.548-1.631c0-0.646,0.161-1.195,0.48-1.646
        c0.321-0.451,0.833-0.678,1.539-0.678v0.805c-0.34,0.043-0.6,0.131-0.781,0.268c-0.331,0.25-0.497,0.676-0.497,1.277
        c0,0.484,0.106,0.834,0.319,1.045c0.213,0.215,0.459,0.318,0.742,0.318c0.31,0,0.537-0.123,0.682-0.375
        c0.092-0.164,0.207-0.535,0.345-1.115l0.202-0.861c0.1-0.416,0.232-0.734,0.401-0.963c0.297-0.389,0.726-0.584,1.29-0.584
        c0.703,0,1.203,0.25,1.506,0.748s0.453,1.076,0.453,1.734c0,0.77-0.202,1.371-0.604,1.805c-0.398,0.436-0.942,0.646-1.625,0.639
        v-0.809H39.857z"
        />
        <path
          fill="#010202"
          d="M35.461,297.482c0.877,0.451,1.522,0.746,1.937,0.881c0.63,0.203,1.357,0.307,2.183,0.307
        c0.835,0,1.597-0.117,2.287-0.35c0.426-0.146,1.039-0.43,1.838-0.852v0.523c-0.655,0.42-1.074,0.68-1.255,0.779
        c-0.18,0.1-0.427,0.209-0.738,0.328c-0.426,0.162-0.879,0.273-1.363,0.336c-0.25,0.033-0.49,0.047-0.716,0.047
        c-0.85,0-1.607-0.135-2.272-0.402c-0.424-0.168-1.055-0.521-1.9-1.061v-0.537H35.461z"
        />
        <path
          fill="#010202"
          d="M37.282,296.586v-0.77h0.656c-0.229-0.182-0.394-0.35-0.497-0.502c-0.176-0.254-0.263-0.545-0.263-0.869
        c0-0.369,0.09-0.664,0.271-0.891c0.104-0.127,0.256-0.24,0.458-0.344c-0.247-0.174-0.432-0.375-0.551-0.609
        c-0.118-0.232-0.178-0.494-0.178-0.785c0-0.621,0.224-1.043,0.673-1.268c0.24-0.121,0.567-0.18,0.974-0.18h3.076v0.807h-3.209
        c-0.306,0-0.519,0.076-0.635,0.232c-0.116,0.154-0.171,0.34-0.171,0.562c0,0.303,0.102,0.566,0.305,0.787
        c0.205,0.219,0.545,0.328,1.024,0.328h2.687v0.791h-3.016c-0.313,0-0.542,0.037-0.686,0.111c-0.216,0.117-0.324,0.338-0.324,0.658
        c0,0.295,0.115,0.562,0.342,0.801c0.228,0.238,0.639,0.361,1.234,0.361h2.451v0.775h-4.62V296.586z"
        />
        <path
          fill="#010202"
          d="M39.107,286.748c-0.024-0.176-0.098-0.297-0.226-0.355c-0.069-0.035-0.167-0.053-0.298-0.053
        c-0.266,0-0.458,0.094-0.577,0.283c-0.12,0.188-0.179,0.455-0.179,0.807c0,0.404,0.108,0.693,0.329,0.861
        c0.121,0.094,0.303,0.156,0.542,0.184v0.723c-0.571-0.012-0.969-0.197-1.192-0.553c-0.223-0.354-0.335-0.768-0.335-1.234
        c0-0.545,0.103-0.984,0.31-1.322c0.208-0.336,0.529-0.504,0.968-0.504h2.661c0.081,0,0.145-0.018,0.194-0.049
        c0.048-0.033,0.074-0.104,0.074-0.211c0-0.033-0.003-0.072-0.006-0.113c-0.005-0.043-0.011-0.092-0.021-0.139h0.574
        c0.034,0.119,0.055,0.211,0.064,0.275c0.008,0.062,0.013,0.15,0.013,0.26c0,0.266-0.095,0.461-0.286,0.58
        c-0.1,0.064-0.242,0.107-0.427,0.135c0.208,0.158,0.387,0.385,0.539,0.682c0.153,0.297,0.23,0.623,0.23,0.979
        c0,0.43-0.13,0.777-0.39,1.049c-0.26,0.273-0.585,0.408-0.976,0.408c-0.427,0-0.76-0.133-0.995-0.4s-0.379-0.619-0.436-1.053
        L39.107,286.748z M41.204,288.385c0.129-0.166,0.193-0.357,0.193-0.582c0-0.271-0.064-0.537-0.19-0.793
        c-0.21-0.432-0.553-0.646-1.029-0.646h-0.626c0.062,0.096,0.111,0.217,0.151,0.365c0.041,0.152,0.069,0.297,0.087,0.441l0.06,0.469
        c0.037,0.281,0.097,0.492,0.176,0.635c0.135,0.238,0.352,0.359,0.645,0.359C40.899,288.631,41.075,288.549,41.204,288.385z"
        />
        <path
          fill="#010202"
          d="M37.282,284.293v-0.738h0.798c-0.155-0.061-0.345-0.209-0.568-0.445c-0.224-0.234-0.334-0.506-0.334-0.814
        c0-0.016,0.002-0.037,0.003-0.074c0.003-0.033,0.01-0.092,0.018-0.176h0.821c-0.009,0.047-0.014,0.09-0.018,0.129
        c-0.003,0.037-0.005,0.08-0.005,0.125c0,0.391,0.126,0.693,0.377,0.902c0.251,0.211,0.542,0.314,0.869,0.314h2.656v0.777H37.282z"
        />
        <path
          fill="#010202"
          d="M36.713,275.363c0.508-0.277,1.206-0.416,2.092-0.416c0.84,0,1.535,0.126,2.083,0.374
        c0.789,0.364,1.182,0.957,1.182,1.779c0,0.741-0.323,1.295-0.968,1.656c-0.537,0.303-1.259,0.451-2.166,0.451
        c-0.701,0-1.305-0.09-1.808-0.271c-0.937-0.341-1.405-0.953-1.405-1.844C35.726,276.296,36.055,275.717,36.713,275.363z
         M40.829,276.143c-0.356-0.238-1.021-0.359-1.994-0.359c-0.701,0-1.279,0.086-1.73,0.258c-0.453,0.173-0.68,0.51-0.68,1.008
        c0,0.457,0.216,0.792,0.645,1.005c0.431,0.212,1.062,0.318,1.899,0.318c0.631,0,1.137-0.069,1.52-0.205
        c0.582-0.208,0.875-0.562,0.875-1.062C41.364,276.705,41.185,276.382,40.829,276.143z"
        />
        <path
          fill="#010202"
          d="M40.327,273.481c0.442-0.052,0.75-0.257,0.919-0.616c0.085-0.186,0.129-0.396,0.129-0.639
        c0-0.459-0.146-0.8-0.44-1.023c-0.293-0.222-0.617-0.331-0.974-0.331c-0.433,0-0.765,0.132-1,0.394
        c-0.235,0.265-0.355,0.579-0.355,0.948c0,0.268,0.052,0.497,0.157,0.688c0.103,0.191,0.247,0.355,0.432,0.491l-0.039,0.673
        l-3.326-0.471v-3.21h0.751v2.627l1.716,0.265c-0.108-0.145-0.19-0.28-0.245-0.409c-0.095-0.232-0.142-0.499-0.142-0.8
        c0-0.566,0.182-1.045,0.546-1.44c0.366-0.394,0.829-0.592,1.389-0.592c0.584,0,1.1,0.181,1.545,0.542
        c0.445,0.362,0.669,0.937,0.669,1.727c0,0.504-0.142,0.95-0.426,1.336c-0.284,0.385-0.718,0.603-1.306,0.648v-0.807H40.327z"
        />
        <path
          fill="#010202"
          d="M43.706,269.352c-0.892-0.456-1.542-0.753-1.95-0.885c-0.62-0.202-1.344-0.302-2.176-0.302
        c-0.831,0-1.59,0.114-2.282,0.349c-0.426,0.145-1.039,0.429-1.838,0.85v-0.521c0.708-0.442,1.145-0.71,1.309-0.805
        c0.165-0.096,0.394-0.195,0.684-0.304c0.359-0.139,0.714-0.237,1.066-0.297c0.35-0.058,0.688-0.089,1.013-0.089
        c0.852,0,1.611,0.136,2.277,0.405c0.43,0.169,1.06,0.522,1.895,1.058v0.54H43.706z"
        />
      </g>
      <path
        fill="#010202"
        d="M16.495,178.968c0.042,0.042,0,0.153,0.021,0.194c-0.042,0.043-0.173,0.025-0.216,0.025
      c-0.066-0.047-0.022-0.156-0.042-0.195L16.495,178.968"
      />
      <path
        fill="#010202"
        d="M16.514,179.335c0.023,0.066,0.045,0.129,0.045,0.195c-0.066,0.04-0.174,0.022-0.261,0.022
      c0-0.04-0.022-0.129,0-0.171C16.364,179.335,16.451,179.335,16.514,179.335"
      />
      <path
        fill="#010202"
        d="M16.495,175.367c0.085,0,0.239,0,0.239,0.089c0.019,0.109-0.089,0.174-0.173,0.125l-0.088-0.039
      L16.495,175.367"
      />
      <path
        fill="#010202"
        d="M16.58,175c0.089,0,0.239,0,0.239,0.087l-0.042,0.15l-0.216-0.039L16.58,175"
      />
      <path
        fill="#010202"
        d="M16.58,177.732c0.411-0.172,0.826-0.261,1.214-0.435l-1.171-0.568l0.02-0.235
      c0.522-0.02,1.066,0.022,1.608,0.043c0.019,0.043,0,0.153,0,0.214c-0.303,0-0.695-0.04-1.018,0c0.3,0.173,0.65,0.305,0.972,0.457
      v0.195l-0.997,0.348c0.239,0.108,0.651,0.087,0.953,0.108c0.042,0.063,0.042,0.152,0,0.218l-1.603-0.092L16.58,177.732"
      />
      <path
        fill="#010202"
        d="M16.906,178.532c0.214-0.151,0.564-0.171,0.822-0.129c0.218,0.089,0.476,0.261,0.523,0.522
      c0.062,0.234,0.019,0.516-0.11,0.713c-0.193,0.237-0.476,0.326-0.801,0.326c-0.262-0.043-0.542-0.109-0.65-0.394
      C16.514,179.228,16.561,178.771,16.906,178.532"
      />
      <path
        fill="#FFFFFF"
        d="M17.188,178.686c0.195-0.066,0.499-0.066,0.672,0.066c0.153,0.129,0.237,0.345,0.173,0.562
      c-0.02,0.153-0.197,0.258-0.323,0.326c-0.197,0.089-0.477,0.066-0.672-0.025c-0.131-0.086-0.197-0.236-0.239-0.37
      C16.798,179.011,16.93,178.728,17.188,178.686"
      />
      <path
        fill="#010202"
        d="M18.056,180.139c0.197-0.022,0.369,0.089,0.455,0.258c0.043,0.173,0.064,0.391-0.042,0.541
      c-0.108,0.111-0.237,0.132-0.392,0.174c0-0.063-0.042-0.152-0.021-0.236c0.085-0.043,0.239-0.088,0.258-0.218
      c0.024-0.106-0.042-0.195-0.127-0.235c-0.456,0-0.869,0.192-1.303,0.235c-0.02-0.088-0.042-0.151-0.042-0.216L18.056,180.139"
      />
      <path
        fill="#010202"
        d="M18.599,175.02c-0.024,0.066-0.024,0.218-0.108,0.239l-0.415,0.066l-0.129,0.539
      c0.085,0.195,0.562,0.176,0.324,0.501c-0.499-0.258-0.976-0.585-1.431-0.909l0.066-0.195L18.599,175.02"
      />
      <path
        fill="#010202"
        d="M17.948,173.196c0.174,0,0.347,0.134,0.39,0.282c0.022,0.157,0.13,0.024,0.195,0.049l0.627,0.129
      l-0.064,0.192c-0.237,0.088-0.476-0.192-0.693,0l-0.197,0.457l0.566,0.258c-0.022,0.085-0.022,0.195-0.108,0.221
      c-0.478-0.198-0.977-0.369-1.43-0.611C17.406,173.806,17.425,173.196,17.948,173.196"
      />
      <polyline
        fill="#FFFFFF"
        points="17.73,175.738 17.233,175.454 17.794,175.367 17.73,175.738 "
      />
      <path
        fill="#010202"
        d="M18.728,181.938c-0.021-0.263-0.174-0.479-0.218-0.739c0.023-0.04,0.218-0.172,0.218,0l0.324,0.889
      c-0.453,0.221-0.976,0.369-1.474,0.568c-0.064-0.044-0.085-0.129-0.085-0.221L18.728,181.938"
      />
      <path
        fill="#FFFFFF"
        d="M17.883,173.438c0.108,0,0.238,0.039,0.258,0.151c0.064,0.239-0.066,0.415-0.15,0.61
      c-0.173,0-0.326-0.09-0.458-0.174C17.62,173.83,17.645,173.546,17.883,173.438"
      />
      <path
        fill="#010202"
        d="M19.225,182.435l0.131,0.198c-0.476,0.281-0.953,0.539-1.431,0.76c-0.022-0.089-0.13-0.174-0.064-0.237
      L19.225,182.435"
      />
      <path
        fill="#010202"
        d="M18.056,172.396l0.585,0.281c0-0.302-0.174-0.629-0.218-0.929c0-0.11,0.066-0.173,0.15-0.261
      c0.132,0.237,0.153,0.545,0.263,0.803c0.347,0,0.802-0.042,1.171-0.106l-0.129,0.236l-0.956,0.151
      c-0.106,0.35,0.326,0.436,0.522,0.608c-0.045,0.066-0.045,0.155-0.108,0.195l-1.388-0.756
      C17.948,172.525,18.014,172.459,18.056,172.396"
      />
      <path
        fill="#010202"
        d="M19.53,183.001l0.106,0.169c-0.239,0.221-0.542,0.416-0.802,0.611c0.37-0.027,0.714-0.174,1.105-0.218
      l0.066,0.132l-0.585,0.929c0.303-0.088,0.566-0.369,0.847-0.542l0.15,0.169c-0.41,0.328-0.887,0.608-1.322,0.911l-0.172-0.196
      c0.196-0.37,0.456-0.715,0.695-1.084c-0.434,0.026-0.845,0.197-1.279,0.235c-0.066-0.062-0.197-0.171-0.108-0.258L19.53,183.001"
      />
      <path
        fill="#010202"
        d="M19.336,170.34c0.085-0.026,0.127,0.063,0.195,0.104l-0.282,0.39l1.108,0.843
      c-0.045,0.092-0.066,0.132-0.153,0.174l-1.106-0.779c-0.15,0.063-0.216,0.389-0.389,0.28l-0.066-0.062L19.336,170.34"
      />
      <path
        fill="#010202"
        d="M19.769,178.057l12.034,8.779v0.024c-0.714,0.303-1.497,0.479-2.319,0.585l-0.153-0.026l-10.344-7.544
      c-0.173-0.826-0.237-1.647-0.173-2.472L19.769,178.057"
      />
      <path
        fill="#010202"
        d="M19.355,174.759l0.869,0.585l10.97,8c-0.042,0.829,0.953,1.256,1.064,2.019
      c0.151,0.389,0.214,0.8,0.214,1.256l-0.756-0.501l-12.839-9.389C18.944,176.041,19.139,175.388,19.355,174.759"
      />
      <path
        fill="#010202"
        d="M21.828,182.585l6.657,4.862l0.021,0.024c-3.448,0.063-6.678-1.695-8.435-4.73
      c-0.347-0.65-0.693-1.303-0.866-2.04L21.828,182.585"
      />
      <path
        fill="#010202"
        d="M20.594,172.441l10.277,7.5c0.52,0.347,1.039,0.866,1.084,1.497c0,0.519-0.197,0.976-0.587,1.366
      l-0.087-0.021l-11.6-8.5l-0.087-0.066C19.813,173.568,20.201,173.002,20.594,172.441"
      />
      <path
        fill="#010202"
        d="M22.37,170.532l9.586,6.962c0.042,0.192,0.021,0.452,0.021,0.647l-1.95-1.389l-8.024-5.854
      C22.066,170.727,22.239,170.642,22.37,170.532"
      />
      <path
        fill="#010202"
        d="M24.735,169.145l7.241,5.27c0,0.218,0.022,0.436,0,0.648l-7.764-5.636
      C24.344,169.271,24.539,169.208,24.735,169.145"
      />
      <path
        fill="#010202"
        d="M27.988,168.45l3.988,2.908v0.648c-1.605-1.148-3.253-2.319-4.816-3.49
      C27.445,168.471,27.725,168.429,27.988,168.45"
      />
      <path
        fill="#010202"
        d="M31.718,183.153c0.172-0.043,0.324,0.02,0.41,0.151c0.108,0.089,0.085,0.284,0.043,0.37
      c-0.108,0.15-0.281,0.216-0.453,0.15c-0.111-0.02-0.197-0.124-0.219-0.235C31.478,183.411,31.565,183.238,31.718,183.153"
      />
      <path
        fill="#010202"
        d="M32.888,184.627c0.434,0.259,0.869,0.582,1.3,0.906c-0.389,0.327-0.842,0.63-1.3,0.826
      C32.844,185.754,32.909,185.235,32.888,184.627"
      />
      <path
        fill="#010202"
        d="M32.909,181.593c1.019,0.735,2.058,1.474,3.033,2.251c-0.324,0.518-0.822,0.932-1.298,1.366
      c-0.608-0.385-1.172-0.845-1.758-1.277C32.888,183.13,32.888,182.392,32.909,181.593"
      />
      <path
        fill="#010202"
        d="M32.909,178.728c1.43,1.021,2.841,2.06,4.272,3.103c-0.218,0.542-0.499,1.083-0.892,1.562l-3.359-2.406
      C32.865,180.202,32.909,179.464,32.909,178.728"
      />
      <path
        fill="#010202"
        d="M33.083,175.846l4.814,3.533c-0.089,0.671-0.218,1.344-0.522,1.95l-4.465-3.251c-0.023-0.74,0-1.497,0-2.319
      L33.083,175.846"
      />
      <path
        fill="#010202"
        d="M37.916,176.365c0.109,0.802,0.153,1.626,0.064,2.405c-1.711-1.192-3.403-2.45-5.072-3.75
      c0-0.758-0.023-1.561,0-2.319L37.916,176.365"
      />
      <path
        fill="#010202"
        d="M32.909,169.493c2.341,1.15,4.249,3.555,4.813,6.048l-4.813-3.492
      C32.888,171.184,32.93,170.293,32.909,169.493"
      />
      <line
        fill="none"
        stroke="#010202"
        stroke-width="0.363"
        stroke-miterlimit="10"
        x1="508.63"
        y1="270.325"
        x2="508.63"
        y2="38.553"
      />
      <line
        fill="none"
        stroke="#010202"
        stroke-width="0.363"
        stroke-miterlimit="10"
        x1="925.468"
        y1="269.87"
        x2="508.172"
        y2="269.87"
      />
      <line
        fill="none"
        stroke="#010202"
        stroke-width="0.363"
        stroke-miterlimit="10"
        x1="925.011"
        y1="38.553"
        x2="925.011"
        y2="270.325"
      />
      <line
        fill="none"
        stroke="#010202"
        stroke-width="0.363"
        stroke-miterlimit="10"
        x1="913.445"
        y1="38.553"
        x2="925.468"
        y2="38.553"
      />
      <line
        fill="none"
        stroke="#010202"
        stroke-width="0.363"
        stroke-miterlimit="10"
        x1="925.011"
        y1="38.1"
        x2="925.011"
        y2="96.382"
      />
      <line
        fill="none"
        stroke="#010202"
        stroke-width="0.363"
        stroke-miterlimit="10"
        x1="45.979"
        y1="269.87"
        x2="508.572"
        y2="269.87"
      />
      <rect
        x="47.989"
        y="303.705"
        fill="#F1F8F4"
        width="879.029"
        height="48.195"
      />
      <rect
        x="47.989"
        y="308.494"
        fill="#FFFFFF"
        width="312.289"
        height="38.557"
      />
      <rect
        x="514.398"
        y="308.494"
        fill="#FFFFFF"
        width="25.042"
        height="38.557"
      />
      <rect
        x="543.994"
        y="308.494"
        fill="#FFFFFF"
        width="25.041"
        height="38.557"
      />
      <rect
        x="573.588"
        y="308.494"
        fill="#FFFFFF"
        width="25.043"
        height="38.557"
      />
      <rect
        x="610.013"
        y="308.494"
        fill="#FFFFFF"
        width="25.042"
        height="38.557"
      />
      <rect
        x="639.605"
        y="308.494"
        fill="#FFFFFF"
        width="25.042"
        height="38.557"
      />
      <rect
        x="669.203"
        y="308.494"
        fill="#FFFFFF"
        width="25.042"
        height="38.557"
      />
      <rect
        x="704.945"
        y="308.494"
        fill="#FFFFFF"
        width="25.044"
        height="38.557"
      />
      <rect
        x="734.545"
        y="308.494"
        fill="#FFFFFF"
        width="25.043"
        height="38.557"
      />
      <rect
        x="764.139"
        y="308.494"
        fill="#FFFFFF"
        width="25.041"
        height="38.557"
      />
      <rect
        x="806.73"
        y="308.494"
        fill="#FFFFFF"
        width="25.04"
        height="38.557"
      />
      <rect
        x="836.326"
        y="308.494"
        fill="#FFFFFF"
        width="25.04"
        height="38.557"
      />
      <line
        fill="none"
        stroke="#010202"
        stroke-width="0.363"
        stroke-miterlimit="10"
        x1="45.528"
        y1="38.553"
        x2="925.011"
        y2="38.553"
      />
      <line
        fill="none"
        stroke="#010202"
        stroke-width="0.363"
        stroke-miterlimit="10"
        x1="45.979"
        y1="352.758"
        x2="45.979"
        y2="38.1"
      />
      <line
        fill="none"
        stroke="#010202"
        stroke-width="0.363"
        stroke-miterlimit="10"
        x1="508.63"
        y1="154.21"
        x2="924.976"
        y2="154.21"
      />
      <g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M599.797,45.686c0.52,0.503,0.777,1.247,0.777,2.228c0,0.946-0.229,1.729-0.688,2.348
          c-0.456,0.618-1.169,0.926-2.13,0.926c-0.805,0-1.442-0.272-1.914-0.819c-0.473-0.546-0.708-1.282-0.708-2.203
          c0-0.987,0.248-1.774,0.746-2.358c0.499-0.585,1.166-0.879,2.007-0.879C598.641,44.928,599.277,45.179,599.797,45.686z
           M599.183,49.593c0.238-0.495,0.361-1.045,0.361-1.653c0-0.548-0.087-0.994-0.26-1.337c-0.276-0.542-0.75-0.812-1.424-0.812
          c-0.597,0-1.031,0.231-1.304,0.69c-0.271,0.461-0.408,1.018-0.408,1.667c0,0.626,0.138,1.146,0.408,1.562
          c0.272,0.418,0.703,0.626,1.294,0.626C598.497,50.335,598.942,50.088,599.183,49.593z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M592.605,43.457h0.998v1.642h0.939v0.808h-0.939v3.838c0,0.205,0.07,0.342,0.209,0.411
          c0.078,0.04,0.206,0.06,0.384,0.06c0.048,0,0.1,0,0.154-0.003c0.055-0.001,0.119-0.008,0.192-0.019v0.785
          c-0.113,0.034-0.23,0.057-0.354,0.071c-0.123,0.017-0.255,0.023-0.4,0.023c-0.459,0-0.772-0.118-0.938-0.355
          c-0.164-0.235-0.245-0.542-0.245-0.919v-3.892h-0.796v-0.808h0.796V43.457z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M586.158,45.097h0.938v0.835c0.279-0.344,0.573-0.59,0.886-0.74c0.31-0.152,0.654-0.226,1.037-0.226
          c0.835,0,1.397,0.291,1.691,0.873c0.161,0.318,0.242,0.772,0.242,1.368v3.772h-1.005v-3.706c0-0.359-0.054-0.648-0.161-0.868
          c-0.175-0.366-0.494-0.55-0.955-0.55c-0.235,0-0.428,0.026-0.575,0.073c-0.271,0.081-0.509,0.24-0.715,0.482
          c-0.164,0.195-0.271,0.396-0.319,0.602c-0.048,0.208-0.074,0.503-0.074,0.887v3.08h-0.988v-5.881H586.158z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M584.167,45.686c0.519,0.503,0.777,1.247,0.777,2.228c0,0.946-0.229,1.729-0.688,2.348
          c-0.456,0.618-1.169,0.926-2.13,0.926c-0.805,0-1.442-0.272-1.915-0.819c-0.472-0.546-0.708-1.282-0.708-2.203
          c0-0.987,0.249-1.774,0.747-2.358c0.498-0.585,1.166-0.879,2.006-0.879C583.012,44.928,583.647,45.179,584.167,45.686z
           M583.554,49.593c0.239-0.495,0.361-1.045,0.361-1.653c0-0.548-0.087-0.994-0.26-1.337c-0.275-0.542-0.75-0.812-1.424-0.812
          c-0.597,0-1.03,0.231-1.303,0.69c-0.271,0.461-0.408,1.018-0.408,1.667c0,0.626,0.137,1.146,0.408,1.562
          c0.272,0.418,0.703,0.626,1.293,0.626C582.868,50.335,583.312,50.088,583.554,49.593z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M574.545,42.914h0.952v4.682l2.531-2.498h1.263l-2.259,2.187l2.385,3.693h-1.261l-1.85-2.992l-0.81,0.786
          v2.206h-0.952V42.914z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M572.579,45.686c0.52,0.503,0.777,1.247,0.777,2.228c0,0.946-0.229,1.729-0.687,2.348
          c-0.457,0.618-1.169,0.926-2.131,0.926c-0.805,0-1.441-0.272-1.914-0.819c-0.473-0.546-0.708-1.282-0.708-2.203
          c0-0.987,0.248-1.774,0.747-2.358c0.498-0.585,1.166-0.879,2.006-0.879C571.423,44.928,572.061,45.179,572.579,45.686z
           M571.965,49.593c0.239-0.495,0.361-1.045,0.361-1.653c0-0.548-0.087-0.994-0.26-1.337c-0.275-0.542-0.75-0.812-1.424-0.812
          c-0.597,0-1.03,0.231-1.303,0.69c-0.271,0.461-0.408,1.018-0.408,1.667c0,0.626,0.137,1.146,0.408,1.562
          c0.272,0.418,0.703,0.626,1.293,0.626C571.279,50.335,571.725,50.088,571.965,49.593z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M564.602,45.097h0.938v1.016c0.076-0.197,0.265-0.437,0.566-0.721c0.302-0.284,0.645-0.426,1.038-0.426
          c0.02,0,0.049,0.002,0.094,0.005c0.045,0.003,0.118,0.011,0.226,0.023v1.042c-0.06-0.011-0.112-0.018-0.163-0.022
          c-0.048-0.003-0.102-0.005-0.162-0.005c-0.499,0-0.881,0.161-1.146,0.48c-0.269,0.319-0.402,0.69-0.402,1.108v3.382h-0.988V45.097
          z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M562.078,42.914h1.004v1.119h-1.004V42.914z M562.078,45.126h1.004v5.853h-1.004V45.126z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M558.83,43.144c0.788,0.421,1.273,1.16,1.449,2.213h-1.082c-0.129-0.589-0.395-1.02-0.802-1.287
          c-0.404-0.27-0.916-0.405-1.53-0.405c-0.732,0-1.348,0.281-1.848,0.84s-0.75,1.393-0.75,2.503c0,0.959,0.208,1.74,0.619,2.341
          c0.413,0.604,1.085,0.905,2.018,0.905c0.714,0,1.307-0.211,1.773-0.635c0.47-0.422,0.708-1.106,0.718-2.05h-2.523v-0.906h3.541
          v4.315h-0.704l-0.264-1.039c-0.364,0.406-0.687,0.688-0.967,0.845c-0.473,0.271-1.071,0.406-1.799,0.406
          c-0.939,0-1.748-0.308-2.425-0.927c-0.737-0.776-1.108-1.842-1.108-3.195c0-1.351,0.361-2.425,1.082-3.224
          c0.688-0.761,1.574-1.142,2.663-1.142C557.638,42.704,558.284,42.851,558.83,43.144z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M548.292,49.134c0.031,0.329,0.115,0.581,0.254,0.758c0.251,0.318,0.693,0.478,1.32,0.478
          c0.373,0,0.703-0.081,0.985-0.239c0.285-0.16,0.428-0.405,0.428-0.739c0-0.251-0.113-0.443-0.341-0.576
          c-0.145-0.081-0.432-0.172-0.859-0.279l-0.797-0.198c-0.509-0.125-0.887-0.263-1.127-0.418c-0.432-0.266-0.648-0.637-0.648-1.109
          c0-0.557,0.205-1.006,0.611-1.352c0.406-0.343,0.951-0.514,1.639-0.514c0.898,0,1.545,0.259,1.941,0.779
          c0.249,0.329,0.369,0.684,0.361,1.064h-0.932c-0.02-0.223-0.101-0.424-0.242-0.606c-0.232-0.258-0.634-0.387-1.206-0.387
          c-0.384,0-0.673,0.071-0.869,0.213c-0.197,0.144-0.297,0.331-0.297,0.564c0,0.255,0.129,0.459,0.389,0.613
          c0.149,0.092,0.369,0.171,0.659,0.24l0.665,0.161c0.724,0.171,1.209,0.339,1.454,0.5c0.384,0.252,0.576,0.648,0.576,1.19
          c0,0.524-0.202,0.976-0.604,1.356c-0.401,0.381-1.015,0.571-1.839,0.571c-0.885,0-1.513-0.199-1.88-0.595
          c-0.368-0.397-0.567-0.889-0.592-1.474h0.949V49.134z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M542.441,45.097v3.904c0,0.302,0.045,0.545,0.139,0.735c0.17,0.352,0.49,0.527,0.958,0.527
          c0.669,0,1.126-0.307,1.369-0.922c0.131-0.329,0.197-0.781,0.197-1.356v-2.888h0.988v5.88h-0.933l0.01-0.868
          c-0.125,0.224-0.282,0.413-0.469,0.566c-0.369,0.307-0.819,0.461-1.345,0.461c-0.824,0-1.383-0.281-1.683-0.84
          c-0.161-0.302-0.242-0.702-0.242-1.203v-3.998h1.01V45.097z"
          />
        </g>
        <g enable-background="new">
          <path fill="#010202" d="M538.987,42.914h0.987v8.065h-0.987V42.914z" />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M531.694,42.914h3.609c0.713,0,1.289,0.202,1.728,0.606s0.658,0.974,0.658,1.705
          c0,0.63-0.197,1.177-0.588,1.646c-0.39,0.466-0.99,0.7-1.796,0.7h-2.52v3.409h-1.093L531.694,42.914L531.694,42.914z
           M535.933,44.021c-0.24-0.113-0.569-0.169-0.987-0.169h-2.157v2.793h2.157c0.489,0,0.883-0.104,1.188-0.312
          c0.303-0.209,0.453-0.576,0.453-1.104C536.588,44.636,536.37,44.234,535.933,44.021z"
          />
        </g>
        <g enable-background="new">
          <path fill="#010202" d="M525.865,42.914h0.986v8.065h-0.986V42.914z" />
        </g>
        <g enable-background="new">
          <path fill="#010202" d="M523.367,42.914h0.986v8.065h-0.986V42.914z" />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M520.845,42.914h1.005v1.119h-1.005V42.914z M520.845,45.126h1.005v5.853h-1.005V45.126z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M519.971,42.914v0.961h-2.718v7.104h-1.106v-7.104h-2.717v-0.961H519.971z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M231.949,45.097h0.94v0.835c0.277-0.344,0.572-0.59,0.884-0.74c0.311-0.152,0.656-0.226,1.039-0.226
          c0.835,0,1.398,0.291,1.69,0.873c0.161,0.318,0.242,0.772,0.242,1.368v3.772h-1.005v-3.706c0-0.359-0.053-0.648-0.16-0.868
          c-0.176-0.366-0.494-0.55-0.955-0.55c-0.235,0-0.427,0.026-0.576,0.073c-0.271,0.081-0.508,0.24-0.714,0.482
          c-0.165,0.195-0.271,0.396-0.321,0.602c-0.05,0.208-0.074,0.503-0.074,0.887v3.08h-0.988v-5.881H231.949z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M229.329,45.26c0.388,0.195,0.685,0.448,0.888,0.761c0.197,0.297,0.328,0.642,0.394,1.039
          c0.058,0.271,0.087,0.703,0.087,1.297h-4.277c0.018,0.597,0.158,1.077,0.419,1.44c0.263,0.361,0.668,0.542,1.214,0.542
          c0.511,0,0.921-0.169,1.226-0.513c0.169-0.199,0.292-0.429,0.363-0.689h0.972c-0.026,0.216-0.111,0.457-0.256,0.721
          c-0.144,0.266-0.305,0.482-0.484,0.65c-0.298,0.293-0.667,0.492-1.106,0.593c-0.235,0.058-0.503,0.087-0.801,0.087
          c-0.728,0-1.343-0.266-1.85-0.798c-0.507-0.532-0.758-1.277-0.758-2.237c0-0.945,0.255-1.713,0.765-2.301
          c0.509-0.589,1.175-0.884,1.997-0.884C228.537,44.966,228.938,45.065,229.329,45.26z M229.682,47.569
          c-0.039-0.429-0.132-0.771-0.277-1.027c-0.27-0.476-0.718-0.713-1.345-0.713c-0.45,0-0.827,0.165-1.132,0.492
          c-0.305,0.327-0.466,0.743-0.484,1.248H229.682z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M221.98,45.097h0.938v1.016c0.077-0.197,0.266-0.437,0.566-0.721c0.3-0.284,0.645-0.426,1.037-0.426
          c0.02,0,0.05,0.002,0.093,0.005c0.044,0.003,0.12,0.011,0.226,0.023v1.042c-0.06-0.011-0.113-0.018-0.163-0.022
          c-0.05-0.003-0.103-0.005-0.163-0.005c-0.499,0-0.881,0.161-1.147,0.48c-0.266,0.319-0.401,0.69-0.401,1.108v3.382h-0.989v-5.881
          H221.98z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M218.851,47.419c0.228-0.029,0.379-0.123,0.455-0.286c0.043-0.088,0.066-0.214,0.066-0.379
          c0-0.338-0.119-0.582-0.359-0.735c-0.241-0.153-0.583-0.229-1.027-0.229c-0.515,0-0.881,0.139-1.095,0.419
          c-0.121,0.155-0.2,0.384-0.235,0.69h-0.922c0.018-0.727,0.253-1.233,0.705-1.516c0.452-0.286,0.976-0.427,1.571-0.427
          c0.692,0,1.251,0.13,1.684,0.395c0.427,0.263,0.642,0.672,0.642,1.23v3.388c0,0.102,0.021,0.184,0.063,0.247
          c0.042,0.062,0.131,0.094,0.266,0.094c0.043,0,0.094-0.003,0.148-0.01c0.055-0.005,0.113-0.013,0.176-0.024v0.729
          c-0.153,0.043-0.271,0.073-0.352,0.084s-0.19,0.016-0.331,0.016c-0.34,0-0.587-0.121-0.74-0.363
          c-0.081-0.127-0.137-0.308-0.169-0.543c-0.202,0.263-0.49,0.494-0.868,0.687s-0.792,0.292-1.247,0.292
          c-0.545,0-0.99-0.168-1.337-0.499s-0.519-0.745-0.519-1.242c0-0.545,0.169-0.968,0.509-1.268c0.34-0.3,0.787-0.484,1.34-0.553
          L218.851,47.419z M216.771,50.09c0.208,0.165,0.456,0.245,0.74,0.245c0.348,0,0.684-0.081,1.009-0.24
          c0.548-0.266,0.824-0.705,0.824-1.31V47.99c-0.121,0.078-0.276,0.142-0.466,0.194c-0.19,0.05-0.376,0.088-0.56,0.109l-0.598,0.076
          c-0.358,0.048-0.627,0.123-0.806,0.226c-0.305,0.171-0.458,0.445-0.458,0.822C216.458,49.701,216.561,49.925,216.771,50.09z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M212.72,45.334c0.186,0.129,0.376,0.315,0.569,0.562v-0.742h0.911v5.348c0,0.747-0.109,1.335-0.327,1.767
          c-0.408,0.798-1.181,1.197-2.316,1.197c-0.631,0-1.161-0.142-1.592-0.426s-0.672-0.727-0.723-1.331h1.005
          c0.047,0.263,0.142,0.466,0.284,0.61c0.223,0.219,0.571,0.329,1.048,0.329c0.753,0,1.247-0.268,1.48-0.802
          c0.137-0.314,0.202-0.877,0.19-1.685c-0.197,0.3-0.434,0.522-0.711,0.669c-0.276,0.147-0.644,0.219-1.1,0.219
          c-0.634,0-1.188-0.226-1.665-0.677c-0.476-0.451-0.714-1.2-0.714-2.243c0-0.985,0.24-1.754,0.719-2.308
          c0.479-0.552,1.058-0.829,1.737-0.829C211.975,44.994,212.376,45.107,212.72,45.334z M212.841,46.394
          c-0.298-0.352-0.679-0.527-1.142-0.527c-0.692,0-1.165,0.326-1.419,0.978c-0.134,0.348-0.202,0.803-0.202,1.368
          c0,0.663,0.134,1.167,0.402,1.513c0.268,0.347,0.627,0.519,1.079,0.519c0.707,0,1.203-0.319,1.492-0.961
          c0.16-0.363,0.24-0.786,0.24-1.27C213.288,47.286,213.139,46.745,212.841,46.394z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M206.35,47.419c0.228-0.029,0.379-0.123,0.455-0.286c0.043-0.088,0.066-0.214,0.066-0.379
          c0-0.338-0.12-0.582-0.36-0.735s-0.582-0.229-1.027-0.229c-0.514,0-0.88,0.139-1.095,0.419c-0.121,0.155-0.2,0.384-0.235,0.69
          h-0.923c0.018-0.727,0.253-1.233,0.705-1.516c0.452-0.286,0.976-0.427,1.571-0.427c0.692,0,1.251,0.13,1.684,0.395
          c0.427,0.263,0.642,0.672,0.642,1.23v3.388c0,0.102,0.021,0.184,0.063,0.247c0.042,0.062,0.131,0.094,0.266,0.094
          c0.043,0,0.094-0.003,0.148-0.01c0.055-0.005,0.113-0.013,0.176-0.024v0.729c-0.153,0.043-0.271,0.073-0.352,0.084
          s-0.19,0.016-0.331,0.016c-0.34,0-0.587-0.121-0.74-0.363c-0.081-0.127-0.137-0.308-0.169-0.543
          c-0.202,0.263-0.49,0.494-0.868,0.687s-0.792,0.292-1.247,0.292c-0.545,0-0.99-0.168-1.337-0.499s-0.519-0.745-0.519-1.242
          c0-0.545,0.169-0.968,0.509-1.268s0.787-0.484,1.34-0.553L206.35,47.419z M204.27,50.09c0.208,0.165,0.456,0.245,0.74,0.245
          c0.348,0,0.684-0.081,1.009-0.24c0.548-0.266,0.824-0.705,0.824-1.31V47.99c-0.121,0.078-0.276,0.142-0.466,0.194
          c-0.19,0.05-0.375,0.088-0.56,0.109l-0.598,0.076c-0.358,0.048-0.627,0.123-0.806,0.226c-0.305,0.171-0.458,0.445-0.458,0.822
          C203.957,49.701,204.06,49.925,204.27,50.09z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M200.27,43.457h1v1.642h0.94v0.808h-0.94v3.838c0,0.205,0.069,0.342,0.208,0.411
          c0.078,0.04,0.205,0.06,0.384,0.06c0.049,0,0.1,0,0.155-0.003c0.055-0.001,0.119-0.008,0.192-0.019v0.785
          c-0.115,0.034-0.232,0.057-0.355,0.071c-0.123,0.017-0.255,0.023-0.398,0.023c-0.461,0-0.774-0.118-0.938-0.355
          c-0.165-0.237-0.247-0.542-0.247-0.919v-3.892h-0.797v-0.808h0.797V43.457z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M197.143,43.457h1v1.642h0.94v0.808h-0.94v3.838c0,0.205,0.069,0.342,0.208,0.411
          c0.078,0.04,0.205,0.06,0.384,0.06c0.049,0,0.1,0,0.155-0.003c0.055-0.001,0.119-0.008,0.191-0.019v0.785
          c-0.114,0.034-0.232,0.057-0.354,0.071c-0.123,0.017-0.255,0.023-0.398,0.023c-0.461,0-0.774-0.118-0.938-0.355
          c-0.165-0.237-0.247-0.542-0.247-0.919v-3.892h-0.797v-0.808h0.797V43.457z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M194.955,45.686c0.519,0.503,0.779,1.247,0.779,2.228c0,0.946-0.229,1.729-0.687,2.348
          c-0.458,0.618-1.169,0.926-2.132,0.926c-0.805,0-1.442-0.272-1.915-0.819c-0.472-0.546-0.708-1.282-0.708-2.203
          c0-0.987,0.25-1.774,0.747-2.358c0.498-0.585,1.166-0.879,2.006-0.879C193.798,44.928,194.434,45.179,194.955,45.686z
           M194.34,49.593c0.24-0.495,0.361-1.045,0.361-1.653c0-0.548-0.087-0.994-0.261-1.337c-0.274-0.542-0.748-0.812-1.422-0.812
          c-0.598,0-1.032,0.231-1.303,0.69c-0.271,0.461-0.406,1.018-0.406,1.667c0,0.626,0.135,1.146,0.406,1.562
          c0.271,0.418,0.703,0.626,1.292,0.626C193.656,50.335,194.1,50.088,194.34,49.593z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M181.327,45.097h0.978v0.835c0.235-0.289,0.447-0.5,0.637-0.632c0.326-0.223,0.695-0.334,1.11-0.334
          c0.469,0,0.845,0.115,1.13,0.345c0.161,0.132,0.306,0.326,0.438,0.583c0.219-0.314,0.478-0.548,0.774-0.702
          c0.296-0.152,0.629-0.228,1-0.228c0.791,0,1.329,0.286,1.614,0.855c0.153,0.308,0.231,0.721,0.231,1.242v3.916h-1.027v-4.085
          c0-0.39-0.099-0.66-0.293-0.806c-0.197-0.147-0.436-0.221-0.716-0.221c-0.389,0-0.722,0.131-1.003,0.39
          c-0.279,0.26-0.419,0.694-0.419,1.302v3.42h-1.004v-3.838c0-0.398-0.047-0.69-0.142-0.873c-0.15-0.274-0.431-0.413-0.84-0.413
          c-0.373,0-0.713,0.145-1.018,0.436c-0.306,0.289-0.459,0.812-0.459,1.571v3.119h-0.989V45.097z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M176.294,49.134c0.031,0.329,0.115,0.581,0.253,0.758c0.253,0.318,0.695,0.478,1.322,0.478
          c0.373,0,0.703-0.081,0.985-0.239c0.284-0.16,0.426-0.405,0.426-0.739c0-0.251-0.113-0.443-0.34-0.576
          c-0.145-0.081-0.432-0.172-0.859-0.279l-0.798-0.198c-0.51-0.125-0.885-0.263-1.127-0.418c-0.432-0.266-0.646-0.637-0.646-1.109
          c0-0.557,0.203-1.006,0.609-1.352c0.406-0.343,0.952-0.514,1.639-0.514c0.898,0,1.545,0.259,1.941,0.779
          c0.249,0.329,0.37,0.684,0.361,1.064h-0.934c-0.02-0.223-0.1-0.424-0.242-0.606c-0.232-0.258-0.635-0.387-1.208-0.387
          c-0.382,0-0.673,0.071-0.87,0.213c-0.196,0.144-0.295,0.331-0.295,0.564c0,0.255,0.129,0.459,0.389,0.613
          c0.148,0.092,0.369,0.171,0.66,0.24l0.666,0.161c0.724,0.171,1.209,0.339,1.455,0.5c0.384,0.252,0.576,0.648,0.576,1.19
          c0,0.524-0.202,0.976-0.603,1.356c-0.401,0.381-1.015,0.571-1.837,0.571c-0.885,0-1.513-0.199-1.882-0.595
          c-0.37-0.397-0.566-0.889-0.592-1.474h0.951V49.134z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M172.722,45.334c0.186,0.129,0.376,0.315,0.569,0.562v-0.742h0.911v5.348c0,0.747-0.109,1.335-0.327,1.767
          c-0.408,0.798-1.181,1.197-2.316,1.197c-0.63,0-1.161-0.142-1.592-0.426c-0.43-0.284-0.672-0.727-0.722-1.331h1.004
          c0.047,0.263,0.142,0.466,0.284,0.61c0.222,0.219,0.571,0.329,1.048,0.329c0.753,0,1.247-0.268,1.48-0.802
          c0.137-0.314,0.202-0.877,0.19-1.685c-0.197,0.3-0.434,0.522-0.711,0.669c-0.276,0.147-0.644,0.219-1.1,0.219
          c-0.634,0-1.188-0.226-1.664-0.677c-0.476-0.451-0.714-1.2-0.714-2.243c0-0.985,0.24-1.754,0.719-2.308
          c0.479-0.552,1.058-0.829,1.737-0.829C171.979,44.994,172.378,45.107,172.722,45.334z M172.843,46.394
          c-0.298-0.352-0.679-0.527-1.142-0.527c-0.692,0-1.165,0.326-1.419,0.978c-0.134,0.348-0.201,0.803-0.201,1.368
          c0,0.663,0.134,1.167,0.401,1.513c0.268,0.347,0.627,0.519,1.079,0.519c0.706,0,1.203-0.319,1.492-0.961
          c0.16-0.363,0.24-0.786,0.24-1.27C173.291,47.286,173.141,46.745,172.843,46.394z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M163.202,45.097h0.94v0.835c0.277-0.344,0.572-0.59,0.884-0.74c0.311-0.152,0.656-0.226,1.039-0.226
          c0.835,0,1.398,0.291,1.69,0.873c0.161,0.318,0.242,0.772,0.242,1.368v3.772h-1.004v-3.706c0-0.359-0.053-0.648-0.16-0.868
          c-0.176-0.366-0.494-0.55-0.955-0.55c-0.235,0-0.427,0.026-0.576,0.073c-0.271,0.081-0.508,0.24-0.714,0.482
          c-0.165,0.195-0.271,0.396-0.321,0.602c-0.05,0.208-0.074,0.503-0.074,0.887v3.08h-0.989v-5.881H163.202z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M160.706,42.914h1.004v1.119h-1.004V42.914z M160.706,45.126h1.004v5.853h-1.004V45.126z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M154.454,45.097h0.94v0.835c0.277-0.344,0.573-0.59,0.884-0.74c0.312-0.152,0.656-0.226,1.039-0.226
          c0.835,0,1.398,0.291,1.69,0.873c0.162,0.318,0.242,0.772,0.242,1.368v3.772h-1.005v-3.706c0-0.359-0.053-0.648-0.16-0.868
          c-0.176-0.366-0.494-0.55-0.955-0.55c-0.235,0-0.427,0.026-0.576,0.073c-0.271,0.081-0.508,0.24-0.714,0.482
          c-0.165,0.195-0.271,0.396-0.321,0.602c-0.05,0.208-0.074,0.503-0.074,0.887v3.08h-0.988v-5.881H154.454z"
          />
        </g>
        <g enable-background="new">
          <path fill="#010202" d="M151.984,42.914h0.989v8.065h-0.989V42.914z" />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M148.857,47.419c0.228-0.029,0.379-0.123,0.455-0.286c0.044-0.088,0.066-0.214,0.066-0.379
          c0-0.338-0.12-0.582-0.36-0.735s-0.582-0.229-1.027-0.229c-0.514,0-0.88,0.139-1.095,0.419c-0.121,0.155-0.2,0.384-0.235,0.69
          h-0.923c0.018-0.727,0.253-1.233,0.705-1.516c0.451-0.286,0.976-0.427,1.571-0.427c0.692,0,1.251,0.13,1.684,0.395
          c0.427,0.263,0.642,0.672,0.642,1.23v3.388c0,0.102,0.021,0.184,0.063,0.247c0.042,0.062,0.131,0.094,0.266,0.094
          c0.043,0,0.094-0.003,0.148-0.01c0.055-0.005,0.113-0.013,0.176-0.024v0.729c-0.153,0.043-0.271,0.073-0.352,0.084
          s-0.19,0.016-0.331,0.016c-0.34,0-0.587-0.121-0.74-0.363c-0.081-0.127-0.137-0.308-0.169-0.543
          c-0.202,0.263-0.49,0.494-0.868,0.687s-0.792,0.292-1.247,0.292c-0.545,0-0.99-0.168-1.337-0.499s-0.519-0.745-0.519-1.242
          c0-0.545,0.169-0.968,0.509-1.268s0.787-0.484,1.34-0.553L148.857,47.419z M146.775,50.09c0.208,0.165,0.457,0.245,0.74,0.245
          c0.348,0,0.684-0.081,1.01-0.24c0.548-0.266,0.824-0.705,0.824-1.31V47.99c-0.121,0.078-0.275,0.142-0.466,0.194
          c-0.19,0.05-0.376,0.088-0.56,0.109l-0.599,0.076c-0.358,0.048-0.627,0.123-0.806,0.226c-0.305,0.171-0.458,0.445-0.458,0.822
          C146.462,49.701,146.567,49.925,146.775,50.09z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M142.777,43.457h1v1.642h0.94v0.808h-0.94v3.838c0,0.205,0.069,0.342,0.208,0.411
          c0.078,0.04,0.205,0.06,0.384,0.06c0.048,0,0.1,0,0.155-0.003c0.055-0.001,0.12-0.008,0.192-0.019v0.785
          c-0.114,0.034-0.232,0.057-0.354,0.071c-0.123,0.017-0.255,0.023-0.398,0.023c-0.461,0-0.774-0.118-0.938-0.355
          c-0.165-0.237-0.247-0.542-0.247-0.919v-3.892h-0.796v-0.808h0.796V43.457z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M139.96,45.26c0.388,0.195,0.685,0.448,0.888,0.761c0.197,0.297,0.328,0.642,0.394,1.039
          c0.058,0.271,0.087,0.703,0.087,1.297h-4.277c0.018,0.597,0.158,1.077,0.419,1.44c0.263,0.361,0.668,0.542,1.214,0.542
          c0.511,0,0.921-0.169,1.226-0.513c0.169-0.199,0.292-0.429,0.363-0.689h0.972c-0.026,0.216-0.111,0.457-0.256,0.721
          c-0.144,0.266-0.305,0.482-0.484,0.65c-0.298,0.293-0.667,0.492-1.106,0.593c-0.235,0.058-0.503,0.087-0.801,0.087
          c-0.728,0-1.343-0.266-1.85-0.798s-0.758-1.277-0.758-2.237c0-0.945,0.255-1.713,0.765-2.301c0.51-0.589,1.176-0.884,1.997-0.884
          C139.168,44.966,139.57,45.065,139.96,45.26z M140.313,47.569c-0.039-0.429-0.132-0.771-0.277-1.027
          c-0.27-0.476-0.718-0.713-1.345-0.713c-0.45,0-0.827,0.165-1.132,0.492c-0.305,0.327-0.466,0.743-0.484,1.248H140.313z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M129.994,42.884h0.961v2.926c0.216-0.281,0.476-0.497,0.777-0.644c0.301-0.148,0.629-0.223,0.98-0.223
          c0.735,0,1.331,0.25,1.789,0.755s0.687,1.245,0.687,2.227c0,0.929-0.226,1.701-0.678,2.317c-0.451,0.615-1.079,0.922-1.88,0.922
          c-0.448,0-0.827-0.108-1.135-0.324c-0.184-0.127-0.38-0.333-0.59-0.616v0.753h-0.911V42.884L129.994,42.884z M133.772,49.656
          c0.266-0.426,0.4-0.984,0.4-1.681c0-0.619-0.134-1.13-0.4-1.537c-0.268-0.406-0.66-0.61-1.176-0.61
          c-0.452,0-0.847,0.168-1.187,0.5c-0.338,0.334-0.509,0.882-0.509,1.646c0,0.553,0.069,1.001,0.209,1.345
          c0.261,0.648,0.747,0.973,1.459,0.973C133.104,50.292,133.506,50.079,133.772,49.656z"
          />
        </g>
        <g enable-background="new">
          <path fill="#010202" d="M124.48,42.914h0.989v8.065h-0.989V42.914z" />
        </g>
        <g enable-background="new">
          <path fill="#010202" d="M121.985,42.914h0.988v8.065h-0.988V42.914z" />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M119.463,42.914h1.005v1.119h-1.005V42.914L119.463,42.914z M119.463,45.126h1.005v5.853h-1.005V45.126
          L119.463,45.126z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M116.533,43.457h1v1.642h0.94v0.808h-0.94v3.838c0,0.205,0.069,0.342,0.208,0.411
          c0.077,0.04,0.205,0.06,0.384,0.06c0.048,0,0.1,0,0.155-0.003c0.055-0.001,0.119-0.008,0.192-0.019v0.785
          c-0.115,0.034-0.232,0.057-0.355,0.071c-0.123,0.017-0.254,0.023-0.398,0.023c-0.461,0-0.774-0.118-0.939-0.355
          c-0.165-0.237-0.247-0.542-0.247-0.919v-3.892h-0.797v-0.808h0.797V43.457z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M110.59,45.26c0.389,0.195,0.686,0.448,0.889,0.761c0.197,0.297,0.328,0.642,0.394,1.039
          c0.058,0.271,0.087,0.703,0.087,1.297h-4.277c0.018,0.597,0.158,1.077,0.419,1.44c0.263,0.361,0.667,0.542,1.214,0.542
          c0.511,0,0.921-0.169,1.226-0.513c0.169-0.199,0.292-0.429,0.363-0.689h0.973c-0.026,0.216-0.111,0.457-0.256,0.721
          c-0.144,0.266-0.305,0.482-0.484,0.65c-0.298,0.293-0.667,0.492-1.106,0.593c-0.235,0.058-0.503,0.087-0.801,0.087
          c-0.728,0-1.343-0.266-1.85-0.798c-0.506-0.532-0.758-1.277-0.758-2.237c0-0.945,0.255-1.713,0.765-2.301
          c0.509-0.589,1.175-0.884,1.996-0.884C109.798,44.966,110.2,45.065,110.59,45.26z M110.943,47.569
          c-0.039-0.429-0.132-0.771-0.277-1.027c-0.27-0.476-0.718-0.713-1.345-0.713c-0.45,0-0.827,0.165-1.132,0.492
          c-0.305,0.327-0.466,0.743-0.483,1.248H110.943z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M103.938,45.279c0.174,0.11,0.375,0.303,0.597,0.576v-2.971h0.95v8.094h-0.889v-0.817
          c-0.23,0.363-0.501,0.624-0.814,0.784s-0.672,0.24-1.077,0.24c-0.653,0-1.218-0.274-1.697-0.826
          c-0.477-0.552-0.716-1.284-0.716-2.198c0-0.856,0.218-1.598,0.653-2.227c0.437-0.629,1.058-0.942,1.868-0.942
          C103.26,44.994,103.636,45.091,103.938,45.279z M101.733,49.688c0.266,0.424,0.692,0.637,1.277,0.637
          c0.455,0,0.829-0.197,1.123-0.59c0.293-0.392,0.44-0.958,0.44-1.693c0-0.744-0.152-1.293-0.453-1.65
          c-0.303-0.356-0.676-0.535-1.12-0.535c-0.495,0-0.896,0.19-1.205,0.571c-0.306,0.38-0.461,0.94-0.461,1.68
          C101.333,48.735,101.465,49.263,101.733,49.688z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M94.455,45.097h0.94v0.835c0.278-0.344,0.573-0.59,0.884-0.74c0.312-0.152,0.657-0.226,1.039-0.226
          c0.835,0,1.398,0.291,1.69,0.873c0.161,0.318,0.242,0.772,0.242,1.368v3.772h-1.005v-3.706c0-0.359-0.053-0.648-0.16-0.868
          c-0.176-0.366-0.493-0.55-0.955-0.55c-0.235,0-0.427,0.026-0.576,0.073c-0.271,0.081-0.508,0.24-0.714,0.482
          c-0.165,0.195-0.271,0.396-0.321,0.602c-0.05,0.208-0.074,0.503-0.074,0.887v3.08h-0.99V45.097z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M91.354,47.419c0.227-0.029,0.379-0.123,0.455-0.286c0.043-0.088,0.066-0.214,0.066-0.379
          c0-0.338-0.12-0.582-0.36-0.735s-0.582-0.229-1.027-0.229c-0.514,0-0.88,0.139-1.095,0.419c-0.121,0.155-0.2,0.384-0.235,0.69
          h-0.922c0.018-0.727,0.253-1.233,0.705-1.516c0.452-0.286,0.976-0.427,1.571-0.427c0.692,0,1.251,0.13,1.684,0.395
          c0.428,0.263,0.642,0.672,0.642,1.23v3.388c0,0.102,0.021,0.184,0.063,0.247c0.042,0.062,0.13,0.094,0.266,0.094
          c0.043,0,0.093-0.003,0.148-0.01c0.055-0.005,0.113-0.013,0.176-0.024v0.729c-0.153,0.043-0.271,0.073-0.352,0.084
          c-0.081,0.011-0.19,0.016-0.331,0.016c-0.34,0-0.587-0.121-0.74-0.363c-0.081-0.127-0.137-0.308-0.169-0.543
          c-0.202,0.263-0.49,0.494-0.868,0.687s-0.792,0.292-1.247,0.292c-0.545,0-0.99-0.168-1.337-0.499s-0.52-0.745-0.52-1.242
          c0-0.545,0.169-0.968,0.51-1.268s0.787-0.484,1.34-0.553L91.354,47.419z M89.273,50.09c0.208,0.165,0.457,0.245,0.74,0.245
          c0.348,0,0.684-0.081,1.01-0.24c0.548-0.266,0.824-0.705,0.824-1.31V47.99c-0.121,0.078-0.275,0.142-0.466,0.194
          c-0.19,0.05-0.376,0.088-0.56,0.109l-0.599,0.076c-0.358,0.048-0.627,0.123-0.806,0.226c-0.305,0.171-0.458,0.445-0.458,0.822
          C88.96,49.701,89.065,49.925,89.273,50.09z"
          />
        </g>
        <g enable-background="new">
          <path fill="#010202" d="M85.733,42.914h0.989v8.065h-0.989V42.914z" />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M83.087,45.26c0.389,0.195,0.685,0.448,0.888,0.761c0.197,0.297,0.328,0.642,0.394,1.039
          c0.058,0.271,0.087,0.703,0.087,1.297H80.18c0.018,0.597,0.158,1.077,0.419,1.44c0.263,0.361,0.668,0.542,1.214,0.542
          c0.511,0,0.921-0.169,1.226-0.513c0.169-0.199,0.292-0.429,0.363-0.689h0.972c-0.026,0.216-0.111,0.457-0.256,0.721
          c-0.144,0.266-0.305,0.482-0.484,0.65c-0.298,0.293-0.667,0.492-1.106,0.593c-0.236,0.058-0.503,0.087-0.802,0.087
          c-0.727,0-1.343-0.266-1.85-0.798s-0.758-1.277-0.758-2.237c0-0.945,0.255-1.713,0.765-2.301c0.51-0.589,1.176-0.884,1.997-0.884
          C82.295,44.966,82.697,45.065,83.087,45.26z M83.44,47.569c-0.039-0.429-0.132-0.771-0.277-1.027
          c-0.27-0.476-0.718-0.713-1.345-0.713c-0.45,0-0.827,0.165-1.132,0.492c-0.305,0.327-0.466,0.743-0.484,1.248H83.44z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M76.435,45.279c0.174,0.11,0.374,0.303,0.597,0.576v-2.971h0.95v8.094h-0.889v-0.817
          c-0.23,0.363-0.501,0.624-0.814,0.784c-0.312,0.16-0.672,0.24-1.077,0.24c-0.653,0-1.218-0.274-1.696-0.826
          c-0.478-0.552-0.716-1.284-0.716-2.198c0-0.856,0.218-1.598,0.653-2.227c0.437-0.629,1.058-0.942,1.867-0.942
          C75.756,44.994,76.132,45.091,76.435,45.279z M74.229,49.688c0.266,0.424,0.692,0.637,1.277,0.637c0.455,0,0.829-0.197,1.123-0.59
          c0.293-0.392,0.44-0.958,0.44-1.693c0-0.744-0.152-1.293-0.453-1.65c-0.303-0.356-0.676-0.535-1.12-0.535
          c-0.495,0-0.896,0.19-1.205,0.571c-0.306,0.38-0.461,0.94-0.461,1.68C73.829,48.735,73.962,49.263,74.229,49.688z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M70.183,45.279c0.174,0.11,0.374,0.303,0.597,0.576v-2.971h0.95v8.094H70.84v-0.817
          c-0.23,0.363-0.501,0.624-0.814,0.784c-0.312,0.16-0.672,0.24-1.077,0.24c-0.653,0-1.217-0.274-1.696-0.826
          c-0.478-0.552-0.716-1.284-0.716-2.198c0-0.856,0.218-1.598,0.653-2.227c0.437-0.629,1.058-0.942,1.867-0.942
          C69.503,44.994,69.879,45.091,70.183,45.279z M67.977,49.688c0.266,0.424,0.691,0.637,1.277,0.637c0.455,0,0.829-0.197,1.123-0.59
          c0.293-0.392,0.44-0.958,0.44-1.693c0-0.744-0.152-1.293-0.453-1.65c-0.303-0.356-0.676-0.535-1.119-0.535
          c-0.495,0-0.897,0.19-1.205,0.571c-0.306,0.38-0.461,0.94-0.461,1.68C67.576,48.735,67.71,49.263,67.977,49.688z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M64.332,45.26c0.389,0.195,0.686,0.448,0.889,0.761c0.197,0.297,0.328,0.642,0.394,1.039
          c0.058,0.271,0.087,0.703,0.087,1.297h-4.277c0.018,0.597,0.158,1.077,0.419,1.44c0.263,0.361,0.667,0.542,1.214,0.542
          c0.511,0,0.921-0.169,1.226-0.513c0.169-0.199,0.292-0.429,0.363-0.689h0.973c-0.026,0.216-0.111,0.457-0.256,0.721
          c-0.144,0.266-0.305,0.482-0.484,0.65c-0.298,0.293-0.667,0.492-1.106,0.593c-0.235,0.058-0.503,0.087-0.801,0.087
          c-0.728,0-1.344-0.266-1.85-0.798s-0.758-1.277-0.758-2.237c0-0.945,0.255-1.713,0.765-2.301c0.509-0.589,1.175-0.884,1.996-0.884
          C63.54,44.966,63.941,45.065,64.332,45.26z M64.685,47.569c-0.039-0.429-0.132-0.771-0.278-1.027
          c-0.269-0.476-0.717-0.713-1.345-0.713c-0.45,0-0.827,0.165-1.132,0.492c-0.305,0.327-0.466,0.743-0.483,1.248H64.685z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M51.438,42.914h1.566l2.319,6.82l2.303-6.82h1.555v8.065h-1.043v-4.761c0-0.165,0.003-0.437,0.011-0.817
          c0.008-0.381,0.012-0.789,0.012-1.224l-2.303,6.802h-1.082l-2.324-6.802v0.245c0,0.198,0.005,0.5,0.016,0.905
          s0.016,0.703,0.016,0.892v4.761H51.44v-8.065H51.438z"
          />
        </g>
      </g>
      <g enable-background="new">
        <path
          fill="#010202"
          d="M514.084,100.745h3.486c0.952,0,1.629,0.282,2.029,0.845c0.235,0.333,0.354,0.716,0.354,1.153
        c0,0.508-0.144,0.926-0.434,1.251c-0.151,0.171-0.366,0.329-0.648,0.471c0.414,0.158,0.724,0.335,0.929,0.534
        c0.363,0.352,0.542,0.837,0.542,1.453c0,0.52-0.164,0.99-0.492,1.411c-0.488,0.631-1.268,0.945-2.336,0.945h-3.431V100.745
        L514.084,100.745z M517.166,104.155c0.466,0,0.829-0.064,1.087-0.192c0.406-0.202,0.611-0.564,0.611-1.088
        c0-0.526-0.218-0.882-0.648-1.064c-0.245-0.102-0.608-0.155-1.089-0.155h-1.971v2.5H517.166L517.166,104.155z M517.533,107.876
        c0.678,0,1.16-0.194,1.445-0.581c0.183-0.245,0.272-0.542,0.272-0.891c0-0.585-0.265-0.983-0.792-1.196
        c-0.28-0.113-0.651-0.169-1.114-0.169h-2.188v2.838h2.377V107.876z"
        />
        <path
          fill="#010202"
          d="M525.111,103.091c0.391,0.195,0.688,0.448,0.891,0.761c0.196,0.297,0.327,0.642,0.392,1.039
        c0.06,0.271,0.089,0.703,0.089,1.297h-4.276c0.019,0.597,0.157,1.077,0.419,1.44c0.263,0.361,0.668,0.542,1.216,0.542
        c0.511,0,0.919-0.169,1.226-0.513c0.17-0.198,0.294-0.429,0.364-0.688h0.973c-0.025,0.216-0.109,0.457-0.255,0.721
        c-0.145,0.266-0.305,0.482-0.485,0.649c-0.297,0.294-0.666,0.492-1.106,0.594c-0.235,0.058-0.503,0.087-0.8,0.087
        c-0.728,0-1.345-0.266-1.85-0.798s-0.758-1.277-0.758-2.237c0-0.945,0.253-1.712,0.763-2.301c0.51-0.588,1.176-0.884,1.996-0.884
        C524.321,102.799,524.723,102.896,525.111,103.091z M525.467,105.4c-0.039-0.429-0.133-0.771-0.277-1.027
        c-0.27-0.476-0.717-0.713-1.346-0.713c-0.449,0-0.827,0.165-1.132,0.492c-0.305,0.328-0.466,0.744-0.485,1.249H525.467z"
        />
        <path
          fill="#010202"
          d="M527.931,101.288h1v1.642h0.94v0.808h-0.94v3.838c0,0.205,0.071,0.342,0.21,0.411
        c0.075,0.041,0.204,0.06,0.384,0.06c0.046,0,0.098,0,0.154-0.003c0.054-0.001,0.118-0.008,0.192-0.02v0.786
        c-0.115,0.034-0.234,0.056-0.356,0.071c-0.123,0.016-0.255,0.022-0.397,0.022c-0.461,0-0.773-0.118-0.938-0.354
        c-0.166-0.235-0.248-0.542-0.248-0.919v-3.891h-0.795v-0.808h0.795V101.288z"
        />
        <path
          fill="#010202"
          d="M534.011,105.25c0.228-0.029,0.377-0.123,0.454-0.286c0.044-0.089,0.066-0.214,0.066-0.379
        c0-0.339-0.119-0.582-0.359-0.735s-0.581-0.229-1.029-0.229c-0.513,0-0.879,0.139-1.095,0.419c-0.121,0.155-0.2,0.384-0.235,0.69
        h-0.923c0.019-0.727,0.252-1.233,0.705-1.516c0.451-0.286,0.974-0.428,1.571-0.428c0.693,0,1.253,0.131,1.685,0.396
        c0.428,0.263,0.641,0.672,0.641,1.23v3.388c0,0.102,0.021,0.184,0.062,0.247c0.042,0.062,0.131,0.094,0.267,0.094
        c0.043,0,0.095-0.003,0.148-0.01c0.055-0.005,0.112-0.013,0.176-0.024v0.729c-0.155,0.043-0.271,0.073-0.352,0.084
        c-0.081,0.011-0.19,0.016-0.329,0.016c-0.341,0-0.589-0.121-0.742-0.363c-0.08-0.127-0.137-0.308-0.169-0.543
        c-0.202,0.263-0.49,0.493-0.868,0.687c-0.377,0.193-0.792,0.292-1.246,0.292c-0.545,0-0.989-0.167-1.336-0.498
        s-0.519-0.745-0.519-1.242c0-0.545,0.169-0.968,0.51-1.268c0.34-0.3,0.786-0.484,1.34-0.553L534.011,105.25z M531.929,107.921
        c0.209,0.165,0.456,0.245,0.741,0.245c0.349,0,0.686-0.081,1.01-0.24c0.55-0.266,0.826-0.705,0.826-1.31v-0.795
        c-0.121,0.077-0.277,0.142-0.467,0.193c-0.189,0.05-0.375,0.089-0.56,0.11l-0.597,0.076c-0.359,0.048-0.627,0.123-0.808,0.226
        c-0.305,0.171-0.458,0.445-0.458,0.823C531.615,107.532,531.721,107.756,531.929,107.921z"
        />
        <path fill="#010202" d="M537.139,100.745h0.987v8.065h-0.987V100.745z" />
        <path
          fill="#010202"
          d="M539.611,102.93h0.938v0.835c0.277-0.344,0.572-0.59,0.884-0.74c0.31-0.152,0.656-0.226,1.039-0.226
        c0.835,0,1.397,0.29,1.691,0.872c0.161,0.318,0.24,0.772,0.24,1.368v3.772h-1.005v-3.706c0-0.359-0.052-0.648-0.159-0.867
        c-0.176-0.366-0.495-0.55-0.955-0.55c-0.235,0-0.429,0.026-0.576,0.073c-0.271,0.081-0.509,0.24-0.714,0.482
        c-0.165,0.195-0.271,0.396-0.321,0.602c-0.048,0.208-0.074,0.503-0.074,0.887v3.08h-0.986v-5.881H539.611z"
        />
        <path
          fill="#010202"
          d="M545.862,100.745h1.005v1.119h-1.005V100.745z M545.862,102.957h1.005v5.853h-1.005V102.957z"
        />
        <path
          fill="#010202"
          d="M548.36,102.93h0.94v0.835c0.278-0.344,0.57-0.59,0.883-0.74c0.312-0.152,0.657-0.226,1.039-0.226
        c0.835,0,1.398,0.29,1.69,0.872c0.163,0.318,0.241,0.772,0.241,1.368v3.772h-1.004v-3.706c0-0.359-0.054-0.648-0.16-0.867
        c-0.174-0.366-0.493-0.55-0.956-0.55c-0.234,0-0.426,0.026-0.576,0.073c-0.271,0.081-0.506,0.24-0.713,0.482
        c-0.166,0.195-0.272,0.396-0.319,0.602c-0.05,0.208-0.075,0.503-0.075,0.887v3.08h-0.988v-5.881H548.36z"
        />
        <path
          fill="#010202"
          d="M557.887,103.165c0.185,0.129,0.374,0.314,0.567,0.562v-0.742h0.911v5.348c0,0.747-0.108,1.335-0.327,1.768
        c-0.408,0.798-1.179,1.197-2.316,1.197c-0.632,0-1.161-0.142-1.592-0.426s-0.671-0.728-0.722-1.331h1.004
        c0.047,0.263,0.144,0.466,0.286,0.61c0.221,0.219,0.57,0.329,1.046,0.329c0.755,0,1.247-0.268,1.48-0.801
        c0.138-0.314,0.202-0.877,0.19-1.686c-0.196,0.3-0.436,0.522-0.711,0.669c-0.276,0.147-0.642,0.219-1.1,0.219
        c-0.634,0-1.188-0.226-1.663-0.677c-0.478-0.452-0.715-1.2-0.715-2.243c0-0.985,0.239-1.754,0.72-2.308
        c0.479-0.552,1.058-0.829,1.737-0.829C557.142,102.825,557.543,102.939,557.887,103.165z M558.006,104.225
        c-0.3-0.352-0.679-0.527-1.142-0.527c-0.692,0-1.165,0.326-1.42,0.978c-0.135,0.348-0.203,0.803-0.203,1.367
        c0,0.663,0.134,1.168,0.401,1.513c0.269,0.347,0.628,0.52,1.079,0.52c0.706,0,1.203-0.319,1.492-0.961
        c0.161-0.363,0.238-0.785,0.238-1.269C558.452,105.117,558.304,104.576,558.006,104.225z"
        />
        <path
          fill="#010202"
          d="M561.455,106.966c0.03,0.329,0.114,0.581,0.253,0.758c0.252,0.318,0.693,0.478,1.321,0.478
        c0.372,0,0.703-0.081,0.985-0.239c0.285-0.16,0.427-0.405,0.427-0.739c0-0.251-0.112-0.443-0.34-0.576
        c-0.146-0.081-0.433-0.172-0.859-0.279l-0.797-0.198c-0.51-0.125-0.887-0.263-1.127-0.418c-0.433-0.266-0.648-0.637-0.648-1.109
        c0-0.557,0.205-1.006,0.611-1.352c0.406-0.343,0.951-0.515,1.639-0.515c0.897,0,1.545,0.26,1.941,0.779
        c0.248,0.329,0.369,0.684,0.361,1.064h-0.933c-0.02-0.223-0.1-0.424-0.241-0.606c-0.232-0.258-0.634-0.387-1.207-0.387
        c-0.384,0-0.672,0.071-0.869,0.213c-0.196,0.144-0.297,0.331-0.297,0.564c0,0.255,0.129,0.459,0.389,0.613
        c0.15,0.092,0.37,0.171,0.66,0.24l0.664,0.161c0.725,0.171,1.21,0.339,1.455,0.5c0.384,0.252,0.575,0.648,0.575,1.19
        c0,0.524-0.201,0.976-0.603,1.356c-0.402,0.381-1.015,0.571-1.839,0.571c-0.886,0-1.513-0.199-1.88-0.595
        c-0.368-0.397-0.568-0.889-0.593-1.474h0.95V106.966z"
        />
        <path
          fill="#010202"
          d="M566.49,102.93h0.976v0.835c0.235-0.289,0.446-0.5,0.639-0.632c0.325-0.223,0.694-0.333,1.109-0.333
        c0.468,0,0.844,0.114,1.13,0.345c0.162,0.132,0.306,0.326,0.438,0.583c0.221-0.315,0.479-0.548,0.774-0.702
        c0.297-0.152,0.63-0.228,0.998-0.228c0.79,0,1.329,0.286,1.616,0.855c0.152,0.308,0.23,0.721,0.23,1.242v3.916h-1.027v-4.085
        c0-0.39-0.099-0.66-0.294-0.806c-0.196-0.147-0.436-0.221-0.718-0.221c-0.387,0-0.721,0.131-1.001,0.39
        c-0.28,0.26-0.419,0.694-0.419,1.302v3.42h-1.005v-3.838c0-0.398-0.047-0.69-0.144-0.872c-0.15-0.274-0.431-0.413-0.841-0.413
        c-0.374,0-0.712,0.145-1.019,0.436c-0.307,0.289-0.458,0.812-0.458,1.571v3.119h-0.989v-5.881H566.49z"
        />
        <path
          fill="#010202"
          d="M580.119,103.517c0.519,0.503,0.778,1.247,0.778,2.227c0,0.947-0.23,1.729-0.687,2.348
        c-0.458,0.618-1.17,0.926-2.132,0.926c-0.804,0-1.442-0.272-1.915-0.819c-0.472-0.546-0.708-1.282-0.708-2.203
        c0-0.987,0.25-1.774,0.749-2.358c0.498-0.585,1.166-0.879,2.006-0.879C578.963,102.759,579.598,103.012,580.119,103.517z
         M579.506,107.424c0.239-0.495,0.359-1.045,0.359-1.653c0-0.548-0.085-0.993-0.26-1.337c-0.275-0.542-0.748-0.811-1.422-0.811
        c-0.599,0-1.032,0.231-1.305,0.69c-0.271,0.461-0.406,1.018-0.406,1.667c0,0.625,0.136,1.146,0.406,1.562
        c0.272,0.418,0.703,0.626,1.293,0.626C578.819,108.167,579.263,107.921,579.506,107.424z"
        />
        <path
          fill="#010202"
          d="M582.309,101.288h0.998v1.642h0.939v0.808h-0.939v3.838c0,0.205,0.069,0.342,0.208,0.411
        c0.079,0.041,0.207,0.06,0.384,0.06c0.049,0,0.101,0,0.155-0.003c0.055-0.001,0.119-0.008,0.192-0.02v0.786
        c-0.113,0.034-0.231,0.056-0.354,0.071c-0.123,0.016-0.255,0.022-0.4,0.022c-0.459,0-0.772-0.118-0.938-0.354
        c-0.164-0.235-0.245-0.542-0.245-0.919v-3.891h-0.797v-0.808h0.797V101.288z"
        />
        <path
          fill="#010202"
          d="M585.435,101.288h0.998v1.642h0.938v0.808h-0.938v3.838c0,0.205,0.069,0.342,0.208,0.411
        c0.079,0.041,0.206,0.06,0.384,0.06c0.048,0,0.1,0,0.154-0.003c0.056-0.001,0.12-0.008,0.192-0.02v0.786
        c-0.113,0.034-0.23,0.056-0.354,0.071c-0.122,0.016-0.255,0.022-0.399,0.022c-0.46,0-0.772-0.118-0.938-0.354
        c-0.165-0.235-0.245-0.542-0.245-0.919v-3.891h-0.797v-0.808h0.797V101.288z"
        />
        <path
          fill="#010202"
          d="M591.511,105.25c0.228-0.029,0.379-0.123,0.455-0.286c0.043-0.089,0.065-0.214,0.065-0.379
        c0-0.339-0.119-0.582-0.359-0.735s-0.582-0.229-1.027-0.229c-0.516,0-0.882,0.139-1.097,0.419c-0.119,0.155-0.198,0.384-0.235,0.69
        h-0.922c0.02-0.727,0.255-1.233,0.706-1.516c0.45-0.286,0.976-0.428,1.572-0.428c0.69,0,1.25,0.131,1.683,0.396
        c0.429,0.263,0.642,0.672,0.642,1.23v3.388c0,0.102,0.022,0.184,0.062,0.247c0.042,0.062,0.131,0.094,0.267,0.094
        c0.043,0,0.093-0.003,0.149-0.01c0.054-0.005,0.113-0.013,0.175-0.024v0.729c-0.153,0.043-0.271,0.073-0.352,0.084
        c-0.083,0.011-0.19,0.016-0.331,0.016c-0.34,0-0.585-0.121-0.74-0.363c-0.081-0.127-0.137-0.308-0.169-0.543
        c-0.202,0.263-0.492,0.493-0.868,0.687c-0.376,0.193-0.792,0.292-1.245,0.292c-0.545,0-0.991-0.167-1.337-0.498
        c-0.347-0.331-0.518-0.745-0.518-1.242c0-0.545,0.17-0.968,0.51-1.268c0.341-0.3,0.787-0.484,1.339-0.553L591.511,105.25z
         M589.431,107.921c0.208,0.165,0.454,0.245,0.74,0.245c0.349,0,0.684-0.081,1.011-0.24c0.549-0.266,0.822-0.705,0.822-1.31v-0.795
        c-0.119,0.077-0.273,0.142-0.464,0.193c-0.19,0.05-0.376,0.089-0.56,0.11l-0.599,0.076c-0.356,0.048-0.627,0.123-0.806,0.226
        c-0.306,0.171-0.459,0.445-0.459,0.823C589.117,107.532,589.221,107.756,589.431,107.921z"
        />
        <path
          fill="#010202"
          d="M597.881,103.165c0.186,0.129,0.374,0.314,0.569,0.562v-0.742h0.911v5.348c0,0.747-0.109,1.335-0.327,1.768
        c-0.41,0.798-1.181,1.197-2.316,1.197c-0.632,0-1.162-0.142-1.593-0.426s-0.671-0.728-0.721-1.331h1.004
        c0.047,0.263,0.143,0.466,0.284,0.61c0.223,0.219,0.571,0.329,1.048,0.329c0.754,0,1.247-0.268,1.48-0.801
        c0.138-0.314,0.202-0.877,0.189-1.686c-0.195,0.3-0.435,0.522-0.71,0.669s-0.644,0.219-1.1,0.219c-0.636,0-1.188-0.226-1.665-0.677
        c-0.476-0.452-0.714-1.2-0.714-2.243c0-0.985,0.24-1.754,0.721-2.308c0.479-0.552,1.058-0.829,1.735-0.829
        C597.138,102.825,597.537,102.939,597.881,103.165z M598.002,104.225c-0.299-0.352-0.678-0.527-1.141-0.527
        c-0.693,0-1.166,0.326-1.42,0.978c-0.136,0.348-0.202,0.803-0.202,1.367c0,0.663,0.132,1.168,0.4,1.513
        c0.268,0.347,0.629,0.52,1.08,0.52c0.706,0,1.203-0.319,1.49-0.961c0.161-0.363,0.24-0.785,0.24-1.269
        C598.45,105.117,598.3,104.576,598.002,104.225z"
        />
        <path
          fill="#010202"
          d="M604.018,105.25c0.227-0.029,0.379-0.123,0.456-0.286c0.044-0.089,0.066-0.214,0.066-0.379
        c0-0.339-0.12-0.582-0.36-0.735s-0.582-0.229-1.028-0.229c-0.515,0-0.881,0.139-1.096,0.419c-0.12,0.155-0.199,0.384-0.235,0.69
        h-0.922c0.02-0.727,0.253-1.233,0.706-1.516c0.45-0.286,0.974-0.428,1.571-0.428c0.691,0,1.251,0.131,1.684,0.396
        c0.429,0.263,0.642,0.672,0.642,1.23v3.388c0,0.102,0.021,0.184,0.062,0.247c0.041,0.062,0.129,0.094,0.265,0.094
        c0.044,0,0.096-0.003,0.15-0.01c0.055-0.005,0.112-0.013,0.174-0.024v0.729c-0.153,0.043-0.271,0.073-0.35,0.084
        c-0.083,0.011-0.192,0.016-0.331,0.016c-0.34,0-0.587-0.121-0.74-0.363c-0.082-0.127-0.139-0.308-0.169-0.543
        c-0.202,0.263-0.492,0.493-0.869,0.687c-0.376,0.193-0.791,0.292-1.245,0.292c-0.545,0-0.99-0.167-1.337-0.498
        s-0.518-0.745-0.518-1.242c0-0.545,0.169-0.968,0.509-1.268c0.341-0.3,0.787-0.484,1.341-0.553L604.018,105.25z M601.937,107.921
        c0.21,0.165,0.455,0.245,0.742,0.245c0.347,0,0.684-0.081,1.01-0.24c0.55-0.266,0.824-0.705,0.824-1.31v-0.795
        c-0.12,0.077-0.276,0.142-0.467,0.193c-0.188,0.05-0.374,0.089-0.558,0.11l-0.599,0.076c-0.357,0.048-0.627,0.123-0.806,0.226
        c-0.306,0.171-0.46,0.445-0.46,0.823C601.624,107.532,601.728,107.756,601.937,107.921z"
        />
        <path
          fill="#010202"
          d="M607.146,102.93h0.938v1.016c0.078-0.197,0.267-0.437,0.566-0.721c0.302-0.284,0.646-0.425,1.039-0.425
        c0.019,0,0.048,0.001,0.093,0.004c0.046,0.003,0.12,0.011,0.228,0.023v1.042c-0.06-0.011-0.112-0.018-0.164-0.022
        c-0.049-0.003-0.102-0.005-0.161-0.005c-0.499,0-0.883,0.161-1.148,0.48c-0.267,0.319-0.401,0.69-0.401,1.108v3.382h-0.987v-5.881
        H607.146z"
        />
        <path
          fill="#010202"
          d="M614.496,103.091c0.389,0.195,0.688,0.448,0.889,0.761c0.198,0.297,0.328,0.642,0.394,1.039
        c0.059,0.271,0.089,0.703,0.089,1.297h-4.277c0.02,0.597,0.158,1.077,0.42,1.44c0.261,0.361,0.668,0.542,1.216,0.542
        c0.51,0,0.919-0.169,1.226-0.513c0.17-0.198,0.292-0.429,0.363-0.688h0.974c-0.027,0.216-0.111,0.457-0.257,0.721
        c-0.143,0.266-0.305,0.482-0.483,0.649c-0.298,0.294-0.668,0.492-1.106,0.594c-0.235,0.058-0.503,0.087-0.801,0.087
        c-0.728,0-1.344-0.266-1.851-0.798c-0.503-0.532-0.758-1.277-0.758-2.237c0-0.945,0.253-1.712,0.765-2.301
        c0.511-0.588,1.176-0.884,1.996-0.884C613.706,102.799,614.106,102.896,614.496,103.091z M614.85,105.4
        c-0.037-0.429-0.132-0.771-0.277-1.027c-0.268-0.476-0.716-0.713-1.345-0.713c-0.45,0-0.827,0.165-1.131,0.492
        c-0.305,0.328-0.466,0.744-0.485,1.249H614.85z"
        />
        <path
          fill="#010202"
          d="M622.844,100.612c-0.574,1.116-0.948,1.938-1.119,2.464c-0.26,0.801-0.389,1.727-0.389,2.778
        c0,1.061,0.148,2.032,0.443,2.911c0.183,0.542,0.543,1.321,1.08,2.338h-0.662c-0.534-0.835-0.866-1.368-0.994-1.597
        c-0.129-0.232-0.268-0.543-0.417-0.938c-0.207-0.542-0.349-1.121-0.429-1.735c-0.039-0.318-0.062-0.623-0.062-0.911
        c0-1.083,0.169-2.048,0.511-2.893c0.217-0.539,0.666-1.343,1.35-2.416h0.688V100.612z"
        />
        <path
          fill="#010202"
          d="M627.619,103.091c0.389,0.195,0.687,0.448,0.889,0.761c0.198,0.297,0.327,0.642,0.393,1.039
        c0.059,0.271,0.089,0.703,0.089,1.297h-4.276c0.02,0.597,0.158,1.077,0.419,1.44c0.262,0.361,0.668,0.542,1.216,0.542
        c0.51,0,0.92-0.169,1.226-0.513c0.17-0.198,0.292-0.429,0.363-0.688h0.974c-0.027,0.216-0.111,0.457-0.256,0.721
        c-0.144,0.266-0.305,0.482-0.484,0.649c-0.298,0.294-0.667,0.492-1.105,0.594c-0.236,0.058-0.504,0.087-0.802,0.087
        c-0.728,0-1.344-0.266-1.85-0.798c-0.504-0.532-0.758-1.277-0.758-2.237c0-0.945,0.253-1.712,0.764-2.301
        c0.512-0.588,1.176-0.884,1.997-0.884C626.828,102.799,627.229,102.896,627.619,103.091z M627.972,105.4
        c-0.037-0.429-0.132-0.771-0.277-1.027c-0.268-0.476-0.716-0.713-1.345-0.713c-0.45,0-0.827,0.165-1.131,0.492
        c-0.305,0.328-0.466,0.744-0.485,1.249H627.972z"
        />
        <path
          fill="#010202"
          d="M630.239,102.93h0.938v0.835c0.279-0.344,0.573-0.59,0.886-0.74c0.31-0.152,0.654-0.226,1.037-0.226
        c0.835,0,1.398,0.29,1.691,0.872c0.161,0.318,0.242,0.772,0.242,1.368v3.772h-1.005v-3.706c0-0.359-0.053-0.648-0.161-0.867
        c-0.175-0.366-0.493-0.55-0.955-0.55c-0.235,0-0.427,0.026-0.575,0.073c-0.271,0.081-0.508,0.24-0.715,0.482
        c-0.164,0.195-0.271,0.396-0.319,0.602c-0.048,0.208-0.074,0.503-0.074,0.887v3.08h-0.988v-5.881H630.239z"
        />
        <path
          fill="#010202"
          d="M639.724,103.11c0.176,0.11,0.374,0.303,0.597,0.576v-2.971h0.95v8.094h-0.889v-0.818
        c-0.23,0.363-0.503,0.625-0.816,0.784c-0.312,0.159-0.672,0.24-1.077,0.24c-0.651,0-1.219-0.274-1.696-0.826
        c-0.478-0.552-0.716-1.284-0.716-2.198c0-0.856,0.217-1.598,0.652-2.227s1.059-0.942,1.868-0.942
        C639.046,102.825,639.421,102.921,639.724,103.11z M637.518,107.52c0.265,0.424,0.691,0.637,1.279,0.637
        c0.453,0,0.828-0.197,1.12-0.59c0.294-0.392,0.44-0.958,0.44-1.693c0-0.744-0.151-1.293-0.453-1.65
        c-0.303-0.356-0.678-0.536-1.121-0.536c-0.495,0-0.896,0.19-1.203,0.571c-0.308,0.381-0.463,0.94-0.463,1.681
        C637.117,106.568,637.251,107.095,637.518,107.52z"
        />
        <path
          fill="#010202"
          d="M645.898,105.25c0.228-0.029,0.379-0.123,0.455-0.286c0.043-0.089,0.066-0.214,0.066-0.379
        c0-0.339-0.12-0.582-0.36-0.735s-0.582-0.229-1.027-0.229c-0.516,0-0.882,0.139-1.097,0.419c-0.119,0.155-0.198,0.384-0.235,0.69
        h-0.922c0.02-0.727,0.255-1.233,0.706-1.516c0.45-0.286,0.976-0.428,1.572-0.428c0.69,0,1.25,0.131,1.683,0.396
        c0.429,0.263,0.642,0.672,0.642,1.23v3.388c0,0.102,0.022,0.184,0.062,0.247c0.042,0.062,0.131,0.094,0.267,0.094
        c0.043,0,0.094-0.003,0.149-0.01c0.054-0.005,0.113-0.013,0.175-0.024v0.729c-0.153,0.043-0.271,0.073-0.352,0.084
        c-0.083,0.011-0.19,0.016-0.331,0.016c-0.34,0-0.585-0.121-0.74-0.363c-0.08-0.127-0.137-0.308-0.169-0.543
        c-0.202,0.263-0.492,0.493-0.868,0.687c-0.375,0.193-0.791,0.292-1.245,0.292c-0.545,0-0.991-0.167-1.337-0.498
        c-0.347-0.331-0.518-0.745-0.518-1.242c0-0.545,0.17-0.968,0.51-1.268c0.341-0.3,0.787-0.484,1.339-0.553L645.898,105.25z
         M643.818,107.921c0.208,0.165,0.455,0.245,0.74,0.245c0.349,0,0.684-0.081,1.011-0.24c0.549-0.266,0.823-0.705,0.823-1.31v-0.795
        c-0.12,0.077-0.274,0.142-0.465,0.193c-0.19,0.05-0.376,0.089-0.56,0.11l-0.599,0.076c-0.356,0.048-0.627,0.123-0.806,0.226
        c-0.305,0.171-0.458,0.445-0.458,0.823C643.504,107.532,643.608,107.756,643.818,107.921z"
        />
        <path
          fill="#010202"
          d="M649.589,106.966c0.028,0.329,0.112,0.581,0.251,0.758c0.254,0.318,0.695,0.478,1.322,0.478
        c0.371,0,0.703-0.081,0.985-0.239s0.426-0.405,0.426-0.739c0-0.251-0.112-0.443-0.34-0.576c-0.146-0.081-0.433-0.172-0.859-0.279
        l-0.797-0.198c-0.51-0.125-0.886-0.263-1.127-0.418c-0.433-0.266-0.647-0.637-0.647-1.109c0-0.557,0.203-1.006,0.61-1.352
        c0.406-0.343,0.951-0.515,1.638-0.515c0.898,0,1.547,0.26,1.943,0.779c0.249,0.329,0.369,0.684,0.361,1.064h-0.934
        c-0.02-0.223-0.099-0.424-0.24-0.606c-0.232-0.258-0.636-0.387-1.208-0.387c-0.382,0-0.671,0.071-0.869,0.213
        c-0.195,0.144-0.295,0.331-0.295,0.564c0,0.255,0.129,0.459,0.389,0.613c0.148,0.092,0.369,0.171,0.658,0.24l0.666,0.161
        c0.722,0.171,1.207,0.339,1.452,0.5c0.385,0.252,0.576,0.648,0.576,1.19c0,0.524-0.2,0.976-0.602,1.356
        c-0.403,0.381-1.015,0.571-1.838,0.571c-0.886,0-1.514-0.199-1.883-0.595c-0.369-0.397-0.565-0.889-0.592-1.474h0.952V106.966z"
        />
        <path
          fill="#010202"
          d="M654.82,101.288h0.998v1.642h0.938v0.808h-0.938v3.838c0,0.205,0.069,0.342,0.208,0.411
        c0.079,0.041,0.206,0.06,0.384,0.06c0.049,0,0.1,0,0.154-0.003c0.056-0.001,0.12-0.008,0.192-0.02v0.786
        c-0.113,0.034-0.23,0.056-0.354,0.071c-0.122,0.016-0.255,0.022-0.399,0.022c-0.46,0-0.772-0.118-0.938-0.354
        c-0.165-0.235-0.245-0.542-0.245-0.919v-3.891h-0.797v-0.808h0.797V101.288z"
        />
        <path
          fill="#010202"
          d="M660.871,102.93h0.938v0.835c0.277-0.344,0.572-0.59,0.884-0.74c0.31-0.152,0.656-0.226,1.038-0.226
        c0.836,0,1.398,0.29,1.692,0.872c0.161,0.318,0.24,0.772,0.24,1.368v3.772h-1.005v-3.706c0-0.359-0.052-0.648-0.16-0.867
        c-0.176-0.366-0.495-0.55-0.954-0.55c-0.235,0-0.43,0.026-0.576,0.073c-0.271,0.081-0.51,0.24-0.714,0.482
        c-0.165,0.195-0.271,0.396-0.321,0.602c-0.049,0.208-0.074,0.503-0.074,0.887v3.08h-0.987L660.871,102.93L660.871,102.93z"
        />
        <path
          fill="#010202"
          d="M670.276,105.25c0.228-0.029,0.377-0.123,0.453-0.286c0.043-0.089,0.065-0.214,0.065-0.379
        c0-0.339-0.119-0.582-0.359-0.735s-0.58-0.229-1.027-0.229c-0.515,0-0.88,0.139-1.097,0.419c-0.119,0.155-0.198,0.384-0.235,0.69
        h-0.922c0.018-0.727,0.253-1.233,0.704-1.516c0.452-0.286,0.976-0.428,1.572-0.428c0.692,0,1.252,0.131,1.684,0.396
        c0.428,0.263,0.641,0.672,0.641,1.23v3.388c0,0.102,0.022,0.184,0.063,0.247c0.043,0.062,0.132,0.094,0.268,0.094
        c0.043,0,0.093-0.003,0.148-0.01c0.053-0.005,0.112-0.013,0.176-0.024v0.729c-0.155,0.043-0.271,0.073-0.354,0.084
        c-0.081,0.011-0.188,0.016-0.329,0.016c-0.34,0-0.587-0.121-0.742-0.363c-0.079-0.127-0.135-0.308-0.169-0.543
        c-0.201,0.263-0.49,0.493-0.866,0.687c-0.377,0.193-0.793,0.292-1.246,0.292c-0.546,0-0.99-0.167-1.336-0.498
        c-0.347-0.331-0.519-0.745-0.519-1.242c0-0.545,0.169-0.968,0.509-1.268c0.341-0.3,0.787-0.484,1.339-0.553L670.276,105.25z
         M668.194,107.921c0.208,0.165,0.456,0.245,0.74,0.245c0.35,0,0.685-0.081,1.011-0.24c0.549-0.266,0.824-0.705,0.824-1.31v-0.795
        c-0.121,0.077-0.275,0.142-0.465,0.193c-0.191,0.05-0.377,0.089-0.561,0.11l-0.597,0.076c-0.358,0.048-0.628,0.123-0.809,0.226
        c-0.305,0.171-0.456,0.445-0.456,0.823C667.883,107.532,667.986,107.756,668.194,107.921z"
        />
        <path
          fill="#010202"
          d="M673.377,102.93h0.978v0.835c0.233-0.289,0.445-0.5,0.637-0.632c0.326-0.223,0.697-0.333,1.109-0.333
        c0.47,0,0.846,0.114,1.131,0.345c0.161,0.132,0.308,0.326,0.44,0.583c0.219-0.315,0.476-0.548,0.772-0.702
        c0.297-0.152,0.629-0.228,1-0.228c0.79,0,1.327,0.286,1.612,0.855c0.155,0.308,0.232,0.721,0.232,1.242v3.916h-1.027v-4.085
        c0-0.39-0.1-0.66-0.295-0.806c-0.195-0.147-0.436-0.221-0.715-0.221c-0.389,0-0.723,0.131-1.003,0.39
        c-0.279,0.26-0.421,0.694-0.421,1.302v3.42h-1.005v-3.838c0-0.398-0.047-0.69-0.142-0.872c-0.151-0.274-0.433-0.413-0.84-0.413
        c-0.371,0-0.712,0.145-1.018,0.436c-0.306,0.289-0.46,0.812-0.46,1.571v3.119h-0.987V102.93L673.377,102.93z"
        />
        <path
          fill="#010202"
          d="M682.742,102.93h0.94v0.835c0.278-0.344,0.57-0.59,0.884-0.74c0.311-0.152,0.656-0.226,1.038-0.226
        c0.836,0,1.398,0.29,1.69,0.872c0.163,0.318,0.242,0.772,0.242,1.368v3.772h-1.005v-3.706c0-0.359-0.054-0.648-0.16-0.867
        c-0.174-0.366-0.493-0.55-0.956-0.55c-0.233,0-0.426,0.026-0.576,0.073c-0.271,0.081-0.506,0.24-0.712,0.482
        c-0.167,0.195-0.273,0.396-0.319,0.602c-0.051,0.208-0.076,0.503-0.076,0.887v3.08h-0.988v-5.881H682.742z"
        />
        <path
          fill="#010202"
          d="M688.66,111.104c0.583-1.134,0.958-1.963,1.126-2.482c0.257-0.786,0.384-1.709,0.384-2.768
        c0-1.056-0.148-2.025-0.443-2.904c-0.184-0.542-0.543-1.321-1.082-2.338h0.664c0.564,0.901,0.905,1.456,1.024,1.666
        c0.119,0.211,0.248,0.501,0.387,0.871c0.176,0.457,0.302,0.908,0.374,1.356c0.076,0.445,0.113,0.875,0.113,1.29
        c0,1.084-0.171,2.05-0.516,2.899c-0.215,0.545-0.665,1.349-1.346,2.41H688.66z"
        />
      </g>
      <g enable-background="new">
        <path
          fill="#010202"
          d="M516.466,158.577h1.235l2.926,8.065h-1.197l-0.84-2.416h-3.166l-0.874,2.416h-1.119L516.466,158.577z
         M518.277,163.336l-1.242-3.562l-1.29,3.562H518.277z"
        />
      </g>
      <g enable-background="new">
        <path
          fill="#010202"
          d="M521.553,160.761l1.57,4.788l1.637-4.788h1.081l-2.213,5.88h-1.053l-2.169-5.88H521.553z"
        />
        <path
          fill="#010202"
          d="M527.275,164.797c0.031,0.329,0.113,0.581,0.252,0.758c0.255,0.318,0.697,0.478,1.322,0.478
        c0.373,0,0.703-0.081,0.985-0.239c0.282-0.158,0.426-0.405,0.426-0.739c0-0.251-0.112-0.443-0.34-0.576
        c-0.146-0.081-0.433-0.172-0.858-0.279l-0.798-0.199c-0.508-0.124-0.886-0.263-1.128-0.417c-0.432-0.266-0.645-0.637-0.645-1.109
        c0-0.557,0.201-1.006,0.607-1.352c0.407-0.344,0.953-0.515,1.641-0.515c0.898,0,1.545,0.26,1.941,0.779
        c0.249,0.329,0.369,0.684,0.363,1.064h-0.934c-0.02-0.222-0.101-0.424-0.24-0.606c-0.234-0.258-0.638-0.387-1.21-0.387
        c-0.381,0-0.671,0.071-0.867,0.213c-0.197,0.144-0.296,0.331-0.296,0.564c0,0.255,0.129,0.459,0.388,0.613
        c0.148,0.092,0.369,0.171,0.659,0.24l0.666,0.161c0.723,0.171,1.208,0.339,1.453,0.5c0.384,0.251,0.576,0.648,0.576,1.19
        c0,0.524-0.2,0.976-0.604,1.356c-0.401,0.381-1.015,0.571-1.837,0.571c-0.886,0-1.513-0.198-1.882-0.595
        c-0.369-0.397-0.566-0.889-0.592-1.474h0.949V164.797z"
        />
        <path
          fill="#010202"
          d="M535.462,163.083c0.228-0.029,0.377-0.123,0.455-0.286c0.043-0.089,0.065-0.214,0.065-0.379
        c0-0.339-0.119-0.582-0.359-0.735s-0.58-0.229-1.028-0.229c-0.514,0-0.88,0.139-1.096,0.419c-0.121,0.155-0.2,0.384-0.235,0.69
        h-0.922c0.018-0.727,0.251-1.233,0.704-1.516c0.452-0.286,0.975-0.428,1.571-0.428c0.693,0,1.253,0.131,1.685,0.396
        c0.428,0.263,0.641,0.672,0.641,1.23v3.388c0,0.102,0.021,0.184,0.062,0.247c0.042,0.062,0.131,0.094,0.267,0.094
        c0.044,0,0.095-0.003,0.148-0.01c0.055-0.005,0.113-0.013,0.176-0.024v0.729c-0.155,0.043-0.271,0.073-0.352,0.084
        c-0.081,0.011-0.19,0.016-0.329,0.016c-0.34,0-0.589-0.121-0.742-0.363c-0.08-0.127-0.137-0.308-0.169-0.543
        c-0.201,0.263-0.49,0.493-0.867,0.687c-0.378,0.193-0.792,0.292-1.247,0.292c-0.545,0-0.988-0.168-1.335-0.499
        s-0.52-0.745-0.52-1.242c0-0.545,0.169-0.968,0.51-1.268c0.34-0.3,0.787-0.484,1.34-0.553L535.462,163.083z M533.38,165.753
        c0.21,0.165,0.456,0.245,0.742,0.245c0.348,0,0.685-0.081,1.009-0.24c0.551-0.266,0.826-0.705,0.826-1.31v-0.795
        c-0.121,0.077-0.277,0.142-0.466,0.193c-0.19,0.05-0.376,0.089-0.56,0.11l-0.597,0.076c-0.36,0.048-0.628,0.123-0.809,0.226
        c-0.305,0.171-0.458,0.445-0.458,0.823C533.068,165.365,533.172,165.587,533.38,165.753z M533.35,158.708h1.027v1.144h-1.027
        V158.708z M535.16,158.708h1.027v1.144h-1.027V158.708z"
        />
        <path
          fill="#010202"
          d="M538.564,160.761h0.938v0.835c0.277-0.344,0.573-0.59,0.884-0.74c0.31-0.152,0.656-0.226,1.039-0.226
        c0.835,0,1.398,0.291,1.691,0.873c0.161,0.318,0.24,0.772,0.24,1.368v3.772h-1.005v-3.706c0-0.359-0.052-0.648-0.159-0.868
        c-0.176-0.366-0.495-0.55-0.955-0.55c-0.235,0-0.429,0.026-0.575,0.073c-0.271,0.081-0.51,0.24-0.715,0.482
        c-0.164,0.195-0.271,0.395-0.321,0.602c-0.048,0.208-0.073,0.503-0.073,0.887v3.08h-0.987v-5.881H538.564z"
        />
        <path
          fill="#010202"
          d="M548.049,160.941c0.173,0.109,0.373,0.303,0.595,0.576v-2.971h0.95v8.094h-0.891v-0.818
        c-0.23,0.363-0.501,0.624-0.814,0.784c-0.312,0.16-0.672,0.24-1.077,0.24c-0.652,0-1.217-0.274-1.696-0.826
        c-0.478-0.551-0.716-1.284-0.716-2.198c0-0.856,0.22-1.598,0.653-2.227c0.437-0.629,1.058-0.942,1.867-0.942
        C547.37,160.657,547.746,160.752,548.049,160.941z M545.843,165.352c0.266,0.424,0.691,0.637,1.277,0.637
        c0.456,0,0.83-0.197,1.122-0.59c0.295-0.392,0.438-0.958,0.438-1.693c0-0.744-0.151-1.293-0.453-1.65
        c-0.303-0.356-0.674-0.536-1.117-0.536c-0.497,0-0.898,0.19-1.206,0.571c-0.305,0.38-0.46,0.94-0.46,1.68
        C545.444,164.399,545.576,164.926,545.843,165.352z"
        />
        <path
          fill="#010202"
          d="M554.223,163.083c0.227-0.029,0.379-0.123,0.456-0.286c0.044-0.089,0.066-0.214,0.066-0.379
        c0-0.339-0.12-0.582-0.36-0.735s-0.582-0.229-1.028-0.229c-0.515,0-0.881,0.139-1.095,0.419c-0.121,0.155-0.2,0.384-0.236,0.69
        h-0.922c0.02-0.727,0.253-1.233,0.706-1.516c0.45-0.286,0.975-0.428,1.571-0.428c0.691,0,1.251,0.131,1.684,0.396
        c0.429,0.263,0.642,0.672,0.642,1.23v3.388c0,0.102,0.021,0.184,0.062,0.247c0.041,0.062,0.129,0.094,0.265,0.094
        c0.044,0,0.096-0.003,0.15-0.01c0.055-0.005,0.112-0.013,0.174-0.024v0.729c-0.153,0.043-0.271,0.073-0.35,0.084
        c-0.083,0.011-0.192,0.016-0.331,0.016c-0.34,0-0.587-0.121-0.74-0.363c-0.082-0.127-0.139-0.308-0.169-0.543
        c-0.202,0.263-0.492,0.493-0.869,0.687c-0.376,0.193-0.791,0.292-1.245,0.292c-0.545,0-0.99-0.168-1.337-0.499
        s-0.518-0.745-0.518-1.242c0-0.545,0.169-0.968,0.51-1.268c0.34-0.3,0.786-0.484,1.34-0.553L554.223,163.083z M552.142,165.753
        c0.21,0.165,0.455,0.245,0.742,0.245c0.347,0,0.684-0.081,1.01-0.24c0.55-0.266,0.824-0.705,0.824-1.31v-0.795
        c-0.12,0.077-0.276,0.142-0.467,0.193c-0.188,0.05-0.374,0.089-0.558,0.11l-0.599,0.076c-0.357,0.048-0.627,0.123-0.806,0.226
        c-0.306,0.171-0.46,0.445-0.46,0.823C551.829,165.365,551.933,165.587,552.142,165.753z"
        />
        <path
          fill="#010202"
          d="M557.353,160.761h0.938v1.016c0.076-0.197,0.265-0.438,0.566-0.721s0.645-0.426,1.038-0.426
        c0.02,0,0.049,0.001,0.094,0.005s0.118,0.011,0.226,0.022v1.042c-0.06-0.011-0.112-0.018-0.163-0.022
        c-0.048-0.003-0.102-0.005-0.162-0.005c-0.499,0-0.881,0.161-1.147,0.48c-0.268,0.319-0.401,0.69-0.401,1.108v3.382h-0.986v-5.881
        H557.353z"
        />
        <path
          fill="#010202"
          d="M564.701,160.923c0.389,0.195,0.688,0.448,0.889,0.761c0.198,0.297,0.327,0.642,0.394,1.039
        c0.059,0.271,0.089,0.703,0.089,1.297h-4.277c0.02,0.597,0.158,1.077,0.42,1.44c0.261,0.361,0.668,0.542,1.216,0.542
        c0.51,0,0.919-0.169,1.226-0.513c0.169-0.198,0.292-0.429,0.363-0.688h0.974c-0.027,0.216-0.111,0.457-0.257,0.721
        c-0.143,0.267-0.305,0.482-0.483,0.65c-0.298,0.293-0.668,0.492-1.106,0.593c-0.235,0.058-0.503,0.087-0.801,0.087
        c-0.728,0-1.344-0.266-1.851-0.798c-0.503-0.532-0.758-1.277-0.758-2.236c0-0.945,0.253-1.713,0.765-2.302
        c0.511-0.588,1.176-0.884,1.996-0.884C563.911,160.63,564.312,160.729,564.701,160.923z M565.055,163.231
        c-0.037-0.429-0.132-0.771-0.277-1.027c-0.268-0.476-0.716-0.713-1.345-0.713c-0.45,0-0.827,0.165-1.131,0.492
        c-0.305,0.328-0.466,0.744-0.485,1.249H565.055z"
        />
        <path
          fill="#010202"
          d="M573.049,158.445c-0.572,1.116-0.948,1.938-1.117,2.464c-0.262,0.801-0.391,1.727-0.391,2.778
        c0,1.061,0.148,2.032,0.443,2.911c0.183,0.542,0.545,1.321,1.082,2.338h-0.663c-0.535-0.835-0.865-1.368-0.995-1.597
        c-0.127-0.232-0.268-0.543-0.416-0.938c-0.206-0.542-0.35-1.121-0.429-1.735c-0.04-0.318-0.062-0.623-0.062-0.911
        c0-1.084,0.17-2.048,0.512-2.893c0.215-0.539,0.664-1.343,1.35-2.416h0.686V158.445z"
        />
        <path
          fill="#010202"
          d="M574.192,160.761h0.938v0.835c0.277-0.344,0.572-0.59,0.884-0.74c0.31-0.152,0.656-0.226,1.038-0.226
        c0.836,0,1.398,0.291,1.692,0.873c0.161,0.318,0.24,0.772,0.24,1.368v3.772h-1.005v-3.706c0-0.359-0.052-0.648-0.16-0.868
        c-0.176-0.366-0.495-0.55-0.954-0.55c-0.235,0-0.429,0.026-0.576,0.073c-0.271,0.081-0.51,0.24-0.714,0.482
        c-0.165,0.195-0.271,0.395-0.321,0.602c-0.048,0.208-0.074,0.503-0.074,0.887v3.08h-0.987L574.192,160.761L574.192,160.761z"
        />
        <path
          fill="#010202"
          d="M583.598,163.083c0.227-0.029,0.377-0.123,0.453-0.286c0.043-0.089,0.065-0.214,0.065-0.379
        c0-0.339-0.119-0.582-0.359-0.735s-0.58-0.229-1.027-0.229c-0.515,0-0.88,0.139-1.097,0.419c-0.119,0.155-0.198,0.384-0.235,0.69
        h-0.922c0.018-0.727,0.253-1.233,0.704-1.516c0.452-0.286,0.976-0.428,1.572-0.428c0.692,0,1.252,0.131,1.684,0.396
        c0.428,0.263,0.641,0.672,0.641,1.23v3.388c0,0.102,0.022,0.184,0.062,0.247c0.044,0.062,0.133,0.094,0.269,0.094
        c0.043,0,0.093-0.003,0.148-0.01c0.053-0.005,0.112-0.013,0.176-0.024v0.729c-0.155,0.043-0.271,0.073-0.354,0.084
        c-0.081,0.011-0.188,0.016-0.329,0.016c-0.34,0-0.587-0.121-0.742-0.363c-0.079-0.127-0.135-0.308-0.169-0.543
        c-0.201,0.263-0.49,0.493-0.866,0.687c-0.377,0.193-0.793,0.292-1.246,0.292c-0.546,0-0.99-0.168-1.336-0.499
        c-0.347-0.331-0.519-0.745-0.519-1.242c0-0.545,0.169-0.968,0.509-1.268c0.341-0.3,0.787-0.484,1.339-0.553L583.598,163.083z
         M581.516,165.753c0.208,0.165,0.456,0.245,0.74,0.245c0.35,0,0.686-0.081,1.011-0.24c0.549-0.266,0.824-0.705,0.824-1.31v-0.795
        c-0.121,0.077-0.275,0.142-0.465,0.193c-0.191,0.05-0.377,0.089-0.561,0.11l-0.597,0.076c-0.358,0.048-0.628,0.123-0.809,0.226
        c-0.305,0.171-0.456,0.445-0.456,0.823C581.204,165.365,581.308,165.587,581.516,165.753z"
        />
        <path
          fill="#010202"
          d="M586.698,160.761h0.978v0.835c0.233-0.289,0.445-0.5,0.637-0.632c0.326-0.223,0.697-0.334,1.109-0.334
        c0.47,0,0.846,0.115,1.131,0.345c0.161,0.132,0.309,0.326,0.44,0.582c0.219-0.314,0.476-0.548,0.772-0.701
        c0.297-0.152,0.629-0.228,1-0.228c0.79,0,1.327,0.285,1.612,0.854c0.155,0.308,0.232,0.721,0.232,1.242v3.916h-1.027v-4.085
        c0-0.39-0.1-0.66-0.295-0.807c-0.195-0.146-0.436-0.221-0.715-0.221c-0.389,0-0.723,0.13-1.003,0.39
        c-0.279,0.26-0.421,0.693-0.421,1.302v3.42h-1.005v-3.838c0-0.398-0.047-0.69-0.142-0.873c-0.151-0.274-0.433-0.413-0.84-0.413
        c-0.371,0-0.712,0.145-1.018,0.436c-0.306,0.289-0.46,0.812-0.46,1.57v3.119h-0.987V160.761L586.698,160.761z"
        />
        <path
          fill="#010202"
          d="M596.063,160.761h0.94v0.835c0.278-0.344,0.57-0.59,0.884-0.74c0.311-0.152,0.656-0.226,1.038-0.226
        c0.836,0,1.398,0.291,1.69,0.873c0.163,0.318,0.242,0.772,0.242,1.368v3.772h-1.005v-3.706c0-0.359-0.054-0.648-0.16-0.868
        c-0.174-0.366-0.493-0.55-0.956-0.55c-0.233,0-0.426,0.026-0.575,0.073c-0.271,0.081-0.507,0.24-0.713,0.482
        c-0.166,0.195-0.273,0.395-0.319,0.602c-0.051,0.208-0.076,0.503-0.076,0.887v3.08h-0.988v-5.881H596.063z"
        />
        <path
          fill="#010202"
          d="M609.702,161.348c0.519,0.503,0.778,1.247,0.778,2.227c0,0.947-0.229,1.729-0.687,2.348
        c-0.458,0.618-1.169,0.926-2.131,0.926c-0.805,0-1.443-0.272-1.915-0.819c-0.473-0.547-0.708-1.282-0.708-2.203
        c0-0.987,0.248-1.774,0.746-2.357c0.499-0.585,1.168-0.879,2.007-0.879C608.547,160.591,609.183,160.843,609.702,161.348z
         M609.089,165.255c0.24-0.495,0.363-1.045,0.363-1.653c0-0.548-0.089-0.993-0.262-1.337c-0.274-0.542-0.75-0.811-1.422-0.811
        c-0.597,0-1.032,0.23-1.304,0.69c-0.271,0.461-0.407,1.018-0.407,1.668c0,0.625,0.137,1.146,0.407,1.562
        c0.271,0.417,0.703,0.626,1.292,0.626C608.405,165.999,608.849,165.752,609.089,165.255z"
        />
        <path
          fill="#010202"
          d="M615.58,161.074c0.416,0.322,0.664,0.877,0.75,1.663h-0.961c-0.06-0.363-0.192-0.663-0.4-0.903
        c-0.209-0.239-0.545-0.361-1.005-0.361c-0.63,0-1.08,0.307-1.351,0.921c-0.176,0.398-0.265,0.892-0.265,1.476
        c0,0.589,0.126,1.084,0.374,1.487c0.248,0.402,0.639,0.604,1.176,0.604c0.409,0,0.733-0.126,0.974-0.375
        c0.239-0.25,0.406-0.592,0.498-1.025h0.962c-0.11,0.779-0.384,1.35-0.824,1.711s-1,0.541-1.686,0.541
        c-0.77,0-1.381-0.281-1.84-0.842c-0.46-0.561-0.688-1.263-0.688-2.106c0-1.032,0.25-1.837,0.754-2.409
        c0.501-0.574,1.138-0.863,1.915-0.863C614.625,160.591,615.164,160.752,615.58,161.074z"
        />
        <path
          fill="#010202"
          d="M617.318,158.548h0.987v3.009c0.235-0.296,0.445-0.505,0.632-0.627c0.318-0.208,0.715-0.312,1.19-0.312
        c0.854,0,1.434,0.298,1.735,0.895c0.166,0.326,0.247,0.777,0.247,1.356v3.772h-1.017v-3.706c0-0.432-0.053-0.748-0.164-0.95
        c-0.18-0.323-0.517-0.484-1.012-0.484c-0.409,0-0.78,0.14-1.114,0.422c-0.332,0.282-0.5,0.816-0.5,1.598v3.119h-0.986
        L617.318,158.548L617.318,158.548z"
        />
        <path
          fill="#010202"
          d="M626.614,160.79h0.968v0.779c0.198-0.268,0.414-0.474,0.648-0.621c0.333-0.221,0.726-0.329,1.177-0.329
        c0.668,0,1.235,0.255,1.701,0.766c0.467,0.511,0.7,1.24,0.7,2.187c0,1.282-0.337,2.196-1.008,2.745
        c-0.429,0.348-0.923,0.522-1.49,0.522c-0.443,0-0.817-0.098-1.117-0.292c-0.176-0.11-0.373-0.3-0.589-0.566v3.003h-0.988v-8.194
        H626.614z M630.331,165.397c0.307-0.385,0.46-0.963,0.46-1.732c0-0.468-0.067-0.871-0.203-1.206
        c-0.258-0.648-0.728-0.974-1.411-0.974c-0.688,0-1.156,0.343-1.411,1.027c-0.136,0.368-0.203,0.832-0.203,1.395
        c0,0.454,0.067,0.84,0.203,1.158c0.258,0.608,0.728,0.911,1.411,0.911C629.641,165.978,630.025,165.784,630.331,165.397z"
        />
        <path
          fill="#010202"
          d="M637.209,161.348c0.52,0.503,0.779,1.247,0.779,2.227c0,0.947-0.229,1.729-0.687,2.348
        c-0.458,0.618-1.17,0.926-2.131,0.926c-0.805,0-1.443-0.272-1.916-0.819s-0.708-1.282-0.708-2.203c0-0.987,0.249-1.774,0.747-2.357
        c0.498-0.585,1.168-0.879,2.006-0.879C636.053,160.591,636.69,160.843,637.209,161.348z M636.595,165.255
        c0.24-0.495,0.363-1.045,0.363-1.653c0-0.548-0.089-0.993-0.262-1.337c-0.273-0.542-0.749-0.811-1.422-0.811
        c-0.597,0-1.032,0.23-1.303,0.69c-0.271,0.461-0.408,1.018-0.408,1.668c0,0.625,0.137,1.146,0.408,1.562
        c0.271,0.417,0.703,0.626,1.291,0.626C635.911,165.999,636.356,165.752,636.595,165.255z"
        />
        <path
          fill="#010202"
          d="M639.79,164.797c0.032,0.329,0.114,0.581,0.253,0.758c0.253,0.318,0.695,0.478,1.32,0.478
        c0.375,0,0.703-0.081,0.986-0.239c0.285-0.16,0.427-0.405,0.427-0.739c0-0.251-0.112-0.443-0.34-0.576
        c-0.146-0.081-0.433-0.172-0.858-0.279l-0.798-0.199c-0.509-0.124-0.888-0.263-1.128-0.417c-0.432-0.266-0.646-0.637-0.646-1.109
        c0-0.557,0.203-1.006,0.609-1.352c0.407-0.344,0.953-0.515,1.641-0.515c0.898,0,1.543,0.26,1.939,0.779
        c0.249,0.329,0.37,0.684,0.363,1.064h-0.932c-0.02-0.222-0.101-0.424-0.242-0.606c-0.234-0.258-0.636-0.387-1.208-0.387
        c-0.382,0-0.673,0.071-0.868,0.213c-0.198,0.144-0.296,0.331-0.296,0.564c0,0.255,0.129,0.459,0.387,0.613
        c0.15,0.092,0.369,0.171,0.661,0.24l0.664,0.161c0.725,0.171,1.21,0.339,1.455,0.5c0.384,0.251,0.576,0.648,0.576,1.19
        c0,0.524-0.202,0.976-0.605,1.356c-0.399,0.381-1.014,0.571-1.837,0.571c-0.885,0-1.513-0.198-1.88-0.595
        c-0.368-0.397-0.567-0.889-0.592-1.474h0.948V164.797z"
        />
        <path
          fill="#010202"
          d="M645.021,159.121h1v1.642h0.939v0.808h-0.939v3.838c0,0.205,0.07,0.342,0.209,0.412
        c0.076,0.04,0.205,0.06,0.384,0.06c0.047,0,0.099,0,0.155-0.003c0.053-0.001,0.118-0.008,0.191-0.019v0.785
        c-0.114,0.034-0.233,0.057-0.355,0.071c-0.123,0.016-0.255,0.023-0.397,0.023c-0.461,0-0.773-0.118-0.938-0.355
        c-0.166-0.235-0.248-0.542-0.248-0.919v-3.891h-0.795v-0.808h0.795V159.121z"
        />
        <path
          fill="#010202"
          d="M651.1,163.083c0.228-0.029,0.379-0.123,0.456-0.286c0.044-0.089,0.066-0.214,0.066-0.379
        c0-0.339-0.119-0.582-0.359-0.735s-0.582-0.229-1.029-0.229c-0.515,0-0.881,0.139-1.095,0.419c-0.121,0.155-0.2,0.384-0.235,0.69
        h-0.923c0.02-0.727,0.253-1.233,0.706-1.516c0.45-0.286,0.975-0.428,1.571-0.428c0.691,0,1.251,0.131,1.684,0.396
        c0.429,0.263,0.642,0.672,0.642,1.23v3.388c0,0.102,0.021,0.184,0.063,0.247c0.04,0.062,0.129,0.094,0.264,0.094
        c0.044,0,0.096-0.003,0.15-0.01c0.055-0.005,0.113-0.013,0.174-0.024v0.729c-0.152,0.043-0.271,0.073-0.35,0.084
        c-0.082,0.011-0.191,0.016-0.33,0.016c-0.341,0-0.588-0.121-0.74-0.363c-0.083-0.127-0.139-0.308-0.17-0.543
        c-0.201,0.263-0.492,0.493-0.869,0.687c-0.376,0.193-0.79,0.292-1.245,0.292c-0.545,0-0.99-0.168-1.337-0.499
        s-0.518-0.745-0.518-1.242c0-0.545,0.169-0.968,0.51-1.268c0.34-0.3,0.787-0.484,1.34-0.553L651.1,163.083z M649.02,165.753
        c0.209,0.165,0.454,0.245,0.741,0.245c0.347,0,0.685-0.081,1.01-0.24c0.55-0.266,0.824-0.705,0.824-1.31v-0.795
        c-0.119,0.077-0.275,0.142-0.466,0.193c-0.189,0.05-0.374,0.089-0.559,0.11l-0.598,0.076c-0.358,0.048-0.628,0.123-0.807,0.226
        c-0.305,0.171-0.46,0.445-0.46,0.823C648.706,165.365,648.81,165.587,649.02,165.753z"
        />
        <path
          fill="#010202"
          d="M657.433,160.941c0.174,0.109,0.372,0.303,0.597,0.576v-2.971h0.948v8.094h-0.889v-0.818
        c-0.23,0.363-0.501,0.624-0.816,0.784c-0.311,0.162-0.671,0.24-1.077,0.24c-0.651,0-1.217-0.274-1.694-0.826
        c-0.479-0.551-0.718-1.284-0.718-2.198c0-0.856,0.219-1.598,0.654-2.227s1.059-0.942,1.868-0.942
        C656.754,160.657,657.131,160.752,657.433,160.941z M655.227,165.352c0.266,0.424,0.693,0.637,1.278,0.637
        c0.455,0,0.829-0.197,1.121-0.59c0.295-0.392,0.44-0.958,0.44-1.693c0-0.744-0.151-1.293-0.453-1.65
        c-0.303-0.356-0.676-0.536-1.119-0.536c-0.497,0-0.896,0.19-1.205,0.571c-0.307,0.38-0.461,0.94-0.461,1.68
        C654.828,164.399,654.962,164.926,655.227,165.352z"
        />
        <path
          fill="#010202"
          d="M660.483,160.761h0.938v1.016c0.078-0.197,0.265-0.438,0.568-0.721c0.3-0.284,0.645-0.426,1.037-0.426
        c0.019,0,0.05,0.001,0.095,0.005s0.118,0.011,0.225,0.022v1.042c-0.059-0.011-0.111-0.018-0.162-0.022
        c-0.05-0.003-0.103-0.005-0.164-0.005c-0.498,0-0.879,0.161-1.146,0.48s-0.4,0.69-0.4,1.108v3.382h-0.988v-5.881H660.483z"
        />
        <path
          fill="#010202"
          d="M667.831,160.923c0.391,0.195,0.688,0.448,0.891,0.761c0.196,0.297,0.327,0.642,0.392,1.039
        c0.06,0.271,0.089,0.703,0.089,1.297h-4.277c0.02,0.597,0.158,1.077,0.42,1.44c0.263,0.361,0.667,0.542,1.216,0.542
        c0.511,0,0.919-0.169,1.226-0.513c0.169-0.198,0.293-0.429,0.364-0.688h0.973c-0.025,0.216-0.109,0.457-0.255,0.721
        c-0.146,0.267-0.305,0.482-0.485,0.65c-0.297,0.293-0.666,0.492-1.106,0.593c-0.235,0.058-0.503,0.087-0.8,0.087
        c-0.728,0-1.345-0.266-1.85-0.798s-0.758-1.277-0.758-2.236c0-0.945,0.253-1.713,0.763-2.302c0.51-0.588,1.176-0.884,1.996-0.884
        C667.041,160.63,667.442,160.729,667.831,160.923z M668.186,163.231c-0.038-0.429-0.132-0.771-0.277-1.027
        c-0.269-0.476-0.716-0.713-1.345-0.713c-0.45,0-0.827,0.165-1.132,0.492c-0.305,0.328-0.466,0.744-0.485,1.249H668.186z"
        />
        <path
          fill="#010202"
          d="M671.042,164.797c0.029,0.329,0.113,0.581,0.252,0.758c0.253,0.318,0.694,0.478,1.322,0.478
        c0.371,0,0.703-0.081,0.985-0.239c0.282-0.158,0.426-0.405,0.426-0.739c0-0.251-0.113-0.443-0.341-0.576
        c-0.145-0.081-0.432-0.172-0.859-0.279l-0.797-0.199c-0.509-0.124-0.885-0.263-1.127-0.417c-0.433-0.266-0.646-0.637-0.646-1.109
        c0-0.557,0.203-1.006,0.609-1.352c0.406-0.344,0.951-0.515,1.639-0.515c0.898,0,1.547,0.26,1.943,0.779
        c0.248,0.329,0.369,0.684,0.361,1.064h-0.935c-0.019-0.222-0.098-0.424-0.24-0.606c-0.231-0.258-0.635-0.387-1.207-0.387
        c-0.383,0-0.671,0.071-0.869,0.213c-0.195,0.144-0.296,0.331-0.296,0.564c0,0.255,0.129,0.459,0.389,0.613
        c0.148,0.092,0.369,0.171,0.658,0.24l0.666,0.161c0.723,0.171,1.208,0.339,1.453,0.5c0.384,0.251,0.575,0.648,0.575,1.19
        c0,0.524-0.199,0.976-0.602,1.356c-0.402,0.381-1.014,0.571-1.838,0.571c-0.886,0-1.513-0.198-1.882-0.595
        c-0.369-0.397-0.566-0.889-0.592-1.474h0.951V164.797z"
        />
        <path
          fill="#010202"
          d="M676.664,164.797c0.029,0.329,0.112,0.581,0.251,0.758c0.254,0.318,0.695,0.478,1.323,0.478
        c0.37,0,0.703-0.081,0.985-0.239c0.282-0.158,0.426-0.405,0.426-0.739c0-0.251-0.113-0.443-0.341-0.576
        c-0.145-0.081-0.432-0.172-0.859-0.279l-0.797-0.199c-0.51-0.124-0.885-0.263-1.127-0.417c-0.433-0.266-0.646-0.637-0.646-1.109
        c0-0.557,0.203-1.006,0.609-1.352c0.406-0.344,0.951-0.515,1.639-0.515c0.898,0,1.546,0.26,1.943,0.779
        c0.248,0.329,0.369,0.684,0.36,1.064h-0.934c-0.019-0.222-0.098-0.424-0.24-0.606c-0.232-0.258-0.635-0.387-1.208-0.387
        c-0.382,0-0.671,0.071-0.869,0.213c-0.195,0.144-0.295,0.331-0.295,0.564c0,0.255,0.129,0.459,0.389,0.613
        c0.148,0.092,0.369,0.171,0.658,0.24l0.666,0.161c0.723,0.171,1.208,0.339,1.453,0.5c0.384,0.251,0.575,0.648,0.575,1.19
        c0,0.524-0.2,0.976-0.602,1.356c-0.403,0.381-1.014,0.571-1.838,0.571c-0.886,0-1.513-0.198-1.882-0.595
        c-0.37-0.397-0.566-0.889-0.593-1.474h0.952V164.797z"
        />
        <path
          fill="#010202"
          d="M681.363,168.936c0.582-1.134,0.958-1.963,1.124-2.482c0.258-0.786,0.384-1.709,0.384-2.768
        c0-1.056-0.148-2.025-0.443-2.904c-0.183-0.542-0.544-1.321-1.081-2.338h0.665c0.564,0.901,0.904,1.456,1.023,1.666
        c0.118,0.211,0.247,0.502,0.388,0.871c0.174,0.457,0.3,0.908,0.374,1.356c0.076,0.445,0.113,0.875,0.113,1.29
        c0,1.083-0.173,2.05-0.517,2.899c-0.216,0.545-0.664,1.348-1.345,2.41H681.363z"
        />
      </g>
      <line
        fill="none"
        stroke="#010202"
        stroke-width="1.088"
        stroke-miterlimit="10"
        x1="508.63"
        y1="271.235"
        x2="508.63"
        y2="250.594"
      />
      <line
        fill="none"
        stroke="#010202"
        stroke-width="1.088"
        stroke-miterlimit="10"
        x1="527.906"
        y1="269.87"
        x2="507.263"
        y2="269.87"
      />
      <line
        fill="none"
        stroke="#010202"
        stroke-width="1.088"
        stroke-miterlimit="10"
        x1="905.734"
        y1="154.21"
        x2="926.379"
        y2="154.21"
      />
      <line
        fill="none"
        stroke="#010202"
        stroke-width="1.088"
        stroke-miterlimit="10"
        x1="925.011"
        y1="152.844"
        x2="925.011"
        y2="173.49"
      />
      <g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M902.756,385.727l0.683-3.342h-1.326v-1.168h1.562l0.579-2.848h-2.142v-1.17h2.379l0.682-3.365h1.176
          l-0.684,3.365h2.473l0.681-3.365h1.184l-0.681,3.365h1.356v1.17h-1.592l-0.589,2.848h2.182v1.168h-2.415l-0.683,3.342h-1.176
          l0.675-3.342h-2.461l-0.685,3.342H902.756z M904.85,381.217h2.462l0.589-2.848h-2.471L904.85,381.217z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M895.578,385.531v-2.754h-4.988v-1.295l5.248-7.451h1.153v7.451h1.553v1.295h-1.553v2.754H895.578z
           M895.578,381.482v-5.186l-3.6,5.186H895.578z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M879.486,379.859c0-1.359,0.139-2.451,0.42-3.281c0.28-0.828,0.696-1.467,1.246-1.918
          c0.552-0.447,1.243-0.674,2.082-0.674c0.618,0,1.158,0.125,1.624,0.373c0.465,0.246,0.849,0.605,1.153,1.074
          c0.305,0.467,0.54,1.039,0.713,1.711c0.172,0.67,0.258,1.578,0.258,2.717c0,1.35-0.139,2.439-0.416,3.268
          c-0.277,0.83-0.69,1.469-1.243,1.92c-0.552,0.453-1.248,0.68-2.091,0.68c-1.109,0-1.979-0.398-2.61-1.191
          C879.865,383.578,879.486,382.02,879.486,379.859z M880.937,379.859c0,1.889,0.223,3.146,0.664,3.77
          c0.44,0.625,0.985,0.938,1.636,0.938c0.649,0,1.193-0.312,1.635-0.941c0.442-0.629,0.663-1.881,0.663-3.766
          c0-1.893-0.221-3.148-0.663-3.771c-0.44-0.623-0.991-0.934-1.649-0.934c-0.648,0-1.166,0.273-1.554,0.824
          C881.18,376.678,880.937,377.973,880.937,379.859z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M868.062,385.727l0.682-3.342h-1.325v-1.168h1.562l0.579-2.848h-2.142v-1.17h2.379l0.682-3.365h1.176
          l-0.684,3.365h2.472l0.681-3.365h1.184l-0.681,3.365h1.356v1.17h-1.592l-0.588,2.848h2.182v1.168h-2.416l-0.682,3.342h-1.176
          l0.674-3.342h-2.461l-0.687,3.342H868.062z M870.154,381.217h2.462l0.589-2.848h-2.471L870.154,381.217z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M370.642,385.727l0.682-3.342h-1.326v-1.168h1.561l0.581-2.848h-2.142v-1.17h2.377l0.682-3.365h1.175
          l-0.682,3.365h2.471l0.682-3.365h1.184l-0.682,3.365h1.356v1.17h-1.592l-0.588,2.848h2.18v1.168h-2.416l-0.682,3.342h-1.175
          l0.674-3.342H372.5l-0.682,3.342H370.642z M372.735,381.217h2.462l0.589-2.848h-2.471L372.735,381.217z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M46.789,385.727l0.682-3.342h-1.326v-1.168h1.561l0.581-2.848h-2.142v-1.17h2.377l0.682-3.365h1.176
          l-0.682,3.365h2.471l0.682-3.365h1.184l-0.682,3.365h1.356v1.17h-1.592l-0.589,2.848h2.181v1.168h-2.416l-0.682,3.342h-1.176
          l0.674-3.342h-2.463l-0.682,3.342H46.789z M48.884,381.217h2.462l0.589-2.848h-2.471L48.884,381.217z"
          />
        </g>
      </g>
      <g enable-background="new">
        <path
          fill="#010202"
          d="M51.569,310.859h5.594v0.988h-4.502v2.449h3.959v0.961h-3.959v3.668h-1.093v-8.066H51.569z"
        />
      </g>
      <g enable-background="new">
        <path
          fill="#010202"
          d="M57.667,313.045h0.938v1.016c0.078-0.197,0.266-0.438,0.566-0.721c0.3-0.283,0.646-0.426,1.037-0.426
        c0.02,0,0.05,0.002,0.094,0.006c0.043,0.002,0.119,0.01,0.226,0.021v1.043c-0.06-0.012-0.113-0.02-0.163-0.023
        c-0.05-0.004-0.103-0.004-0.163-0.004c-0.499,0-0.88,0.16-1.146,0.48c-0.266,0.318-0.402,0.689-0.402,1.107v3.381h-0.988v-5.881
        H57.667z"
        />
        <path
          fill="#010202"
          d="M64.535,315.365c0.228-0.029,0.379-0.123,0.455-0.285c0.043-0.088,0.066-0.215,0.066-0.379
        c0-0.338-0.119-0.582-0.359-0.734c-0.24-0.154-0.583-0.23-1.027-0.23c-0.515,0-0.881,0.139-1.095,0.42
        c-0.121,0.154-0.2,0.383-0.235,0.689h-0.922c0.018-0.727,0.253-1.232,0.705-1.516c0.452-0.285,0.976-0.428,1.571-0.428
        c0.692,0,1.251,0.131,1.684,0.396c0.427,0.262,0.642,0.672,0.642,1.23v3.387c0,0.102,0.021,0.186,0.063,0.248
        c0.042,0.061,0.13,0.094,0.266,0.094c0.044,0,0.094-0.004,0.148-0.01c0.055-0.006,0.113-0.014,0.176-0.025v0.73
        c-0.153,0.043-0.271,0.072-0.352,0.082c-0.081,0.012-0.19,0.018-0.331,0.018c-0.34,0-0.587-0.121-0.74-0.363
        c-0.081-0.127-0.137-0.309-0.169-0.543c-0.201,0.262-0.49,0.492-0.867,0.686c-0.377,0.193-0.792,0.293-1.247,0.293
        c-0.545,0-0.99-0.168-1.337-0.498c-0.347-0.332-0.519-0.746-0.519-1.242c0-0.545,0.169-0.967,0.51-1.268s0.787-0.484,1.34-0.553
        L64.535,315.365z M62.455,318.037c0.208,0.166,0.457,0.246,0.74,0.246c0.348,0,0.684-0.08,1.009-0.24
        c0.548-0.266,0.824-0.705,0.824-1.311v-0.795c-0.121,0.078-0.276,0.143-0.466,0.193c-0.19,0.051-0.376,0.09-0.56,0.111
        l-0.599,0.074c-0.358,0.049-0.627,0.123-0.806,0.227c-0.305,0.17-0.458,0.445-0.458,0.822
        C62.142,317.648,62.245,317.871,62.455,318.037z M63.848,309.996c0.314,0,0.585,0.113,0.81,0.34
        c0.226,0.225,0.337,0.494,0.337,0.809s-0.113,0.584-0.337,0.811c-0.224,0.225-0.495,0.338-0.81,0.338s-0.583-0.113-0.806-0.338
        c-0.224-0.227-0.335-0.496-0.335-0.811s0.111-0.584,0.335-0.809C63.264,310.111,63.533,309.996,63.848,309.996z M63.848,311.838
        c0.19,0,0.354-0.068,0.489-0.203c0.134-0.137,0.202-0.299,0.202-0.49c0-0.189-0.068-0.352-0.202-0.488
        c-0.135-0.135-0.298-0.203-0.489-0.203c-0.187,0-0.348,0.068-0.483,0.203c-0.136,0.137-0.204,0.299-0.204,0.488
        c0,0.191,0.068,0.354,0.204,0.49C63.5,311.77,63.663,311.838,63.848,311.838z"
        />
        <path
          fill="#010202"
          d="M67.636,313.045h0.94v0.836c0.277-0.344,0.573-0.59,0.884-0.74c0.312-0.152,0.656-0.227,1.039-0.227
        c0.835,0,1.398,0.291,1.69,0.873c0.162,0.318,0.242,0.771,0.242,1.367v3.771h-1.005v-3.705c0-0.359-0.053-0.648-0.16-0.867
        c-0.176-0.367-0.493-0.551-0.955-0.551c-0.235,0-0.427,0.025-0.576,0.072c-0.271,0.082-0.508,0.24-0.714,0.482
        c-0.165,0.195-0.271,0.395-0.321,0.602c-0.05,0.209-0.074,0.504-0.074,0.887v3.08h-0.99V313.045z"
        />
        <path
          fill="#010202"
          d="M77.251,310.859h3.609c0.713,0,1.289,0.201,1.728,0.607c0.438,0.404,0.658,0.973,0.658,1.703
        c0,0.631-0.197,1.178-0.587,1.646c-0.392,0.467-0.99,0.701-1.796,0.701h-2.518v3.408h-1.093V310.859z M81.489,311.969
        c-0.241-0.113-0.569-0.168-0.987-0.168h-2.158v2.793h2.158c0.487,0,0.882-0.104,1.186-0.312s0.455-0.574,0.455-1.104
        C82.144,312.584,81.924,312.182,81.489,311.969z"
        />
        <path fill="#010202" d="M84.542,310.859h0.988v8.066h-0.988V310.859z" />
        <path
          fill="#010202"
          d="M88,313.045v3.904c0,0.301,0.047,0.545,0.139,0.736c0.171,0.352,0.49,0.527,0.956,0.527
        c0.671,0,1.127-0.307,1.37-0.924c0.132-0.328,0.198-0.779,0.198-1.355v-2.889h0.988v5.879h-0.934l0.011-0.867
        c-0.125,0.225-0.282,0.414-0.469,0.566c-0.369,0.307-0.819,0.461-1.347,0.461c-0.822,0-1.382-0.279-1.68-0.84
        c-0.162-0.301-0.242-0.701-0.242-1.203v-3.998H88V313.045z"
        />
        <path
          fill="#010202"
          d="M93.854,317.082c0.031,0.328,0.114,0.58,0.253,0.758c0.253,0.316,0.695,0.477,1.322,0.477
        c0.373,0,0.703-0.08,0.985-0.238c0.284-0.16,0.426-0.404,0.426-0.738c0-0.252-0.113-0.443-0.34-0.576
        c-0.145-0.08-0.432-0.172-0.86-0.279l-0.798-0.197c-0.51-0.125-0.886-0.264-1.127-0.418c-0.432-0.266-0.646-0.637-0.646-1.109
        c0-0.557,0.203-1.006,0.609-1.352c0.406-0.344,0.952-0.516,1.639-0.516c0.898,0,1.545,0.26,1.941,0.779
        c0.249,0.33,0.369,0.684,0.361,1.064h-0.934c-0.02-0.223-0.1-0.424-0.242-0.605c-0.232-0.258-0.635-0.387-1.208-0.387
        c-0.382,0-0.672,0.07-0.869,0.213s-0.295,0.33-0.295,0.564c0,0.254,0.129,0.459,0.389,0.611c0.148,0.092,0.369,0.172,0.66,0.24
        l0.666,0.162c0.724,0.17,1.209,0.338,1.455,0.5c0.384,0.252,0.576,0.648,0.576,1.189c0,0.525-0.202,0.977-0.603,1.357
        c-0.402,0.381-1.015,0.57-1.837,0.57c-0.885,0-1.513-0.199-1.882-0.596c-0.369-0.396-0.566-0.889-0.592-1.473h0.952V317.082z"
        />
        <path
          fill="#010202"
          d="M104.388,311.09c0.79,0.422,1.274,1.16,1.45,2.213h-1.081c-0.129-0.588-0.396-1.02-0.801-1.287
        c-0.405-0.27-0.916-0.404-1.532-0.404c-0.732,0-1.347,0.281-1.848,0.84c-0.5,0.561-0.75,1.395-0.75,2.504
        c0,0.959,0.207,1.74,0.618,2.342c0.413,0.602,1.085,0.904,2.018,0.904c0.713,0,1.305-0.211,1.772-0.637
        c0.469-0.422,0.708-1.105,0.719-2.049h-2.525v-0.906h3.541v4.316h-0.703l-0.263-1.039c-0.364,0.406-0.687,0.688-0.968,0.844
        c-0.471,0.271-1.071,0.406-1.798,0.406c-0.94,0-1.748-0.307-2.426-0.926c-0.738-0.775-1.108-1.842-1.108-3.195
        c0-1.352,0.361-2.426,1.083-3.225c0.685-0.76,1.572-1.141,2.662-1.141C103.197,310.65,103.844,310.797,104.388,311.09z"
        />
        <path
          fill="#010202"
          d="M107.634,310.859h1.005v1.119h-1.005V310.859z M107.634,313.072h1.005v5.854h-1.005V313.072z"
        />
        <path
          fill="#010202"
          d="M110.159,313.045h0.939v1.016c0.077-0.197,0.266-0.438,0.566-0.721s0.645-0.426,1.037-0.426
        c0.019,0,0.05,0.002,0.093,0.006c0.043,0.002,0.12,0.01,0.226,0.021v1.043c-0.06-0.012-0.113-0.02-0.163-0.023
        s-0.103-0.004-0.163-0.004c-0.498,0-0.88,0.16-1.146,0.48c-0.266,0.318-0.401,0.689-0.401,1.107v3.381h-0.989v-5.881H110.159z"
        />
        <path
          fill="#010202"
          d="M118.137,313.633c0.52,0.502,0.779,1.246,0.779,2.227c0,0.947-0.229,1.729-0.687,2.348
        c-0.458,0.617-1.169,0.926-2.132,0.926c-0.805,0-1.441-0.273-1.914-0.818c-0.473-0.547-0.708-1.283-0.708-2.203
        c0-0.988,0.25-1.775,0.747-2.359c0.499-0.584,1.166-0.879,2.006-0.879C116.981,312.873,117.618,313.127,118.137,313.633z
         M117.524,317.539c0.24-0.494,0.361-1.045,0.361-1.652c0-0.549-0.087-0.994-0.261-1.338c-0.274-0.541-0.749-0.811-1.422-0.811
        c-0.598,0-1.032,0.23-1.303,0.689c-0.271,0.463-0.406,1.018-0.406,1.668c0,0.627,0.135,1.146,0.406,1.562
        c0.271,0.418,0.703,0.627,1.292,0.627C116.839,318.283,117.283,318.037,117.524,317.539z"
        />
        <path
          fill="#010202"
          d="M121.967,310.859h0.835l-2.559,8.066h-0.835L121.967,310.859z"
        />
        <path
          fill="#010202"
          d="M123.172,313.072h0.966v0.779c0.198-0.268,0.415-0.475,0.65-0.621c0.334-0.221,0.726-0.328,1.177-0.328
        c0.667,0,1.235,0.254,1.701,0.766c0.466,0.51,0.7,1.24,0.7,2.186c0,1.283-0.335,2.197-1.008,2.746
        c-0.428,0.348-0.923,0.521-1.489,0.521c-0.445,0-0.817-0.098-1.119-0.291c-0.176-0.109-0.373-0.301-0.59-0.566v3.002h-0.988
        V313.072z M126.891,317.682c0.306-0.385,0.459-0.963,0.459-1.732c0-0.467-0.068-0.871-0.203-1.207
        c-0.258-0.646-0.728-0.973-1.411-0.973c-0.687,0-1.156,0.344-1.411,1.027c-0.135,0.367-0.203,0.832-0.203,1.395
        c0,0.453,0.068,0.84,0.203,1.158c0.256,0.607,0.728,0.91,1.411,0.91C126.199,318.26,126.583,318.068,126.891,317.682z"
        />
        <path
          fill="#010202"
          d="M133.14,313.207c0.389,0.195,0.686,0.447,0.889,0.76c0.197,0.297,0.327,0.643,0.394,1.039
        c0.058,0.271,0.087,0.703,0.087,1.297h-4.277c0.018,0.596,0.158,1.076,0.419,1.439c0.263,0.361,0.667,0.543,1.214,0.543
        c0.511,0,0.921-0.17,1.226-0.514c0.169-0.197,0.292-0.428,0.363-0.688h0.973c-0.026,0.215-0.111,0.455-0.256,0.721
        c-0.144,0.266-0.305,0.482-0.484,0.648c-0.298,0.295-0.668,0.492-1.106,0.594c-0.235,0.059-0.503,0.088-0.801,0.088
        c-0.728,0-1.344-0.266-1.85-0.799c-0.506-0.531-0.758-1.277-0.758-2.236c0-0.945,0.255-1.713,0.764-2.301
        c0.51-0.59,1.176-0.885,1.997-0.885C132.348,312.914,132.75,313.012,133.14,313.207z M133.493,315.516
        c-0.039-0.43-0.132-0.771-0.277-1.027c-0.269-0.477-0.717-0.713-1.345-0.713c-0.45,0-0.828,0.164-1.132,0.492
        c-0.305,0.326-0.466,0.744-0.484,1.248H133.493z"
        />
        <path
          fill="#010202"
          d="M135.788,313.045h0.938v1.016c0.078-0.197,0.266-0.438,0.566-0.721c0.3-0.283,0.645-0.426,1.037-0.426
        c0.02,0,0.05,0.002,0.094,0.006c0.043,0.002,0.119,0.01,0.226,0.021v1.043c-0.06-0.012-0.113-0.02-0.163-0.023
        c-0.05-0.004-0.104-0.004-0.163-0.004c-0.498,0-0.88,0.16-1.146,0.48c-0.266,0.318-0.401,0.689-0.401,1.107v3.381h-0.987V313.045z"
        />
        <path
          fill="#010202"
          d="M140.092,317.082c0.031,0.328,0.115,0.58,0.253,0.758c0.253,0.316,0.695,0.477,1.322,0.477
        c0.373,0,0.703-0.08,0.985-0.238c0.284-0.16,0.426-0.404,0.426-0.738c0-0.252-0.113-0.443-0.34-0.576
        c-0.145-0.08-0.432-0.172-0.859-0.279l-0.798-0.197c-0.51-0.125-0.885-0.264-1.127-0.418c-0.432-0.266-0.646-0.637-0.646-1.109
        c0-0.557,0.203-1.006,0.609-1.352c0.407-0.344,0.952-0.516,1.639-0.516c0.898,0,1.545,0.26,1.942,0.779
        c0.248,0.33,0.369,0.684,0.361,1.064h-0.934c-0.02-0.223-0.1-0.424-0.242-0.605c-0.232-0.258-0.636-0.387-1.208-0.387
        c-0.382,0-0.672,0.07-0.869,0.213s-0.295,0.33-0.295,0.564c0,0.254,0.129,0.459,0.389,0.611c0.148,0.092,0.37,0.172,0.66,0.24
        l0.666,0.162c0.724,0.17,1.209,0.338,1.455,0.5c0.384,0.252,0.576,0.648,0.576,1.189c0,0.525-0.202,0.977-0.603,1.357
        s-1.014,0.57-1.837,0.57c-0.885,0-1.513-0.199-1.882-0.596c-0.37-0.396-0.566-0.889-0.592-1.473h0.951V317.082z"
        />
        <path
          fill="#010202"
          d="M149.388,313.633c0.519,0.502,0.779,1.246,0.779,2.227c0,0.947-0.229,1.729-0.687,2.348
        c-0.458,0.617-1.169,0.926-2.132,0.926c-0.805,0-1.442-0.273-1.915-0.818c-0.473-0.547-0.708-1.283-0.708-2.203
        c0-0.988,0.25-1.775,0.747-2.359c0.499-0.584,1.166-0.879,2.006-0.879C148.233,312.873,148.869,313.127,149.388,313.633z
         M148.775,317.539c0.24-0.494,0.361-1.045,0.361-1.652c0-0.549-0.087-0.994-0.261-1.338c-0.274-0.541-0.748-0.811-1.422-0.811
        c-0.598,0-1.032,0.23-1.303,0.689c-0.271,0.463-0.407,1.018-0.407,1.668c0,0.627,0.136,1.146,0.407,1.562
        c0.271,0.418,0.703,0.627,1.292,0.627C148.09,318.283,148.535,318.037,148.775,317.539z"
        />
        <path
          fill="#010202"
          d="M151.381,313.045h0.94v0.836c0.277-0.344,0.573-0.59,0.884-0.74c0.312-0.152,0.656-0.227,1.039-0.227
        c0.835,0,1.398,0.291,1.69,0.873c0.161,0.318,0.242,0.771,0.242,1.367v3.771h-1.005v-3.705c0-0.359-0.053-0.648-0.16-0.867
        c-0.176-0.367-0.493-0.551-0.955-0.551c-0.235,0-0.427,0.025-0.576,0.072c-0.271,0.082-0.508,0.24-0.714,0.482
        c-0.165,0.195-0.271,0.395-0.321,0.602c-0.05,0.209-0.074,0.504-0.074,0.887v3.08h-0.989v-5.881H151.381z"
        />
      </g>
      <g enable-background="new">
        <path
          fill="#010202"
          d="M157.613,310.859h0.95v4.682l2.534-2.498h1.263l-2.259,2.188l2.387,3.693h-1.263l-1.85-2.992l-0.811,0.785
        v2.207h-0.95V310.859z"
        />
      </g>
      <g enable-background="new">
        <path
          fill="#010202"
          d="M167.237,313.633c0.519,0.502,0.779,1.246,0.779,2.227c0,0.947-0.229,1.729-0.687,2.348
        c-0.458,0.617-1.169,0.926-2.132,0.926c-0.805,0-1.442-0.273-1.915-0.818c-0.472-0.547-0.708-1.283-0.708-2.203
        c0-0.988,0.25-1.775,0.747-2.359c0.498-0.584,1.166-0.879,2.006-0.879C166.081,312.873,166.716,313.127,167.237,313.633z
         M166.623,317.539c0.241-0.494,0.361-1.045,0.361-1.652c0-0.549-0.087-0.994-0.261-1.338c-0.274-0.541-0.748-0.811-1.422-0.811
        c-0.598,0-1.032,0.23-1.303,0.689c-0.271,0.463-0.406,1.018-0.406,1.668c0,0.627,0.136,1.146,0.406,1.562
        c0.271,0.418,0.703,0.627,1.292,0.627C165.939,318.283,166.382,318.037,166.623,317.539z"
        />
        <path
          fill="#010202"
          d="M169.23,313.045h0.94v0.836c0.277-0.344,0.572-0.59,0.883-0.74c0.312-0.152,0.657-0.227,1.039-0.227
        c0.835,0,1.398,0.291,1.69,0.873c0.161,0.318,0.242,0.771,0.242,1.367v3.771h-1.004v-3.705c0-0.359-0.054-0.648-0.16-0.867
        c-0.176-0.367-0.493-0.551-0.955-0.551c-0.235,0-0.427,0.025-0.576,0.072c-0.271,0.082-0.508,0.24-0.714,0.482
        c-0.165,0.195-0.271,0.395-0.321,0.602c-0.05,0.209-0.074,0.504-0.074,0.887v3.08h-0.989v-5.881H169.23z"
        />
        <path
          fill="#010202"
          d="M175.681,311.402h1v1.643h0.94v0.809h-0.94v3.838c0,0.205,0.069,0.342,0.208,0.41
        c0.077,0.041,0.205,0.061,0.384,0.061c0.048,0,0.1,0,0.155-0.004c0.055-0.002,0.119-0.008,0.192-0.02v0.787
        c-0.114,0.033-0.232,0.055-0.355,0.07c-0.122,0.016-0.254,0.021-0.398,0.021c-0.461,0-0.774-0.117-0.939-0.354
        c-0.164-0.238-0.247-0.543-0.247-0.92v-3.891h-0.796v-0.809h0.796V311.402z"
        />
        <path
          fill="#010202"
          d="M182.867,313.633c0.52,0.502,0.779,1.246,0.779,2.227c0,0.947-0.229,1.729-0.687,2.348
        c-0.458,0.617-1.169,0.926-2.132,0.926c-0.805,0-1.441-0.273-1.914-0.818c-0.473-0.547-0.708-1.283-0.708-2.203
        c0-0.988,0.25-1.775,0.747-2.359c0.499-0.584,1.166-0.879,2.006-0.879C181.711,312.873,182.348,313.127,182.867,313.633z
         M182.254,317.539c0.24-0.494,0.361-1.045,0.361-1.652c0-0.549-0.087-0.994-0.261-1.338c-0.274-0.541-0.748-0.811-1.422-0.811
        c-0.598,0-1.032,0.23-1.303,0.689c-0.271,0.463-0.406,1.018-0.406,1.668c0,0.627,0.136,1.146,0.406,1.562
        c0.271,0.418,0.703,0.627,1.292,0.627C181.569,318.283,182.013,318.037,182.254,317.539z"
        />
        <path
          fill="#010202"
          d="M190.587,310.727c-0.574,1.117-0.948,1.939-1.119,2.465c-0.26,0.803-0.391,1.727-0.391,2.779
        c0,1.061,0.148,2.031,0.445,2.91c0.182,0.543,0.543,1.32,1.081,2.338h-0.665c-0.536-0.834-0.866-1.367-0.994-1.596
        c-0.129-0.232-0.268-0.543-0.417-0.939c-0.205-0.541-0.348-1.119-0.429-1.734c-0.041-0.318-0.062-0.623-0.062-0.91
        c0-1.084,0.169-2.049,0.511-2.895c0.216-0.539,0.666-1.344,1.35-2.416h0.689V310.727z"
        />
        <path
          fill="#010202"
          d="M192.214,313.045l1.571,4.789l1.635-4.789h1.082l-2.214,5.881h-1.053l-2.169-5.881H192.214z"
        />
        <path
          fill="#010202"
          d="M197.351,310.859h1.004v1.119h-1.004V310.859z M197.351,313.072h1.004v5.854h-1.004V313.072z"
        />
        <path
          fill="#010202"
          d="M203.079,313.225c0.174,0.111,0.374,0.305,0.597,0.576v-2.971h0.95v8.096h-0.889v-0.818
        c-0.231,0.363-0.502,0.623-0.814,0.783c-0.313,0.16-0.673,0.24-1.077,0.24c-0.653,0-1.218-0.273-1.697-0.826
        c-0.478-0.551-0.716-1.283-0.716-2.197c0-0.855,0.218-1.598,0.653-2.227c0.437-0.629,1.058-0.941,1.868-0.941
        C202.402,312.939,202.777,313.037,203.079,313.225z M200.875,317.637c0.266,0.424,0.692,0.637,1.277,0.637
        c0.455,0,0.829-0.197,1.123-0.59s0.44-0.959,0.44-1.693c0-0.744-0.151-1.293-0.453-1.65c-0.303-0.355-0.676-0.535-1.119-0.535
        c-0.495,0-0.896,0.189-1.205,0.57c-0.307,0.381-0.461,0.941-0.461,1.682C200.475,316.684,200.608,317.211,200.875,317.637z"
        />
        <path
          fill="#010202"
          d="M212.496,313.281c0.186,0.129,0.376,0.314,0.569,0.561V313.1h0.911v5.348c0,0.746-0.11,1.336-0.328,1.768
        c-0.408,0.799-1.18,1.197-2.315,1.197c-0.631,0-1.161-0.143-1.592-0.426c-0.431-0.285-0.672-0.729-0.723-1.33h1.005
        c0.047,0.262,0.142,0.465,0.284,0.609c0.223,0.219,0.571,0.328,1.048,0.328c0.753,0,1.247-0.268,1.48-0.801
        c0.137-0.314,0.202-0.877,0.19-1.686c-0.197,0.299-0.434,0.521-0.711,0.67c-0.276,0.146-0.644,0.219-1.1,0.219
        c-0.634,0-1.189-0.227-1.665-0.678s-0.714-1.199-0.714-2.244c0-0.984,0.24-1.754,0.719-2.307c0.479-0.551,1.058-0.828,1.737-0.828
        C211.752,312.939,212.152,313.055,212.496,313.281z M212.617,314.342c-0.298-0.352-0.679-0.527-1.142-0.527
        c-0.691,0-1.164,0.326-1.419,0.977c-0.134,0.35-0.202,0.803-0.202,1.367c0,0.664,0.134,1.168,0.401,1.514
        c0.268,0.346,0.627,0.52,1.079,0.52c0.707,0,1.203-0.32,1.492-0.961c0.16-0.363,0.24-0.785,0.24-1.27
        C213.065,315.232,212.915,314.691,212.617,314.342z"
        />
        <path
          fill="#010202"
          d="M215.48,310.859h1.005v1.119h-1.005V310.859z M215.48,313.072h1.005v5.854h-1.005V313.072z"
        />
        <path
          fill="#010202"
          d="M218.006,313.045h0.938v1.016c0.077-0.197,0.266-0.438,0.566-0.721s0.645-0.426,1.037-0.426
        c0.02,0,0.05,0.002,0.093,0.006c0.044,0.002,0.12,0.01,0.226,0.021v1.043c-0.06-0.012-0.113-0.02-0.163-0.023
        c-0.05-0.004-0.103-0.004-0.163-0.004c-0.499,0-0.881,0.16-1.147,0.48c-0.266,0.318-0.401,0.689-0.401,1.107v3.381h-0.989v-5.881
        H218.006z"
        />
        <path
          fill="#010202"
          d="M225.355,313.207c0.389,0.195,0.686,0.447,0.889,0.76c0.197,0.297,0.328,0.643,0.394,1.039
        c0.058,0.271,0.087,0.703,0.087,1.297h-4.277c0.018,0.596,0.158,1.076,0.419,1.439c0.263,0.361,0.668,0.543,1.214,0.543
        c0.511,0,0.921-0.17,1.226-0.514c0.169-0.197,0.292-0.428,0.363-0.688h0.972c-0.026,0.215-0.111,0.455-0.256,0.721
        c-0.144,0.266-0.305,0.482-0.484,0.648c-0.298,0.295-0.667,0.492-1.106,0.594c-0.235,0.059-0.503,0.088-0.801,0.088
        c-0.728,0-1.343-0.266-1.85-0.799c-0.507-0.531-0.758-1.277-0.758-2.236c0-0.945,0.255-1.713,0.765-2.301
        c0.509-0.59,1.175-0.885,1.997-0.885C224.563,312.914,224.965,313.012,225.355,313.207z M225.708,315.516
        c-0.039-0.43-0.132-0.771-0.277-1.027c-0.27-0.477-0.718-0.713-1.345-0.713c-0.45,0-0.827,0.164-1.132,0.492
        c-0.305,0.326-0.466,0.744-0.484,1.248H225.708z"
        />
      </g>
      <g enable-background="new">
        <path
          fill="#010202"
          d="M228.001,313.045h0.939v1.016c0.077-0.197,0.266-0.438,0.566-0.721c0.3-0.283,0.645-0.426,1.037-0.426
        c0.02,0,0.05,0.002,0.094,0.006c0.043,0.002,0.119,0.01,0.226,0.021v1.043c-0.06-0.012-0.113-0.02-0.163-0.023
        c-0.05-0.004-0.103-0.004-0.163-0.004c-0.498,0-0.88,0.16-1.146,0.48c-0.266,0.318-0.401,0.689-0.401,1.107v3.381H228v-5.881
        H228.001z"
        />
      </g>
      <g enable-background="new">
        <path
          fill="#010202"
          d="M231.854,310.859h1.004v1.119h-1.004V310.859z M231.854,313.072h1.004v5.854h-1.004V313.072z"
        />
        <path
          fill="#010202"
          d="M234.351,313.045h0.94v0.836c0.277-0.344,0.572-0.59,0.884-0.74c0.311-0.152,0.656-0.227,1.039-0.227
        c0.835,0,1.398,0.291,1.69,0.873c0.161,0.318,0.242,0.771,0.242,1.367v3.771h-1.005v-3.705c0-0.359-0.053-0.648-0.16-0.867
        c-0.176-0.367-0.494-0.551-0.955-0.551c-0.236,0-0.428,0.025-0.576,0.072c-0.271,0.082-0.508,0.24-0.714,0.482
        c-0.165,0.195-0.271,0.395-0.321,0.602c-0.05,0.209-0.074,0.504-0.074,0.887v3.08h-0.988v-5.881H234.351z"
        />
        <path
          fill="#010202"
          d="M243.875,313.281c0.185,0.129,0.375,0.314,0.569,0.561V313.1h0.911v5.348c0,0.746-0.109,1.336-0.327,1.768
        c-0.408,0.799-1.181,1.197-2.316,1.197c-0.63,0-1.161-0.143-1.592-0.426c-0.43-0.285-0.672-0.729-0.722-1.33h1.004
        c0.047,0.262,0.142,0.465,0.284,0.609c0.222,0.219,0.571,0.328,1.048,0.328c0.753,0,1.246-0.268,1.48-0.801
        c0.137-0.314,0.202-0.877,0.19-1.686c-0.197,0.299-0.434,0.521-0.711,0.67c-0.276,0.146-0.644,0.219-1.1,0.219
        c-0.634,0-1.188-0.227-1.664-0.678c-0.476-0.451-0.715-1.199-0.715-2.244c0-0.984,0.241-1.754,0.72-2.307
        c0.479-0.551,1.058-0.828,1.737-0.828C243.13,312.939,243.532,313.055,243.875,313.281z M243.995,314.342
        c-0.298-0.352-0.679-0.527-1.142-0.527c-0.692,0-1.165,0.326-1.419,0.977c-0.134,0.35-0.202,0.803-0.202,1.367
        c0,0.664,0.134,1.168,0.402,1.514c0.268,0.346,0.627,0.52,1.079,0.52c0.707,0,1.203-0.32,1.492-0.961
        c0.16-0.363,0.24-0.785,0.24-1.27C244.443,315.232,244.293,314.691,243.995,314.342z"
        />
        <path
          fill="#010202"
          d="M246.525,321.219c0.582-1.133,0.958-1.961,1.125-2.48c0.257-0.785,0.384-1.711,0.384-2.768
        s-0.148-2.025-0.443-2.904c-0.184-0.543-0.543-1.32-1.082-2.34h0.665c0.564,0.902,0.905,1.457,1.024,1.666
        c0.119,0.213,0.249,0.502,0.387,0.871c0.176,0.457,0.301,0.908,0.375,1.357c0.076,0.445,0.113,0.875,0.113,1.289
        c0,1.084-0.171,2.051-0.516,2.9c-0.216,0.545-0.665,1.348-1.345,2.408H246.525z"
        />
      </g>
      <g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M822.543,293.324c0.39,0.195,0.687,0.449,0.89,0.762c0.197,0.297,0.327,0.643,0.393,1.039
          c0.06,0.271,0.088,0.703,0.088,1.297h-4.276c0.02,0.596,0.158,1.076,0.419,1.439c0.263,0.361,0.668,0.543,1.216,0.543
          c0.512,0,0.92-0.17,1.226-0.514c0.17-0.199,0.294-0.43,0.365-0.689h0.972c-0.025,0.217-0.109,0.457-0.254,0.721
          c-0.146,0.268-0.305,0.482-0.486,0.65c-0.296,0.295-0.666,0.492-1.105,0.594c-0.235,0.059-0.504,0.088-0.8,0.088
          c-0.728,0-1.346-0.268-1.851-0.799c-0.504-0.533-0.758-1.277-0.758-2.236c0-0.945,0.254-1.713,0.763-2.303
          c0.51-0.588,1.176-0.883,1.997-0.883C821.752,293.033,822.154,293.131,822.543,293.324z M822.897,295.635
          c-0.039-0.43-0.132-0.771-0.277-1.027c-0.27-0.477-0.716-0.713-1.345-0.713c-0.45,0-0.828,0.164-1.133,0.492
          c-0.305,0.326-0.466,0.742-0.485,1.248H822.897z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M815.193,293.164h0.938v1.016c0.076-0.197,0.265-0.438,0.566-0.721s0.646-0.426,1.039-0.426
          c0.019,0,0.048,0.002,0.093,0.004c0.046,0.004,0.118,0.012,0.227,0.023v1.041c-0.061-0.01-0.113-0.018-0.163-0.021
          c-0.049-0.004-0.102-0.006-0.163-0.006c-0.498,0-0.881,0.162-1.146,0.48c-0.268,0.32-0.401,0.691-0.401,1.109v3.381h-0.987v-5.881
          H815.193z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M813.178,293.75c0.52,0.504,0.777,1.248,0.777,2.229c0,0.945-0.229,1.729-0.688,2.348
          c-0.456,0.617-1.169,0.926-2.13,0.926c-0.805,0-1.441-0.273-1.914-0.82c-0.473-0.545-0.708-1.281-0.708-2.201
          c0-0.988,0.248-1.775,0.746-2.359c0.499-0.584,1.166-0.879,2.007-0.879C812.021,292.992,812.658,293.246,813.178,293.75z
           M812.563,297.658c0.238-0.494,0.361-1.045,0.361-1.652c0-0.549-0.087-0.994-0.26-1.338c-0.276-0.541-0.75-0.811-1.424-0.811
          c-0.597,0-1.031,0.23-1.304,0.689c-0.271,0.461-0.407,1.018-0.407,1.668c0,0.625,0.137,1.146,0.407,1.562
          c0.272,0.418,0.703,0.627,1.294,0.627C811.88,298.402,812.323,298.154,812.563,297.658z M809.905,291.109h1.027v1.143h-1.027
          V291.109z M811.717,291.109h1.027v1.143h-1.027V291.109z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M589.252,293.164h0.938v1.016c0.075-0.197,0.264-0.438,0.565-0.721s0.646-0.426,1.039-0.426
          c0.02,0,0.048,0.002,0.094,0.004c0.045,0.004,0.117,0.012,0.226,0.023v1.041c-0.06-0.01-0.113-0.018-0.163-0.021
          c-0.049-0.004-0.102-0.006-0.163-0.006c-0.498,0-0.88,0.162-1.146,0.48c-0.268,0.32-0.401,0.691-0.401,1.109v3.381h-0.987
          L589.252,293.164L589.252,293.164z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M587.232,293.75c0.52,0.504,0.777,1.248,0.777,2.229c0,0.945-0.229,1.729-0.688,2.348
          c-0.456,0.617-1.169,0.926-2.13,0.926c-0.805,0-1.442-0.273-1.914-0.82c-0.473-0.545-0.708-1.281-0.708-2.201
          c0-0.988,0.248-1.775,0.746-2.359c0.499-0.584,1.166-0.879,2.007-0.879C586.076,292.992,586.713,293.246,587.232,293.75z
           M586.618,297.658c0.238-0.494,0.361-1.045,0.361-1.652c0-0.549-0.087-0.994-0.26-1.338c-0.276-0.541-0.75-0.811-1.424-0.811
          c-0.597,0-1.031,0.23-1.304,0.689c-0.271,0.461-0.408,1.018-0.408,1.668c0,0.625,0.138,1.146,0.408,1.562
          c0.272,0.418,0.703,0.627,1.294,0.627C585.933,298.402,586.378,298.154,586.618,297.658z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M576.718,293.164h0.938V294c0.279-0.344,0.572-0.592,0.886-0.74c0.31-0.152,0.654-0.227,1.036-0.227
          c0.836,0,1.398,0.291,1.692,0.873c0.161,0.316,0.241,0.771,0.241,1.367v3.771h-1.004v-3.705c0-0.359-0.054-0.648-0.161-0.869
          c-0.175-0.365-0.494-0.549-0.955-0.549c-0.235,0-0.428,0.025-0.576,0.072c-0.271,0.08-0.508,0.24-0.714,0.482
          c-0.165,0.195-0.271,0.395-0.319,0.602c-0.049,0.207-0.074,0.504-0.074,0.887v3.08h-0.988v-5.881H576.718z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M574.728,293.75c0.52,0.504,0.777,1.248,0.777,2.229c0,0.945-0.229,1.729-0.687,2.348
          c-0.457,0.617-1.17,0.926-2.131,0.926c-0.805,0-1.441-0.273-1.914-0.82c-0.473-0.545-0.708-1.281-0.708-2.201
          c0-0.988,0.248-1.775,0.746-2.359c0.499-0.584,1.166-0.879,2.007-0.879C573.571,292.992,574.208,293.246,574.728,293.75z
           M574.113,297.658c0.238-0.494,0.361-1.045,0.361-1.652c0-0.549-0.087-0.994-0.26-1.338c-0.275-0.541-0.75-0.811-1.424-0.811
          c-0.597,0-1.031,0.23-1.304,0.689c-0.271,0.461-0.407,1.018-0.407,1.668c0,0.625,0.137,1.146,0.407,1.562
          c0.272,0.418,0.703,0.627,1.294,0.627C573.428,298.402,573.873,298.154,574.113,297.658z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M566.753,293.164h0.938v1.016c0.076-0.197,0.265-0.438,0.566-0.721c0.301-0.283,0.645-0.426,1.038-0.426
          c0.02,0,0.049,0.002,0.094,0.004c0.045,0.004,0.118,0.012,0.226,0.023v1.041c-0.06-0.01-0.112-0.018-0.163-0.021
          c-0.048-0.004-0.102-0.006-0.162-0.006c-0.499,0-0.881,0.162-1.147,0.48c-0.268,0.32-0.401,0.691-0.401,1.109v3.381h-0.986v-5.881
          H566.753z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M561.076,290.979h0.951v4.682l2.532-2.498h1.263l-2.26,2.188l2.386,3.693h-1.262l-1.85-2.992l-0.81,0.785
          v2.207h-0.951V290.979L561.076,290.979z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M554.874,295.484c0.228-0.029,0.377-0.123,0.453-0.285c0.043-0.09,0.065-0.215,0.065-0.379
          c0-0.34-0.119-0.582-0.359-0.736c-0.24-0.152-0.58-0.229-1.027-0.229c-0.514,0-0.88,0.139-1.097,0.42
          c-0.119,0.154-0.198,0.383-0.235,0.689h-0.922c0.018-0.727,0.253-1.234,0.704-1.516c0.452-0.285,0.976-0.428,1.573-0.428
          c0.691,0,1.251,0.131,1.684,0.395c0.427,0.264,0.64,0.674,0.64,1.23v3.389c0,0.102,0.022,0.184,0.063,0.248
          c0.043,0.061,0.132,0.092,0.268,0.092c0.043,0,0.094-0.002,0.148-0.008c0.053-0.006,0.112-0.014,0.176-0.025v0.729
          c-0.155,0.045-0.271,0.074-0.354,0.084c-0.081,0.012-0.188,0.018-0.329,0.018c-0.34,0-0.587-0.121-0.742-0.363
          c-0.079-0.127-0.135-0.309-0.169-0.543c-0.201,0.262-0.49,0.492-0.866,0.686c-0.377,0.193-0.793,0.293-1.246,0.293
          c-0.545,0-0.99-0.168-1.336-0.498c-0.347-0.332-0.519-0.746-0.519-1.242c0-0.545,0.169-0.969,0.51-1.268
          c0.34-0.301,0.786-0.484,1.338-0.553L554.874,295.484z M552.792,298.154c0.208,0.166,0.456,0.246,0.74,0.246
          c0.35,0,0.686-0.08,1.011-0.24c0.549-0.266,0.824-0.705,0.824-1.311v-0.795c-0.121,0.078-0.275,0.143-0.465,0.193
          c-0.191,0.051-0.377,0.09-0.561,0.111l-0.597,0.074c-0.358,0.049-0.628,0.123-0.808,0.227c-0.306,0.17-0.457,0.445-0.457,0.822
          C552.48,297.766,552.584,297.99,552.792,298.154z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M546.08,290.979h0.951v4.682l2.532-2.498h1.263l-2.26,2.188l2.386,3.693h-1.262l-1.85-2.992l-0.81,0.785
          v2.207h-0.951V290.979L546.08,290.979z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M541.064,297.201c0.03,0.328,0.114,0.58,0.253,0.758c0.252,0.316,0.693,0.477,1.321,0.477
          c0.372,0,0.703-0.08,0.985-0.238c0.285-0.16,0.427-0.404,0.427-0.738c0-0.252-0.112-0.443-0.34-0.576
          c-0.146-0.08-0.433-0.172-0.859-0.279l-0.797-0.197c-0.51-0.125-0.887-0.264-1.127-0.418c-0.433-0.268-0.648-0.637-0.648-1.109
          c0-0.557,0.205-1.008,0.611-1.352s0.951-0.516,1.638-0.516c0.898,0,1.545,0.26,1.942,0.779c0.248,0.328,0.369,0.684,0.361,1.064
          h-0.933c-0.02-0.223-0.1-0.424-0.242-0.605c-0.231-0.26-0.634-0.389-1.206-0.389c-0.384,0-0.672,0.072-0.869,0.213
          c-0.196,0.145-0.297,0.332-0.297,0.564c0,0.256,0.129,0.461,0.389,0.613c0.15,0.092,0.369,0.172,0.66,0.24l0.664,0.162
          c0.724,0.17,1.209,0.338,1.455,0.5c0.384,0.252,0.575,0.648,0.575,1.189c0,0.523-0.201,0.977-0.603,1.355
          c-0.402,0.381-1.015,0.572-1.839,0.572c-0.886,0-1.513-0.199-1.881-0.596c-0.367-0.396-0.567-0.889-0.592-1.475H541.064
          L541.064,297.201z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M534.225,293.164h0.938V294c0.279-0.344,0.573-0.592,0.886-0.74c0.31-0.152,0.655-0.227,1.037-0.227
          c0.835,0,1.398,0.291,1.691,0.873c0.161,0.316,0.242,0.771,0.242,1.367v3.771h-1.005v-3.705c0-0.359-0.053-0.648-0.161-0.869
          c-0.174-0.365-0.493-0.549-0.955-0.549c-0.235,0-0.427,0.025-0.575,0.072c-0.271,0.08-0.508,0.24-0.715,0.482
          c-0.164,0.195-0.271,0.395-0.319,0.602c-0.048,0.207-0.074,0.504-0.074,0.887v3.08h-0.988v-5.881H534.225z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M531.607,293.324c0.391,0.195,0.688,0.449,0.891,0.762c0.196,0.297,0.327,0.643,0.392,1.039
          c0.06,0.271,0.089,0.703,0.089,1.297h-4.277c0.02,0.596,0.158,1.076,0.42,1.439c0.263,0.361,0.667,0.543,1.216,0.543
          c0.511,0,0.919-0.17,1.226-0.514c0.169-0.199,0.293-0.43,0.364-0.689h0.973c-0.026,0.217-0.109,0.457-0.255,0.721
          c-0.146,0.268-0.305,0.482-0.485,0.65c-0.297,0.295-0.666,0.492-1.106,0.594c-0.235,0.059-0.503,0.088-0.8,0.088
          c-0.728,0-1.345-0.268-1.85-0.799c-0.505-0.533-0.758-1.277-0.758-2.236c0-0.945,0.253-1.713,0.763-2.303
          c0.51-0.588,1.176-0.883,1.996-0.883C530.815,293.033,531.217,293.131,531.607,293.324z M531.961,295.635
          c-0.039-0.43-0.133-0.771-0.277-1.027c-0.27-0.477-0.717-0.713-1.346-0.713c-0.449,0-0.827,0.164-1.132,0.492
          c-0.305,0.326-0.466,0.742-0.485,1.248H531.961z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M523.121,293.164l1.573,4.787l1.635-4.787h1.084l-2.214,5.879h-1.054l-2.169-5.879H523.121z"
          />
        </g>
        <g enable-background="new">
          <path
            fill="#010202"
            d="M515.982,296.441c0.024,0.457,0.129,0.83,0.311,1.113c0.351,0.535,0.967,0.803,1.847,0.803
          c0.396,0,0.755-0.059,1.079-0.176c0.629-0.227,0.942-0.633,0.942-1.219c0-0.438-0.133-0.752-0.398-0.938
          c-0.27-0.184-0.692-0.344-1.267-0.48l-1.058-0.246c-0.691-0.162-1.181-0.338-1.468-0.531c-0.496-0.338-0.745-0.842-0.745-1.51
          c0-0.727,0.244-1.32,0.73-1.783c0.489-0.467,1.181-0.699,2.074-0.699c0.824,0,1.522,0.203,2.097,0.611
          c0.575,0.41,0.862,1.061,0.862,1.957h-1.027c-0.051-0.432-0.164-0.762-0.337-0.992c-0.317-0.422-0.859-0.633-1.625-0.633
          c-0.618,0-1.062,0.135-1.332,0.406c-0.271,0.271-0.405,0.586-0.405,0.945c0,0.395,0.16,0.686,0.479,0.867
          c0.209,0.117,0.682,0.264,1.419,0.439l1.097,0.258c0.528,0.123,0.937,0.295,1.226,0.51c0.495,0.375,0.743,0.924,0.743,1.641
          c0,0.895-0.316,1.533-0.95,1.916c-0.635,0.385-1.37,0.576-2.209,0.576c-0.976,0-1.743-0.256-2.297-0.77
          c-0.555-0.508-0.825-1.197-0.813-2.068h1.025V296.441z"
          />
        </g>
      </g>
      <circle
        fill="none"
        stroke="#010202"
        stroke-width="0.036"
        stroke-miterlimit="10"
        cx="797.643"
        cy="345.208"
        r="2.05"
      />
      <path
        fill="#010202"
        d="M799.647,345.141c0-1.109-0.901-2.004-2.003-2.004c-1.106,0-2.003,0.896-2.003,2.004
      c0,1.104,0.904,2,2.003,2C798.749,347.141,799.647,346.244,799.647,345.141"
      />
      <line
        fill="none"
        stroke="#010202"
        stroke-width="0.363"
        stroke-miterlimit="10"
        x1="508.63"
        y1="96.382"
        x2="924.976"
        y2="96.382"
      />
    </svg>

    <image
      href="@/assets/images/inline-31992392.png"
      :height="dummyStyle.height"
      :width="dummyStyle.width"
      :x="dummyStyle.x"
      :y="dummyStyle.y"
      preserveAspectRatio="xMaxYMin"
      opacity="0"
    />
  </svg>
</template>

<script>
import moment from 'moment'
import _ from 'lodash'

export default {
  props: {
    print: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    invoice: {
      type: Object,
      default: () => {},
    },
    attachment: {
      type: String,
      default: '',
    },
    containerWidth: {
      type: Number,
      default: 100,
    },
    scale: {
      type: Number,
      default: 1,
    },
  },
  data: () => ({
    localInvoice: null,
    loading: true,
    pxInMm: 0.264583333,
    persons: [],
    invoiceRowsList: [],
    invoiceRowsQuery: {
      query: {},
      paginate: false,
    },
  }),
  async created() {
    if (!this.invoice) return
    await this.initInvoice()
  },
  methods: {
    mmToPx(mm = 0) {
      // const scale = this.scale * (3.78)
      return mm * 3.77952755905511 // / scale
    },
    async initInvoice() {
      this.localInvoice = null
      this.localInvoice = _.cloneDeep(this.invoice)
      await this.fetchInvoiceRows()

      this.localInvoice.dispatchDate = moment
        .utc(this.invoice.dispatchDate)
        .format('Y-MM-DD')
      this.localInvoice.dueDate = moment
        .utc(this.invoice.dueDate)
        .format('Y-MM-DD')
    },
    scaleStyle(style) {
      return style
    },
    pxToMm() {
      return this.pxInMm
    },
    async fetchInvoiceRows() {
      if (!this.invoice._id) return

      this.invoiceRowsQuery.query.invoiceId = this.invoice._id

      try {
        const { Invoicerow } = this.$FeathersVuex.api

        const invoiceRows = await Invoicerow.find(this.invoiceRowsQuery)
        // console.log(invoiceRows)

        this.invoiceRowsList = invoiceRows
      } catch (e) {
        console.log(e)
      }
    },
  },
  computed: {
    invoiceCardStyle() {
      if (this.print)
        return {
          width: 210 + 'mm',
          height: 297.5 + 'mm',
        }
      // background-color:white; width: 84mm; height: 118.8mm; float:left; border: 1px solid rgba(100,100,100,.3); border-radius:3px; position: relative;
      return {
        width: 210 + 'mm',
        height: 297.5 + 'mm',
      }
    },
    invoiceWrapperStyle() {
      // background-color:white; width: 84mm; height: 118.8mm; float:left; border: 1px solid rgba(100,100,100,.3); border-radius:3px; position: relative;
      if (this.print)
        return {
          backgroundColor: 'white',
          width: 210 + 'mm',
          height: 297.5 + 'mm',
          position: 'relative',
          top: 0,
          left: 0,
        }

      return {
        backgroundColor: 'white',
        width: 210 + 'mm',
        height: 297.5 + 'mm',
        border: this.print ? '0' : '1px solid rgba(100,100,100,.3)',
        borderRadius: this.print ? '0' : '3px',
        position: 'relative',
      }
    },
    dummyStyle() {
      // position: absolute; top: 2.4mm; left: 6mm; height: 6mm;

      return {
        y: this.mmToPx(0),
        x: this.mmToPx(0),
        height: this.mmToPx(297),
        width: this.mmToPx(210),
      }
    },
    logoStyle() {
      // position: absolute; top: 2.4mm; left: 6mm; height: 6mm;

      return {
        y: this.mmToPx(7.5),
        x: this.mmToPx(18),
        height: this.mmToPx(15),
      }
    },
    returnAddressStyle() {
      // position: absolute; top: 9.2mm; left: 6mm; font-size: 4px;

      return {
        y: this.mmToPx(27),
        x: this.mmToPx(24),
        fontSize: 8 + 'px',
      }
    },
    contactDataStyle() {
      // position: absolute; top: 18mm; left: 6mm; font-size: 5.2px;

      return {
        y: this.mmToPx(46),
        x: this.mmToPx(18),
        fontSize: 12 + 'px',
      }
    },
    ibanDataStyle() {
      // position: absolute; top: 22mm; left: 6mm; font-size: 5.2px;

      return {
        position: 'absolute',
        y: this.mmToPx(60),
        x: this.mmToPx(18),
        fontSize: 12 + 'px',
      }
    },
    invoiceTitleStyle() {
      // padding: 5mm 4mm 0mm; margin-left: 34mm; position: relative; height: 12mm; font-size: 8px;

      return {
        y: this.mmToPx(12),
        x: this.mmToPx(92),
        fontSize: `${18}px`,
      }
    },
    invoiceDatesStyle() {
      // position: absolute; top: 2.4mm; left: 60mm; height: 12mm; font-size: 8px;

      return {
        y: this.mmToPx(6.5),
        x: this.mmToPx(153),
        fontSize: `${10}px`,
      }
    },
    dueDatesStyle() {
      // position: absolute; top: 2.4mm; left: 60mm; height: 12mm; font-size: 8px;

      return {
        y: this.mmToPx(6.5),
        x: this.mmToPx(176),
        fontSize: `${10}px`,
      }
    },
    invoiceDatesInnerStyle() {
      // position: absolute; top: 2.4mm; left: 60mm; height: 12mm; font-size: 8px;

      return {
        fontSize: `${12}px`,
      }
    },
    personBlockStyle() {
      // position: absolute; top: 13.6mm; left: 43.2mm; font-size: 5.6px;

      return {
        y: this.mmToPx(39),
        x: this.mmToPx(110),
        fontSize: `${10}px`,
      }
    },
    paymentCardStyle() {
      // position: absolute; top: 79.2mm; left: 0mm; width: 84mm; height: 39.6mm;

      return {
        top: `${190}mm`,
        left: `${0}mm`,
        position: `absolute`,
        height: `${99}mm`,
        width: `${210}mm`,
      }
    },
    paymentCardCustomerNumberStyle() {
      // position: absolute; top: 83.6mm; left: 6.4mm; font-size: 8px;
      return {
        x: this.mmToPx(19),
        y: this.mmToPx(216),
        style: {
          fontSize: `${18}px`,
        },
      }
    },
    paymentCardPlusgiroNumberStyle() {
      // position: absolute; top: 83.6mm; left: 46.4mm; font-size: 8px;
      return {
        x: this.mmToPx(119),
        y: this.mmToPx(216),
        style: {
          fontSize: `${20}px`,
        },
      }
    },
    paymentCardTilllivTitleStyle() {
      // position: absolute; top: 89.2mm; left: 46.4mm; font-size: 8px; line-height: 1.1em;
      return {
        x: this.mmToPx(119),
        y: this.mmToPx(229),
        style: {
          fontSize: `${18}px`,
        },
      }
    },
    paymentCardPersonBlockStyle() {
      // position: absolute; top: 93.6mm; left: 46.4mm; font-size: 5.6px;
      return {
        x: this.mmToPx(119),
        y: this.mmToPx(239),
        style: {
          fontSize: `${12}px`,
        },
      }
    },
    canceledStyle() {
      // position: absolute; top: 93.6mm; left: 46.4mm; font-size: 5.6px;
      return {
        x: this.mmToPx(105),
        y: this.mmToPx(148.5),
        style: {
          fontSize: `${140}px`,
          transformBox: 'fill-box',
          transformOrigin: 'center',
          transform: `rotate(-45deg)`,
        },
      }
    },
    attachmentStyle() {
      // position: absolute; top: 93.6mm; left: 46.4mm; font-size: 5.6px;
      return {
        x: this.mmToPx(205),
        y: this.mmToPx(6),
        style: {
          fontSize: `${8}px`,
          transformBox: 'fill-box',
          transformOrigin: 'center',
          transform: `rotate(-90deg)`,
        },
      }
    },
    invoiceRowsRectStyle() {
      // position: absolute; top: 30mm; left: 6mm; width: 68mm; height: 40mm;
      // border: 1px solid rgb(200, 200, 200); padding: 2mm;
      return {
        x: this.mmToPx(15),
        y: this.mmToPx(75),
        style: {
          width: this.mmToPx(185),
          height: this.mmToPx(125),
          fill: 'none',
          stroke: 'rgb(200,200,200)',
          strokeWidth: '0.5px',
        },
      }
    },
    invoiceRowsStyle() {
      // position: absolute; top: 30mm; left: 6mm; width: 68mm; height: 40mm;
      // border: 1px solid rgb(200, 200, 200); padding: 2mm;
      return {
        x: this.mmToPx(20),
        y: this.mmToPx(80),
        style: {
          fontSize: `${13}px`,
        },
      }
    },
    invoiceTotalRows() {
      return this.invoiceRows.reduce((a, b) => a + b.rowsLength, 0)
    },
    invoiceRows() {
      if (this.invoiceRowsList.length > 6) {
        let c = 1

        const rows = [
          {
            rows: [
              'MÅNGA ORDERRADER, se antal exemplar och summa grupperat på produkt',
            ],
            text:
              'MÅNGA ORDERRADER, se antal exemplar och summa grupperat på produkt',
            quantity: null,
            price: null,
            rowsLength: 1,
            rowsBeforeLength: c,
          },
        ]

        const key = 'articleId'
        /* eslint-disable no-unused-vars */
        let reduced = this.invoiceRowsList.reduce(function(r, a) {
          r[a[key]] = r[a[key]] || []
          r[a[key]].push(a)
          return r
        }, Object.create(null))

        const articles = this.$store.getters['articles/list']
        const prices = this.$store.getters['prices/list']

        // console.log(this.nameRows[0])
        // console.log('result', reduced)
        Object.keys(reduced).forEach(key => {
          const first = reduced[key][0]
          const text = first.text.split('\n'
          )[0]
          const article = articles.find(a => a._id === prices.find(p => p._id === first.articleId))

          const quantity = reduced[key].reduce(
            (tot, item) => (tot += item.quantity),
            0
          )
          const price = reduced[key].reduce(
            (tot, item) => (tot += item.quantity * item.price),
            0
          )

          c++

          rows.push({
            rows: [text],
            text,
            quantity,
            first,
            article,
            price,
            rowsLength: 1,
            rowsBeforeLength: c,
          })
        })
        // console.log('result', rows)
        /* eslint-disable no-unused-vars */
        //result = null
        /*const first = this.invoiceRowsList[0];
         [{
          ...first,
          text: first.text.replace(/(?:\r\n|\r|\n)/g, '<br>')
        }]*/

        return rows
      }

      let c = 0
      return this.invoiceRowsList.map(row => {
        const rows = (row.text || '').split(/(?:\r\n|\r|\n)/g)
        c += rows.length
        return {
          ...row,
          rows,
          rowsLength: rows.length,
          rowsBeforeLength: c,
        }
      })
    },
    nameRows() {
      if (!this.invoice || !this.invoice._id || !this.invoice.payer) return []
      // person = `${person.lastname} ${person.firstname}, ${person.postal_address}`
      const person = this.invoice.payer
      if (person.type === 1)
        return [
          `${person.firstname} ${person.lastname}`,
          `${person.careof}`,
          `${person.street_address}`,
          `${person.postal_number} ${person.postal_address}`,
        ].filter(o => o)

      if (person.type === 2)
        return [
          `${person.lastname}`,
          `${person.careof}`,
          `${person.street_address}`,
          `${person.postal_number} ${person.postal_address}`,
        ].filter(o => o)

      return []
    },
    invoiceTotal() {
      if (!this.invoiceRowsList.length) return 0

      return this.invoiceRowsList.reduce((a, b) => a + b.quantity * b.price, 0)
    },
  },
  watch: {
    invoice: {
      handler(newVal, oldVal) {
        this.initInvoice()
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped>
svg {
  font-family: 'Roboto';
}
.print {
  display: none;
}
@media print {
  .non-print {
    display: none;
  }
  .print {
    display: unset;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    .invoice-wrapper-container {
      box-shadow: unset !important;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10000;
      width: 210mm;
      height: 297mm;
    }
  }
}
</style>
