import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'



import globalMethods from '../_helpers/globalMethods'

import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)

Vue.mixin(globalMethods)

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// import { createProvider } from './vue-apollo'
library.add(faUserSecret)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,

  // apolloProvider: createProvider(),
  render: h => h(App),
}).$mount('#app')
