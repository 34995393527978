import Vue from 'vue'
import Vuetify from 'vuetify/lib'

// Your own translation file
import sv from 'vuetify/es5/locale/sv'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { sv },
    current: 'sv',
  },
})
