<template>
  <v-list
    :height="height"
    class="sticky-header"
    :class="{ 'overflow-y-auto': overFlowScrollable }"
    :dense="dense"
    :elevation="elevation"
    :disabled="disabled"
    text
  >
    <v-subheader class="sticky" v-if="subHeader">{{ subHeader }}</v-subheader>
    <v-progress-linear v-if="loading" />
    <v-list-item
      v-else
      v-for="(item, idx) in items"
      :key="idx"
      @click="toggleActive(idx)"
      :class="{ active: componentActiveItems.indexOf(idx) > -1 }"
    >
      <slot name="item" :item="item" :index="idx"></slot>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
    },
    height: {
      default: 0,
    },
    single: {
      type: Boolean,
      default: false,
    },
    returnIndex: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    elevation: {
      type: Number,
      default: 0,
    },
    subHeader: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    overFlowScrollable: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      componentActiveItems: [],
    }
  },
  methods: {
    reset() {
      this.componentActiveItems = []
    },
    toggleActive(idx) {
      if (this.single) {
        idx =
          this.componentActiveItems && this.componentActiveItems[0] == idx
            ? -1
            : idx
        this.componentActiveItems = [idx]
      } else {
        let pos = this.componentActiveItems.indexOf(idx)
        pos === -1
          ? this.componentActiveItems.push(idx)
          : this.componentActiveItems.splice(pos, 1)
      }
    },
  },
  computed: {
    _scopedSlots() {
      return this.$scopedSlots
    },
    slots() {
      return this.$slots
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: false,
      handler(value) {
        if (value) this.componentActiveItems = value
      },
    },
    componentActiveItems(activeItems) {
      if (this.single) {
        if (this.returnIndex) this.$emit('selectedIndex', activeItems[0])
        else this.$emit('activeItems', this.items[activeItems[0]])
      } else {
        if (this.returnIndex) this.$emit('selectedIndex', activeItems)
        else
          this.$emit(
            'activeItems',
            this.items.filter((item, index) => activeItems.includes(index))
          )
      }
    },
  },
}
</script>

<style scoped>
.active {
  background: rgba(17, 128, 17, 0.3);
}
.is-green {
  background: #4caf50 !important;
}
.is-gray {
  background: #505050 !important;
}
.v-list.sticky-header {
  position: relative;
}
.v-subheader.sticky {
  background-color: #ffffff;
  position: sticky;
  z-index: 3;
  top: -10px;
}
</style>
