import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '../../feathers-client'

class Payment extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'Payment'
  // Define default properties here
  static instanceDefaults() {
    return {
      personId: '',
      invoiceId: '',
      amount: 0,
      date: '',
    }
  }
}
const servicePath = 'payments'
const servicePlugin = makeServicePlugin({
  Model: Payment,
  service: feathersClient.service(servicePath),
  servicePath,
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [
      hook => {
        if (hook.params.query && hook.params.query.$paginate) {
          hook.params.paginate =
            hook.params.query.$paginate === 'false' ||
            hook.params.query.$paginate === false
          delete hook.params.query.$paginate
        }
      },
    ],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
})

export default servicePlugin
