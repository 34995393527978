var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',{staticClass:"grey lighten-3"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-expansion-panels',{model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Statistik")])],1),_c('v-expansion-panel-content',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('DatePickerMenu',{staticClass:"ma-4",attrs:{"full-width":false,"label":"Jämför","max":_vm.endDate.substring(0, 10),"returnFormat":"YYYY-MM-DDT00:00:00.000Z","displayFormat":"MMMM YYYY"},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('DatePickerMenu',{staticClass:"ma-4",attrs:{"full-width":false,"label":"med","hint":"","min":_vm.startDate.substring(0, 10),"returnFormat":"YYYY-MM-DDT23:59:59.999Z","displayFormat":"MMMM YYYY"},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[_c('v-autocomplete',{attrs:{"disabled":_vm.loadingPersons || _vm.loadingSubscriptions,"items":_vm.subscriptionTypes,"return-object":true,"chips":"","color":"blue-grey lighten-2","label":"Filtrera","item-text":"description","item-value":"_id","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var selected = ref.selected;
var select = ref.select;
return [_c('v-chip',_vm._b({attrs:{"input-value":selected,"close":""},on:{"click":select,"click:close":function($event){_vm.subscriptionTypeFilter.splice(
                    _vm.subscriptionTypeFilter.findIndex(function (s) { return s._id === item._id; }),
                    1
                  )}}},'v-chip',attrs,false),[_vm._v(" "+_vm._s(item.article.name)+": "+_vm._s(item.description)+" ")])]}},{key:"item",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.article.name)+": "+_vm._s(item.description)+" ")]}}]),model:{value:(_vm.subscriptionTypeFilter),callback:function ($$v) {_vm.subscriptionTypeFilter=$$v},expression:"subscriptionTypeFilter"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"disabled":_vm.isLoading},on:{"click":_vm.fetchSubscriptions}},[_vm._v("Hämta data")])],1)],1)],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{staticStyle:{"min-height":"500px"}},[(_vm.isLoading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"yellow darken-2"}}):_vm._e(),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{attrs:{"color":"#385F73","dark":""}},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Prenumerationer")]),_c('v-card-title',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.subscriptionsLength)+" st")]),_c('v-card-subtitle',_vm._l((_vm.subscriptionsByArticleType),function(item){return _c('div',{key:item.articleName},[_c('b',[_vm._v(_vm._s(item.articleName))]),_vm._v(" "+_vm._s(item.subs.length)+" st ")])}),0)],1),_vm._l((_vm.subscriptionsByPriceType),function(article,index){return _c('v-card',{key:index,staticClass:"ma-2",attrs:{"color":"#1F7087","dark":""}},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(_vm._s(article.articleName))]),_c('v-card-subtitle',_vm._l((article.priceTypes),function(item){return _c('div',{key:item.priceObject._id},[_c('b',[_vm._v(_vm._s(item.priceObject.description))]),_vm._v(" "+_vm._s(item.subs.length)+" st ")])}),0)],1)})],2),_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{attrs:{"color":"#385F73","dark":""}},[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Prenumeranter")]),_c('v-card-title',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.personsLength))])],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }