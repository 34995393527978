<template>
  <div :style="invoiceWrapperStyle" v-if="localInvoice">
    <img
      src="@/assets/images/TillLivloggasvart.svg"
      contain
      :style="logoStyle"
    />
    <div :style="returnAddressStyle">
      Returadress: <br />Rebecka Carlsson, Hanaskogsvägen 590, 291 95 Färlöv
    </div>
    <div :style="contactDataStyle">
      <div>Till Liv, Sandgatan 14b, 223 50 Lund</div>
      <div>Org.nr: 802403-0705</div>
    </div>
    <div :style="ibanDataStyle">
      <br />IBAN: SE33 9500 0099 6026 6079 8949<br />
      BIC: NDEASESS
    </div>
    <div :style="invoiceTitleStyle">
      <div style="margin-bottom: 1.2mm;">
        FAKTURA {{ localInvoice.id || 'x' }}
      </div>
    </div>
    <div :style="invoiceDatesStyle">
      <div :style="invoiceDatesInnerStyle">
        <div>Fakturadatum</div>
        <div>{{ localInvoice.dispatchDate }}</div>
      </div>
      <div :style="invoiceDatesInnerStyle">
        <div>Förfallodatum</div>
        <div>{{ localInvoice.dueDate }}</div>
      </div>
      <div :style="invoiceDatesInnerStyle">
        <div>Plusgiro</div>
        <div>607 98 94-9</div>
      </div>
    </div>

    <div class="person" :style="personBlockStyle">
      <div v-for="(row, index) in nameRows" :key="index">
        {{ row }}
      </div>
    </div>

    <div
      style="position: absolute; top: 76mm; left: 4mm; font-size: 4px; display: none;"
    >
      RedTL2011
    </div>

    <div v-html="infoTextFormatted" :style="infoTextStyle"></div>

    <img
      src="@/assets/images/inbetalningskortet.svg"
      :style="paymentCardStyle"
    />

    <div :style="paymentCardCustomerNumberStyle">
      Kundnummer: {{ localInvoice.payerId }}<br />Fakturanummer:
      {{ localInvoice.id || 'x' }}
    </div>
    <div :style="paymentCardPlusgiroNumberStyle">
      607 98 94-9
    </div>
    <div :style="paymentCardTilllivTitleStyle">
      Till Liv
    </div>
    <div :style="paymentCardPersonBlockStyle">
      <div v-for="(row, index) in nameRows" :key="index">
        {{ row }}
      </div>
    </div>
    <div
      style="position: absolute; top: 105.2mm; right: 8.6mm; font-size: 12px; letter-spacing: 0.164em;"
    ></div>

    <div :style="invoiceRowsStyle">
      <template v-for="(row, index) in invoiceRows">
        <div :key="index" v-html="row.text"></div>
        <div
          :key="index + '_'"
          style="text-align: right;"
          v-html="row.quantity"
        ></div>
        <div :key="index + '__'" v-html="row.price"></div>
      </template>

      <div style="text-align: right; grid-column: 1 / 3">SUMMA:</div>
      <div class="summa">{{ invoiceTotal }}</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import moment from 'moment'

export default {
  props: {
    print: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    invoice: {
      type: Object,
      default: () => {},
    },
    containerWidth: {
      type: Number,
      default: 100,
    },
    scale: {
      type: Number,
      default: 1,
    },
    infoText: {
      type: String,
      default: ''
    }
  },
  components: {},
  data: () => ({
    localInvoice: null,
    loading: true,
    pxInMm: 0.264583333,
    persons: [],
    invoiceRowsList: [],
    invoiceRowsQuery: {
      query: {},
      paginate: false,
    },
  }),
  async created() {
    if (!this.invoice) return
    await this.initInvoice()
  },
  methods: {
    ...mapActions('invoices', { invoicesCount: 'count' }),
    ...mapActions('invoices', { findInvoices: 'find' }),
    async initInvoice() {
      await this.fetchInvoiceRows()

      this.localInvoice = { ...this.invoice }

      this.localInvoice.dispatchDate = moment
        .utc(this.invoice.dispatchDate)
        .format('Y-MM-DD')
      this.localInvoice.dueDate = moment
        .utc(this.invoice.dueDate)
        .format('Y-MM-DD')
    },
    scaleStyle(style) {
      return style
    },
    pxToMm() {
      return this.pxInMm
    },
    async fetchInvoiceRows() {
      if (!this.invoice._id) return

      this.invoiceRowsQuery.query.invoiceId = this.invoice._id

      try {
        const { Invoicerow } = this.$FeathersVuex.api

        const invoiceRows = await Invoicerow.find(this.invoiceRowsQuery)

        this.invoiceRowsList = invoiceRows
      } catch (e) {
        console.log(e)
      }
    },
  },
  computed: {
    ...mapState('invoicerows', { loadingInvoiceRows: 'isFindPending' }),
    ...mapGetters('persons', { personsList: 'list' }),
    ...mapState('persons', { loadingPersons: 'isFindPending' }),
    invoiceCardStyle() {
      if (this.print)
        return {
          width: 210 + 'mm',
          height: 297.5 + 'mm',
        }
      // background-color:white; width: 84mm; height: 118.8mm; float:left; border: 1px solid rgba(100,100,100,.3); border-radius:3px; position: relative;
      return {
        width: 210 * this.scale + 'mm',
        height: 297.5 * this.scale + 'mm',
      }
    },
    invoiceWrapperStyle() {
      // background-color:white; width: 84mm; height: 118.8mm; float:left; border: 1px solid rgba(100,100,100,.3); border-radius:3px; position: relative;
      if (this.print)
        return {
          backgroundColor: 'white',
          width: 210 * this.scale + 'mm',
          height: 297 * this.scale + 'mm',
          lineHeight: '100%',
          position: 'relative',
          top: 0,
          left: 0,
        }

      return {
        width: 210 * this.scale + 'mm',
        height: 297 * this.scale + 'mm',
        borderRadius: this.print ? '0' : '3px',
        lineHeight: '100%',
        position: 'relative',
        fontFamily: 'Roboto',
      }
    },
    logoStyle() {
      // position: absolute; top: 2.4mm; left: 6mm; height: 6mm;

      return {
        position: 'absolute',
        top: 6 * this.scale + 'mm',
        left: 15 * this.scale + 'mm',
        height: 15 * this.scale + 'mm',
      }
    },
    returnAddressStyle() {
      // position: absolute; top: 9.2mm; left: 6mm; font-size: 4px;

      return {
        position: 'absolute',
        top: 23 * this.scale + 'mm',
        left: 15 * this.scale + 'mm',
        lineHeight: '130%',
        fontSize: 10 * this.scale + 'px',
      }
    },
    infoTextStyle() {
      // position: absolute; top: 18mm; left: 6mm; font-size: 5.2px;

      return {
        position: 'absolute',
        top: 178 * this.scale + 'mm',
        left: 20 * this.scale + 'mm',
        lineHeight: '130%',
        width: 160 * this.scale + 'mm',
        fontSize: 11 * this.scale + 'px',
      }
    },
    infoTextFormatted() {
      return (this.invoice && this.invoice.infoText || this.infoText).replace(/\n/g, '<br>');
    },
    contactDataStyle() {
      // position: absolute; top: 18mm; left: 6mm; font-size: 5.2px;

      return {
        position: 'absolute',
        top: 45 * this.scale + 'mm',
        left: 15 * this.scale + 'mm',
        fontSize: 13 * this.scale + 'px',
        lineHeight: '130%',
      }
    },
    ibanDataStyle() {
      // position: absolute; top: 22mm; left: 6mm; font-size: 5.2px;

      return {
        position: 'absolute',
        top: 55 * this.scale + 'mm',
        left: 15 * this.scale + 'mm',
        fontSize: 13 * this.scale + 'px',
        lineHeight: '130%',
      }
    },
    invoiceTitleStyle() {
      // padding: 5mm 4mm 0mm; margin-left: 34mm; position: relative; height: 12mm; font-size: 8px;

      return {
        padding: `${12 * this.scale}mm ${10 * this.scale}mm 0mm`,
        marginLeft: `${85 * this.scale}mm`,
        position: `relative`,
        fontSize: `${20 * this.scale}px`,
        lineHeight: '100%',
      }
    },
    invoiceDatesStyle() {
      // position: absolute; top: 2.4mm; left: 60mm; height: 12mm; font-size: 8px;

      return {
        top: `${6 * this.scale}mm`,
        left: `${150 * this.scale}mm`,
        position: `absolute`,
        fontSize: `${20 * this.scale}px`,
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: `${5 * this.scale}mm`,
        lineHeight: '100%',
      }
    },
    invoiceDatesInnerStyle() {
      // position: absolute; top: 2.4mm; left: 60mm; height: 12mm; font-size: 8px;

      return {
        fontSize: `${12 * this.scale}px`,
        lineHeight: '130%',
      }
    },
    personBlockStyle() {
      // position: absolute; top: 13.6mm; left: 43.2mm; font-size: 5.6px;

      return {
        top: `${34 * this.scale}mm`,
        left: `${108 * this.scale}mm`,
        position: `absolute`,
        fontSize: `${14 * this.scale}px`,
        lineHeight: '130%',
      }
    },
    paymentCardStyle() {
      // position: absolute; top: 79.2mm; left: 0mm; width: 84mm; height: 39.6mm;

      return {
        top: `${208 * this.scale}mm`,
        left: `${0 * this.scale}mm`,
        position: `absolute`,
        height: `${99 * this.scale}mm`,
        width: `${210 * this.scale}mm`,
        lineHeight: '100%',
      }
    },
    paymentCardCustomerNumberStyle() {
      // position: absolute; top: 83.6mm; left: 6.4mm; font-size: 8px;
      return {
        top: `${220 * this.scale}mm`,
        left: `${16 * this.scale}mm`,
        position: `absolute`,
        fontSize: `${20 * this.scale}px`,
        lineHeight: '130%',

      }
    },
    paymentCardPlusgiroNumberStyle() {
      // position: absolute; top: 83.6mm; left: 46.4mm; font-size: 8px;
      return {
        top: `${220 * this.scale}mm`,
        left: `${116 * this.scale}mm`,
        position: `absolute`,
        fontSize: `${20 * this.scale}px`,
      }
    },
    paymentCardTilllivTitleStyle() {
      // position: absolute; top: 89.2mm; left: 46.4mm; font-size: 8px; line-height: 1.1em;
      return {
        top: `${232 * this.scale}mm`,
        left: `${116 * this.scale}mm`,
        position: `absolute`,
        fontSize: `${20 * this.scale}px`,
      }
    },
    paymentCardPersonBlockStyle() {
      // position: absolute; top: 93.6mm; left: 46.4mm; font-size: 5.6px;
      return {
        top: `${246 * this.scale}mm`,
        left: `${116 * this.scale}mm`,
        position: `absolute`,
        fontSize: `${14 * this.scale}px`,
        lineHeight: '130%',
      }
    },
    invoiceRowsStyle() {
      // position: absolute; top: 30mm; left: 6mm; width: 68mm; height: 40mm;
      // border: 1px solid rgb(200, 200, 200); padding: 2mm;
      return {
        position: 'absolute',
        top: `${75 * this.scale}mm`,
        left: `${15 * this.scale}mm`,
        width: `${185 * this.scale}mm`,
        height: `${130 * this.scale}mm`,
        padding: `${15 * this.scale}px`,
        border: '1px solid rgb(200, 200, 200)',
        lineHeight: '120%',
        display: 'grid',
        alignContent: 'flex-start',
        gridTemplateColumns: `${130 * this.scale}mm ${15 * this.scale}mm ${20 *
        this.scale}mm`,
        gridGap: `${15 * this.scale}px`,
        fontSize: `${14 * this.scale}px`,
      }
    },
    invoiceRows() {
      if (this.invoiceRowsList.length > 6) {
        let c = 1

        const rows = [
          {
            rows: [
              '<em>MÅNGA ORDERRADER, se antal exemplar och summa grupperat på produkt</em>',
            ],
            text:
              '<em>MÅNGA ORDERRADER, se antal exemplar och summa grupperat på produkt</em>',
            quantity: null,
            price: null,
            rowsLength: 1,
            rowsBeforeLength: c,
          },
        ]

        const key = 'articleId'
        /* eslint-disable no-unused-vars */
        let reduced = this.invoiceRowsList.reduce(function(r, a) {
          r[a[key]] = r[a[key]] || []
          r[a[key]].push(a)
          return r
        }, Object.create(null))

        const articles = this.$store.getters['articles/list']
        const prices = this.$store.getters['prices/list']

        // console.log(this.nameRows[0])
        // console.log('result', reduced)
        Object.keys(reduced).forEach(key => {
          const first = reduced[key][0]
          const text = first.text.split('\n'
          )[0]
          const article = articles.find(a => a._id === prices.find(p => p._id === first.articleId))

          const quantity = reduced[key].reduce(
            (tot, item) => (tot += item.quantity),
            0
          )
          const price = reduced[key].reduce(
            (tot, item) => (tot += item.quantity * item.price),
            0
          )

          c++

          rows.push({
            rows: [text],
            text,
            quantity,
            first,
            article,
            price,
            rowsLength: 1,
            rowsBeforeLength: c,
          })
        })

        return rows
      }

      let c = 0
      return this.invoiceRowsList.map(row => {
        const rows = (row.text || '').split(/(?:\r\n|\r|\n)/g)
        c += rows.length
        return {
          ...row,
          rows,
          rowsLength: rows.length,
          rowsBeforeLength: c,
        }
      })
    },
    nameRows() {
      if (!this.invoice) return []
      // person = `${person.lastname} ${person.firstname}, ${person.postal_address}`
      const person = this.invoice.payer

      if (!person) return []

      if (person.type === 1)
        return [
          `${person.firstname} ${person.lastname}`,
          `${person.careof}`,
          `${person.street_address}`,
          `${person.postal_number} ${person.postal_address}`,
        ].filter(o => o)

      if (person.type === 2)
        return [
          `${person.lastname}`,
          `${person.careof}`,
          `${person.street_address}`,
          `${person.postal_number} ${person.postal_address}`,
        ].filter(o => o)

      return []
    },
    invoiceTotal() {
      if (!this.invoiceRowsList.length) return 0

      return this.invoiceRowsList.reduce((a, b) => a + b.quantity * b.price, 0)
    },
  },
  watch: {
    async invoice(invoice) {
      await this.initInvoice()
    },
  },
}
</script>

<style lang="scss" scoped>
.print {
  display: none;
}
@media print {
  .non-print {
    display: none;
  }
  .print {
    display: unset;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    .invoice-wrapper-container {
      box-shadow: unset !important;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 10000;
      width: 210mm;
      height: 297mm;
    }
  }
}
</style>
