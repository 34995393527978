import moment from 'moment'

export default {
  methods: {
    showMessage(response, messageArgs = {}) {
      // console.log(error.response)
      console.log(response)
      if(!response.Message)
        return
      const message = response.Message;

      this.$store.commit('showMessage', {
        position: 'bottom',
        timeout: 4000,
        text: `${message}`,
        visible: true,
        color: 'info',
        ...messageArgs
      })

    },
    showErrorMessage(error, messageArgs = {}) {
      // console.log(error.response)
      console.log(error)
      if(!error.response || !error.response.data)
        return
      const message = error.response.data.Message;

      this.$store.commit('showMessage', {
        position: 'bottom',
        timeout: 4000,
        text: `${message}`,
        visible: true,
        color: 'error',
        ...messageArgs
      })

    },
    formatDate(date = '', format = 'Y-MM-DD', utc = true){

      let dateobject = utc ? moment.utc(date) : moment(date)

      if(date === 'today')
        return moment().format(format);

      if(format === 'iso')
        return dateobject.toISOString();

      return dateobject.format(format);
    },
    isDue(dueDate, compareDate = null) {
      return moment.utc(dueDate).isBefore(moment.utc(compareDate||''))
    },
    groupBy2(arr, k, fn = () => true){
      return arr.reduce((r, c) => (fn(c[k]) ? r[c[k]] = [...r[c[k]] || [], c] : null, r), {});
    },
    findDateByValueGroupBy(data, objectKey = '', compareKey = '', compareValue) {
      /*const groupBy = (arr, k, fn = () => true) => {
        return arr.reduce((r, c) => (fn(c[k]) ? r[c[k]] = [...r[c[k]] || [], c] : null, r), {});
      }*/

      const result = Object.values(data.reduce((r, o) => {
        r[o[objectKey]] = (r[o[objectKey]] && r[o[objectKey]][compareKey] > compareValue) ? r[o[objectKey]] : o

        return r
      }, {}))

      return result
    },
    groupPriceByDate(data, objectKey = '', compareKey = '') {
      const result = Object.values(data.reduce((r, o) => {
        r[o[objectKey]] = (r[o[objectKey]] && r[o[objectKey]][compareKey] > o[compareKey]) ? r[o[objectKey]] : o

        return r
      }, {}))

      return result
    },
    multiSortArray(array, sortObject = {}) {
      const sortKeys = Object.keys(sortObject);

      // Return array if no sort object is supplied.
      if (!sortKeys.length) {
        return array;
      }

      // Change the values of the sortObject keys to -1, 0, or 1.
      for (let key in sortObject) {
        sortObject[key] = sortObject[key] === 'desc' || sortObject[key] === -1 ? -1
          : (sortObject[key] === 'skip' || sortObject[key] === 0 ? 0 : 1);
      }

      const keySort = (a, b, direction) => {
        direction = direction !== null ? direction : 1;

        if (a === b) { // If the values are the same, do not switch positions.
          return 0;
        }

        // If b > a, multiply by -1 to get the reverse direction.
        return a > b ? direction : -1 * direction;
      };

      return array.sort((a, b) => {
        let sorted = 0;
        let index = 0;

        // Loop until sorted (-1 or 1) or until the sort keys have been processed.
        while (sorted === 0 && index < sortKeys.length) {
          const key = sortKeys[index];

          if (key) {
            const direction = sortObject[key];

            sorted = keySort(a[key], b[key], direction);
            index++;
          }
        }

        return sorted;
      });
    },
  }
}
