<template>
  <v-dialog ref="menu" v-model="menu" :return-value.sync="date" width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateFormatted"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        :hint="hint"
        persistent-hint
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      width="300"
      :type="datePickerType"
      no-title
      scrollable
      :full-width="fullWidth"
      :min="min"
      :max="max"
      @input="$refs.menu.save(date); menu = false"
    >
      <v-spacer></v-spacer>
      <v-btn v-if="0" text color="primary" @click="menu = false">
        Avbryt
      </v-btn>
      <v-btn v-if="0" text color="primary" @click="$refs.menu.save(date)">
        OK
      </v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
import moment from 'moment'
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    datePickerType: {
      type: String,
      default: 'month',
    },
    label: {
      type: String,
      default: '',
    },
    min: {
      type: String,
      default: '',
    },
    max: {
      type: String,
      default: '',
    },
    fullWidth: {
      type: Boolean,
      default: true,
    },
    hint: {
      type: String,
      default: '',
    },
    returnFormat: {
      type: String,
      default: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
    },
    displayFormat: {
      type: String,
      default: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
    },
  },
  data: () => ({
    date: new Date().toISOString().substr(0, 7),
    menu: false,
    modal: false,
  }),
  mounted() {
    if (this.value) this.date = this.value
  },
  computed: {
    dateFormatted() {
      return moment.utc(this.date).format(this.displayFormat)
    },
    dateReturnFormatted() {
      return moment.utc(this.date).format(this.returnFormat)
    },
  },
  watch: {
    value(value) {
      // console.log(value)
      this.date = value
    },
    date(date) {
      let newDate = new Date(date).toISOString()
      if (this.returnFormat)
        newDate = this.dateReturnFormatted
      this.$emit('input', newDate)
    },
  },
}
</script>

<style scoped></style>
