<template>
  <div>
    <v-main class="grey lighten-3">
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-btn
              color="primary"
              text
              v-for="action in buttons" :key="action"
              @click="runAction(action)"
              x-small
            >
              {{ action }}
            </v-btn>
          </v-col>
          <v-col cols="12">
            <button v-if="0" @click="saveFile">save</button>

            <div v-if="!subscriptionsList.length" v-html="textarea"></div>

            <template v-if="currentButton === 'fixSubscriptionDates'">
              <div v-for="(sub,index) in subscriptionsList" :key="sub._id">
                <div>
                  {{ `Pren #${sub._id}: Nya datum är ${sub.startDate} och ${sub.endDate}` }}
                </div>
                <button class="primary" @click="updateSubscription(sub, index)">Uppdatera</button>
              </div>
            </template>

            <template v-if="currentButton === 'fixInvoiceBillectaId'">
              <div v-for="(invoice,index) in invoiceList" :key="invoice._id">
                <div>
                  {{ `Faktura #${invoice._id}: ` }}<br>
                  Fakturadatum: {{formatDate(invoice.dispatchDate)}}<br>
                  Billecta-id: {{invoice.billecta_id}}
                </div>
                <button class="primary" @click="updateInvoice(invoice, index)">Uppdatera</button>
              </div>
            </template>

            <v-bottom-navigation
              v-if="currentButton === 'fixInvoiceBillectaId' && invoiceList.length"
              fixed
              hide-on-scroll
              horizontal
              scroll-target="#hide-on-scroll-example"
            >
              <v-btn
                color="deep-purple accent-4"
                text
                @click="updateLocalInvoices(50)"
              >
                <span>Uppdatera {{invoiceList.length > 50 ? 50 : invoiceList.length}} fakturor
                  <span v-if="invoiceList.length > 50">av {{invoiceList.length}}</span>
                </span>

                <v-icon>mdi-history</v-icon>
              </v-btn>
            </v-bottom-navigation>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
// import persons from '../json/persons.json'
// import invoices from '../json/invoices.json'
// import articles from '../json/articles.json'
// import prices from '../json/prices.json'
// import invoicerows from '../json/invoicerows.json'
// import subscriptions from '../json/subscriptions.json'
// import payments from '../json/payments.json'

import {models} from 'feathers-vuex'

import moment from 'moment'
import _ from "lodash";
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      buttons: [
        'fixSubscriptionDates',
        'fixInvoiceBillectaId',
      ],
      currentButton: '',
      textarea: '',
      startDate: '',
      endDate: '',
      persons: [],
      invoices: [],
      articles: [],
      prices: [],
      invoicerows: [],
      subscriptions: [],
      payments: [],
      subscriptionsList: [],
      invoiceList: [],
    }
  },
  mounted() {
    //this.fixSubscriptionDates()
    this.startDate = moment().add(-2, 'years').format('Y-MM-DD')
    this.endDate = moment().add(2, 'years').endOf('month').format('Y-MM-DD')
  },
  methods: {
    runAction(action) {
      const self = this
      eval(self[action]())
      this.currentButton = action
    },
    saveFile: function () {
      const data = this.textarea
      const blob = new Blob([data], {type: 'text/plain'})
      const e = document.createEvent('MouseEvents'),
        a = document.createElement('a')
      a.download = 'textarea.json'
      a.href = window.URL.createObjectURL(blob)
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':')
      e.initEvent(
        'click',
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      )
      a.dispatchEvent(e)
    },
    async updateSubscription(sub, index) {

      const subscription = new models.api.Subscription(sub)
      await subscription.update()

      this.$store.commit('showMessage', {
        position: 'bottom',
        timeout: 2000,
        text: `${sub._id} uppdaterad`,
        visible: true,
      })

      this.subscriptionsList.splice(index, 1)
    },
    async updateInvoice(sub) {

      const subscription = new models.api.Invoice(sub)
      await subscription.update()

      this.invoiceList = this.invoiceList.filter(o => o._id !== sub._id)
    },
    async updateLocalInvoices(limit = 50) {

      const invoices = JSON.parse(JSON.stringify(this.invoiceList)).splice(0, limit)

      console.log(invoices.length)

      for await (const [index, invoice] of invoices.entries()) {
        await this.updateInvoice(invoice, index)

        this.$store.commit('showMessage', {
          position: 'bottom',
          timeout: 2000,
          text: `${invoice._id} uppdaterad ${index+1}/${limit}`,
          visible: true,
        })
      }
    },
    async fixInvoiceBillectaId() {
      const query = {
        query: {
          /*invoiceRowId: '',
          status: 'active',
          articleId: {$ne: ''},
         */
          id: {$gt: this.billectFirstInvoiceNumber},
          billecta_id: '',
        },
      }

      this.subscriptionsList = []

      const {Invoice, Person} = models.api

      this.$store.commit('showMessage', {
        position: 'bottom',
        timeout: 2000,
        text: 'Laddar fakturor från databasen',
        visible: true,
      })

      const data = await Invoice.find(query)

      //console.log(data)

      this.persons = await Person.find({query: {active: true}})

      this.$store.commit('showMessage', {
        position: 'bottom',
        timeout: 2000,
        text: `${data.length} fakturor hämtades`,
        visible: true,
      })

      this.$store.commit('showMessage', {
        position: 'bottom',
        timeout: 2000,
        text: 'Laddar fakturadata från Billecta',
        visible: true,
      })

      try {
        await this.$store.dispatch('billecta_invoice/getAllClosedInvoices', {
          id: this.mainCreditorPublicId,
          from: this.startDate.substring(0, 10),
          to: this.endDate.substring(0, 10),
          sortingfield: 'InvoiceNumber',
          asc: true,
        })
      } catch (error) {
        this.showErrorMessage(error)
      }
      //}
      //else {
      try {
        await this.$store.dispatch('billecta_invoice/getAllOpenInvoices', {
          id: this.mainCreditorPublicId,
          from: this.startDate.substring(0, 10),
          to: this.endDate.substring(0, 10),
          sortingfield: 'InvoiceNumber',
          asc: true,
        })
      } catch (error) {
        this.showErrorMessage(error)
      }

      this.$store.commit('showMessage', {
        position: 'bottom',
        timeout: 2000,
        text: `${this.billectaInvoices.length} fakturor hämtades från Billecta`,
        visible: true,
      })

      this.invoices = data

      this.invoiceList = data.map(invoice => {
        const billectaInvoice = this.billectaInvoices.find(b => b.InvoiceNumber === invoice.id.toString())
        if (billectaInvoice)
          invoice.billecta_id = billectaInvoice.ActionPublicId

        return invoice
      }).filter(i => i.billecta_id)

    },
    async fixSubscriptionDates() {

      const query = {
        /*invoiceRowId: '',
        billecta_id: '',
        status: 'active',
        articleId: {$ne: ''},
         */
        query: {
          startDate: {$gte: new Date(moment().startOf('year').add(-2, "year"))}
        }
      }

      this.subscriptionsList = []

      const {Subscription} = models.api

      this.$store.commit('showMessage', {
        position: 'bottom',
        timeout: 2000,
        text: 'Laddar prenumerationer från databasen',
        visible: true,
      })

      const data = await Subscription.find(query)

      const dataWithWrongStartDate = data.filter(o => o.startDate.match(/(?:000Z)$/) === null || o.endDate.match(/(?:999Z)$/) === null)

      this.$store.commit('showMessage', {
        position: 'bottom',
        timeout: 2000,
        text: `${dataWithWrongStartDate.length} prenumerationer hämtades`,
        visible: true,
      })

      this.subscriptionsList = dataWithWrongStartDate.map(o => {
        o.startDate = o.startDate.slice(0, -13) + '00:00:00.000Z'
        o.endDate = o.endDate.slice(0, -13) + '23:59:59.999Z'

        return o
      })

      /*this.textarea = this.subscriptionsList.map(o => {
        const oldPost = data.find(d => d._id === o._id)
        const rows = []
        rows.push(`Pren #${o._id}: Nya datum är ${o.startDate} och ${o.endDate}`)
        if(oldPost)
          rows.push(`Gamla datum är ${oldPost.startDate} och ${oldPost.endDate}`)
        return rows.join('<br>')
      }).join('<br><br>')*/

    },
    fixSubscriptions() {
      const priceList = _.orderBy(this.prices, ['pricePeriodStart'], ['desc'])
      // conxole.log(priceList)
      const textareadata = this.subscriptions.map(sub => {
        const person = this.persons.find(p => p.id === sub.personId)
        const payer = this.persons.find(p => p.id === sub.payerId)
        const startTime = sub.startDate.$date;
        const pricesList = [];
        priceList.forEach(price => {
          //console.log(price, price._id)
          if (!pricesList.find(p => p.description === price.description) && price.pricePeriodStart && new Date(price.pricePeriodStart.$date).getTime() <= new Date(startTime).getTime())
            pricesList.push(price)
        })
        //console.log(pricesList)
        const price = pricesList.find(p => p.id === sub.articleId)
        // const article = articles.find(a => a.id === price.articleId)
        const invoicerow = this.invoicerows.find(p => p.id === sub.invoiceRowId)
        const renewedrow = this.subscriptions.find(p => p.id === sub.renewedId)
        let data = {
          ...sub,
        }
        // delete data.id

        if (renewedrow) data.renewedId = renewedrow._id.$oid
        else data.renewedId = ''
        if (invoicerow) data.invoiceRowId = invoicerow._id.$oid
        else data.invoiceRowId = ''
        if (price) data.articleId = price._id.$oid
        if (person) data.personId = person._id.$oid
        if (person) data.payerId = payer._id.$oid

        return data
      })
      this.textarea = JSON.stringify(textareadata, null, 2)
    },
    fixPrices() {
      const textareadata = this.prices.map(sub => {
        const article = this.articles.find(p => p.id === sub.articleId)
        const pricePeriodStart = sub.pricePeriodStart.$date
        //const pricePeriodEnd = moment.utc(sub.pricePeriodEnd).toISOString()
        let data = {
          ...sub,
          articleId: article._id.$oid,
          pricePeriodStart,
        }
        // delete data.id

        return data
      })
      this.textarea = JSON.stringify(textareadata, null, 2)
    },
    fixInvoices() {
      const textareadata = this.invoices.map(sub => {
        // const article = articles.find(p => p.id === sub.articleId)
        // const pricePeriodStart = moment.utc(sub.pricePeriodStart).toISOString()
        // const pricePeriodEnd = moment.utc(sub.pricePeriodEnd).toISOString()
        const payer = this.persons.find(p => p.id === sub.payerId)
        const dispatchDate = moment.utc(sub.dispatchDate).toISOString()
        const dueDate = moment.utc(sub.dueDate).toISOString()
        const payDate = moment.utc(sub.payDate).toISOString()
        const printed = moment.utc(sub.printed).toISOString()
        const created = moment.utc(sub.Created).toISOString()
        let data = {
          ...sub,
          payerId: payer._id.$oid,
          dispatchDate: sub.dispatchDate ? {$date: dispatchDate} : null,
          dueDate: sub.dueDate ? {$date: dueDate} : null,
          payDate: sub.payDate ? {$date: payDate} : null,
          printed: sub.printed ? {$date: printed} : null,
          created,
        }
        // delete data.id
        delete data.Printed
        delete data.Created

        return data
      })
      this.textarea = JSON.stringify(textareadata, null, 2)
    },
    fixInvoiceRows() {
      const textareadata = this.invoicerows.map(sub => {
        // const article = articles.find(p => p.id === sub.articleId)
        // const pricePeriodStart = moment.utc(sub.pricePeriodStart).toISOString()
        // const pricePeriodEnd = moment.utc(sub.pricePeriodEnd).toISOString()
        const payer = this.persons.find(p => p.id === sub.payerId)
        const invoice = this.invoices.find(p => p.id === sub.invoiceId)
        const price = this.prices.find(p => p.id === sub.articleId)
        let data = {
          ...sub,
          payerId: payer._id.$oid,
          invoiceId: invoice._id.$oid,
          articleId: price._id.$oid,
        }

        return data
      })
      this.textarea = JSON.stringify(textareadata, null, 2)
    },
    fixPayments() {
      const textareadata = this.payments.map(sub => {
        // const article = articles.find(p => p.id === sub.articleId)
        // const pricePeriodStart = moment.utc(sub.pricePeriodStart).toISOString()
        const date = moment.utc(sub.datum).toISOString()
        const person = this.persons.find(p => p.id === sub.personId)
        const invoice = this.invoices.find(p => p.id === sub.invoiceId)

        let data = {
          ...sub,
          personId: person._id.$oid,
          invoiceId: invoice._id.$oid,
          date,
        }
        // delete data.id;
        delete data.datum

        return data
      })
      this.textarea = JSON.stringify(textareadata, null, 2)
    },
  },
  computed: {
    ...mapGetters({
      closedInvoices: 'billecta_invoice/closedInvoices',
      openInvoices: 'billecta_invoice/openInvoices',
      mainCreditorPublicId: 'billecta_creditor/mainCreditorPublicId',
      billectFirstInvoiceNumber: 'billecta_invoice/billectFirstInvoiceNumber',
    }),
    billectaInvoices() {
      return [...this.closedInvoices, ...this.openInvoices]
    },
    uniqueSubs() {
      const unique = [...new Set(this.subscriptions.map(item => item.articleId))]; // [ 'A', 'B']
      return unique
    }
  },
}
</script>

<style scoped></style>
