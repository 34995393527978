<template>
  <v-dialog v-model="dialogOpen" persistent max-width="900px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark v-bind="attrs" v-on="on">
        Ny prenumerant
      </v-btn>
    </template>
    <v-card height="85vh" v-if="person">
      <v-card-title class="flex justify-space-between align-center" :title="person._id">
        <span class="headline">{{ personName }}</span>

        <v-avatar v-if="person.billecta_id" title="Finns i Billecta" right color="indigo" size="24">
          <span class="white--text">B</span>
        </v-avatar>
      </v-card-title>
      <v-card-text class="tabs">
        <v-tabs v-model="tab" background-color="primary" dark center-active>
          <v-tab v-for="item in tabItems" :key="item.tab">
            {{ item.tab }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-form ref="form" v-model="valid">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-card-subtitle class="pa-0 ma-0"
                      >Personuppgifter</v-card-subtitle
                    >

                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12">
                          <v-btn-toggle class="mb-2 ml-2" v-model="person.type">
                            <v-btn small :value="1">
                              Person
                            </v-btn>
                            <v-btn small :value="2">
                              Icke-person
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col v-if="person.type === 1" cols="6" sm="12">
                          <v-text-field
                            v-model="person.firstname"
                            label="Förnamn*"
                            :rules="mandatory"
                            required
                            class="pa-0"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="12">
                          <v-text-field
                            v-model="person.lastname"
                            :label="
                              person.type === 1
                                ? 'Efternamn*'
                                : 'Företag/förening/organisation*'
                            "
                            :rules="mandatory"
                            required
                            class="pa-0"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="person.careof"
                            label="C/O"
                            class="pa-0"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="person.street_address"
                            label="Adress"
                            class="pa-0"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            v-model="person.postal_number"
                            label="Postnummer"
                            class="pa-0"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="person.postal_address"
                            label="Postort"
                            class="pa-0"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="person.country"
                            label="Land"
                            class="pa-0"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>

                  <v-col cols="12" sm="12" md="6">
                    <v-card-subtitle class="pa-0 ma-0"
                      >Kontaktuppgifter</v-card-subtitle
                    >

                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="person.phone"
                            type="tel"
                            label="Telefon"
                            class="pa-0"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="person.cell_phone"
                            type="tel"
                            label="Mobil"
                            class="pa-0"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="person.email_address"
                            type="email"
                            label="E-postadress"
                            :rules="emailRules"
                            class="pa-0"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-textarea
                            v-model="person.misc"
                            label="Övrigt"
                            class="pa-0"
                          ></v-textarea>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <p>Leveranssätt av faktura</p>
                          <v-btn-toggle
                            v-model="person.billecta_invoice_type"
                            dense
                          >
                            <v-btn :value="0" small>
                              <v-icon small class="mr-1">
                                mdi-at
                              </v-icon>
                              <span class="hidden-sm-and-down">E-post</span>
                            </v-btn>

                            <v-btn :value="1" small>
                              <v-icon small class="mr-1">
                                mdi-email
                              </v-icon>
                              <span class="hidden-sm-and-down">Post</span>
                            </v-btn>

                            <v-btn :value="4" small>
                              <v-icon small class="mr-1">
                                mdi-monitor
                              </v-icon>
                              <span class="hidden-sm-and-down">E-faktura</span>
                            </v-btn>

                            <v-btn v-if="0" :value="5" small>
                              <v-icon small class="mr-1">
                                mdi-monitor
                              </v-icon>
                              <span class="hidden-sm-and-down">Kivra</span>
                            </v-btn>

                            <v-btn :value="2" small>
                              <v-icon small class="mr-1">
                                mdi-exclamation
                              </v-icon>
                              <span class="hidden-sm-and-down">Skicka inte</span>
                            </v-btn>
                          </v-btn-toggle>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <v-switch
                            v-model="person.active"
                            :label="`${person.active ? 'Aktiv' : 'Inaktiv'}`"
                          ></v-switch>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-tab-item>
          <v-tab-item v-if="person._id">
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <PersonSubscriptions
                    v-if="person._id"
                    :personId="person._id"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item v-if="person._id">
            <PersonHistory v-if="person._id" :personId="person._id" :billectaId="person.billecta_id" />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <v-card-actions>
        <confirm-button
          color="red darken-1"
          :text="true"
          :messages="['Radera', 'Säker?', '✔']"
          @confirmation-success="deletePerson"
        >
          Radera
        </confirm-button>

        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="dialogOpen = false">
          Stäng
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          :title="!valid?'Fält med * är obligatoriska':''"
          @click="savePerson"
          :disabled="!valid"
        >
          Spara
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PersonHistory from '@/components/PersonHistory'
import PersonSubscriptions from '@/components/PersonSubscriptions'
import ConfirmButton from '@/components/ConfirmButton'
import { models } from 'feathers-vuex'
export default {
  components: {
    PersonHistory,
    PersonSubscriptions,
    ConfirmButton,
  },
  data: () => ({
    dialog: false,
    valid: true,
    tab: null,
    mandatory: [v => !!v || 'Obligatoriskt'],
    nameRules: [v => !!v || 'Namn är obligatoriskt'],
    emailRules: [
      v => !v || /.+@.+\..+/.test(v) || 'E-mail måste vara rätt formatterad',
    ],
    items: [
      { tab: 'Personuppgifter', content: 'Tab 1 Content' },
      { tab: 'Prenumerationer', content: 'Tab 2 Content' },
      { tab: 'Historik', content: 'Tab 3 Content' },
    ],
  }),
  methods: {
    close() {
      this.dialogOpen = false
    },
    async deletePerson() {
      // const person = new models.api.Person(this.person)

      // await person.remove()

      this.$store.commit('showMessage', {
        position: 'bottom',
        timeout: 2000,
        text: `Person raderad`,
        visible: true,
      })

      this.close()
      this.$emit('personRemoved', this.person._id)
    },
    async savePerson() {
      //console.log(this.person)

      let DebtorView = await this.$store.dispatch('billecta_debtor/localPersonToBillectaCustomer', this.person)
      // create billecta debtor (customer) if it doesnt exist
      if(!this.person.billecta_id) {
        //console.log(DebtorView)
        try{
          const billecta_debtor = await this.$store.dispatch('billecta_debtor/createDebtor', DebtorView);
          this.person.billecta_id = billecta_debtor.PublicId
          DebtorView = await this.$store.dispatch('billecta_debtor/localPersonToBillectaCustomer', this.person)
          DebtorView.DebtorPublicId = billecta_debtor.PublicId
        }
        catch (error) {
          this.showErrorMessage(error)
        }
      }
      else {
        try {
          DebtorView = await this.$store.dispatch('billecta_debtor/fetchDebtor', this.person.billecta_id);
          DebtorView.DebtorPublicId = this.person.billecta_id
          //console.log(DebtorView)
          //return
          await this.$store.dispatch('billecta_debtor/updateDebtor', DebtorView);
        }
        catch (error) {
          this.showErrorMessage(error)
        }
      }

      const person = new models.api.Person(this.person)

      if (this.person._id) {
        const item = await person.update()
        this.person = { ...item }
        this.$emit('personUpdated', item)
      } else {
        const item = await person.save()
        this.person = { ...item }
        this.$emit('personUpdated', item)
      }

      DebtorView = await this.$store.dispatch('billecta_debtor/localPersonToBillectaCustomer', this.person)
      DebtorView.DebtorPublicId = this.person.billecta_id
      await this.$store.dispatch('billecta_debtor/updateDebtor', DebtorView);

      this.$store.commit('showMessage', {
        position: 'bottom',
        timeout: 2000,
        text: `Person ${this.person._id ? 'uppdaterad' : 'tillagd'}`,
        visible: true,
      })

      this.close()
    },
  },
  computed: {
    tabItems() {
      if (this.person && !this.person._id)
        return this.items.filter((o, index) => o && !index)
      return this.items
    },
    personName() {
      if (!this.person) return 'Ny prenumerant'
      if (this.person && this.person._id && this.person.type === 1)
        return `${this.person.id} ${this.person.lastname} ${this.person.firstname}, ${this.person.postal_address}`
      if (this.person && this.person._id && this.person.type === 2)
        return `${this.person.id} ${this.person.lastname}, ${this.person.postal_address}`

      return ''
    },
    dialogOpen: {
      get() {
        return this.$store.state.dialogPersonOpen
      },
      set(bool) {
        this.$store.state.dialogPersonOpen = bool
      },
    },
    person: {
      get() {
        return this.$store.state.dialogPerson
      },
      set(bool) {
        this.$store.state.dialogPerson = bool
      },
    },
  },
  watch: {
    'person.type'(type) {
      if (type === 2) this.person.first_name = ''
    },
    dialogOpen(bool) {
      if (bool) {
        if (!this.person) {
          const { Person } = models.api

          this.person = { ...Person.instanceDefaults() }

          this.person.type = 1;
        }
      } else {
        setTimeout(() => {
          this.person = null
        }, 1000)
      }
    },
  },
}
</script>
