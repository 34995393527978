<template>
  <div id="app" :class="{ hidden: initializing }">
    <div v-if="isAuthenticated && printInvoices" class="print-only">
      <template v-for="(invoice, index) in printInvoices">
        <div
          class="page"
          :class="{ 'last-page': index === printInvoices.length - 1 }"
          style="width: 210mm; height: 296mm; background-color: white; box-sizing: border-box"
          :key="`svg_${invoice.ActionPublicId || index}`"
        >
          <pdf v-if="pdf && pdf[index] && !invoice._id" :src="pdf[index]"></pdf>
          <InvoiceSVG v-if="invoice && invoice._id"  :invoice="invoice" />

        </div>
        <div
          class="page"
          v-if="0 && invoiceAttachment || invoiceAttachmentSVG"
          :class="{ 'last-page': index === printInvoices.length - 1 }"
          style="width: 210mm; height: 296mm; background-color: white; box-sizing: border-box"
          :key="`svga_${invoice._id || index}`"
        >
          <InvoiceAttachment
            :scale="1"
            :invoice="invoice"
            :attachment="invoiceAttachment"
            :attachmentSVG="invoiceAttachmentSVG"
          />
        </div>
      </template>
    </div>

    <Login v-if="!isAuthenticated" />
    <v-app v-else id="inspire">
      <v-navigation-drawer v-model="drawer" app>
        <Sidebar />
      </v-navigation-drawer>

      <Header
        @drawer="drawerState => (drawer = drawerState)"
        :drawer="drawer"
      />

      <router-view />

      <v-snackbar-queue
        :items="snackbars"
        @remove="removeItem"
      ></v-snackbar-queue>

      <v-snackbar
        v-model="snackbar.visible"
        :color="snackbar.color"
        :multi-line="snackbar.mode === 'multi-line'"
        :timeout="snackbar.timeout"
        :top="snackbar.position.indexOf('top') > -1"
        :bottom="snackbar.position.indexOf('bottom') > -1"
        :left="snackbar.position.indexOf('left') > -1"
        :right="snackbar.position.indexOf('right') > -1"
      >
        <v-layout align-center pr-4>
          <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
          <v-layout column>
            <div v-if="snackbar.title">
              <strong>{{ snackbar.title }}</strong>
            </div>
            <div>{{ snackbar.text }}</div>
          </v-layout>
        </v-layout>
        <v-btn
          v-if="snackbar.timeout === 0"
          icon
          @click="snackbar.visible = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-snackbar>

      <div style="position: fixed; z-index: 400; pointer-events: none; background: black; color: white; right: 0; bottom: 0; padding: 5px; font-size: 12px">v. {{dateChanged}}</div>
    </v-app>
  </div>
</template>

<script>
import {onMounted, computed, ref, onBeforeMount} from '@vue/composition-api'
import Login from '@/views/Login'
import Header from '@/components/Header'
import Sidebar from '@/components/Sidebar'
import InvoiceAttachment from '@/components/invoice/InvoiceAttachment'

import metaData from './metaData.json';

import pdf from 'vue-pdf'

import VSnackbarQueue from './components/VSnackbarQue'
import InvoiceSVG from "@/components/invoice/InvoiceSVG";

export default {
  name: 'App',
  components: {
    InvoiceSVG,
    pdf,
    Login,
    Header,
    Sidebar,
    InvoiceAttachment,
    VSnackbarQueue,
  },
  setup(props, context) {
    const { $store, $route } = context.root

    let initializing = ref(true)
    let drawer = ref(true)

    const dateChanged = ref('')

    onBeforeMount(() => {
      dateChanged.value = `${metaData.buildDate}.${metaData.buildMinor}` || '-'
    })

    const isAuthenticated = computed(
      () => context.root.$store.getters['auth/isAuthenticated']
    )

    const removeItem = (id) => {
      const vm = this
      const index = vm.items.findIndex(c =>c.id === id)

      if (index !== -1) {
        vm.items.splice(index, 1)
      }
    }

    const snackbars = computed({
      get: () =>context.root.$store.state.snackbars,
      set: (items) =>context.root.$store.state.snackbars = items
    });
    const snackbar = computed(() => context.root.$store.state.snackbar)

    const printInvoices = computed(
      () => context.root.$store.state.printInvoices
    )
    const pdf = computed(
      () => context.root.$store.state.pdf
    )
    const invoiceAttachment = computed(
      () => context.root.$store.state.invoiceAttachment
    )
    const invoiceAttachmentSVG = computed(
      () => context.root.$store.state.invoiceAttachmentSVG
    )

    // Redirect to chat page if there's a user, otherwise to login page.
    /* watch(
      () => $store.state.auth.user,
      user => {
        //const toRouteName = user ? 'Home' : 'Login'
        if (!user) {
          $router.replace({ name: 'Login' })
        }
        // const toRouteName = 'Login'
        // $router.replace({ name: toRouteName })
      },
      { lazy: true }
    )*/

    // Attempt jwt auth when the app mounts.
    onMounted(() => {

      $store.dispatch('billecta_creditor/fetchCreditors')
      $store.dispatch('billecta_creditor/fetchSubCreditors')

      if ($route.path === '/print-invoice') {
        console.log('')
        // const email = 'henrikwikstrom@musiker.nu'
        // const password = 'adambertil'
        //$store
        //  .dispatch('auth/authenticate', { strategy: 'local', email, password })
      }
      $store
        .dispatch('auth/authenticate')
        .then(() => {
          initializing.value = false
        })
        .catch(error => {
          if (!error.message.includes('Could not find stored JWT')) {
            //console.error(error)
            initializing.value = false
          }
        })
    })

    return {
      drawer,
      isAuthenticated,
      initializing,
      printInvoices,
      pdf,
      removeItem,
      snackbar,
      snackbars,
      invoiceAttachment,
      invoiceAttachmentSVG,

      dateChanged,
    }
  },
}
</script>

<style lang="scss">
.print-only {
  display: none;
}

@media print {
  @page {
    size: A4 portrait;
    margin: 0;
  }

  .page {
    page-break-after: always;
  }

  .last-page {
    page: last_page;
    page-break-after: avoid; /* Use if your last page is blank, else omit. */
  }

  .non-print {
    display: none;
  }
  #inspire {
    display: none;
  }
  .print-only {
    display: unset;
  }
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: opacity 200ms linear;
  &.hidden {
    opacity: 0;
  }
}
.v-badge__wrapper {
  z-index: 1;
}

.v-select__selections {
  .v-list-item__content {
    white-space: pre;
    font-size: 70%;
  }
  > input {
    flex: 0 !important;
  }
}

.v-bottom-navigation .v-btn {
  max-width: unset !important;
}

@media only screen and (min-width: 600px) {
  .v-dialog {
    display: flex;
    flex-flow: column;

    > .v-card {
      max-height: 85vh;
      display: grid;
      grid-template-rows: auto 1fr;

      > .v-card__text {
        overflow: auto;

        &.tabs {
          overflow: hidden;
          display: grid;
          grid-template-rows: auto 1fr;

          > .v-tabs-items {
            overflow: auto;
          }
        }
      }
    }
  }
}
</style>
