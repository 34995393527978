<!-- eslint-disable -->
<template>
  <v-data-table
    :loading="loading"
    :headers="headers"
    :items="subscriptions"
    v-model="selectedSubscriptions"
    :single-select="singleSelect"
    show-select
    :hide-default-header="isMobile"
    :hide-default-footer="true"
    :footer-propayerps="footerProps"
    item-key="id"
    class="elevation-0 mb-12"
    :class="{ mobile: isMobile }"
    :search="search"
    :sort-by="['lastname', 'firstname']"
    :sort-desc="[false, false]"
    multi-sort
    :custom-filter="filterOnlyCapsText"
  >
    <template
      #top="{ pagination, itemsPerPageOptions, options, updateOptions }"
    >

      <div class="flex flex-nowrap pa-4">
        <v-container fluid class="pa-0 ma-0">
          <v-row>
            <v-col cols="12" sm="4">
              <DatePickerMenu
                class="ma-4"
                :full-width="false"
                v-model="subscriptionStartDate"
                label="Prenumerationsstart"
                hint="(alltid den första)"
                returnFormat="YYYY-MM-DDTHH:mm:ss.SSSZ"
                displayFormat="YYYY-MM"
                @input="setDate"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <DatePickerMenu
                class="ma-4"
                :full-width="false"
                datePickerType="date"
                v-model="invoiceDate"
                label="Fakturadatum"
                hint=""
                returnFormat="YYYY-MM-DDTHH:mm:ss.SSSZ"
                displayFormat="YYYY-MM-DD"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <DatePickerMenu
                class="ma-4"
                :full-width="false"
                datePickerType="date"
                v-model="dueDate"
                label="Förfallodatum"
                hint=""
                returnFormat="YYYY-MM-DDTHH:mm:ss.SSSZ"
                displayFormat="YYYY-MM-DD"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-tabs
                v-model="tab"
                color="deep-purple accent-4"
              >

                <v-tab
                  v-for="item in items"
                  :key="item"
                >
                  {{ item }}
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item>
                  <v-row class="mb-2">
                    <v-col cols="6">
                      <v-textarea class="mt-4" v-model="infoText"
                                  label="Informationstext som skrivs ut under alla rader på fakturan"
                                  persistent-hint
                                  hint="Texten i detta fält sparas inte"/>
                    </v-col>
                    <v-col cols="6">
                      <v-textarea class="mt-4" v-model="infoText2"
                                  label="Informationstext slask"
                                  persistent-hint
                                  hint="Denna text sparas i din webbläsare"/>
                    </v-col>
                  </v-row>
                </v-tab-item>

                <v-tab-item>
                  <v-row>
                    <v-col cols="12" xs="12" sm="12" md="4">
                      <v-select
                        class="mt-4"
                        :items="attachmentItems"
                        v-model="attachment"
                        label="Använd uppladdade bilagor"
                        no-data-text="Inga bilagor"
                        item-value="value"
                        item-text="text"
                        dense
                        chips
                        clearable
                        :return-object="false"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" md="4">
                      <FileReader classes="mb-8" @load="addAttachment" label="Använd en lokal bilaga"
                                  accept="application/pdf" hint="PDF-fil"/>
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" md="4">
                      <v-btn small v-if="attachment" @click="attachment = null">
                        <v-icon small>mdi-close</v-icon>
                        Ta bort
                      </v-btn>
                      <pdf v-if="attachment" :src="attachment"></pdf>
                    </v-col>
                  </v-row>
                </v-tab-item>

                <v-tab-item>
                  <v-row class="mt-2">
                    <v-col cols="12" xs="12" sm="12" md="4">
                      <v-text-field v-model="paymentOverride.PaymentReceiver" label="Betalningsmottagare"/>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="4">
                      <v-select
                        class="mt-4"
                        :items="receivingAccountType"
                        v-model.number="paymentOverride.ReceivingAccountType"
                        label="Betalsätt"
                        item-value="key"
                        item-text="label"
                        :return-object="false"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="4">
                      <v-text-field v-if="paymentOverride.ReceivingAccountType === 0"
                                    v-model="paymentOverride.BankGiro"
                                    label="BankGiro"
                      />
                      <v-text-field v-if="paymentOverride.ReceivingAccountType === 1"
                                    v-model="paymentOverride.PlusGiro"
                                    label="PlusGiro"
                      />
                      <v-text-field v-if="paymentOverride.ReceivingAccountType === 2"
                                    v-model="paymentOverride.ClearingNo"
                                    label="Clearingnummer"
                      />
                      <v-text-field v-if="paymentOverride.ReceivingAccountType === 2"
                                    v-model="paymentOverride.AccountNo"
                                    label="Kontonummer"
                      />
                      <v-text-field v-if="paymentOverride.ReceivingAccountType === 3"
                                    v-model="paymentOverride.BankName"
                                    label="Bank"
                      />
                      <v-text-field v-if="paymentOverride.ReceivingAccountType === 3"
                                    v-model="paymentOverride.Iban"
                                    label="IBAN"
                      />
                      <v-text-field v-if="paymentOverride.ReceivingAccountType === 3"
                                    v-model="paymentOverride.Bic"
                                    label="BIC"
                      />
                    </v-col>

                    <v-col cols="12" xs="12" sm="12" md="4">
                      <v-select
                        class="mt-4"
                        :items="referenceTypes"
                        v-model.number="paymentOverride.ReferenceType"
                        label="Referenstyp"
                        item-value="key"
                        item-text="label"
                        :return-object="false"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="4">
                      <v-text-field
                        v-if="paymentOverride.ReceivingAccountType !== 0"
                        v-model="paymentOverride.Reference"
                        label="OCR/Meddelande"
                        hint="{invoiceId} ersätts med rätt fakturanummer"
                        persistent-hint
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>


        </v-container>

        <v-btn small class="ma-4" @click="fetchSubscriptions">
          Läs in data från databasen
        </v-btn>
      </div>

      <v-data-footer
        :pagination="pagination"
        :items-per-page-options="footerProps.itemsPerPageOptions"
        :options="options"
        @update:options="updateOptions"
        :items-per-page-text="footerProps.itemsPerPageText"
      />

      <div v-if="selectedSubscriptions.length > 0" class="flex flex-nowrap">
        <v-switch
          v-if="0"
          v-model="singleSelect"
          label="Single select"
          class="pa-3"
        ></v-switch>
        <confirm-button
          color="orange lighten-1"
          :small="true"
          :plain="true"
          :messages="[`Skapa alla ${selectedSubscriptions.length } fakturor`, 'Säker?', '✔']"
          @confirmation-success="createInvoices"
        ></confirm-button>

        <div v-if="errorMessages.length">
          <template v-for="m in errorMessages">
            <div style="padding-bottom: 10px; display: flex; gap: 10px;">
              <div>{{m.message}}</div>
              <div>{{m.person.firstname}} {{m.person.lastname}}</div>
            </div>
          </template>
        </div>
      </div>

      <v-bottom-navigation
        fixed
        hide-on-scroll
        horizontal
        scroll-target="#hide-on-scroll-example"
      >
        <v-btn
          color="deep-purple accent-4"
          text
          :disabled="subscriptions.length < 1"
          @click="createInvoices"
        >
          <span>Skapa fakturor</span>
          <v-icon>mdi-history</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-bottom-sheet
        v-model="item.sheet"
        inset
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn small depressed class="ma-4"
            v-bind="attrs"
            v-on="on"
                 @click="clickedInvoice = item"
          >
            Visa rader
          </v-btn>
        </template>
        <v-sheet
          class="text-center pa-2 mb-4"
          v-if="clickedInvoice"
        >
          <v-btn
            class="mt-6"
            text
            color="error"
            @click="closeSheet(item)"
          >
            stäng
          </v-btn>
          <div class="my-3">
            {{clickedInvoice.invoice.payerName}}
          </div>
          <div class="my-3">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Artikel
                  </th>
                  <th class="text-left">
                    Pris
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                  v-for="item in clickedInvoice.items"
                  :key="item.text"
                  @click=""
                >
                  <td class="text-left" :title="item.priceId" v-html="item.text.replace(/\n/, '<br>')"></td>
                  <td class="text-left">{{ item.price * item.quantity }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>

        </v-sheet>

      </v-bottom-sheet>
      <v-btn small depressed class="ma-4" @click="createInvoice(item)">
        Skapa faktura
      </v-btn>
    </template>

    <template #item.sas="props">
      <tr v-if="!isMobile" class="persons-row">
        <td class="text-xs-left nowrap">
          {{ props.item.invoice.payerName }}
        </td>
      </tr>
      <tr v-else class="mobile">
        <td>
          <div class="flex-content flex-wrap">
            <div class="mobile-cell" :data-label="''">
              {{ props.item.personName }}
            </div>

            <div class="mobile-cell" :data-label="''">
              <v-btn
                small
                class="ma-4"
                @click="createInvoice(subscriptions[props.item.index])"
              >
                Skapa faktura
              </v-btn>
            </div>
          </div>
        </td>
      </tr>
    </template>

  </v-data-table>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import moment from 'moment'
import DatePickerMenu from '@/components/DatePickerMenu'
import FileReader from '@/components/FileReader'
import ConfirmButton from "@/components/ConfirmButton";
import {models} from 'feathers-vuex'
import pdf from 'vue-pdf'

import _ from 'lodash'

export default {
  components: {DatePickerMenu, FileReader, ConfirmButton, pdf},
  data: () => ({
    tab: null,
    items: ['Infotext', 'Bilagor', 'Betalningsinställningar'],
    singleSelect: false,
    selectedSubscriptions: [],
    subscriptionStartDate: '',
    invoiceDate: '',
    dueDate: '',
    search: '',
    clickedInvoice: null,
    loading: false,
    isMobile: false,
    active: true,
    articles: [],
    errorMessages: [],
    prices: [],
    infoText: '',
    infoText2: '',
    referenceTypes: [
      {key: 0, label: 'None'},
      {key: 1, label: 'OCR'},
      {key: 2, label: 'Meddelande'},
    ],
    receivingAccountType: [
      {key: 0, label: 'BankGiro'},
      {key: 1, label: 'PlusGiro'},
      {key: 2, label: 'Bankkonto'},
      {key: 3, label: 'IBAN'},
      {key: -1, label: 'Okänd'},
    ],
    paymentOverride: {
      BankGiro: '',
      PlusGiro: '60798949',
      ClearingNo: '',
      AccountNo: '',
      Iban: '',
      Bic: '',
      BankName: '',
      PaymentReceiver: 'Till Liv, Sandgatan 14b, 22350 Lund',
      Reference: 'Faktura {invoiceId}',
      ReferenceType: 2,
      ReceivingAccountType: 1,
    },
    attachmentItems: [],
    subscriptionsList: [],
    subscriptionCount: 0,
    subscriptionRange: 'active',
    subscriptionType: [1, 2],
    footerProps: {
      options: {
        itemsPerPage: 20,
        sortBy: ['firstname'],
        sortDesc: [false],
      },
      pagination: {
        rowsPerPage: 20,
        sortBy: 'lastname',
        ascending: true,
      },
      itemsPerPageOptions: [
        5,
        10,
        15,
        20,
        25,
        30,
        40,
        {text: 'Alla', value: -1},
      ],
      itemsPerPageText: 'Prenumeranter per sida',
    },
    subscriptionsQuery: {
      query: {},
    },
    pricesQuery: {
      query: {},
      paginate: true,
    },
    personsQuery: {
      query: {
        active: true,
        type: {
          $in: [1, 2],
        },
      },
      paginate: true,
    },
  }),
  async created() {
    if (localStorage.getItem('tillliv_invoice_infotext'))
      this.infoText2 = localStorage.getItem('tillliv_invoice_infotext')

    /*this.infoText =
      'Ordinarie 590kr (ny pren 350kr), Till Liv pdf 390kr, Fullpris 700kr\n' +
      'Taltidning 590kr (ny pren 350kr), Student 290kr\n' +
      'Begrunda, 2 nr/år (bilaga i Till Liv) \n' +
      'För Till Liv-pren 120kr/år, Endast Begrunda 150kr\n' +
      '\n' +
      'prenumeration@tillliv.se'*/

    this.attachmentItems = [
      {
        value: '@/assets/images/bilagor/bilaga_2014-11-11.pdf',
        text: 'Bilaga 2014-11-11.pdf',
      },
      {
        value: '@/assets/images/bilagor/bilaga_2014-11-11.svg',
        text: 'Bilaga 2014-11-11.svg',
      },
    ]

    this.subscriptionStartDate = moment
      .utc()
      .add(1, 'month')
      .startOf('month')
      .format('YYYY-MM-DD')
    this.invoiceDate = moment().toISOString()
    this.dueDate = moment
      .utc(this.invoiceDate)
      .add(1, 'month')
      .endOf('month')
      .toISOString()

    await this.fetchProducts()
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    ...mapActions('subscriptions', {createSubscription: 'create'}),
    ...mapActions('invoicerows', {findInvoiceRows: 'find'}),
    ...mapActions('persons', {findPersons: 'find'}),
    ...mapMutations('persons', {
      clearPersons: 'clearAll', // lets you do `this.clearTodos()` inside the component
    }),
    ...mapMutations('subscriptions', {
      clearSubscriptions: 'clearAll', // lets you do `this.clearTodos()` inside the component
    }),
    ...mapActions('prices', {findPrices: 'find'}),
    ...mapMutations('prices', {
      clearPrices: 'clearAll', // lets you do `this.clearTodos()` inside the component
    }),
    ...mapActions('articles', {findArticles: 'find'}),
    ...mapMutations('articles', {
      clearArticles: 'clearAll', // lets you do `this.clearTodos()` inside the component
    }),
    closeSheet(item) {
      item.sheet = false
      this.clickedInvoice = null
      //this.map[this.clickedId].sheet = false
    },
    async fetchProducts() {
      if (this.mainCreditorPublicId)
        await this.$store.dispatch('billecta_product/fetchProducts', {
          id: this.mainCreditorPublicId,
        })
    },
    async createInvoices() {
      this.errorMessages = []
      for await (let invoiceObject of this.selectedSubscriptions) {
        const message = await this.createInvoice(invoiceObject)
        console.log('message', message)
        if (message)
          this.errorMessages.push(message)
      }

      console.log(this.errorMessages)

      if (this.errorMessages.length) {
        console.log(this.errorMessages)
        this.$store.commit('showMessage', {
          color: 'warning',
          position: 'bottom',
          timeout: 40000,
          text: `${this.errorMessages.length} fakturor kunde inte skapas`,
          visible: true,
        })

        return
      }


      await this.fetchSubscriptions()
    },
    // eslint-disable-next-line no-unused-vars
    async createInvoice(subscriptionObject = {}) {
      // console.log(subscriptionObject.invoice)

      const {Person} = this.$FeathersVuex.api
      /*


      let personQuery = {
        query: {
          id: subscriptionObject.invoice.payer.id,
        },
        paginate: false,
      }

      let person = await Person.find(personQuery)

      if(person.length)
        person = person[0]

      // console.log(person)
      */
      const person = subscriptionObject.invoice.payer

      //console.log(person)

      if (!person) {
        this.$store.commit('showMessage', {
          position: 'bottom',
          timeout: 4000,
          color: 'error',
          text: `Personen hittades inte`,
          visible: true,
        })

        return false
      }

      // eslint-disable-next-line no-unused-vars
      let billecta_debtor = null

      if (person.billecta_id) {
        billecta_debtor = await this.$store.dispatch(
          'billecta_debtor/fetchDebtor',
          person.billecta_id
        )

        this.$store.commit('showMessage', {
          position: 'bottom',
          timeout: 4000,
          text: `Person ${name} hämtad`,
          visible: true,
        })
      } else {
        const DebtorView = await this.$store.dispatch(
          'billecta_debtor/localPersonToBillectaCustomer',
          person || subscriptionObject.invoice.payer
        )

        //console.log('DebtorView', DebtorView)

        billecta_debtor = await this.$store.dispatch(
          'billecta_debtor/createDebtor',
          DebtorView
        )
        //console.log('billecta_debtor',billecta_debtor)

        const patchPerson = {
          _id: subscriptionObject.invoice.payer._id,
          billecta_id: billecta_debtor.PublicId,
        }

        // console.log('patchPerson', patchPerson)

        const person = new Person(patchPerson)

        await person.patch()

        this.$store.commit('showMessage', {
          position: 'bottom',
          timeout: 4000,
          text: `Person ${name} skapad`,
          visible: true,
        })
      }
      //console.log(billecta_debtor)

      // eslint-disable-next-line no-unused-vars
      let InvoiceActionEntryView = _.cloneDeep(
        this.$store.getters['billecta_invoice/InvoiceActionEntryView']
      )

      /*const newInvoiceActionEntryView1 = {
        "ActionType": 2,
        "CreditorPublicId": this.mainCreditorPublicId,
        "DebtorPublicId": billecta_debtor.DebtorPublicId || "",
        "InvoiceDate": subscriptionObject.invoice.dispatchDate || '',
        "DueDate": moment.utc(subscriptionObject.invoice.dueDate.substring(0,10)).toISOString() || '',
        "DeliveryDate": null,
        "Records": [],
        "InterestPercentage": 8,
        "ReasonForHigherInterest": null,
        "InterestStartInDaysAfterDueDate": 0,
        "InterestType": 1,
        "OurReference": "",
        "YourReference": null,
        "DeliveryMethod": 1,
        "CommunicationLanguage": 0,
        "Message": null,
        "InvoiceNumber": "",
        "InvoiceFee": null,
        "FreightFee": null,
        "VatIsIncluded": false,
        "SendByMailIfEmailNotViewedInDays": null,
        "DeliveryAddressOverride": null,
        "DebtCollectionDetails": {
          "SendToDebtCollection": true,
          "StartDebtCollectionActionLevel": 2,
          "EndDebtCollectionActionLevel": 3,
          "NumberOfReminders": 0,
          "DaysDelayAfterDueDate": 15,
          "PaymentTermsInDays": 10,
          "Eviction": false,
          "InformSocialWelfare": false
        },
        "ReminderInvoiceDetails": {
          "SendReminderInvoice": true,
          "DaysDelayAfterDueDate": 1
        },
        "ReverseVATDetails": null,
        "RotRutDetails": null,
        "PaymentOverride": null,
        "Appendixes": [],
        "Attachments": [],
        "Autogiro": null,
        "CreditCard": null,
        "InvoicePDF": null,
        "CreditingInvoicePublicId": null,
        "ExternalReference": null,
        "IsLocked": false,
        "UseDebtorBalance": false,
        "InvoiceSendDateOverride": null
      }*/

      let newInvoiceActionEntryView = await this.$store.dispatch(
        'billecta_invoice/localInvoiceToBillectaInvoice',
        {
          invoice: subscriptionObject.invoice,
          debtor: billecta_debtor,
        }
      )

      /*function _base64ToArrayBuffer(base64){
        const binary_string = atob(base64);
        const len = binary_string.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
      }*/

      if (this.attachment) {
        const attachment = {
          File: {
            FilePublicId: '00000000-0000-0000-0000-000000000000' || null,
            ContentType: 'application/pdf',
            //Data: Buffer.from(this.attachment.replace('application/pdf;base64,',''), 'base64').toString('utf-8'),
            Data: this.attachment.replace('data:application/pdf;base64,', ''),
            FileName: 'BeforeInvoice.pdf',
          },
          IsCoverSheet: false,
          SortNumber: 1,
        }

        newInvoiceActionEntryView.Attachments.push(attachment)
      }

      const payerName = subscriptionObject.invoice.payerName
      delete subscriptionObject.invoice.payerName

      let invoice = new models.api.Invoice(subscriptionObject.invoice)

      let invoiceItem = null
      invoiceItem = await invoice.save()

      const PaymentOverride = {
        BankGiro: this.paymentOverride.BankGiro || null,
        PlusGiro: this.paymentOverride.PlusGiro || null,
        ClearingNo: this.paymentOverride.ClearingNo || null,
        AccountNo: this.paymentOverride.AccountNo || null,
        Iban: this.paymentOverride.Iban || null,
        Bic: this.paymentOverride.Bic || null,
        BankName: this.paymentOverride.BankName || null,
        PaymentReceiver: this.paymentOverride.PaymentReceiver || null,
        Reference: this.paymentOverride.Reference && this.paymentOverride.Reference.replace('{invoiceId}', invoiceItem.id) || null,
        ReferenceType: this.paymentOverride.ReferenceType || 2,
        ReceivingAccountType: this.paymentOverride.ReceivingAccountType || 1,
      }

      newInvoiceActionEntryView.PaymentOverride = PaymentOverride

      const invoiceSuccessItems = []

      for await (let invoiceRowObject of subscriptionObject.items) {
        const billectaProduct = this.billectaProducts.find(
          product => product.ArticleNumber === '' + invoiceRowObject.priceId
        )
        const subscriptionId = invoiceRowObject.subscriptionId
        delete invoiceRowObject.subscriptionId

        console.log(billectaProduct)
        console.log(this.billectaProducts, invoiceRowObject)

        //extract the first row
        //invoiceRowObject.text = invoiceRowObject.text.split('\n')[0]

        const billectaRecordItem = await this.$store.dispatch(
          'billecta_invoice/localInvoiceRowToBillectaInvoiceItem',
          {
            invoice: subscriptionObject.invoice,
            billectaProduct,
            localInvoiceRow: {
              ...invoiceRowObject,
              text: invoiceRowObject.text.split('\n')[0]
            }
          }
        )

        billectaRecordItem.VAT = 0
        newInvoiceActionEntryView.Records.push(billectaRecordItem)

        const invoiceRowText = invoiceRowObject.text.split('\n')
        console.log(invoiceRowObject, invoiceRowText.length)


        if (invoiceRowText.length > 1) {
          for (let i = 1; i < invoiceRowText.length; i++) {
            const text = invoiceRowText[i]
            //add blank row
            const billectaMessageRecordItem = {
              ...this.$store.state.billecta_invoice.billectaMessageRecordItem,
              ArticleDescription: text,
            }

            newInvoiceActionEntryView.Records.push(billectaMessageRecordItem)
          }
        }

        if (invoiceItem) {
          const object = {
            ...invoiceRowObject,
            invoiceId: invoiceItem._id,
          }
          const invoiceRow = new models.api.Invoicerow(object)
          const invoiceRowItem = await invoiceRow.save()
          // console.log(invoiceRowItem)

          const data = {
            _id: subscriptionId,
            invoiceRowId: invoiceRowItem._id,
          }

          invoiceSuccessItems.push(data)

        }
        // console.log(subscriptionRowItem)
      }

      if (this.infoText) {
        const Message = this.infoText // this.infoText.replace(/\n/g, '; ')

        //add blank row
        const billectaMessageRecordItem = {
          ...this.$store.state.billecta_invoice.billectaMessageRecordItem,
          ArticleDescription: '',
        }

        newInvoiceActionEntryView.Records.push(billectaMessageRecordItem)

        Message.split('\n').forEach(row => {
          const billectaMessageRecordItem = {
            ...this.$store.state.billecta_invoice.billectaMessageRecordItem,
            ArticleDescription: row,
          }

          newInvoiceActionEntryView.Records.push(billectaMessageRecordItem)
        })

        // newInvoiceActionEntryView.Message = Message
      }

      InvoiceActionEntryView = {
        ...InvoiceActionEntryView,
        ...newInvoiceActionEntryView,
      }

      try {
        InvoiceActionEntryView.InvoiceNumber = invoiceItem.id

        const billecta_invoice = await this.$store.dispatch(
          'billecta_invoice/createInvoice',
          InvoiceActionEntryView
        )


        console.log('billecta_invoice', billecta_invoice)

        if (billecta_invoice?.Message === 'Data stream could not be interpreted. Verify that you send the correct JSON.') {
          this.$store.commit('showMessage', {
            color: 'error',
            position: 'bottom',
            timeout: 4000,
            text: billecta_invoice.Message,
            visible: true,
          })


        } else if (billecta_invoice?.Message) {
          return {
            message: billecta_invoice.Message,
            person
          }
        } else {

          for await(const data of invoiceSuccessItems) {
            const subscriptionRow = new models.api.Subscription(data)
            await subscriptionRow.save()
          }

          /*this.$store.commit('showMessage', {
          position: 'bottom',
          timeout: 4000,
          text: `Faktura skapad`,
          visible: true,
        })*/

          const invoiceAPI = new models.api.Invoice({
            _id: invoice._id,
            billecta_id: billecta_invoice.ActionPublicId,
          })

          await invoiceAPI.save()

          this.$store.commit('showMessage', {
            position: 'bottom',
            timeout: 4000,
            text: `Faktura ${invoiceItem.id} skapad för ${payerName}`,
            visible: true,
          })

          this.subscriptionsList = this.subscriptionsList.filter(
            sub => sub._id !== subscriptionObject._id
          )
        }
      } catch (error) {
        this.showErrorMessage(error)
      }
    },
    getList(item, queryText) {
      const search = queryText
        .toLowerCase()
        .split(' ')
        .filter(o => o)
      let count = 0
      Object.keys(item).forEach(key => {
        if (typeof item[key] === 'string' || typeof item[key] === 'number') {
          const find = search.find(
            s => ('' + item[key]).toLowerCase().indexOf(s) > -1
          )

          if (find) count += 1
        }
      })
      return count === search.length
    },
    setEndDate(date, subscription) {
      const endDate = moment
        .utc(date)
        .add(1, 'y')
        .add(-1, 'd')
        .format('YYYY-MM-DDT23:59:59.999Z')
      subscription.endDate = endDate
    },
    onResize() {
      this.isMobile = this.$vuetify.breakpoint.smAndDown
    },

    async fetchArticles() {
      const {Article} = models.api

      const data = await Article.find()

      this.articles = data
    },
    async fetchPrices() {
      const {Price} = models.api

      const data = await Price.find()

      this.prices = data.sort(
        (a, b) => new Date(b.pricePeriodStart) - new Date(a.pricePeriodStart)
      )
    },
    filterPrices(articleId) {
      return this.groupPriceByDate(
        this.pricesList.filter(price => price.articleId === articleId),
        'description',
        'pricePeriodStart'
      )
    },
    setDate() {
      // console.log(date)
      //this.subscriptionStartDate = moment().utc(date).format('YYYY-MM-DD')
      this.invoiceDate = moment().toISOString()
      this.dueDate = moment
        .utc(this.invoiceDate)
        .add(30, 'd')
        .toISOString()
    },
    async fetchSubscriptions() {
      this.loading = true
      const tomorrow = moment.utc(this.subscriptionStartDate)

      await this.fetchArticles()
      await this.fetchPrices()

      this.$store.commit('showMessage', {
        position: 'bottom',
        timeout: 2000,
        text: 'Laddar prenumerationer',
        visible: true,
      })

      this.subscriptionsQuery.query = {
        startDate: new Date(tomorrow),
        invoiceRowId: '',
        billecta_id: '',
        status: 'active',
        articleId: {$ne: ''},
      }

      this.subscriptionsList = []

      const {Subscription} = models.api

      const data = await Subscription.find(this.subscriptionsQuery)
      console.log(JSON.stringify(this.subscriptionsQuery.query))

      this.subscriptionsList = data /* .map(sub => {
        return {
          ...sub,
        }
      }) */

      await this.findPersons(this.personsQuery)

      this.$store.commit('showMessage', {
        position: 'bottom',
        timeout: 3500,
        text: `${this.subscriptions.length} prenumeration${
          this.subscriptions.length !== 1 ? 'er' : ''
        } inläst${this.subscriptions.length !== 1 ? 'a' : ''}`,
        visible: true,
      })

      this.loading = false
    },

    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === 'string' &&
        value
          .toString()
          .toLowerCase()
          .indexOf(search.toLowerCase()) !== -1
      )
    },
    filterOnlyCapsText1(value, search, item) {
      let found = Object.keys(item).filter(key => {
        if (item[key]) {
          let compare_value = item[key].toString()

          return search
            .toLowerCase()
            .split(' ')
            .filter(
              string =>
                compare_value
                  .toString()
                  .toLowerCase()
                  .indexOf(string) !== -1
            ).length
            ? true
            : false
        }
        return false
      })

      return (
        value != null &&
        search != null &&
        typeof value === 'string' &&
        found.length
        //
      )
    },
    addAttachment(svg) {
      //onst typedarray = new Uint8Array(svg);
      this.attachment = svg
    },
  },
  computed: {
    ...mapGetters({
      mainCreditorPublicId: 'billecta_creditor/mainCreditorPublicId',
      billectaProducts: 'billecta_product/list',
    }),
    ...mapGetters('subscriptions', {subscriptionsLength: 'count'}),
    ...mapState('subscriptions', {loadingSubscriptions: 'isFindPending'}),
    ...mapGetters('persons', {personsList: 'list'}),
    ...mapState('persons', {loadingPersons: 'isFindPending'}),
    ...mapState('articles', {loadingArticles: 'isFindPending'}),
    ...mapGetters('articles', {articlesList: 'list'}),
    ...mapState('prices', {loadingPrices: 'isFindPending'}),
    ...mapGetters('prices', {pricesList: 'list'}),
    errorMessageList() {
      return null
    },
    attachment: {
      get() {
        return this.$store.state.invoiceAttachment
      },
      set(attachment) {
        this.$store.state.invoiceAttachment = attachment
      },
    },
    map() {
      if (!this.personsList.length) return []

      const map = {}

      this.subscriptionsList.forEach((item, index) => {
        const _id = item.payerId

        const payer = this.personsList.find(p => p._id === item.payerId)
        let payerName
        if (payer) {
          if (payer.type === 1)
            payerName = `${payer.id} ${payer.lastname} ${payer.firstname}, ${payer.postal_address}`
          if (payer.type === 2)
            payerName = `${payer.id} ${payer.lastname}, ${payer.postal_address}`
        }

        const person = this.personsList.find(p => p._id === item.personId)
        let personName
        if (person) {
          if (person.type === 1)
            personName = `${person.id} ${person.lastname} ${person.firstname}, ${person.postal_address}`
          if (person.type === 2)
            personName = `${person.id} ${person.lastname}, ${person.postal_address}`
        }

        map[_id] = map[_id] || {
          _id: item._id,
          index,
          sheet: false,
          id: Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, '')
            .substr(0, 8),
          invoice: {
            person,
            payer,
            payerName,
            payerId: (payer && payer._id) || '',
            amount: 0,
            dispatchDate: this.invoiceDate,
            dueDate: this.dueDate,
          },
          items: [],
        }

        const rowsum = item.ex * item.price
        map[_id].invoice.amount += rowsum

        const startDate = moment.utc(item.startDate).format('Y-MM-DD')
        const endDate = moment.utc(item.endDate).format('Y-MM-DD')

        const dates = `${startDate}–${endDate}`

        const price = this.pricesList.find(o => o._id === item.articleId)
        let article
        if (price) article = this.articles.find(o => o._id === price.articleId)
        let text = ''
        if (price && article) {
          text = `${article.name} ${price.description} ${dates}\n${personName}`
        } else text = ''

        map[_id].items.push({
          priceId: price.id,
          subscriptionId: item._id,
          text,
          payerId: _id,
          invoiceId: '',
          articleId: item._id,
          priceObject: item,
          price: item.price,
          quantity: item.ex,
        })

        map[_id].invoice.amount = map[_id].items.reduce(
          (a, b) => a + b.quantity * b.price,
          0
        )

        return map[_id]
      })

      return map
    },
    subscriptions() {
      return Object.values(this.map)
        .filter(o => o.invoice.payerName)
        .map(value => value)
    },
    pricesByDate() {
      if (!this.pricesList.length) return null

      const key = 'pricePeriodStart'
      const result = this.pricesList.reduce(function (r, a) {
        const k = moment.utc(a[key]).format('YYYY-MM-DD')
        r[k] = r[k] || []
        r[k].push(a)
        return r
      }, Object.create(null))

      return result
    },
    pricesByDateArray() {
      if (!this.pricesByDate) return []

      return Object.entries(this.pricesByDate)
        .map(([key, value]) => {
          return {
            key,
            items: value,
          }
        })
        .sort((a, b) => new Date(b.key) - new Date(a.key))
    },
    currentPricesByArray() {
      if (!this.pricesByDate || !this.subscriptionStartDate) return []

      //reverse sort newest date to oldest
      let array = [...this.pricesByDateArray] //.sort((a,b) => new Date(a.key) - new Date(b.key))

      array = array.find(
        date =>
          new Date(date.key).toJSON().slice(0, 10) <=
          new Date().toJSON().slice(0, 10)
      )

      return (array && array.items) || []
    },
    currentPricesFullYear() {
      if (!this.pricesList.length) return []

      return this.pricesList.filter(price => price.wholeYear)
    },
    currentPricesFullYearIds() {
      return this.currentPricesFullYear.map(price => price._id)
    },
    currentPricesNonFullYear() {
      if (!this.pricesList.length) return []

      return this.pricesList.filter(price => !price.wholeYear)
    },
    currentPricesNonFullYearIds() {
      return this.currentPricesNonFullYear.map(price => price._id)
    },
    persons() {
      return this.personsList
    },
    headers() {
      return [
        {
          text: 'Prenumerant',
          align: 'start',
          sortable: true,
          value: 'invoice.payerName',
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'actions',
        },
      ]
    },
  },
  watch: {
    async subscriptions(subs) {
      this.selectedSubscriptions = subs
    },
    async mainCreditorPublicId() {
      await this.fetchProducts()
    },
    infoText2(text) {
      localStorage.setItem('tillliv_invoice_infotext', text)
    },
  },
}
</script>



<style lang="scss" scoped>
@media screen and (max-width: 768px) {
  .mobile table.v-table tr {
    max-width: 100%;
    position: relative;
    display: block;
  }

  .mobile table.v-table tr:nth-child(odd) {
    border-left: 6px solid deeppink;
  }

  .mobile table.v-table tr:nth-child(even) {
    border-left: 6px solid cyan;
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr.mobile td {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #f5f5f5;
    height: auto;
    padding: 10px;
  }

  tr.mobile .mobile-cell[data-label]:before {
    content: attr(data-label);
    padding-right: 0.5em;
    text-align: left;
    display: block;
    color: #999;
  }
}
</style>
