var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","xs":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loadingUsers,"headers":_vm.headers,"items":_vm.filteredUsers,"hide-default-header":_vm.isMobile,"hide-default-footer":true,"footer-props":_vm.footerProps,"item-key":"_id","sort-by":['name'],"sort-desc":[false],"multi-sort":""},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Användare")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Ny användare ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"type":"text","label":"Användarnamn"},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-text-field',{attrs:{"type":"email","label":"E-postadress"},model:{value:(_vm.editedItem.email),callback:function ($$v) {_vm.$set(_vm.editedItem, "email", $$v)},expression:"editedItem.email"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Förnamn"},model:{value:(_vm.editedItem.first_name),callback:function ($$v) {_vm.$set(_vm.editedItem, "first_name", $$v)},expression:"editedItem.first_name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Efternamn"},model:{value:(_vm.editedItem.last_name),callback:function ($$v) {_vm.$set(_vm.editedItem, "last_name", $$v)},expression:"editedItem.last_name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-select',{attrs:{"items":[
                            { value: 1, text: 'Användare' },
                            { value: 2, text: 'Admin' } ],"item-text":"text","item-value":"value","label":"Nivå","return-object":false,"single-line":""},model:{value:(_vm.editedItem.level),callback:function ($$v) {_vm.$set(_vm.editedItem, "level", _vm._n($$v))},expression:"editedItem.level"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"type":"password","label":"Lösenord"},model:{value:(_vm.editedItem.password),callback:function ($$v) {_vm.$set(_vm.editedItem, "password", $$v)},expression:"editedItem.password"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Avbryt ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Är du säker på att du vill ta bort denna användare?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Avbryt")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                          var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reset ")])]},proxy:true}])})],1)],1),(0)?_c('v-fab-transition',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(true),expression:"true"}],attrs:{"color":"pink","fab":"","dark":"","small":"","absolute":"","top":"","right":""}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }