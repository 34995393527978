import Vue from 'vue'
import Vuex from 'vuex'
import { FeathersVuex } from '../feathers-client'
import auth from './store.auth'

import billecta_invoice from './modules/billecta.invoice'
import billecta_creditor from './modules/billecta.creditor'
import billecta_debtor from './modules/billecta.debtor'
import billecta_product from './modules/billecta.product'

Vue.use(Vuex)
Vue.use(FeathersVuex)

import axios from 'axios'

axios.defaults.headers['Access-Control-Allow-Origin'] = '*'

const requireModule = require.context(
  // The path where the service modules live
  './services',
  // Whether to look in subfolders
  false,
  // Only include .js files (prevents duplicate imports`)
  /\.js$/
)
const servicePlugins = requireModule
  .keys()
  .map(modulePath => requireModule(modulePath).default)

export default new Vuex.Store({
  state: {
    snackbars: [],
    snackbar: {
      color: null,
      icon: null,
      mode: null,
      position: 'top',
      text: null,
      timeout: 7500,
      title: null,
      visible: false,
    },
    invoiceAttachment: null,
    invoiceAttachmentSVG: null,
    printInvoices: null,
    currentInvoice: null,
    pdf: null,
    dialogPersonOpen: false,
    dialogPerson: null,
    dialogSubscriptionOpen: false,
    dialogSubscription: null,
    rules: {
      required: value => !!value || 'Obligatorisk.',
      notZero: value => value > 0 || 'Värdet måste vara mer än 0',
      counter: value => value.length <= 20 || 'Max 20 tecken',
      password2Complexity: value => {
        const pattern = /(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/
        return (
          !value ||
          pattern.test(value) ||
          'Lösenordet måste innehålla minst 8 tecken; små och stora bokstäver'
        )
      },
      password4Complexity: value => {
        const pattern = /(?=(.*[0-9]))(?=.*[!@#$%^&*()[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{12,}/
        return (
          !value ||
          pattern.test(value) ||
          'Lösenordet måste innehålla minst 12 tecken; små och stora bokstäver, siffror och !@#$%^&*()[]{}-_+=~`|:;"\'<>,.'
        )
      },
      numeric: value => {
        const pattern = /[0-9]/
        return pattern.test(value) || 'Alla tecken måste vara siffror'
      },
      nonNumeric: value => {
        const pattern = /[0-9]/
        return pattern.test(value) || 'Inga tecken får vara siffror'
      },
      sweIntlPhoneNumber: value => {
        const pattern = /^\+46/
        return !value || pattern.test(value) || 'Felaktigt telefonnummer'
      },
      sweCellphoneNumber: value => {
        const pattern = /0[0-9]{9}/
        return !value || pattern.test(value) || 'Felaktigt mobiltelefonnummer'
      },
      intlPhoneNumber: value => {
        const pattern = /^\+[0-9]{2}/
        return !value || pattern.test(value) || 'Felaktigt telefonnummer'
      },
      intlCellphoneNumber: value => {
        const pattern = /\+[0-9]{11}/
        return !value || pattern.test(value) || 'Felaktigt mobiltelefonnummer'
      },
      swedishSSN: value => {
        const pattern = /^(19|20)?(\d{6}(-|\s)\d{4}|(?!19|20)\d{10})$/
        return !value || pattern.test(value) || 'Felaktigt personnummer'
      },
      swedishSSN12: value => {
        const pattern = /^(19|20)(\d{6}(-|\s)\d{4}|(?!19|20)\d{10})$/
        return !value || pattern.test(value) || 'Inled med 19 eller 20'
      },
      swedishSSN8: value => {
        const pattern = /^(19|20)(\d{2}-\d{2}-\d{2})$/
        return !value || pattern.test(value) || 'Fel formattering'
      },
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return !value || pattern.test(value) || 'Ogiltig e-postadress.'
      },
    },
  },
  getters: {},
  mutations: {
    showMessage1(state, payload) {
      state.snackbars.push({
        color: 'info',
        icon: 'mdi-information-outline',
        mode: 'multi-line',
        position: 'bottom',
        timeout: 6000,
        text: '',
        visible: true,
        ...payload,
      })
    },
    showMessage(state, payload) {
      state.snackbar = {
        color: 'info',
        icon: 'mdi-information-outline',
        mode: 'multi-line',
        position: 'bottom',
        timeout: 3000,
        text: '',
        visible: true,
        ...payload,
      }
    },
  },
  actions: {},
  modules: {
    billecta_creditor,
    billecta_invoice,
    billecta_debtor,
    billecta_product,
  },
  plugins: [...servicePlugins, auth],
})
