import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '../../feathers-client'

class Price extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'Price'
  // Define default properties here
  static instanceDefaults() {
    return {
      billecta_id: '',
      description: '',
      articleId: '',
      price: 0,
      months: 12,
      personType: [1],
      noMail: false,
      pricePeriodStart: '',
      pricePeriodEnd: '',
      ignoreExtraMonth: false,
      wholeYear: false,
      active: true,
    }
  }
}
const servicePath = 'prices'
const servicePlugin = makeServicePlugin({
  Model: Price,
  service: feathersClient.service(servicePath),
  servicePath,
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
})

export default servicePlugin
