<template>
  <v-main class="grey lighten-3">
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-sheet rounded="lg">
            <v-card>
              <RenewSubscriptions />
            </v-card>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions } from 'vuex'

import RenewSubscriptions from '@/components/invoice/RenewSubscriptions'

export default {
  components: { RenewSubscriptions },
  data: () => ({
    activeSubscriptions: 0,
    upcomingSubscriptions: 0,

    query: {
      query: {},
      paginate: true,
    },
  }),
  async created() {},
  methods: {
    ...mapActions('subscriptions', { findSubscriptions: 'find' }),
  },
}
</script>
