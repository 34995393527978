<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card flat>
          <v-card-title class="pt-0 pl-sm-0 pr-sm-0">
            Skapa fakturor
          </v-card-title>

          <v-card-text class="flex pl-sm-0 pr-sm-0">
            <CreateInvoicesTable />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CreateInvoicesTable from '@/components/invoice/CreateInvoicesTable'
export default {
  components: { CreateInvoicesTable },
}
</script>
