var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{attrs:{"rounded":"lg"}},[_c('v-system-bar'),_c('v-list',[_c('v-list-item',[(0)?_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":"https://cdn.vuetifyjs.com/images/john.png"}})],1):_vm._e(),(_vm.user !== null)?_c('v-avatar',{style:({ 'background-color': 'rgb(53,118,203)' }),attrs:{"size":40}},[_c('strong',{staticClass:"initials",style:({ color: 'white', 'font-size': '14px' })},[_vm._v(" "+_vm._s(_vm.user.first_name[0] + _vm.user.last_name[0] || '?')+" ")])]):_vm._e()],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.userName)+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.user.email))])],1),_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-menu-down")])],1)],1)],1),_c('v-divider'),_c('v-list',{attrs:{"nav":"","dense":""}},[_vm._l((_vm.menuitems),function(item){return _c('v-list-group',{key:item.title,attrs:{"color":"primary","value":true},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{attrs:{"to":item.link}},[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)]},proxy:true}],null,true)},_vm._l((item.menuitems),function(ref,i){
var title = ref.title;
var icon = ref.icon;
var click = ref.click;
var link = ref.link;
return _c('v-list-item',{key:i,staticClass:"pl-6",attrs:{"link":"","to":link},on:{"click":function () { return click && click(); }}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(title)}}),_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(icon)}})],1)],1)}),1)}),(0)?_c('v-list-group',{attrs:{"value":true,"prepend-icon":"mdi-account-circle"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v("Users")])]},proxy:true}],null,false,4038924901)},[_c('v-list-group',{attrs:{"value":true,"no-action":"","sub-group":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Admin")])],1)]},proxy:true}],null,false,1688918343)},_vm._l((_vm.admins),function(ref,i){
var title = ref[0];
var icon = ref[1];
return _c('v-list-item',{key:i,attrs:{"link":""}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(title)}}),_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(icon)}})],1)],1)}),1),_c('v-list-group',{attrs:{"no-action":"","sub-group":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Actions")])],1)]},proxy:true}],null,false,2268469413)},_vm._l((_vm.cruds),function(ref,i){
var title = ref[0];
var icon = ref[1];
return _c('v-list-item',{key:i,attrs:{"link":""}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(title)}}),_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(icon)}})],1)],1)}),1)],1):_vm._e()],2),(0)?_c('v-list',{attrs:{"color":"transparent"}},[_vm._l((5),function(n){return _c('v-list-item',{key:n,attrs:{"link":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" List Item "+_vm._s(n)+" ")])],1)],1)}),_c('v-divider',{staticClass:"my-2"}),_c('v-list-item',{attrs:{"link":"","color":"grey lighten-4"}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Refresh ")])],1)],1)],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }