<template>
  <div>
    <v-subheader>PRENUMERATIONER</v-subheader>
    <v-card class="mx-auto overflow-y-auto" max-height="500" flat tile>
      <v-card-text>
        <v-progress-circular
          v-if="loading"
          indeterminate
          color="primary"
        ></v-progress-circular>

        <v-list
          v-if="!loading"
          max-height="400"
          three-line
          dense
          :elevation="0"
        >
          <v-list-item-group color="primary" v-model="selectedItem">
            <v-list-item
              v-for="(item, i) in computedSubscriptions"
              :key="i"
              :class="item.color"
              :titles="
                `${(item.article && item.article._id) || ''} ${
                  item.price._id
                } ${item._id}`
              "
              @click="setSelectedSubscription(item)"
            >
              <svg
                v-if="item.invoice && item.invoice.canceled"
                class="displayMode"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                data-v-47ece690=""
                style="position: absolute; z-index: 9999; left: 0px; top: 0px; width: 100%; height: 100%; transition-duration: 1000ms;"
              >
                <line
                  x1="0"
                  y1="0"
                  x2="100%"
                  y2="100%"
                  data-v-47ece690=""
                  style="stroke: rgba(0, 0, 0, 0.2); stroke-width: 5;"
                ></line>
                <!--v-if-->
              </svg>

              <v-list-item-content>
                <v-list-item-title
                  >{{ `${item.article.name} ${item.ex}st` }}
                </v-list-item-title>
                <v-list-item-subtitle
                  >{{ `${item.price.description}` }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="item.subtitle"
                  >{{ `${item.subtitle}` }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="item._id"
                  >{{ `${item._id}` }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-action-text v-if="item.invoice">
                  Faktura #{{ item.invoice.id }}
                  {{ formatDate(item.invoice.createdAt, 'Y-MM-DD') }}
                </v-list-item-action-text>
                <v-list-item-action-text>
                  {{ `${item.startDateYM}–${item.endDateYM}` }}
                </v-list-item-action-text>
                <v-list-item-action-text v-if="!item.invoiceRowId">
                  Ej fakturerad
                </v-list-item-action-text>

                <template v-if="item.invoice" :title="item.invoice.id">
                  <v-list-item-action-text v-if="item.invoice.canceled">
                    Makulerad
                  </v-list-item-action-text>
                  <v-list-item-action-text
                    v-else-if="isBillectaInvoiceOverdue(item.invoice.id)"
                  >
                    Förfallen: {{ formatDate(item.invoice.dueDate, 'Y-MM-DD') }}
                  </v-list-item-action-text>
                  <v-list-item-action-text
                    v-else-if="isBillectaInvoicePayed(item.invoice.id)"
                  >
                    Betalad: {{ getPayDate(item.invoice.id) }}
                  </v-list-item-action-text>
                  <v-list-item-action-text v-else>
                    <div v-if="getBillectaInvoice(item.invoice.id)">
                      Förfaller:
                      {{
                        formatDate(
                          getBillectaInvoice(item.invoice.id).DueDate,
                          'Y-MM-DD'
                        )
                      }}
                    </div>
                  </v-list-item-action-text>
                </template>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>
    <v-card-actions>
      <v-btn small color="primary" @click="newSubscription">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-spacer />
      <v-btn
        small
        color="primary"
        :disabled="selectedItem !== undefined && selectedItem === -1"
        @click="editSubscription"
      >
        <v-icon>mdi-pen</v-icon>
      </v-btn>
      <v-spacer />
      <confirm-button
        class="ma-4 primary"
        :small="true"
        :plain="true"
        :disabled="selectedItem == undefined || selectedItem === -1"
        :messages="[
          `Förnya alla ${subscriptions.length} prenumerationer`,
          'Bekräfta förnyelse',
          '✔',
        ]"
        @confirmation-success="deleteSubscription"
      >
        <template #step_0="">
          <v-icon>mdi-delete</v-icon>
        </template>
        <template #step_1="">
          Bekräfta
          <v-icon>mdi-thumb-up</v-icon>
        </template>
      </confirm-button>

      <v-btn small color="primary" @click="init">
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
      <v-spacer />

      <v-btn
        small
        color="primary"
        @click="status = status === 'current' ? 'old' : 'current'"
      >
        {{ status === 'current' ? 'Gamla' : 'Aktuella' }}
      </v-btn>
    </v-card-actions>

    <SubscriptionDialog @subscriptionUpdated="fetchSubscriptions" />
  </div>
</template>

<script>
import { models } from 'feathers-vuex'
import moment from 'moment'
import _ from 'lodash'
import SubscriptionDialog from '@/components/SubscriptionDialog'
import ConfirmButton from '@/components/ConfirmButton'
import { mapGetters, mapState } from 'vuex'

export default {
  components: { SubscriptionDialog, ConfirmButton },
  props: {
    personId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedItem: -1,
      selectedSubscription: null,
      loading: true,
      status: 'current',
      articles: [],
      prices: [],
      subscriptions: [],
      subscriptionsRaw: [],
      persons: [],
      invoicesQuery: {
        query: {},
      },
      invoicesList: [],
      invoiceRowsQuery: {
        query: {},
      },
      invoiceRowsList: [],
    }
  },
  async mounted() {
    await this.init()
  },
  methods: {
    newSubscription() {
      this.$store.state.dialogSubscriptionOpen = true
    },
    setSelectedSubscription(item) {
      this.selectedSubscription = _.cloneDeep(item)
    },
    editSubscription() {
      this.$store.state.dialogSubscriptionOpen = true
      this.$store.state.dialogSubscription = {
        ...this.selectedSubscription,
      }
    },
    async deleteSubscription() {
      const subscription = new models.api.Subscription(
        this.selectedSubscription
      )

      this.selectedItem = -1

      await subscription.remove()

      this.$store.commit('showMessage', {
        position: 'bottom',
        timeout: 2000,
        text: `Prenumeration raderad`,
        visible: true,
      })

      await this.fetchSubscriptions()
    },
    async init() {
      this.loading = true
      await this.fetchArticles()
      await this.fetchSubscriptions()
      await this.fetchInvoices()
      setTimeout(() => {
        this.loading = false

      }, 2000)
    },
    async fetchArticles() {
      this.articles = []
      const { Article } = models.api

      const data = await Article.find()

      this.articles = data
    },
    async fetchPrices() {
      this.prices = []
      const { Price } = models.api

      const data = await Price.find()

      this.prices = data
    },
    async fetchInvoices() {
      const { Invoice, Invoicerow } = this.$FeathersVuex.api

      this.invoicesList = []
      this.invoiceRowsList = []

      this.invoiceRowsQuery = {
        query: {
          _id: {
            $in: this.subscriptions
              .filter(o => o.invoiceRowId)
              .map(sub => sub.invoiceRowId),
          },
        },
        paginate: false,
      }

      const invoiceRows = await Invoicerow.find(this.invoiceRowsQuery)
      this.invoiceRowsList = invoiceRows

      this.invoicesQuery = {
        query: {
          _id: { $in: invoiceRows.map(o => o.invoiceId) },
        },
        paginate: false,
      }

      //await this.getAllOpenInvoices()
      //await this.getAllClosednvoices()

      const invoices = await Invoice.find(this.invoicesQuery)
      this.invoicesList = invoices

      await this.getMyInvoices()
      //await this.getMyInvoiceEvents()
    },
    async getMyInvoiceEvents() {
      try {
        await this.$store.dispatch('billecta_invoice/getMyInvoiceEvents', {
          id: this.mainCreditorPublicId,
          billecta_ids: this.invoicesList
            .filter(i => i.billecta_id)
            .map(i => i.billecta_id),
          sortingfield: 'InvoiceNumber',
          asc: true,
        })
      } catch (error) {
        this.showErrorMessage(error)
      }
    },
    async getMyInvoices() {
      try {
        await this.$store.dispatch('billecta_invoice/getMyInvoices', {
          id: this.mainCreditorPublicId,
          billecta_ids: this.invoicesList
            .filter(i => i.billecta_id)
            .map(i => i.billecta_id),
          sortingfield: 'InvoiceNumber',
          asc: true,
        })
      } catch (error) {
        this.showErrorMessage(error)
      }
    },
    async getAllOpenInvoices() {
      try {
        await this.$store.dispatch('billecta_invoice/getAllOpenInvoices', {
          id: this.mainCreditorPublicId,
          from: moment()
            .add(-1, 'Y')
            .format('Y-MM-DD'),
          to: moment().format('Y-MM-DD'),
          sortingfield: 'InvoiceNumber',
          asc: true,
        })
      } catch (error) {
        this.showErrorMessage(error)
      }
    },
    async getAllClosednvoices() {
      try {
        await this.$store.dispatch('billecta_invoice/getAllClosedInvoices', {
          id: this.mainCreditorPublicId,
          from: moment()
            .add(-1, 'Y')
            .format('Y-MM-DD'),
          to: moment().format('Y-MM-DD'),
          sortingfield: 'InvoiceNumber',
          asc: true,
        })
      } catch (error) {
        this.showErrorMessage(error)
      }
    },
    async fetchSubscriptions() {
      await this.fetchPrices()

      const { Subscription } = models.api

      const subscriptionQuery = {
        query: {
          articleId: { $ne: '' },
          $and: [
            {
              $or: [{ payerId: this.personId }, { personId: this.personId }],
            },
          ],
        },
      }

      if (this.status === 'current') {
        subscriptionQuery.query['$and'].push({
          endDate: { $gte: new Date() },
        })
      } else {
        subscriptionQuery.query['$and'].push({
          endDate: { $lte: new Date() },
        })
      }

      const data = await Subscription.find(subscriptionQuery)

      // console.log(data)

      const { Person } = this.$FeathersVuex.api

      this.persons = await Person.find({
        query: {
          _id: {
            $in: data.map(d => [d.payerId, d.personId]).flat(),
          },
        },
      })

      this.subscriptionsRaw = [...data]

      this.subscriptions = data.map(sub => {
        console.log(sub)
        const price = this.prices.find(price => price._id === sub.articleId)
        let article
        if (price)
          article = this.articles.find(
            _article => _article._id === price.articleId
          )
        const payer = this.persons.find(person => person._id === sub.payerId)
        let personObject = null,
          subtitle = null,
          color = ''

        // console.log(sub.payerId, sub.personId, article.description, personObject)
        //Om jag betalar åt någon annan
        if (this.personId !== sub.personId && sub.personId !== sub.payerId) {
          personObject = this.persons.find(
            person => person._id === sub.personId
          )
          color = 'elm-red'
          subtitle = `GÅVA: ${personObject.firstname} ${personObject.lastname}, ${personObject.postal_address}`
        }
        //Om någon betalar åt mig
        else if (
          this.personId !== sub.payerId &&
          sub.personId !== sub.payerId
        ) {
          color = 'elm-green'
          subtitle = `ANNAN BET: ${payer.firstname} ${payer.lastname}, ${payer.postal_address}`
        }
        //Om jag betalar åt mig själv
        else {
          color = 'elm-orange'
        }

        return {
          ...sub,
          article,
          price,
          startDateYM: moment.utc(sub.startDate).format('Y-MM'),
          endDateYM: moment.utc(sub.endDate).format('Y-MM'),
          payer,
          person: personObject,
          color,
          subtitle,
        }
      })

      this.subscriptions.sort(
        (a, b) => new Date(b.startDateYM) - new Date(a.startDateYM)
      )
    },
    dialogSubscriptionOpen() {
      return this.$store.state.dialogSubscriptionOpen
    },
    getPayDate(id) {
      if (id < this.billectFirstInvoiceNumber) {
        const invoice = this.invoicesList.find(i => i.id === id)
        if (invoice?.payDate) return moment(invoice.payDate).format('Y-MM-DD')
        else return 'Nej'
      } else {
        const invoice = this.getBillectaInvoice(id)
        if (!invoice) return false
        return !invoice.TotalVATAmount.Value &&
          invoice.State.Stage == 'Completed'
          ? this.formatDate(invoice.State.ClosedDate)
          : 'Nej'
      }

      return 'Nej'
    },
    isBillectaInvoicePayed(id) {
      console.log(id)
      if (id < this.billectFirstInvoiceNumber) {
        const invoice = this.invoicesList.find(i => i.id === id)
        if (invoice && invoice.payDate) return true

        return false
      }

      const invoice = this.getBillectaInvoice(id)
      console.log('binvoice', invoice)
      if (!invoice) return false
      return !invoice.TotalVATAmount.Value
      return (
        !invoice.CurrentAmount.Value &&
        invoice.ClosedDate &&
        invoice.Stage == 'Completed'
      )
    },
    isBillectaInvoiceOverdue(id) {
      if (id < this.billectFirstInvoiceNumber) {
        const invoice = this.invoicesList.find(i => i.id === id)
        if (
          invoice &&
          !invoice.payDate &&
          invoice.dueDate &&
          moment().isSameOrBefore(invoice.dueDate)
        )
          return true

        return false
      }

      const attested = this.personalInvoices.filter(
        invoice => invoice.AttestedDate
      )
      if (!attested) return null
      const overdued = attested.find(
        invoice =>
          invoice.InvoiceNumber === id + '' &&
          !invoice.ClosedDate &&
          moment(invoice.DueDate).isBefore(moment(), 'day')
      )
      return overdued
    },
    getBillectaInvoice(id) {
      const billectaInvoices = this.personalInvoices
      //console.log('length',!billectaInvoices.length)
      if (!billectaInvoices.length) return null

      const invoice = billectaInvoices.find(i => i.InvoiceNumber === id + '')

      if (invoice) {
        return invoice
      }

      return null
    },
  },
  computed: {
    ...mapGetters({
      openInvoices: 'billecta_invoice/openInvoices',
      closedInvoices: 'billecta_invoice/closedInvoices',
      personalInvoices: 'billecta_invoice/closedInvoices',
      mainCreditorPublicId: 'billecta_creditor/mainCreditorPublicId',
      billectFirstInvoiceNumber: 'billecta_invoice/billectFirstInvoiceNumber',
    }),
    ...mapState({
      personalInvoices: state => state.billecta_invoice.personalInvoices,
    }),
    myBillectaInvoices() {
      if (!this.computedSubscriptions.filter(i => i.invoice).length) return []

      const ids = this.computedSubscriptions.map(i => i.invoice?.id + '')

      return [...this.closedInvoices, ...this.openInvoices].filter(bInvoice => {
        return ids.includes(bInvoice.InvoiceNumber)
      })
    },
    computedSubscriptions() {
      return this.subscriptions.map(sub => {
        const invoiceRow = this.invoiceRowsList.find(
          row => row._id === sub.invoiceRowId
        )
        if (invoiceRow) {
          sub.invoiceRow = invoiceRow
          sub.invoice = this.invoicesList.find(
            invoice => invoice._id === invoiceRow.invoiceId
          )
          if(sub.invoice) {
            //const invoice = this.myBillectaInvoices.find(i => i.InvoiceNumber === sub.invoice.id + '')
            //console.log(invoice)
            // if(invoice)
            // sub.billectaInvoice = invoice
          }
        }


        return sub
      })
    },
  },
  watch: {
    dialogSubscriptionOpen(bool) {
      if (!bool) this.fetchSubscriptions()
    },
    async status() {
      await this.fetchSubscriptions()
      await this.fetchInvoices()
    },
  },
}
</script>

<style scoped>
.elm-orange {
  background-color: rgba(255, 174, 0, 0.1);
}

.elm-red {
  background-color: rgba(255, 100, 0, 0.1);
}

.elm-green {
  background-color: rgba(0, 196, 23, 0.1);
}

.elm-blue {
  background-color: rgba(0, 23, 196, 0.1);
}

.stripes {
  height: 100%;
  width: 100%;
  position: absolute;
  background-size: 100%;
}
</style>
