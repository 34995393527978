<template>
  <v-data-table
    :loading="loadingPersons || loadingSubscriptions"
    :headers="headers"
    :items="filteredPersons"
    :hide-default-header="isMobile"
    :hide-default-footer="true"
    :footer-props="footerProps"
    item-key="name"
    class="elevation-1"
    :custom-sort="customSort"
    @click:row="handleClick"
  >
    <template
      v-slot:top="{ pagination, itemsPerPageOptions, options, updateOptions }"
    >
      <v-toolbar flat>
        <v-toolbar-title>Prenumeranter</v-toolbar-title>
        <v-spacer></v-spacer>

        <PersonDialog
          @personRemoved="fetchPersons"
          @personsUpdated="fetchPersons"
        />
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col cols="12" lg="12">
            <v-btn-toggle v-if="0" class="mb-2 ml-2" v-model="subscriptionRange">
              <v-btn small value="current">
                Nuvarande
              </v-btn>

              <v-btn small value="upcoming">
                Kommande
              </v-btn>
              <v-btn small value="all">
                Alla
              </v-btn>
            </v-btn-toggle>
            <v-btn-toggle class="mb-2 ml-2" v-model="active">
              <v-btn small value="active">
                Aktiva
              </v-btn>

              <v-btn small value="inactive">
                Inaktiva
              </v-btn>

              <v-btn small value="all">
                Alla
              </v-btn>
            </v-btn-toggle>
            <v-btn-toggle class="mb-2 ml-2" multiple v-model="subscriptionType">
              <v-btn small :value="1">
                Personer
              </v-btn>

              <v-btn small :value="2">
                Företag
              </v-btn>
            </v-btn-toggle>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6">
            <v-text-field
              v-model="search"
              label="Sök"
              hint="Alla fält är sökbara, separera sökord med kommatecken"
              persistent-hint
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6">
            <v-autocomplete
              v-model="subscriptionTypeFilter"
              :disabled="loadingPersons || loadingSubscriptions"
              :items="subscriptionTypes"
              :return-object="true"
              chips
              color="blue-grey lighten-2"
              label="Filtrera"
              item-text="description"
              item-value="_id"
              multiple
            >
              <template v-slot:selection="{ item, attrs, selected, select }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="
                    subscriptionTypeFilter.splice(
                      subscriptionTypeFilter.findIndex(s => s._id === item._id),
                      1
                    )
                  "
                >
                  {{ item.article.name }}: {{ item.description }}
                </v-chip>
              </template>
              <template v-slot:item="{ item }">
                {{ item.article.name }}: {{ item.description }}
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-container>

      <v-data-footer
        :pagination="pagination"
        :items-per-page-options="footerProps.itemsPerPageOptions"
        :options="options"
        @update:options="updateOptions"
        :items-per-page-text="footerProps.itemsPerPageText"
      />
    </template>

    <!--template v-slot:item.misc="{ item }">
      {{item.misc.replace('', '<br />')}}
    </template-->

    <template v-slot:item.subscriptions="{ item }">
      <template v-for="sub in item.activeSubscriptions">
        <v-tooltip :key="sub._id" top>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-if="sub.priceObject && sub.priceObject.description"
              v-bind="attrs"
              v-on="on"
              class="ma-2"
            >
              <span v-if="sub.articleObject"
                >{{ sub.articleObject.name }}: </span
              >{{ sub.priceObject.description }}
            </v-chip>
          </template>
          {{ sub.subtitle }}
        </v-tooltip>
        <!--a :key="sub._id">{{sub._id}}</a>
        <span v-if="index" :key="sub._id">,</span-->
      </template>
    </template>

    <template v-slot:item.id="{ item }">
      <div
        :title="!item.active && 'Inaktiv medlem'"
        :style="{ opacity: !item.active ? 0.4 : 1 }"
      >
        {{ item.id }}
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import moment from 'moment'
import _ from 'lodash'
import { models } from 'feathers-vuex'
import PersonDialog from '@/components/PersonDialog'

export default {
  components: { PersonDialog },
  data: () => ({
    search: '',
    isMobile: false,
    active: 'active',
    subscriptionTypeFilter: [],
    subscriptionRange: 'all',
    subscriptionType: [1, 2],
    footerProps: {
      options: {
        itemsPerPage: 20,
        sortBy: ['firstname'],
        sortDesc: [false],
      },
      pagination: {
        rowsPerPage: 20,
        sortBy: 'lastname',
        ascending: true,
      },
      itemsPerPageOptions: [
        5,
        10,
        15,
        20,
        25,
        30,
        40,
        { text: 'Alla', value: -1 },
      ],
      itemsPerPageText: 'Personer per sida',
    },
    subscriptionsList: [],
    personsList: [],
    articles: [],
    prices: [],
    subscriptionsQuery: {
      query: {},
    },
    personsQuery: {
      query: {
        /* $select: ['id', 'active', 'type', 'lastname', 'firstname', 'postal_address', 'country'], */
        active: true,
        type: {
          $in: [1, 2],
        },
      },
      paginate: true,
    },
  }),
  async created() {
    // const today = moment().startOf('year');
    // "2018-12-05T00:00:00.00
    const tomorrow = moment().endOf('day')

    this.$store.commit('showMessage', {
      position: 'bottom',
      timeout: 2000,
      text: 'Laddar personer och prenumerationer från databasen',
      visible: true,
    })

    this.loading = true
    // await this.fetchPersons()

    // const personids = this.personsList.map(p => p._id)

    this.subscriptionsQuery.query = {
      endDate: { $gte: new Date(tomorrow) },
    }
    await this.fetchSubscriptions()

    this.loading = false
    /* const persons = this.persons
    await this.findInvoiceRows({
      payerId: { $in: persons.map(p => p.id) }
    }) */
  },
  methods: {
    ...mapActions('subscriptions', { subscriptionCount: 'count' }),
    ...mapActions('invoicerows', { findInvoiceRows: 'find' }),

    ...mapMutations('subscriptions', {
      clearSubscriptions: 'clearAll', // lets you do `this.clearTodos()` inside the component
    }),
    ...mapMutations('persons', {
      clearPersons: 'clearAll', // lets you do `this.clearTodos()` inside the component
    }),
    customSort(items, index, isDescending) {
      // The following is informations as far as I researched.
      // items: 'food' items
      // index: Enabled sort headers value. (black arrow status).
      // isDescending: Whether enabled sort headers is desc
      items.sort((a, b) => {
        //if (index === 'calories') {
        if (isDescending) {
          return b.lastname - a.lastname
        } else {
          return a.lastname - b.lastname
        }
        //}
      })

      return items
    },
    async fetchArticles() {
      const { Article } = models.api

      const data = await Article.find()

      this.articles = data /*.sort(
        (a, b) => new Date(b.pricePeriodStart) - new Date(a.pricePeriodStart)
      )*/
    },
    async fetchPrices() {
      const { Price } = models.api

      const data = await Price.find({
        query: {
          active: true,
        }
      })

      this.prices = data.sort(
        (a, b) => new Date(b.pricePeriodStart) - new Date(a.pricePeriodStart)
      )
    },
    async fetchSubscriptions() {
      await this.fetchArticles()
      await this.fetchPrices()
      await this.fetchPersons()

      const { Subscription } = models.api

      // this.subscriptionsQuery.query.$aggregate = {}

      const data = await Subscription.find(this.subscriptionsQuery)

      this.subscriptionsList = data //.filter(d => new Date(d.startDate) < new Date() && new Date(d.endDate) > new Date())
    },
    async fetchPersons() {
      this.personsList = []

      const { Person } = models.api

      const data = await Person.find(this.personsQuery)

      this.personsList = this.multiSortArray(data, {
        lastname: 'asc',
        firstname: 'asc',
      })
    },
    handleClick(row) {
      this.$store.state.dialogPersonOpen = true
      this.$store.state.dialogPerson = { ...row }
    },
    filterOnlyCapsText(value, search) {
      // console.log(search, value)
      // return search.toLowerCase().split(' ').map(s => s.indexOf((''+value).toLowerCase()) > -1).length
      return (
        value != null &&
        search != null &&
        typeof value === 'string' &&
        value
          .toString()
          .toLowerCase()
          .indexOf(search.toLowerCase()) !== -1
      )
    },
    filterOnlyCapsText1(value, search, item) {
      let found = Object.keys(item).filter(key => {
        if (item[key]) {
          let compare_value = item[key].toString()

          return search
            .toLowerCase()
            .split(' ')
            .filter(
              string =>
                compare_value
                  .toString()
                  .toLowerCase()
                  .indexOf(string) !== -1
            ).length
            ? true
            : false
        }
        return false
      })

      return (
        value != null &&
        search != null &&
        typeof value === 'string' &&
        found.length
        //
      )
    },
  },
  computed: {
    ...mapState('subscriptions', { loadingSubscriptions: 'isFindPending' }),
    ...mapState('persons', { loadingPersons: 'isFindPending' }),
    subscriptionTypes() {
      const prices = _.uniqBy(this.prices, v =>
        [v.description, v.articleId].join()
      )

      return this.articles
        .map(a => {
          return _.orderBy(
            prices.filter(price => price.articleId === a._id),
            'description'
          )
        })
        .flat()
        .map(price => {
          return {
            ...price,
            allids: this.prices.filter(
              p =>
                p.articleId === price.articleId &&
                p.description == price.description
            ),
            article: this.articles.find(a => a._id === price.articleId),
          }
        })
      /*
      return this.groupPriceByDate(
        this.prices.filter(price => price.articleId === articleId),
        'description',
        'pricePeriodStart'
      ) */
    },
    filteredPersons() {
      const search = this.search
        .toLowerCase()
        .split(',')
        .filter(o => o)

      const filterIds = this.subscriptionTypeFilter
        .map(f => f.allids)
        .flat()
        .map(o => o._id)

      if (!this.search && !filterIds.length) return this.persons

      let results = this.persons

      /*const prices = this.groupPriceByDate(this.prices.filter(p => p.description.toLowerCase().indexOf(this.search.toLowerCase()) > -1), 'description', 'pricePeriodStart')
      const priceIds = prices.map(p => p._id)

      //console.log(search, prices)


      results = results.filter(o => {
        const find = o.subscriptions.filter(s => {
          //console.log(s.articleId)
          priceIds.includes(s.articleId)
        })
        //console.log(priceIds, find)
        return find.length > 0

      })*/

      //console.log(search)

      if (filterIds.length) {
        results = results.filter(o => {
          let inSubscriptions = false

          if (filterIds.length && o.subscriptions && o.subscriptions.length) {
            o.subscriptions
              .filter(s => moment.utc(s.endDate).valueOf() > moment().valueOf())
              .forEach(sub => {
                if (filterIds.includes(sub.articleId)) {
                  inSubscriptions = true
                }
              })

            return inSubscriptions
          }
        })
      }

      results = results.filter(o => {
        let count = []

        Object.keys(o).forEach(key => {
          if (typeof o[key] === 'string' || typeof o[key] === 'number') {
            const string = '' + o[key]
            const index = search.findIndex(
              s =>
                string
                  .trim()
                  .toLowerCase()
                  .indexOf(s) > -1
            )

            if (index > -1 && !count.includes(index)) {
              count.push(index)
            }
          }
        })

        return count.length === search.length
      })

      return results
    },
    persons() {
      //const groupBy = this.groupBy(this.subscriptionsList, 'personId')
      //console.log(groupBy)

      /*const groupBy = (arr, k, fn = () => true) => {
        return arr.reduce((r, c) => (fn(c[k]) ? r[c[k]] = [...r[c[k]] || [], c] : null, r), {});
      }*/

      // let mapIds = this.subscriptionsList.reduce((acc, item) => acc.set(item.personId, item), new Map());

      //const filterByDate = this.groupPriceByDate(this.subscriptionsList, 'personId', 'startDate')
      // console.log('mapIds', mapIds)

      const prices = this.groupPriceByDate(
        this.prices,
        'description',
        'pricePeriodStart'
      )

      const _personsList = _.cloneDeep(this.personsList)

      let personsList = this.personsList.map(person => {
        const subscription = this.subscriptionsList.find(
          filter => filter.personId === person._id
        )
        const subscriptions = this.subscriptionsList
          .filter(
            filter =>
              filter.personId === person._id || filter.payerId === person._id
          )
          .map(s => {
            const price = prices.find(p => p._id === s.articleId)
            if (price) s.priceObject = price

            return s
          })

        const activeSubscriptions = this.subscriptionsList
          .filter(
            filter =>
              filter.personId === person._id ||
              (filter.payerId === person._id &&
                moment.utc(filter.endDate).valueOf() > moment().valueOf() &&
                moment.utc(filter.startDate).valueOf() < moment().valueOf())
          )
          .map(s => {
            const price = prices.find(p => p._id === s.articleId)
            if (price) s.priceObject = price

            const payer = _personsList.find(person => person._id === s.payerId)
            let personObject = null,
              subtitle = '',
              color = ''

            // console.log(sub.payerId, sub.personId, article.description, personObject)
            //Om jag betalar åt någon annan
            if (person._id !== s.personId && s.personId !== s.payerId) {
              personObject = _personsList.find(
                person => person._id === s.personId
              )
              color = 'elm-red'
              if (personObject)
                subtitle = `GÅVA: #${personObject.id} ${personObject.firstname} ${personObject.lastname}, ${personObject.postal_address}`
            }
            //Om någon betalar åt mig
            else if (person._id !== s.payerId && s.personId !== s.payerId) {
              color = 'elm-green'
              if (payer)
                subtitle = `ANNAN BET: #${payer.id} ${payer.firstname} ${payer.lastname}, ${payer.postal_address}`
            }
            //Om jag betalar åt mig själv
            else {
              color = 'elm-orange'
              subtitle = `${person.firstname} ${person.lastname}, ${person.postal_address}`
            }

            //s.payer = payer
            //s.person = personObject
            //console.log(subtitle)
            s.color = color
            s.subtitle = subtitle

            return s
          })
          .map(s => {
            if (s.priceObject) {
              const article = this.articles.find(
                a => a._id === s.priceObject.articleId
              )
              if (article) s.articleObject = article
            }

            return s
          })

        //console.log(subscription)
        return {
          ...person,
          subscription,
          subscriptions,
          activeSubscriptions,
        }
      })

      if (this.active === 'active')
        personsList = personsList.filter(person => {
          return person.active
        })

      if (this.active === 'inactive')
        personsList = personsList.filter(person => {
          return !person.active
        })

      //const subs = this.subscriptionsList
      /* if(this.subscriptionRange === 'current') {
        personsList = personsList.filter(person => {
          return true
        });

      }
      if(this.subscriptionRange === 'upcoming') {

      }
      if(this.subscriptionRange === 'all') {

      } */

      return personsList
    },
    headers() {
      return [
        {
          text: 'Medlem',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Efternamn/Företag', value: 'lastname' },
        { text: 'Förnamn/Referens', value: 'firstname' },
        { text: 'Ort', value: 'postal_address' },
        { text: 'Land', value: 'country' },
        { text: 'Övrigt', value: 'misc' },
        /*{
          text: 'Prenumerationer',
          name: 'subscriptions',
          value: 'subscriptions',
        },*/
      ]
    },
  },
  watch: {
    async active(active) {
      if (active === 'all') delete this.personsQuery.query.active
      else this.personsQuery.query.active = active === 'active' ? true : false

      this.loading = true
      await this.fetchPersons()
      this.loading = false
    },

    async subscriptionRange(range) {
      // this.personsQuery.query.active = type

      this.loading = true

      if (range === 'current')
        this.subscriptionsQuery.query = {
          endDate: { $gte: new Date(moment().startOf('day')) },
        }

      if (range === 'upcoming')
        this.subscriptionsQuery.query = {
          startDate: { $gte: new Date(moment().endOf('day')) },
        }

      if (range === 'all') this.subscriptionsQuery.query = {}

      await this.fetchSubscriptions()

      if (range === 'all') {
        delete this.personsQuery.query._id
      } else {
        const subscriberIds = [
          ...new Set(
            this.subscriptionsList
              .map(sub => [sub.payerId, sub.personId])
              .flat()
          ),
        ]

        this.personsQuery.query = {
          ...this.personsQuery.query,
          _id: {
            $in: subscriberIds,
          },
        }
      }

      await this.fetchPersons()

      this.loading = false
      // this.findPersons(this.personsQuery);
    },
    async subscriptionType(type) {
      this.loading = true
      this.personsQuery.query.type = {
        $in: type,
      }
      await this.fetchPersons()
      this.loading = false
    },
  },
}
</script>

<style lang="scss">
.v-list-item-group .v-list-item--active {
  border: 2px solid blue;
}
</style>
