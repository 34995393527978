<template>
  <v-app-bar app color="primary" dark>
    <v-app-bar-nav-icon @click="$emit('drawer', !drawer)"></v-app-bar-nav-icon>
    <div class="d-flex align-center">
      <v-img
        alt=""
        class="shrink mr-2"
        contain
        src="@/assets/images/TillLivloggaVit.svg"
        transition="scale-transition"
        width="100"
      />

      <span class="white--text">Prenumeration</span>
    </div>

    <v-spacer></v-spacer>

    <v-menu bottom left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn dark icon v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          @click="() => item.onClick && item.onClick()"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
export default {
  props: ['drawer'],
  data(self) {
    return {
      items: [
        {
          title: 'Logga ut',
          onClick: () => {
            self.$store.dispatch('auth/logout')
          },
        },
      ],
    }
  },
}
</script>

<style scoped></style>
