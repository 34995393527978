import Vue from 'vue'
import VueRouter from 'vue-router'
import Subscriptions from '../views/Subscriptions.vue'
import Invoices from '../views/Invoices.vue'
import LocalInvoices from '../views/invoices/LocalInvoices.vue'
import RenewSubscriptions from '../views/invoices/RenewSubscriptions.vue'
import CreateAddressFile from '../views/invoices/CreateAddressFile.vue'
import CreateInvoices from '../views/invoices/CreateInvoices.vue'
import SendInvoices from '../views/invoices/SendInvoices.vue'
import Login from '../views/Login'
import FixData from '../views/FixData'
import Users from '../views/Users'
import Articles from '../views/Articles'
import Statistics from '../views/Statistics'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Subscriptions',
    component: Subscriptions,
  },
  {
    path: '/fix',
    name: 'FixData',
    component: FixData,
  },
  {
    path: '/invoices',
    name: 'Invoices',
    component: Invoices,
  },
  {
    path: '/stats',
    name: 'Statistics',
    component: Statistics,
  },
  {
    path: '/local_invoices',
    name: 'LocalInvoices',
    component: LocalInvoices,
  },
  {
    path: '/renew_subscriptions',
    name: 'RenewSubscriptions',
    component: RenewSubscriptions,
  },
  {
    path: '/send_invoices',
    name: 'SendInvoices',
    component: SendInvoices,
  },
  {
    path: '/create_invoices',
    name: 'CreateInvoices',
    component: CreateInvoices,
  },
  {
    path: '/create_address_file',
    name: 'CreateAddressFile',
    component: CreateAddressFile,
  },
  {
    path: '/articles',
    name: 'Articles',
    component: Articles,
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
