<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="4" sm="12">
        <v-card flat>
          <v-card-title>Fakturor</v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-btn-toggle
          class="mb-2 ml-2 colum"
          :class="{ 'flex-wrap': $vuetify.breakpoint.mdAndDown }"
          :style="{ gap: '15px' }"
          v-model="invoiceStatus"
        >
          <v-badge
            bordered
            color="primary"
            :content="invoiceCount.all"
            overlap
            :value="invoiceCount.all"
          >
            <v-btn small value="all" :disabled="loading">
              Alla
            </v-btn>
          </v-badge>

          <v-badge
            bordered
            color="primary"
            :content="invoiceCount.nonAttested"
            overlap
            :value="invoiceCount.nonAttested"
          >
            <v-btn small value="non-attested" :disabled="loading">
              Ej attesterade
            </v-btn>
          </v-badge>

          <v-badge
            bordered
            color="primary"
            :content="invoiceCount.attested"
            overlap
            :value="invoiceCount.attested"
          >
            <v-btn small value="attested" :disabled="loading">
              Attesterade
            </v-btn>
          </v-badge>

          <v-badge
            bordered
            color="warning"
            :content="invoiceCount.nonPayed"
            overlap
            :value="invoiceCount.nonPayed"
          >
            <v-btn small value="non-payed" :disabled="loading">
              Obetalda
            </v-btn>
          </v-badge>
          <v-badge
            bordered
            color="green"
            :content="invoiceCount.payed"
            overlap
            :value="invoiceCount.payed"
          >
            <v-btn small value="payed" :disabled="loading">
              Betalda
            </v-btn>
          </v-badge>
          <v-badge
            bordered
            color="error"
            :content="invoiceCount.overdued"
            overlap
            :value="invoiceCount.overdued"
          >
            <v-btn small value="overdued" :disabled="loading">
              Förfallna
            </v-btn>
          </v-badge>
          <v-badge
            bordered
            color="black"
            :content="invoiceCount.canceled"
            overlap
            :value="invoiceCount.canceled"
          >
            <v-btn small value="canceled" :disabled="loading">
              Makulerade
            </v-btn>
          </v-badge>
        </v-btn-toggle>
      </v-col>

      <v-col cols="12" sm="4">
        <DatePickerMenu
          class="ma-4"
          :full-width="false"
          v-model="startDate"
          datePickerType="date"
          label="Från"
          :max="endDate.substring(0, 10)"
          returnFormat="YYYY-MM-DDTHH:mm:ss.SSSZ"
          displayFormat="YYYY-MM-DD"
        />
      </v-col>
      <v-col cols="12" sm="4">
        <DatePickerMenu
          class="ma-4"
          :full-width="false"
          datePickerType="date"
          v-model="endDate"
          label="Till"
          hint=""
          :min="startDate.substring(0, 10)"
          returnFormat="YYYY-MM-DDTHH:mm:ss.SSSZ"
          displayFormat="YYYY-MM-DD"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="12">
        <v-text-field
          label="Filtrera fakturor"
          type="search"
          clearable
          v-model="invoiceFilter"
          hint="Alla fält är sökbara"
          persistent-hint
          :disabled="loading"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="loading">
      <v-col cols="12">
        <v-progress-linear indeterminate color="cyan"></v-progress-linear>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" lg="4" xs="12">
        <v-data-table
          v-model="selectedInvoices"
          :headers="headers"
          :items="filteredInvoices"
          :items-per-page="-1"
          :hide-default-header="false"
          :hide-default-footer="true"
          item-key="ActionPublicId"
          show-select
          disable-sort
          sort-by="InvoiceNumber"
          sort-desc
          class="elevation-0"
          :single-select="singleSelect"
        >
          <template v-slot:top>
            <v-container class="ma-0 pa-0">
              <v-row>
                <v-col cols="12" md="6" sm="12" class="ma-0 pa-0">
                  <v-switch
                    v-model="singleSelect"
                    :label="singleSelect ? 'Välj en i taget' : 'Välj flera'"
                    class="pa-3"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="6" sm="12" class="ma-0 pa-0 text-right">
                  <confirm-button
                    v-if="!singleSelect && selectedInvoices.length > 1"
                    color="orange lighten-1"
                    :small="true"
                    :plain="false"
                    :outlined="true"
                    :messages="['Attestera alla', 'Säker?', '✔']"
                    @confirmation-success="attestAllInvoices"
                  >Attestera alla
                  </confirm-button
                  >
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:item="{ item, isSelected, select }">
            <tr
              :class="isSelected ? 'blue lighten-5' : ''"
              @mouseenter="tableIsHovered = true"
              @mouseleave="tableIsHovered = false"
              @click="toggle(isSelected, select, $event)"
            >
              <td class="text-start pa-3">
                <v-avatar
                  size="36"
                  :color="invoiceStatusColor(item)"
                  :title="invoiceStatusTextual(item)"
                >
                  <strong class="initials white--text">
                    {{ invoiceStatusText(item) }}
                  </strong>
                </v-avatar>
              </td>
              <td class="text-start pa-3">
                <h4>{{ `Faktura #${item.InvoiceNumber}` }}</h4>
                <h5 class="body-2">{{ item.DebtorName }}</h5>
                <v-banner class="body-2  text--accent-3" v-if="item.NextEvent">
                  {{ item.NextEvent }}
                  {{
                    item.NextEventDate
                      ? '(' +
                      formatDate(item.NextEventDate, 'Y-MM-DD', false) +
                      ')'
                      : ''
                  }}
                </v-banner>
              </td>

              <td class="text-end pa-3">
                <h5 class="caption whitespace-pre">
                  {{ formatDate(item.InvoiceDate, 'Y-MM-DD', false) }}
                </h5>
                <h5 class="caption whitespace-pre">
                  {{ formatDate(item.DueDate, 'Y-MM-DD', false) }}
                </h5>
                <h5 class="whitespace-pre mt-1">{{ getDeliveryMethodType(item.DeliveryMethod) }}</h5>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>

      <v-col
        v-if="currentInvoice && $vuetify.breakpoint.smAndUp"
        cols="12"
        sm="12"
        md="6"
      >
        <div class="d-flex align-center flex-wrap">
          <v-btn
            class="mr-2"
            small
            @click="$refs[`pdf_component_0`].$refs.pdf.print()"
          >Skriv ut
          </v-btn
          >

          <v-btn
            v-if="0 && !currentInvoice.payDate"
            class="mr-2"
            small
            @click="payInvoice"
          >Markera som betald
          </v-btn
          >

          <v-dialog
            v-model="dialogRegisterPayment"
            persistent
            max-width="900px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                small
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                v-if="isInvoiceAttested(currentInvoice)"
                :disabled="
                  isInvoiceAttested(currentInvoice) &&
                    !isInvoicePayed(currentInvoice)
                "
                @click="initPayment"
              >
                Inbetalning
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">Registrera betalning</span>
              </v-card-title>

              <v-card-text v-if="currentPayment && currentInvoice">
                <v-form>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          label="Belopp (inkl. moms)"
                          type="number"
                          :min="0"
                          :max="currentInvoice.CurrentAmount.ValueForView"
                          v-model.number="currentPayment.amount"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          label="Valuta"
                          :items="[{ text: 'SEK', value: 'SEK' }]"
                          v-model="currentPayment.currencyCode"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          label="Återstående belopp (inkl. moms)"
                          :value="currentInvoiceAmountDifference"
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <DatePickerMenu
                          class="ma-4"
                          :full-width="false"
                          v-model="currentPayment.date"
                          datePickerType="date"
                          label="Betaldatum"
                          returnFormat="YYYY-MM-DDTHH:mm:ss.SSSZ"
                          displayFormat="YYYY-MM-DD"
                          :max="formatDate('today')"
                        />
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          label="Betalsätt"
                          :items="paymentOptions"
                          v-model="currentPayment.paymentMeanCode"
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="Referens"
                          type="text"
                          hint="Betalninsreferensen kan vara referensen från inbetalning i banken. Informationen skrivs i bokföringen för spårbarhet"
                          persistent-hint
                          v-model="currentPayment.reference"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                          label="Kommentar"
                          type="text"
                          v-model="currentPayment.comment"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeRegisterPayment">
                  Avbryt
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  primary
                  text
                  @click="registerPayment"
                >
                  Registera betalning
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
            v-model="dialogAttestInvoice"
            persistent
            max-width="400px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="orange lighten-"
                small
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                :disabled="
                  isInvoiceAttested(currentInvoice)
                "
              >
                Attestera
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">Attestera faktura</span>
              </v-card-title>

              <v-card-text v-if="currentInvoice">
                <v-form>
                  <v-container>
                    <v-select
                      label="Leveransmetod"
                      hint="Leveransmetoden sparas automatiskt när du ändrar här ovasett om du väljer att attestera eller inte"
                      persistent-hint
                      v-model="currentInvoice.DeliveryMethod"
                      @change="changeDeliveryMethod"
                      return-object
                      :items="deliveryMethodTypes"/>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialogAttestInvoice = false">
                  Avbryt
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  primary
                  text
                  @click="attestInvoice"
                >
                  Attestera
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!--confirm-button
            :disabled="isInvoiceAttested(currentInvoice)"
            color="orange lighten-1"
            :small="true"
            :plain="false"
            :messages="['Attestera', 'Säker?', '✔']"
            @confirmation-success="attestInvoice(currentInvoice)"
            >Attestera</confirm-button
          -->

          <confirm-button
            v-if="!currentInvoice.canceled"
            color="orange lighten-1"
            :small="true"
            :plain="true"
            :messages="['Makulera', 'Säker?', '✔']"
            :disabled="isInvoiceAttested(currentInvoice)"
            @confirmation-success="cancelInvoice"
          >Makulera
          </confirm-button
          >
        </div>

        <div style="max-width: 400px;">
          <v-card class="mt-4" v-if="loadingBillectaInvoice" flat>
            <v-skeleton-loader
              class="mx-auto"
              max-width="300"
              height="100"
              elevation="0"
              type="list-item"
            ></v-skeleton-loader>
          </v-card>

          <v-card
            class="mt-4"
            flat
            v-else-if="
              currentBillectaInvoice &&
                currentInvoice &&
                isInvoiceAttested(currentInvoice)
            "
          >
            <v-card-title>
              Att betala:
              {{
                currentBillectaInvoice.InvoicedAmount.ValueForView -
                currentBillectaInvoice.PaidAmount.ValueForView
              }}
              {{ currentBillectaInvoice.InvoicedAmount.CurrencyCode }}
            </v-card-title>
            <v-card-subtitle>
              Inbetald summa:
              {{ currentBillectaInvoice.PaidAmount.ValueForView }}
              {{ currentBillectaInvoice.PaidAmount.CurrencyCode }}<br/>
              Totalsumma:
              {{ currentBillectaInvoice.InvoicedAmount.ValueForView }}
              {{ currentBillectaInvoice.InvoicedAmount.CurrencyCode }}
            </v-card-subtitle>
          </v-card>

          <v-card class="mt-4">
            <v-skeleton-loader
              v-if="loadingPDF"
              class="mx-auto"
              max-width="300"
              height="300"
              elevation="0"
              type="article"
            ></v-skeleton-loader>
            <PDFViewer
              v-else-if="pdf && pdf[0]"
              ref="pdf_component_0"
              :src="pdf[0]"
            />
          </v-card>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="0">
      <v-col cols="12" md="6" xs="12">
        <v-list dense :elevation="0" text>
          <v-subheader>FAKTUROR</v-subheader>
          <v-list-item-group color="primary" v-model="selectedInvoices">
            <v-list-item v-for="(item, i) in invoices" :key="i">
              <v-list-item-avatar
                :color="item.color"
                :title="invoiceStatusTextual(item)"
              >
                <strong class="initials white--text">
                  {{ invoiceStatusText(item) }}
                </strong>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{
                    `Faktura #${item.id}`
                  }}
                </v-list-item-title>
                <v-list-item-subtitle
                  v-text="item.person"
                ></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text>
                  <div>
                    {{ item.dispatchDate }}
                  </div>
                  <div>
                    {{ item.dueDate }}
                  </div>
                </v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-col
        v-if="currentInvoice && $vuetify.breakpoint.smAndUp"
        cols="6"
        xs="12"
      >
        <v-btn class="mr-2" small @click="print">Skriv ut</v-btn>

        <v-btn
          class="mr-2"
          small
          v-if="!currentInvoice.payDate"
          @click="payInvoice"
        >Markera som betald
        </v-btn
        >

        <confirm-button
          v-if="!currentInvoice.canceled"
          color="orange lighten-1"
          :small="true"
          :plain="true"
          :messages="['Makulera', 'Säker?', '✔']"
          @confirmation-success="cancelInvoice"
        >Makulera
        </confirm-button
        >

        <div style="max-width: 400px;">
          <v-card class="mt-4">
            <InvoiceSVG
              v-if="currentInvoice"
              :scale="1"
              :invoice="currentInvoice"
            />
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import _ from 'lodash'

import InvoiceSVG from '@/components/invoice/InvoiceSVG'
import {models} from 'feathers-vuex'
import ConfirmButton from '@/components/ConfirmButton'
import DatePickerMenu from '@/components/DatePickerMenu'
import PDFViewer from '@/components/PDFViewer'

//import pdf from 'vue-pdf'
import moment from 'moment'

export default {
  name: 'InvoicesBilleca',
  components: {ConfirmButton, InvoiceSVG, PDFViewer, DatePickerMenu},
  data: () => ({
    startDate: '',
    endDate: '',
    search: '',
    tableIsHovered: false,
    isMobile: false,
    loading: false,
    loadingPDF: false,
    loadingBillectaInvoice: false,
    active: true,
    dialogAttestInvoice: false,
    dialogRegisterPayment: false,
    currentPayment: null,
    currentBillectaInvoice: null,
    invoiceFilter: '',
    invoiceDate: '',
    invoiceId: '',
    invoiceStatus: '',
    invoicesList: [],
    persons: [],
    toggleAllCheckedInvoices: false,
    singleSelect: true,
    selectedInvoices: [],
    headers: [
      {text: 'Fakturor', value: 'name'},
      {text: '', value: 'actions'},
    ],

    invoicesQuery: {
      query: {},
      $sort: {_id: -1},
      paginate: false,
    },
    statusText: ['Obetald', 'Betald', 'Förfallen', 'Makulerad'],
  }),
  async created() {
    // const today = moment().startOf('year');
    // "2018-12-05T00:00:00.00

    /* const persons = this.invoices
    await this.findInvoiceRows({
      payerId: { $in: persons.map(p => p._id) }
    }) */
    this.startDate = moment()
      .add(-1, 'month')
      .toISOString()

    this.endDate = moment()
      .add(2, 'month')
      .endOf('month')
      .toISOString()

    this.$store.commit('showMessage', {
      position: 'bottom',
      timeout: 5000,
      text: 'Laddar fakturadata från databasen',
      visible: true,
    })

    this.currentInvoice = null

    const {Person} = this.$FeathersVuex.api

    this.persons = await Person.find({
      query: {},
    })

    //await this.fetchInvoices()
  },
  beforeDestroy() {
    this.currentInvoice = null
  },
  methods: {
    ...mapActions('invoices', {invoicesCount: 'count'}),
    // ...mapActions('invoices', { findInvoices: 'find' }),
    ...mapActions('invoicerows', {findInvoiceRows: 'find'}),
    ...mapActions('persons', {findPersons: 'find'}),
    ...mapMutations('persons', {
      clearPersons: 'clearAll', // lets you do `this.clearTodos()` inside the component
    }),
    getDeliveryMethodType(method) {
      const types = this.deliveryMethodTypes

      const type = types.find(o => o.value == method)

      //console.log(method, types, type)

      if(type)
        return type.text

      return ''
    },
    enterSelect(values) {
      const names = values.map(function (value) {
        return value
      })
      console.log(names)
    },
    toggle(isSelected, select) {
      select(!isSelected)
    },
    toggleCheckedInvoices() {
      if (this.toggleAllCheckedInvoices) {
        this.selectedInvoices = []
        const a = []
        for (let [index] of this.invoices.entries()) {
          console.log(index)
          a.push(index)
        }
        this.selectedInvoices = a
      } else this.selectedInvoices = []
    },
    closeRegisterPayment() {
      this.dialogRegisterPayment = false

      setTimeout(() => {
        this.currentPayment = null
      }, 500)
    },
    initPayment() {
      const {ActionPublicId} = this.currentInvoice
      this.currentPayment = {
        ActionPublicId,
        currencyCode: this.currentInvoice.CurrentAmount.CurrencyCode,
        amount: this.currentInvoice.CurrentAmount.ValueForView,
        date: this.formatDate('today'),
        paymentMeanCode: 'BG',
        reference: '',
        comment: '',
      }
    },
    async registerPayment() {
      const RegisterPaymentView = {
        ActionPublicId: this.currentInvoice.ActionPublicId,
        Amount: {
          CurrencyCode: this.currentPayment.currencyCode,
          Value: this.currentPayment.amount * 100,
          ValueForView: this.currentPayment.amount,
        },
        WriteOff: null,
        Comment: this.currentPayment.comment || null,
        Date: this.currentPayment.date,
        WriteOffVat: 0,
        OverrideWriteOffAccount: null,
        PaymentMeanCode: this.currentPayment.paymentMeanCode,
        OvershootingAmountHandling: 0,
        PaymentReferenceText: this.currentPayment.comment || null,
      }

      await this.$store.dispatch(
        'billecta_invoice/registerPayment',
        RegisterPaymentView
      )

      this.$store.commit('showMessage', {
        position: 'bottom',
        timeout: 2000,
        text: `Inbetalningen på faktura ${this.currentInvoice.InvoiceNumber} är registerad`,
        visible: true,
      })

      await this.payLocalInvoice()

      this.dialogRegisterPayment = false

      await this.fetchInvoices()

      this.currentInvoice = this.invoices.find(
        i => i.ActionPublicId === this.currentInvoice.ActionPublicId
      )

      await this.fetchBillectaInvoice()
    },
    async payLocalInvoice() {
      const {Invoice} = this.$FeathersVuex.api

      const localInvoice = await Invoice.find({
        query: {
          billecta_id: this.currentInvoice.ActionPublicId,
        },
      })

      const payment = new models.api.Payment({
        invoiceId: localInvoice._id,
        amount: this.currentInvoice.amount,
        personId: localInvoice.payerId,
      })
      await payment.save()

      const invoice = new models.api.Invoice({
        _id: this.currentInvoice._id,
        payDate: new Date(),
      })
      await invoice.save()
    },
    async fetchBillectaInvoice() {
      if (!this.currentInvoice) return

      try {
        const invoice = await this.$store.dispatch(
          'billecta_invoice/getInvoice',
          this.currentInvoice.ActionPublicId
        )

        this.currentBillectaInvoice = invoice
      } catch (error) {
        this.showErrorMessage(error)
      }
    },
    isInvoiceAttested(invoice) {
      if (!invoice) return true

      return invoice.AttestedDate !== null
    },
    isInvoicePayed(invoice) {
      if (!invoice) return true

      return invoice.CurrentAmount.Value !== 0
    },
    isInvoiceOverdued(invoice) {
      if (!invoice) return true

      return (
        invoice.CurrentAmount.Value !== 0 &&
        moment(invoice.DueDate).isBefore(moment(), 'day')
      )
    },
    invoiceStatusText(item) {
      if (
        item.AttestedDate &&
        moment(item.DueDate).isBefore(moment(), 'day') &&
        item.Stage !== 'Completed'
      )
        return 'F'

      if (item.AttestedDate && item.ClosedDate) return 'B'

      if (item.AttestedDate && !item.ClosedDate) return 'EB'

      if (item.AttestedDate) return 'A'

      if (!item.AttestedDate) return 'EA'

      return ''
    },
    invoiceStatusColor(item) {
      if (
        item.AttestedDate &&
        moment(item.DueDate).isBefore(moment(), 'day') &&
        item.Stage !== 'Completed'
      )
        return 'red'

      if (item.AttestedDate && item.ClosedDate) return 'green'

      if (item.AttestedDate && !item.ClosedDate) return 'warning'

      if (item.AttestedDate) return 'primary'

      if (!item.AttestedDate) return 'secondary'

      return ''
    },
    invoiceStatusTextual() {
      return ''
      /* const { nonPayed, payed, overdued, canceled } = this.invoiceStatuses
      if (canceled.find(o => o === item._id)) return 'Makulerad'
      if (overdued.find(o => o === item._id)) return 'Förfallen'
      if (nonPayed.find(o => o === item._id)) return 'Obetald'
      if (payed.find(o => o === item._id)) return 'Betald' */
    },
    print() {
      window.print()
    },
    async fetchInvoiceById() {
      this.selectedInvoices = []
      this.currentInvoice = null
      this.$store.state.printInvoices = []

      this.invoicesQuery = {
        query: {
          id: this.invoiceId,
        },
      }

      this.loading = true

      await this.fetchInvoices()

      this.filterInvoices()

      //this.selectedInvoices = [0]
    },
    async payInvoice() {
      const payment = new models.api.Payment({
        invoiceId: this.currentInvoice._id,
        amount: this.currentInvoice.amount,
        personId: this.currentInvoice.payerId,
      })
      await payment.save()

      const invoice = new models.api.Invoice({
        _id: this.currentInvoice._id,
        payDate: new Date(),
      })
      await invoice.save()

      this.$store.commit('showMessage', {
        position: 'bottom',
        timeout: 2000,
        text: `Faktura ${this.currentInvoice.id} markerad som betald`,
        visible: true,
      })

      this.filterInvoices()
      this.currentInvoice = this.invoices[this.selectedInvoices[0]]
    },
    async attestAllInvoices() {
      const invoices = this.selectedInvoices

      for await (const [i, invoiceObject] of invoices.entries()) {
        this.$store.commit('showMessage', {
          position: 'bottom',
          timeout: 2000,
          text: `Attesterar faktura #${invoiceObject.InvoiceNumber} (${i + 1}/${
            invoices.length
          })`,
          visible: true,
        })

        await this.attestInvoice(invoiceObject, false)
      }

      await this.fetchInvoices()

      this.$store.commit('showMessage', {
        position: 'bottom',
        timeout: 5000,
        text: `Attesteringen är klar`,
        visible: true,
      })

      this.currentInvoice = null
      this.selectedInvoices = []
    },
    async changeDeliveryMethod(data) {
      try {
        let InvoiceActionEntryView = _.cloneDeep(
          this.currentBillectaInvoice
        )

        InvoiceActionEntryView.DeliveryMethod = this.deliveryMethodTypes.findIndex(o => o.value === data.value)
        if(!InvoiceActionEntryView.DebtorPublicId && InvoiceActionEntryView.Debtor) {
          InvoiceActionEntryView.DebtorPublicId = InvoiceActionEntryView.Debtor.DebtorPublicId
          delete InvoiceActionEntryView.Debtor
        }

        const deliveryMethodType = this.deliveryMethodTypes.find(o => o.value === data.value)

        const reponse = await this.$store.dispatch(
          'billecta_invoice/updateInvoice',
          InvoiceActionEntryView
        )

        try {
          await this.$store.dispatch('billecta_invoice/getAllOpenInvoices', {
            id: this.mainCreditorPublicId,
            from: this.startDate.substring(0, 10),
            to: this.endDate.substring(0, 10),
            sortingfield: 'InvoiceNumber',
            asc: true,
          })
        } catch (error) {
          this.showErrorMessage(error)
        }

        if(!reponse.Message) {
          this.$store.commit('showMessage', {
            position: 'bottom',
            timeout: 2000,
            text: `Faktura ${this.currentInvoice.InvoiceNumber} skickas med ${deliveryMethodType.text}`,
            visible: true,
          })
        }
        else {
          this.showMessage(reponse, {
            timeout: 6000,
            color: 'error',
          })
        }


      } catch (error) {
        this.showErrorMessage(error, {
          timeout: 6000,
        })
      }
    },
    async attestInvoice(invoice = null, fetchInvoices = true) {
      invoice = this.currentBillectaInvoice
      try {
        const {ActionPublicId} = invoice
        await this.$store.dispatch(
          'billecta_invoice/attestInvoice',
          ActionPublicId
        )

        if (fetchInvoices) {
          await this.fetchInvoices()

          this.currentInvoice = this.invoices.filter(
            i => i.ActionPublicId === ActionPublicId
          )
        }
        this.dialogAttestInvoice = false
      } catch (error) {
        this.showErrorMessage(error, {
          timeout: 6000,
        })
      }
    },
    async cancelInvoice() {
      const InvoiceNumber = parseInt(this.currentBillectaInvoice.InvoiceNumber)
      const _invoice = this.invoicesList.find(i => i.id == InvoiceNumber)

      const invoice = new models.api.Invoice({
        _id: _invoice._id,
        canceled: new Date().toISOString(),
        billecta_id: '',
      })
      await invoice.save()

      const billectaInvoice = this.currentBillectaInvoice

      await this.$store.dispatch('billecta_invoice/deleteInvoice', billectaInvoice)

      this.$store.commit('showMessage', {
        position: 'bottom',
        timeout: 2000,
        text: `Faktura ${this.currentBillectaInvoice.InvoiceNumber} är nu makulerad`,
        visible: true,
      })

      this.currentBillectaInvoice = null

      this.fetchInvoices()

    },
    download() {
      this.$refs.html2Pdf.generatePdf()
    },
    async fetchInvoices() {
      this.$store.commit('showMessage', {
        position: 'bottom',
        timeout: 2000,
        text: `Hämtar fakturadata`,
        visible: true,
      })

      try {
        await this.$store.dispatch('billecta_invoice/getAllClosedInvoices', {
          id: this.mainCreditorPublicId,
          from: this.startDate.substring(0, 10),
          to: this.endDate.substring(0, 10),
          sortingfield: 'InvoiceNumber',
          asc: true,
        })
      } catch (error) {
        this.showErrorMessage(error)
      }
      //}
      //else {
      try {
        await this.$store.dispatch('billecta_invoice/getAllOpenInvoices', {
          id: this.mainCreditorPublicId,
          from: this.startDate.substring(0, 10),
          to: this.endDate.substring(0, 10),
          sortingfield: 'InvoiceNumber',
          asc: true,
        })
      } catch (error) {
        this.showErrorMessage(error)
      }

      const { Invoice } = this.$FeathersVuex.api

      const query = {
        query: {
          id: {
            $in: this.billectaInvoices
              .map(sub => parseInt(sub.InvoiceNumber))
          }
        }
      }

      const i = await Invoice.find(query)

      this.invoicesList = i
      this.invoicesList = _.orderBy(this.invoicesList, ['_id'], ['desc'])
      //}
    },
    filterInvoices() {
      //const status = this.invoiceStatus
      //const date = this.invoiceDate

      let items = []

      if (this.invoiceId) {
        items = this.invoicesList.filter(
          invoice => invoice.id === this.invoiceId
        )
        if (!items.length) {
          this.$store.commit('showMessage', {
            position: 'bottom',
            timeout: 2000,
            text: `Faktura #${this.invoiceId} finns inte`,
            visible: true,
          })
        }
      } else {
        items = this.invoicesList
        /* items = this.invoicesByDateArray
          .find(i => i.key === date)
          .items.filter(item => {
            if (status === 'all' && item) return true
            if (status === 'non-payed' && item)
              return !item.canceled && !item.payDate
            if (status === 'payed' && item)
              return !item.canceled && item.payDate
            if (status === 'overdued' && item)
              return new Date(item.dueDate) < new Date() && !item.payDate
            if (status === 'canceled' && item) return item.canceled
          }) */
      }

      /*this.invoices = items.map(item => {
        let person, payer;
        person = this.personsList.find(p => p._id === item.payerId)
        if(person) {
          payer = {...person}
          if (person.type === 1)
            person = `${person.id} ${person.lastname} ${person.firstname}, ${person.postal_address}`
          if (person.type === 2)
            person = `${person.id} ${person.lastname}, ${person.postal_address}`
        }

        let color = ''

        const nonPayed = !item.payDate && !item.canceled
        const payed = item.payDate && !item.canceled
        const overdued =
          new Date(item.dueDate) < new Date() && !item.payDate && !item.canceled
        const canceled = item.canceled

        if (overdued) {
          color = 'error'
        } else if (canceled) {
          color = 'black'
        } else if (nonPayed) {
          color = 'warning'
        } else if (payed) {
          color = 'green'
        } else color = ''

        return {
          ...item,
          person,
          payer,
          dispatchDate: item.dispatchDate.substr(0, 10),
          dueDate: item.dueDate.substr(0, 10),
          payerId: person && person.id || '',
          nonPayed,
          payed,
          overdued,
          canceled,
          color,
        }
      })*/
    },
  },
  computed: {
    ...mapGetters({
      closedInvoices: 'billecta_invoice/closedInvoices',
      openInvoices: 'billecta_invoice/openInvoices',
      mainCreditorPublicId: 'billecta_creditor/mainCreditorPublicId',
      billectaProducts: 'billecta_product/list',
      deliveryMethodTypes: 'billecta_invoice/DeliveryMethodTypes',
    }),
    billectaInvoices() {
      return [...this.closedInvoices, ...this.openInvoices]
    },
    paymentOptions() {
      return [
        {
          text: 'Autogiro (AG) - 1930',
          value: 'AG',
        },
        {
          text: 'Bankgiro (BG) - 1930',
          value: 'BG',
        },
        {
          text: 'Fakturaförsäljning (BFF) - 2490',
          value: 'BFF',
        },
        {
          text: 'Kort (KORT) - 1930',
          value: 'KORT',
        },
        {
          text: 'Kreditering (KREDIT) - 1930',
          value: '1930',
        },
        {
          text: 'Swish (SWISH) - 1930',
          value: 'SWISH',
        },
      ]
    },
    currentInvoiceAmountDifference() {
      if (!this.currentPayment || !this.currentInvoice) return 0

      return `${this.currentInvoice.CurrentAmount.ValueForView -
      this.currentPayment.amount} SEK`
    },
    invoiceObjects() {
      const invoices = this.openInvoices.concat(this.closedInvoices)

      const all = invoices

      const nonAttested = invoices.filter(invoice => !invoice.AttestedDate)
      const attested = invoices.filter(invoice => invoice.AttestedDate)
      const attestedLength = invoices.filter(invoice => invoice.AttestedDate)
      const payed = attested.filter(invoice => {
        return (
          !invoice.CurrentAmount.Value &&
          invoice.ClosedDate &&
          invoice.Stage == 'Completed'
        )
      })
      const nonPayed = attested.filter(
        invoice =>
          !invoice.ClosedDate &&
          invoice.CurrentAmount.Value &&
          moment(invoice.DueDate).isSameOrAfter(moment(), 'day')
      )

      const overdued = attested.filter(
        invoice =>
          !invoice.ClosedDate && invoice.CurrentAmount.Value &&
          moment(invoice.DueDate).isBefore(moment(), 'day')
      )
      const canceled = []

      return {
        all,
        nonAttested,
        attested: attestedLength,
        nonPayed,
        payed,
        overdued,
        canceled,
      }
    },
    invoiceCount() {
      const {
        all,
        nonAttested,
        attested,
        nonPayed,
        payed,
        overdued,
        canceled,
      } = this.invoiceObjects

      return {
        all: all.length,
        nonAttested: nonAttested.length,
        attested: attested.length,
        nonPayed: nonPayed.length,
        payed: payed.length,
        overdued: overdued.length,
        canceled: canceled.length,
      }
    },
    invoices() {
      const {
        all,
        nonAttested,
        attested,
        nonPayed,
        payed,
        overdued,
        canceled,
      } = this.invoiceObjects

      if (this.invoiceStatus === 'overdued') return overdued

      if (this.invoiceStatus === 'attested') return attested

      if (this.invoiceStatus === 'non-attested') return nonAttested

      if (this.invoiceStatus === 'payed') return payed

      if (this.invoiceStatus === 'non-payed') return nonPayed

      if (this.invoiceStatus === 'canceled') return canceled

      return all
    },
    filteredInvoices() {
      const search = this.invoiceFilter
        .toLowerCase()
        .split(' ')
        .filter(o => o)

      if (!search) return this.invoices

      let results = this.invoices

      results = results.filter(o => {
        let count = []

        Object.keys(o).forEach(key => {
          if (typeof o[key] === 'string' || typeof o[key] === 'number') {
            const string = '' + o[key]
            const index = search.findIndex(
              s =>
                string
                  .trim()
                  .toLowerCase()
                  .indexOf(s) > -1
            )

            if (index > -1 && !count.includes(index)) {
              count.push(index)
            }
          }
        })

        return count.length === search.length
      })

      results = _.orderBy(results, ['InvoiceNumber'], ['desc'])

      return results
    },
    pdf: {
      get() {
        return this.$store.state.pdf
      },
      set(pdf) {
        this.$store.state.pdf = pdf
      },
    },
    currentInvoice: {
      get() {
        return this.$store.state.currentInvoice
      },
      set(invoice) {
        this.$store.state.currentInvoice = invoice
      },
    },

    invoiceStatuses() {
      const invoiceArray = null
      if (!invoiceArray)
        return {
          all: 0,
          nonPayed: 0,
          payed: 0,
          overdued: 0,
          canceled: 0,
        }

      return {
        all: 0,
        nonPayed: 0,
        payed: 0,
        overdued: 0,
        canceled: 0,
      }
    },
  },
  watch: {
    singleSelect() {
      this.selectedInvoices = []
    },
    toggleCheckedInvoices() {
    },
    currentInvoice(invoice) {
      if (invoice) this.$store.state.printInvoices = [_.cloneDeep(invoice)]
      else this.$store.state.printInvoices = []
    },
    async invoiceStatus() {
      this.selectedInvoice = []
      this.invoicesList = []
      //this.invoices = [];
      this.currentInvoice = null
      this.$store.state.printInvoices = []

      await this.fetchInvoices()
    },
    invoiceDate() {
      this.currentInvoice = null
      this.selectedInvoice = []
      this.invoiceId = ''
      // this.filterInvoices()
    },
    async selectedInvoices(invoices) {
      if (!invoices.length) return
      if (invoices.length > 1) {
        this.currentInvoice = null
        return
      }
      this.currentInvoice = invoices[0]

      this.currentBillectaInvoice = null
      this.loadingBillectaInvoice = true

      this.pdf = []
      this.loadingPDF = true

      await this.fetchBillectaInvoice()

      this.loadingBillectaInvoice = false

      try {
        const pdf = await this.$store.dispatch(
          'billecta_invoice/previewInvoice',
          this.currentInvoice.ActionPublicId
        )

        // console.log(pdf)

        let file = new Blob([pdf], {type: 'application/pdf'})
        const fileURL = URL.createObjectURL(file)
        this.pdf.push(fileURL) //'data:application/pdf;base64,' + btoa(pdf);
      } catch (error) {
        this.showErrorMessage(error)
      }

      this.loadingPDF = false
    },
  },
}
</script>

<style>
.whitespace-pre {
  white-space: pre;
}
</style>
