<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card flat>
          <v-card-title class="pt-0 pl-sm-0 pr-sm-0"
            >Underlag för förnyelse av prenumerationer</v-card-title
          >

          <v-card-text class="flex pl-sm-0 pr-sm-0">
            <RenewSubscriptionsTable />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RenewSubscriptionsTable from '@/components/invoice/RenewSubscriptionsTable'
export default {
  components: { RenewSubscriptionsTable },
  data() {
    return {}
  },
  mounted() {},
}
</script>

<style scoped></style>
