<template>
  <v-card class="mx-auto" tile flat>
    <v-container>
      <v-layout>
        <v-col cols="12" sm="12" md="6">
          <v-list dense :elevation="0" text>
            <v-subheader>HISTORIK</v-subheader>
            <v-list-item-group v-model="invoiceIndex" color="primary">
              <v-list-item
                v-for="(item, i) in history"
                :key="i"
                :class="{
                  'elm-green': item.payDate,
                  'elm-red': item.isDue,
                  'elm-gray': item.canceled,
                  'elm-orange': !item.isDue && !item.payDate,
                }"
              >
                <v-list-item-content>
                  <v-list-item-title>Faktura #{{ item.id }}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-list-item-action-text v-if="item.billectaInvoice">
                    <small>B</small>
                    {{ formatDate(item.billectaInvoice.InvoiceDate, 'Y-MM-DD', false) }}
                  </v-list-item-action-text>
                  <v-list-item-action-text v-if="!item.billectaInvoice">
                    {{ formatDate(item.dispatchDate, 'Y-MM-DD') }}
                  </v-list-item-action-text>

                  <v-list-item-action-text v-if="item.canceled">
                    Makulerad
                    {{
                      typeof item.canceled !== 'boolean' ? item.canceled : ''
                    }}
                  </v-list-item-action-text>
                  <v-list-item-action-text
                    v-if="!item.canceled && item.payDate && !item.isDue"
                  >
                    Betald {{ formatDate(item.payDate, 'Y-MM-DD') }}
                  </v-list-item-action-text>
                  <v-list-item-action-text v-if="!item.canceled && item.isDue">
                    Förfallen {{ formatDate(item.dueDate, 'Y-MM-DD') }}
                  </v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>

        <v-col cols="12" sm="12" md="6">
          <v-card v-if="currentInvoice" class="mb-4" style="width: 500px">
            <pdf :ref="`pdf_component_0`" v-if="pdf && pdf[0]" :src="pdf[0]" />
          </v-card>
        </v-col>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
import { models } from 'feathers-vuex'
import moment from 'moment'

import pdf from 'vue-pdf'


export default {
  components: { pdf },
  props: {
    personId: {
      type: String,
      default: '',
    },
    billectaId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      history: [],
      currentInvoice: null,
      invoiceIndex: -1,
    }
  },
  async mounted() {
    this.history = []

    const Invoice = models.api.Invoice
    const data = await Invoice.find({
      query: {
        payerId: this.personId,
        $sort: {
          _id: -1,
        },
      },
    })

    console.log(data)

    this.history = []


    for await (let invoice of data) {

      const {
        payDate,
        id,
        createdAt,
        created,
        canceled,
        dueDate,
        dispatchDate,
        billecta_id
      } = invoice

      console.log('billeca_id', billecta_id)

      const isDue = !payDate && moment(dueDate).isBefore(moment())

      let billectaInvoice = null;
      if(billecta_id)
        billectaInvoice = await this.$store.dispatch('billecta_invoice/getInvoice', {id: billecta_id})

      this.history.push({
        title: `Faktura #${id} skapad`,
        date: moment.utc(created || createdAt).format('Y-MM-DD'),
        color: 'elm-orange',
        billectaInvoice,
        canceled,
        payDate,
        isDue,
        dueDate,
        dispatchDate,
        id,
        invoice: {
          ...invoice,
          index: 1,
        },
      })
      /* if(created || createdAt)
        this.history.push({
          title: `Faktura #${id} skapad`,
          date: moment.utc(created || createdAt).format('Y-MM-DD'),
          color: 'elm-orange',
          invoice: {
            ...invoice,
            index: 1
          }
        }) */
      /* if (dispatchDate)
        this.history.push({
          title: `Faktura #${id} fakturerad`,
          date: moment.utc(dispatchDate).format('Y-MM-DD'),
          color: 'elm-orange',
          invoice: {
            ...invoice,
            index: 2,
          },
        })
      if (canceled) {
        this.history.push({
          title: `Faktura #${id} makulerad`,
          date: '',
          color: 'elm-black',
          invoice: {
            ...invoice,
            index: 4,
          },
        })
      } else if (dueDate && new Date(dueDate) < new Date() && !payDate) {
        this.history.push({
          title: `Faktura #${id} förfallen`,
          date: moment.utc(dueDate).format('Y-MM-DD'),
          color: 'elm-red',
          invoice: {
            ...invoice,
            index: 3,
          },
        })
      } else if (payDate) {
        this.history.push({
          title: `Faktura #${id} betald`,
          date: moment.utc(payDate).format('Y-MM-DD'),
          color: 'elm-green',
          invoice: {
            ...invoice,
            index: 4,
          },
        })
      } */
    }

    this.history.sort((a, b) => new Date(b.date) - new Date(a.date))
  },
  methods: {},
  computed: {
    pdf: {
      get() {
        return this.$store.state.pdf
      },
      set(pdf) {
        this.$store.state.pdf = pdf
      },
    },
  },
  watch: {
    async invoiceIndex(index) {
      if (index > -1) {
        this.currentInvoice = this.history[index].invoice

        this.$store.state.printInvoices = [this.history[index].invoice]

        this.pdf = []

        if(this.currentInvoice.billecta_id) {
          try {
            const pdf = await this.$store.dispatch('billecta_invoice/previewInvoice', this.currentInvoice.billecta_id)

            let file = new Blob([pdf], {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            this.pdf.push(fileURL);//'data:application/pdf;base64,' + btoa(pdf);
          } catch (error) {
            this.showErrorMessage(error)
          }
        }
      }
    },
  },
}
</script>

<style scoped>
.elm-orange {
  background-color: rgba(255, 174, 0, 0.1);
}
.elm-gray {
  background-color: rgba(100, 100, 100, 0.1);
}
.elm-red {
  background-color: rgba(200, 0, 0, 0.1);
}
.elm-black {
  background-color: rgba(10, 10, 10, 0.1);
}
.elm-green {
  background-color: rgba(0, 196, 23, 0.1);
}
.elm-blue {
  background-color: rgba(0, 23, 196, 0.1);
}
</style>
