<template>
  <v-main class="grey lighten-3">
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-expansion-panels v-model="open">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-card-title class="text-h5">Statistik</v-card-title>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container>
                  <v-row>

                    <v-col cols="12" sm="4">

                      <DatePickerMenu
                        class="ma-4"
                        :full-width="false"
                        v-model="startDate"
                        label="Jämför"
                        :max="endDate.substring(0, 10)"
                        returnFormat="YYYY-MM-DDT00:00:00.000Z"
                        displayFormat="MMMM YYYY"
                      />
                    </v-col>
                    <v-col cols="12" sm="4">
                      <DatePickerMenu
                        class="ma-4"
                        :full-width="false"
                        v-model="endDate"
                        label="med"
                        hint=""
                        :min="startDate.substring(0, 10)"
                        returnFormat="YYYY-MM-DDT23:59:59.999Z"
                        displayFormat="MMMM YYYY"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6" md="6" lg="6">
                      <v-autocomplete
                        v-model="subscriptionTypeFilter"
                        :disabled="loadingPersons || loadingSubscriptions"
                        :items="subscriptionTypes"
                        :return-object="true"
                        chips
                        color="blue-grey lighten-2"
                        label="Filtrera"
                        item-text="description"
                        item-value="_id"
                        multiple
                      >
                        <template v-slot:selection="{ item, attrs, selected, select }">
                          <v-chip
                            v-bind="attrs"
                            :input-value="selected"
                            close
                            @click="select"
                            @click:close="
                    subscriptionTypeFilter.splice(
                      subscriptionTypeFilter.findIndex(s => s._id === item._id),
                      1
                    )
                  "
                          >
                            {{ item.article.name }}: {{ item.description }}
                          </v-chip>
                        </template>
                        <template v-slot:item="{ item }">
                          {{ item.article.name }}: {{ item.description }}
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn :disabled="isLoading" @click="fetchSubscriptions">Hämta data</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-card style="min-height: 500px">
            <v-progress-linear v-if="isLoading"
                               indeterminate
                               color="yellow darken-2"
            ></v-progress-linear>
            <v-container>
              <v-row>
                <v-col cols="4">
                  <v-card color="#385F73" dark>
                    <v-card-title class="text-h5">Prenumerationer</v-card-title>
                    <v-card-title class="text-h6">{{ subscriptionsLength }} st</v-card-title>

                    <v-card-subtitle>
                      <div v-for="item in subscriptionsByArticleType" :key="item.articleName">
                        <b>{{ item.articleName }}</b> {{ item.subs.length }} st
                      </div>
                    </v-card-subtitle>
                  </v-card>

                  <v-card  color="#1F7087" dark class="ma-2" v-for="(article, index) in subscriptionsByPriceType" :key="index">
                    <v-card-title class="text-h5">{{article.articleName}}</v-card-title>

                    <v-card-subtitle>
                      <div v-for="item in article.priceTypes" :key="item.priceObject._id">
                        <b>{{ item.priceObject.description }}</b> {{ item.subs.length }} st
                      </div>
                    </v-card-subtitle>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-card color="#385F73" dark>
                    <v-card-title class="text-h5">Prenumeranter</v-card-title>
                    <v-card-title class="text-h6">{{ personsLength }}</v-card-title>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>


          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>

import moment from "moment";
moment.locale('sv')
import DatePickerMenu from "@/components/DatePickerMenu";
import _ from "lodash";
import {models} from "feathers-vuex";

export default {
  components: {DatePickerMenu},
  data: () => ({
    open: 0,
    startDate: '',
    endDate: '',
    subscriptionTypeFilter: [],
    prices: [],
    articles: [],
    subscriptionsQuery: {},
    subscriptionsList: [],
    subscriptionsListCompare: [],
    personsQuery: {
      query: {
        active: true,
      }
    },
    personsList: [],
    loadingPersons: false,
    loadingSubscriptions: false,
  }),
  async created() {
    this.startDate = moment.utc().startOf('month').format('Y-MM-DDTHH:mm:ss.SSSZ')
    this.endDate = moment.utc(this.startDate).add(-1, 'y').format('Y-MM-DDTHH:mm:ss.SSSZ')
    this.subscriptionsQuery.query = {
      endDate: {$gte: this.startDate},
      startDate: {$lte: this.startDate},
    }
    await this.fetchArticles()
    await this.fetchPrices()
  },
  mounted() {

  },
  methods: {
    async fetchArticles() {
      const {Article} = models.api

      const data = await Article.find()

      this.articles = data /*.sort(
        (a, b) => new Date(b.pricePeriodStart) - new Date(a.pricePeriodStart)
      )*/
    },
    async fetchPrices() {
      const {Price} = models.api

      const data = await Price.find({
        query: {

        }
      })

      this.prices = data.sort(
        (a, b) => new Date(b.pricePeriodStart) - new Date(a.pricePeriodStart)
      )
    },
    async fetchSubscriptions() {
      await this.fetchPersons()

      this.loadingSubscriptions = true

      const {Subscription} = models.api

      // this.subscriptionsQuery.query.$aggregate = {}

      console.log(JSON.stringify(this.subscriptionsQuery.query))

      const data = await Subscription.find(this.subscriptionsQuery)
      //console.log(data)
      this.subscriptionsList = data //.filter(d => new Date(d.startDate) < new Date() && new Date(d.endDate) > new Date())

      this.loadingSubscriptions = false
    },
    async fetchPersons() {
      this.loadingPersons = true
      this.personsList = []

      const {Person} = models.api

      const data = await Person.find(this.personsQuery)

      this.personsList = this.multiSortArray(data, {
        lastname: 'asc',
        firstname: 'asc',
      })

      this.loadingPersons = false
    },
  },
  computed: {
    isLoading() {
      return this.loadingSubscriptions || this.loadingPersons
    },
    computedSubscriptions() {
      return this.subscriptionsList
    },
    subscriptionsLength() {
      return this.computedSubscriptions.length
    },
    personsListIds() {
      return this.personsList.map(p => p._id)
    },
    personsNonPersons() {
      return this.personsList.filter(p => p.type === 2)
    },
    personsPersons() {
      return this.personsList.filter(p => p.type === 1)
    },
    personsListCities() {
      return this.personsList.filter(p => p.postal_address).map(p => p.postal_address)
    },
    personsListCountry() {
      return this.personsList.filter(p => p.country).map(p => p.country)
    },
    personsLength() {
      return this.personsList.length
    },
    subscriptionsByArticleType() {
      const types = this.computedSubscriptions.reduce((arr, item) => {
        let priceObject, articleObject

        priceObject = this.prices.find(
          price => price._id === item.articleId
        )
        //console.log(priceObject)
        if (priceObject && this.articles) {
          articleObject = this.articles.find(
            _article => _article._id === priceObject.articleId
          )
        } else {
          //console.log('not found', item._id)
        }

        if (priceObject && articleObject) {
          // eslint-disable-next-line no-unused-vars
          const {name: articleName, _id: articleId} = articleObject || {}
          //console.log(articleName, articleId)
          //console.log(arr)

          if (arr.find(a => a.articleName === articleName)) {
            const index = arr.findIndex(a => a.articleName === articleName)
            arr[index].subs.push({
              ...item,
              articleObject,
              priceObject,
            })
          } else {
            arr.push({
              articleName,
              subs: [{
                ...item,
                articleObject,
                priceObject,
              }]
            })
          }

        } else {
          //console.log('not found', item._id)
        }

        return arr


      }, [])

      return types
    },
    subscriptionsByPriceType() {
      return this.subscriptionsByArticleType.map(type => {
        const priceTypes = type.subs.reduce((arr, item) => {

          if (arr.find(a => a.priceObject.description === item.priceObject.description)) {
            const index = arr.findIndex(a => a.priceObject.description === item.priceObject.description)
            arr[index].subs.push(item)
          }
          else {
            arr.push({
              articleObject: item.articleObject,
              priceObject: item.priceObject,
              subs: [item]
            })
          }

          return arr
        }, [])

        return {
          ...type,
          priceTypes: _.orderBy(priceTypes, item => item.priceObject.description, ['asv'])
        }
      })
    },
    subscriptionTypes() {
      const prices = _.uniqBy(this.prices, v =>
        [v.description, v.articleId].join()
      )

      return this.articles
        .map(a => {
          return _.orderBy(
            prices.filter(price => price.articleId === a._id),
            'description'
          )
        })
        .flat()
        .map(price => {
          return {
            ...price,
            allids: this.prices.filter(
              p =>
                p.articleId === price.articleId &&
                p.description == price.description
            ),
            article: this.articles.find(a => a._id === price.articleId),
          }
        })
      /*
      return this.groupPriceByDate(
        this.prices.filter(price => price.articleId === articleId),
        'description',
        'pricePeriodStart'
      ) */
    }
  },
  watch: {
    startDate(date) {
      this.subscriptionsQuery.query = {
        startDate: {$lte: (date)},
        endDate: {$gte: date},
      }
    },
    endDate(date) {
      this.subscriptionsQuery.query = {
        startDate: {$lte: (this.startDate)},
        endDate: {$gte: (date)},
      }
    }
  }
}
</script>
