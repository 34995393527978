// eslint-disable
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'

import settings from '../../../config/settings'

const apiUrl = settings.billectaURL
//const apiUrl = 'https://apitest.billecta.com';

const headers = {
  Authorization: `Basic ${process.env.VUE_APP_BILLECTA_BASIC_TOKEN}`,
  Accept: 'application/json',
  token: localStorage.getItem('feathers-jwt'),
}

//console.log(headers)

const axiosInstance = axios.create({
  baseURL: apiUrl,
  timeout: 20000,
  withCredentials: false,
  headers,
})

const state = {
  billectFirstInvoiceNumber: 9244,
  list: [],
  closedInvoices: [],
  openInvoices: [],
  personalInvoices: [],
  personalInvoiceEvents: [],
  debtorContracts: [],
  billectaMessageRecordItem: {
    ProductPublicId: null,
    SequenceNo: 0,
    Units: null,
    ArticleDescription: null,
    ArticleNumber: null,
    Quantity: null,
    UnitPrice: null,
    DiscountAmount: null,
    DiscountPercentage: 0,
    DiscountType: "Amount",
    VAT: 0,
    CostCenter: null,
    PeriodStart: null,
    PeriodEnd: null,
    Project: null,
    RotRutActivated: false,
    RotRutAmount: null,
    RotRutMaterialCostAmount: null,
    RotRutHours: 0,
    RotRutType: "Construction",
    RecordType: 'Message',
    VatIsIncluded: false,
    Hidden: false,
    TotalIncVAT: null,
    TotalExclVATNoDiscount: null,
    TotalExclVAT: null,
  }
}

const getters = {
  billectFirstInvoiceNumber(state) {
    return state.billectFirstInvoiceNumber
  },
  DeliveryMethodTypes() {
    return  [
      {value: "Email", text: "E-post"},
      {value: "Mail", text: "Post"},
      {value: "Manually", text: "Skicka inte"},
      {value: "SMS", text: "SMS"},
      {value: "EInvoice", text: "E-faktura"},
      {value: "Kivra", text: "Kivra"},
    ]
  },
  InvoiceRecordRow() {
    return {
      ProductPublicId: '',
      SequenceNo: 0,
      Units: 'pairs',
      ArticleDescription: 'Socks',
      ArticleNumber: null,
      Quantity: 1,
      UnitPrice: {
        CurrencyCode: 'SEK',
        Value: 10000,
        ValueForView: 100,
      },
      DiscountAmount: null,
      DiscountPercentage: 0,
      DiscountType: 0,
      VAT: 25,
      CostCenter: null,
      PeriodStart: null,
      PeriodEnd: null,
      Project: null,
      RotRutActivated: false,
      RotRutAmount: null,
      RotRutMaterialCostAmount: null,
      RotRutHours: 0,
      RotRutType: 0,
      RecordType: 0,
      VatIsIncluded: false,
      Hidden: false,
      TotalIncVAT: {
        CurrencyCode: 'SEK',
        Value: 12500,
        ValueForView: 125,
      },
      TotalExclVATNoDiscount: {
        CurrencyCode: 'SEK',
        Value: 10000,
        ValueForView: 100,
      },
      TotalExclVAT: {
        CurrencyCode: 'SEK',
        Value: 10000,
        ValueForView: 100,
      },
    }
  },
  InvoiceActionEntryView() {
    return {
      ActionType: 2,
      CreditorPublicId: '',
      DebtorPublicId: '',
      InvoiceDate: '',
      DueDate: '',
      DeliveryDate: null,
      Records: [],
      InterestPercentage: 8,
      ReasonForHigherInterest: null,
      InterestStartInDaysAfterDueDate: 0,
      InterestType: 1,
      OurReference: null,
      YourReference: null,
      DeliveryMethod: 0,
      CommunicationLanguage: 0,
      Message: null,
      InvoiceNumber: '123',
      InvoiceFee: {
        CurrencyCode: 'SEK',
        Value: 2500,
        ValueForView: 25,
      },
      FreightFee: null,
      VatIsIncluded: false,
      SendByMailIfEmailNotViewedInDays: null,
      DeliveryAddressOverride: null,
      DebtCollectionDetails: {
        SendToDebtCollection: true,
        StartDebtCollectionActionLevel: 2,
        EndDebtCollectionActionLevel: 3,
        NumberOfReminders: 0,
        DaysDelayAfterDueDate: 15,
        PaymentTermsInDays: 10,
        Eviction: false,
        InformSocialWelfare: false,
      },
      ReminderInvoiceDetails: {
        SendReminderInvoice: false,
        DaysDelayAfterDueDate: 1,
      },
      ReverseVATDetails: null,
      RotRutDetails: null,
      PaymentOverride: null,
      Appendixes: [],
      Attachments: [],
      Autogiro: null,
      CreditCard: null,
      InvoicePDF: null,
      CreditingInvoicePublicId: null,
      ExternalReference: null,
      IsLocked: false,
      UseDebtorBalance: false,
      InvoiceSendDateOverride: null,
    }
  },

  closedInvoices: state => state.closedInvoices,
  openInvoices: state => state.openInvoices,
}

const actions = {
  async getInvoice(context, id) {
    const url = `/v1/invoice/action/${id}`
    const response = await axiosInstance.get(url)

    return response.data
  },
  async updateInvoice(context, invoiceObject) {
    const url = `/v1/invoice/action/${invoiceObject.ActionPublicId}`
    const response = await axiosInstance.put(url, invoiceObject)

    return response.data
  },
  async createInvoice(context, invoiceObject) {
    const url = `/v1/invoice/action`
    const response = await axiosInstance.post(url, invoiceObject)

    return response.data
  },
  async deleteInvoice(context, invoiceObject) {
    const url = `/v1/invoice/action/${invoiceObject.ActionPublicId}`
    const response = await axiosInstance.delete(url)

    return response.data
  },

  async attestInvoice(context, id) {
    const url = `/v1/invoice/attest/${id}`
    const response = await axiosInstance.put(url)

    return response.data
  },

  async previewInvoice(context, id) {
    const url = `/v1/invoice/preview/${id}`
    const response = await axiosInstance.get(url, {
      responseType: 'blob',
    })

    return response.data
  },

  async sendInvoice(context, { invoiceObject, method = '' }) {
    if (!method) return
    const url = `/v1/invoice/sendinvoice/${invoiceObject.id}?method=${method}`
    const response = await axiosInstance.put(url)

    return response.data
  },

  async openInvoices(context, { invoiceObject, method = '' }) {
    if (!method) return
    const url = `/v1/invoice/sendinvoice/${invoiceObject.id}?method=${method}`
    const response = await axiosInstance.put(url)

    return response.data
  },
  async getDebtorContracts(context, id) {
    //const url = `/v1/creditors/creditors?=offset${offset}&limit=${limit}&sortingfield=${sortingfield}&asc=${asc}`
    const url = `/v1/debtorcontracts/debtorcontracts/${id}`
    try {
      const response = await axiosInstance.get(url)

      context.commit('debtorContracts', response.data)

      return response.data
    } catch (e) {
      return null
    }
  },
  async getMyInvoices(context, args) {

    const invoices = []

    for await (let publicactionid of args.billecta_ids) {
      try {
        const url = `/v1/invoice/action/${publicactionid}`
        const response = await axiosInstance.get(url)

        invoices.push(response.data)

      } catch (e) {
        console.error(e)
      }
    }

    context.state.personalInvoices = invoices;

    return invoices

  },
  async getMyInvoiceEvents(context, args) {

    const invoices = []

    for await (let publicactionid of args.billecta_ids) {
      try {
        const url = `/v1/invoice/action/${publicactionid}`
        const response = await axiosInstance.get(url)

        invoices.push([...invoices, ...response.data])

      } catch (e) {
        console.error(e)
      }
    }

    context.state.personalInvoiceEvents = invoices;

    return invoices

  },
  async getAllOpenInvoices(context, args) {
    /*
    {id}	Creditor public id (guid)
    {offset}	Offset (int) - optional
    {limit}	Limit (int) - optional
    {sortingfield}	Sorting field (string) - optional
    {asc}	Ascending sorting. Default value is true (bool) - optional
     */
    const string = _.map(
      _.omit(args, ['id']),
      (id, key) => id && `${key}=${id}`
    )
      .filter(Boolean)
      .join('&')
    //const url = `/v1/creditors/creditors?=offset${offset}&limit=${limit}&sortingfield=${sortingfield}&asc=${asc}`
    const url = `/v1/invoice/open/${args.id}?${string}`
    try {
      const response = await axiosInstance.get(url)

      const data = _.orderBy(response.data, ['InvoiceDate'], ['desc'])

      context.commit('openInvoices', data)

      return response.data
    } catch (e) {
      return null
    }
  },
  async getAllClosedInvoices(context, args) {
    /*
    {id}	Creditor public id (guid)
    {from}	From date (date)
    {to}	To date (date)
    {offset}	Offset (int) - optional
    {limit}	Limit (int) - optional
    {sortingfield}	Sorting field (string) - optional
    {asc}	Ascending sorting. Default value is true (bool) - optional
     */
    const string = _.map(
      _.omit(args, ['id']),
      (id, key) => id && `${key}=${id}`
    )
      .filter(Boolean)
      .join('&')
    //const url = `/v1/creditors/creditors?=offset${offset}&limit=${limit}&sortingfield=${sortingfield}&asc=${asc}`
    const url = `/v1/invoice/closed/${args.id}?${string}`
    try {
      const response = await axiosInstance.get(url)

      context.commit('closedInvoices', response.data)

      return response.data
    } catch (e) {
      return null
    }
  },

  // eslint-disable-next-line no-unused-vars
  async registerPayment(context, RegisterPaymentView) {
    /*
    {
      "ActionPublicId": "1234567",
      "Amount": {
        "CurrencyCode": "SEK",
        "Value": 100,
        "ValueForView": 1
      },
      "WriteOff": null,
      "Comment": "Paid 1 SEK",
      "Date": "2021-03-30T14:25:01.8800424+00:00",
      "WriteOffVat": 0,
      "OverrideWriteOffAccount": null,
      "PaymentMeanCode": "BG",
      "OvershootingAmountHandling": 0,
      "PaymentReferenceText": null
    }
     */

    const url = `/v1/invoice/registerpayment`
    try {
      const response = await axiosInstance.post(url, RegisterPaymentView)

      return response.data
    } catch (e) {
      return null
    }
  },

  localInvoiceToBillectaInvoice(context, { invoice = {}, debtor = {} }) {
    let InvoiceActionEntryView = _.cloneDeep(
      context.getters.InvoiceActionEntryView
    )

    const mainCreditorPublicId =
      context.rootGetters['billecta_creditor/mainCreditorPublicId']

    const { dispatchDate, dueDate } = invoice

    /*
    "DeliveryMethodTypeView": {
      "type": "integer",
      "description": "",
      "x-enumNames": [
        "Email",
        "Mail",
        "Manually",
        "SMS",
        "EInvoice",
        "Kivra",
        "Unknown"
      ],
      "enum": [
        0,
        1,
        2,
        3,
        4,
        5,
        -1
      ]
    }
     */

    const newView = {
      ActionType: 2,
      CreditorPublicId: mainCreditorPublicId,
      DebtorPublicId: debtor.PublicId || debtor.DebtorPublicId || '',
      InvoiceDate: dispatchDate || '',
      DueDate: moment.utc(dueDate.substring(0, 10)).toISOString() || '',
      DeliveryDate: null,
      Records: [],
      InterestPercentage: 8,
      ReasonForHigherInterest: null,
      InterestStartInDaysAfterDueDate: 0,
      InterestType: 1,
      OurReference: null,
      YourReference: null,
      DeliveryMethod: 1, //DeliveryMethodTypeView
      CommunicationLanguage: 0,
      Message: null,
      InvoiceNumber: '',
      InvoiceFee: null,
      FreightFee: null,
      VatIsIncluded: true,
      SendByMailIfEmailNotViewedInDays: null,
      DeliveryAddressOverride: null,
      DebtCollectionDetails: {
        SendToDebtCollection: true,
        StartDebtCollectionActionLevel: 2,
        EndDebtCollectionActionLevel: 3,
        NumberOfReminders: 0,
        DaysDelayAfterDueDate: 15,
        PaymentTermsInDays: 10,
        Eviction: false,
        InformSocialWelfare: false,
      },
      ReminderInvoiceDetails: {
        SendReminderInvoice: false,
        DaysDelayAfterDueDate: 1,
      },
      ReverseVATDetails: null,
      RotRutDetails: null,
      PaymentOverride: null,
      Appendixes: [],
      Attachments: [],
      Autogiro: null,
      CreditCard: null,
      InvoicePDF: null,
      CreditingInvoicePublicId: null,
      ExternalReference: null,
      IsLocked: false,
      UseDebtorBalance: false,
      InvoiceSendDateOverride: null,
    }

    InvoiceActionEntryView = {
      ...InvoiceActionEntryView,
      ...newView,
    }

    //console.log(DeptorView)

    return InvoiceActionEntryView
  },

  localInvoiceRowToBillectaInvoiceItem(
    context,
    { billectaProduct = {}, localInvoiceRow = {} }
  ) {
    let InvoiceRecordRow = _.cloneDeep(context.getters.InvoiceRecordRow)

    const {
      ProductPublicId,
      Units,
      Description,
      ArticleNumber,
      UnitPrice,
      VAT,
    } = billectaProduct

    // console.log(billectaProduct, localInvoiceRow)

    let unitTotalExclVAT = UnitPrice * localInvoiceRow.quantity
    let unitTotalInclVAT = UnitPrice * localInvoiceRow.quantity * (VAT / 100)

    const recordRow = {
      ProductPublicId: ProductPublicId,
      SequenceNo: 0,
      Units: Units || 'st',
      ArticleDescription: localInvoiceRow.text || Description || '',
      ArticleNumber: ArticleNumber || null,
      Quantity: localInvoiceRow.quantity,
      UnitPrice: {
        CurrencyCode: 'SEK',
        Value: UnitPrice * 100,
        ValueForView: UnitPrice,
      },
      DiscountAmount: null,
      DiscountPercentage: 0,
      DiscountType: 0,
      VAT: VAT || 25,
      CostCenter: null,
      PeriodStart: null,
      PeriodEnd: null,
      Project: null,
      RotRutActivated: false,
      RotRutAmount: null,
      RotRutMaterialCostAmount: null,
      RotRutHours: 0,
      RotRutType: 0,
      RecordType: 0,
      VatIsIncluded: false,
      Hidden: false,
      TotalIncVAT: {
        CurrencyCode: 'SEK',
        Value: unitTotalInclVAT * 100,
        ValueForView: unitTotalInclVAT,
      },
      TotalExclVATNoDiscount: {
        CurrencyCode: 'SEK',
        Value: 10000,
        ValueForView: 100,
      },
      TotalExclVAT: {
        CurrencyCode: 'SEK',
        Value: unitTotalExclVAT * 100,
        ValueForView: unitTotalExclVAT,
      },
    }

    InvoiceRecordRow = {
      ...InvoiceRecordRow,
      ...recordRow,
    }

    //console.log(DeptorView)

    return InvoiceRecordRow
  },
}

const mutations = {
  list: (state, list) => (state.list = list),
  closedInvoices: (state, list) => (state.closedInvoices = list),
  openInvoices: (state, list) => (state.openInvoices = list),
  debtorContracts: (state, list) => (state.debtorContracts = list),
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
