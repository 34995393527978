<template>
  <v-main class="grey lighten-3">
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-card>
            <CreateInvoices />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import CreateInvoices from '@/components/invoice/CreateInvoices'

export default {
  components: { CreateInvoices },
  data: () => ({
    activeSubscriptions: 0,
    upcomingSubscriptions: 0,

    query: {
      query: {},
      paginate: true,
    },
  }),
  async created() {

    if(this.mainCreditorPublicId) {
      await this.fetchData()
    }
  },
  methods: {
    ...mapActions('subscriptions', { findSubscriptions: 'find' }),
    async fetchData() {
      await this.$store.dispatch('billecta_debtor/fetchDebtors', {id: this.mainCreditorPublicId})
      await this.$store.dispatch('billecta_product/fetchProducts', {id: this.mainCreditorPublicId})
    }
  },
  computed: {
    ...mapGetters({
      'mainCreditorPublicId': 'billecta_creditor/mainCreditorPublicId'
    })
  },
  watch: {
    async mainCreditorPublicId(id) {
      if(id)
        await this.fetchData()
    }
  }
}
</script>
