<template>
  <label class="text-reader">
    <input v-if="0" type="file" @change="loadTextFromFile" />
    <v-file-input
      :class="classes"
      :accept="accept"
      :label="label"
      :hint="hint"
      persistent-hint
      @change="loadFile"
    ></v-file-input>
  </label>
</template>

<script>
export default {
  props: {
    classes: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: 'SVG-fil',
    },
    accept: {
      type: String,
      default: 'image/svg+xml',
    },
    label: {
      type: String,
      default: 'Ladda upp lokal bilaga',
    },
  },
  methods: {
    loadTextFromFile(ev) {
      const file = ev.target.files[0]
      const reader = new FileReader()

      reader.onload = e => this.$emit('load', e.target.result)
      reader.readAsText(file)
    },
    loadFile(file) {
      if(this.type === 'blob')
        this.readFileAsBlob(file)

      this.readFileAsBase64(file)
    },
    readFileAsBase64(file) {
      const reader = new FileReader()

      reader.onload = e => this.$emit('load', e.target.result)
      reader.readAsDataURL(file)
    },
    loadTextFromVuetify(file) {
      console.log(file)
      // const file = ev.target.files[0];
      const reader = new FileReader()

      reader.onload = e => this.$emit('load', e.target.result)
      reader.readAsText(file)
    },
  },
}
</script>
