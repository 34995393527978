<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12" xs="12">
        <v-data-table
          :loading="loadingArticles"
          :headers="headers"
          :items="filteredUsers"
          :hide-default-header="isMobile"
          :hide-default-footer="true"
          :footer-props="footerProps"
          item-key="_id"
          class="elevation-1"
          :sort-by="['name']"
          :sort-desc="[false]"
          multi-sort
          @click:row="handleClick"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Artiklar</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialogArticle" persistent max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Ny artikel
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12">
                          <v-text-field
                            type="email"
                            v-model="editedArticleItem.name"
                            label="Artikelnamn"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeArticle">
                      Avbryt
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="saveArticle">
                      {{ buttonText }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline"
                    >Är du säker på att du vill ta bort denna
                    användare?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Avbryt</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <PricesDialog
                :dialog-open="dialogPrice"
                @close="bool => (dialogPrice = bool)"
                :article="editedArticleItem"
              />
              <v-dialog
                v-if="0"
                v-model="dialogPrice"
                persistent
                max-width="700px"
              >
                <v-card v-if="editedArticleItem">
                  <v-card-title>
                    <span class="headline"
                      >Priser för {{ editedArticleItem.name }}</span
                    >
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12">
                          <v-text-field
                            type="email"
                            v-model="editedArticleItem.email"
                            label="E-postadress"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closePrice">
                      Avbryt
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="savePrice">
                      {{ buttonText }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.prices="{ item }">
            <v-icon small class="mr-2" @click="editPrices(item)">
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editArticle(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteArticle(item)">
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
              Reset
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-fab-transition v-if="0">
      <v-btn v-show="true" color="pink" fab dark small absolute top right>
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
  </v-container>
</template>

<script>
import { models } from 'feathers-vuex'
import PricesDialog from '@/components/PricesDialog'
import {mapGetters} from "vuex";

export default {
  components: { PricesDialog },
  data: () => ({
    search: '',
    isMobile: false,
    loadingArticles: false,
    active: 'active',
    footerProps: {
      options: {
        itemsPerPage: 20,
        sortBy: ['firstname'],
        sortDesc: [false],
      },
      pagination: {
        rowsPerPage: 20,
        sortBy: 'lastname',
        ascending: true,
      },
      itemsPerPageOptions: [
        5,
        10,
        15,
        20,
        25,
        30,
        40,
        { text: 'Alla', value: -1 },
      ],
      itemsPerPageText: 'Personer per sida',
    },
    articlesList: [],

    dialogArticle: false,
    dialogPrice: false,
    dialogDelete: false,
    editedPriceItem: {},
    defaultPriceItem: {},
    editedArticleIndex: -1,
    editedArticleItem: {},
    defaultArticleItem: {},
  }),
  async created() {
    this.loadingArticles = true
    const { Article, Price } = models.api

    this.defaultArticleItem = { ...Article.instanceDefaults() }
    this.editedArticleItem = { ...Article.instanceDefaults() }
    this.defaultPriceItem = { ...Price.instanceDefaults() }

    await this.fetchArticles()

    await this.$store.dispatch('billecta_product/fetchProducts', {id: this.mainCreditorPublicId})

    this.loadingArticles = false
  },
  methods: {
    handleClick() {},
    async fetchArticles() {
      this.articlesList = []

      const { Article } = models.api

      const data = await Article.find({})

      this.articlesList = this.multiSortArray(data, {})
    },
    initialize() {
      this.editedArticleItem = { ...this.defaultArticleItem }
    },
    editPrices(item) {
      this.editedArticleIndex = this.articlesList.indexOf(item)
      this.editedArticleItem = Object.assign({}, item)
      this.dialogPrice = true
    },
    editArticle(item) {
      this.editedArticleIndex = this.articlesList.indexOf(item)
      this.editedArticleItem = Object.assign({}, item)
      this.dialogArticle = true
    },

    deleteArticle(item) {
      this.editedArticleIndex = this.articlesList.indexOf(item)
      this.editedArticleItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      const user = new models.api.Article(this.editedArticleItem)
      await user.remove()
      this.articlesList.splice(this.editedArticleIndex, 1)
      this.closeDelete()
    },

    closeArticle() {
      this.dialogArticle = false
      setTimeout(() => {
        this.editedArticleItem = Object.assign({}, this.defaultArticleItem)
        this.editedArticleIndex = -1
      }, 500)
    },

    closeDelete() {
      this.dialogDelete = false
      setTimeout(() => {
        this.editedArticleItem = Object.assign({}, this.defaultArticleItem)
        this.editedArticleIndex = -1
      }, 500)
    },

    async saveArticle() {
      const article = new models.api.Article(this.editedArticleItem)
      if (this.editedArticleIndex > -1) {
        const item = await article.update()
        Object.assign(this.articlesList[this.editedArticleIndex], item)
      } else {
        const item = await article.save()
        this.articlesList.push(item)
      }
      this.close()
    },
  },
  computed: {
    ...mapGetters({
      'mainCreditorPublicId': 'billecta_creditor/mainCreditorPublicId',
    }),
    headers() {
      return [
        {
          text: 'Artikel',
          align: 'start',
          value: 'name',
        },
        { text: 'Redigera artikel', value: 'actions', sortable: false },
        { text: 'Priser', value: 'prices' },
      ]
    },
    formTitle() {
      return this.editedArticleIndex === -1 ? 'Ny artikel' : 'Redigera artikel'
    },
    buttonText() {
      return this.editedArticleIndex === -1 ? 'Lägg till' : 'Uppdatera'
    },
    filteredUsers() {
      if (!this.search) return this.articlesList

      const search = this.search
        .toLowerCase()
        .split(' ')
        .filter(o => o)
      const result = this.persons.filter(o => {
        let count = []
        Object.keys(o).forEach(key => {
          if (typeof o[key] === 'string' || typeof o[key] === 'number') {
            const string = '' + o[key]
            const index = search.findIndex(
              s =>
                string
                  .trim()
                  .toLowerCase()
                  .indexOf(s) > -1
            )

            if (index > -1 && !count.includes(index)) {
              count.push(index)
            }
          }
        })
        return count.length === search.length
      })
      return result
    },
  },
  watch: {},
}
</script>
