<template>
  <div>
    <div style="padding: 9px" v-if="src && pageCount > 1">
      <v-btn

        v-for="n in pageCount"
             small
             class="mr-1"
             :color="n === currentPage?'success':''"
             :key="n"
             @click="currentPage = n">Sida {{n}}</v-btn>
    </div>
    <pdf v-if="src"
         ref="pdf"
      :src="src"
         :page="currentPage"
      @num-pages="pageCount = $event"
      @page-loaded="currentPage = $event"
    ></pdf>
  </div>
</template>

<script>

import pdf from 'vue-pdf'

export default {
  components: {
    pdf
  },
  props: {
    src: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentPage: 1,
      pageCount: 0,
    }
  }
}

</script>
