<template>
  <v-sheet rounded="lg">
    <v-system-bar></v-system-bar>
    <v-list>
      <v-list-item>
        <v-list-item-avatar v-if="0">
          <v-img src="https://cdn.vuetifyjs.com/images/john.png"></v-img>
        </v-list-item-avatar>
        <v-avatar
          v-if="user !== null"
          :size="40"
          :style="{ 'background-color': 'rgb(53,118,203)' }"
        >
          <strong
            class="initials"
            :style="{ color: 'white', 'font-size': '14px' }"
          >
            {{ user.first_name[0] + user.last_name[0] || '?' }}
          </strong>
        </v-avatar>
      </v-list-item>

      <v-list-item link>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ userName }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-icon>mdi-menu-down</v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list nav dense>
      <v-list-group
        color="primary"
        :value="true"
        v-for="item in menuitems"
        :key="item.title"
      >
        <template v-slot:activator>
          <v-list-item :to="item.link">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </template>

        <v-list-item
          v-for="({ title, icon, click, link }, i) in item.menuitems"
          :key="i"
          link
          :to="link"
          class="pl-6"
          @click="() => click && click()"
        >
          <v-list-item-title v-text="title"></v-list-item-title>

          <v-list-item-icon>
            <v-icon v-text="icon"></v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list-group>

      <v-list-group v-if="0" :value="true" prepend-icon="mdi-account-circle">
        <template v-slot:activator>
          <v-list-item-title>Users</v-list-item-title>
        </template>

        <v-list-group :value="true" no-action sub-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Admin</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="([title, icon], i) in admins" :key="i" link>
            <v-list-item-title v-text="title"></v-list-item-title>

            <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>

        <v-list-group no-action sub-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Actions</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="([title, icon], i) in cruds" :key="i" link>
            <v-list-item-title v-text="title"></v-list-item-title>

            <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </v-list-group>
    </v-list>

    <v-list v-if="0" color="transparent">
      <v-list-item v-for="n in 5" :key="n" link>
        <v-list-item-content>
          <v-list-item-title> List Item {{ n }} </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-2"></v-divider>

      <v-list-item link color="grey lighten-4">
        <v-list-item-content>
          <v-list-item-title>
            Refresh
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      menuitems: [
        {
          title: 'Prenumeranter',
          link: '/',
          menuitems: [
            {
              title: '❶ Förnya prenumerationer',
              icon: '',
              onClick: () => {},
              link: '/renew_subscriptions',
            },
            /*['Aktiva pren. och bet.', 'mdi-account-multiple-outline', () => {
              self.query.query = {
                active: true
              }
              self.findPersons(self.query);
            }],
            ['Kommande pren. och bet.', 'mdi-account-multiple-outline'],
            ['Inaktiva', 'mdi-account-multiple-outline', () => {
              self.query.query = {
                active: false
              }
              self.findPersons(self.query);
            }],
            ['Visa alla personer.', 'mdi-account-multiple-outline'],
            ['Visa alla företag', 'mdi-account-multiple-outline'],
            */
          ],
        },
        {
          title: 'Produkter',
          link: '/articles',
          menuitems: [],
        },
        {
          title: 'Statistik',
          link: {name: 'Statistics'},
          menuitems: [],
        },
        {
          title: 'Fakturor/Utskick',
          link: '/invoices',
          menuitems: [
            {
              title: 'Gamla fakturor',
              icon: '',
              onClick: () => {},
              link: '/local_invoices',
            },

            {
              title: '❷ Skapa fakturor',
              icon: '',
              onClick: () => {},
              link: '/create_invoices',
            },
            /*{
              title: '❸ Skicka fakturor',
              icon: '',
              onClick: () => {},
              link: '/send_invoices',
            },*/
            {
              title: '❸ Skapa adressfil',
              icon: '',
              onClick: () => {},
              link: '/create_address_file',
            },
          ],
        },
        {
          title: 'Användare',
          link: '/users',
          menuitems: [],
        },
      ],
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
    userName() {
      return this.user.first_name + ' ' + this.user.last_name
    },
  },
}
</script>

<style scoped></style>
