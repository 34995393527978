<template>
  <v-sheet>
    <v-container style="max-width: 100%" class="px-3 py-0 ma-0 mb-4">
      <v-row>
        <v-col cols="12" xs="12" sm="6" md="3" lg="3"
               :class="{'px-0': $vuetify.breakpoint.mdAndDown}"
        >
          <DatePickerMenu
            class="ma-4"
            :full-width="false"
            v-model="subscriptionEndDate"
            label="Hämta prenumerationer som slutar"
            hint="dagen ignoreras"
            returnFormat="YYYY-MM-DDTHH:mm:ss.SSSZ"
            displayFormat="YYYY-MM"
            @input="setDate"
          />
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="3" lg="3">
          <v-text-field
            v-model.number="subscriptionLimit"
            label="Hämta antal poster"
            hint="Tänk på att det kan finnas fler prenumerationer än de du hämtar första gången"
            persistent-hint
          />
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="3" lg="3"
               :class="{'px-0': $vuetify.breakpoint.mdAndDown, 'mx-0': $vuetify.breakpoint.mdAndDown}"
        >
          <v-btn
            small
            :class="{'ml-4': $vuetify.breakpoint.smAndUp,'mt-4': $vuetify.breakpoint.smAndUp}"
            @click="fetchSubscriptions(currentPricesFullYearIds)"
          >
            Hämta helårsprenumerationer
          </v-btn>
        </v-col>
        <v-col cols="12" xs="12" sm="6" md="3" lg="3"
               :class="{'px-0': $vuetify.breakpoint.mdAndDown, 'mx-0': $vuetify.breakpoint.mdAndDown}"
        >
          <v-btn
            small
            :class="{'ml-4': $vuetify.breakpoint.smAndUp,'mt-4': $vuetify.breakpoint.smAndUp}"
            @click="fetchSubscriptions(currentPricesNonFullYearIds)"
          >
            Hämta övriga prenumerationer
          </v-btn>
        </v-col>

      </v-row>
    </v-container>


    <v-progress-linear v-if="loading" value="1" indeterminate/>

    <div v-if="subscriptions.length > 1" class="flex flex-nowrap">
      <confirm-button
        class="ma-4 primary"
        :small="true"
        :plain="true"
        :messages="[
          `Förnya alla ${subscriptionsLength} prenumerationer`,
          'Bekräfta förnyelse',
          '✔',
        ]"
        @confirmation-success="renewAllSubscriptions"
      >
      </confirm-button>
    </div>

    <v-container grid-list-xl>
      <v-layout row wrap>
        <v-flex
          v-for="(subscription, index) in subscriptions"
          xs12
          sm6
          md4
          xl4
          :key="index"
        >
          <v-card>
            <v-card-text class="warning" v-if="!isInvoicePayed(subscription.invoice.id)">
              EJ BETALD {{subscription.invoice.id}} {{isInvoicePayed(subscription.invoice.id)}}
            </v-card-text>

            <v-card-text class="blue-grey text--white">
              {{ subscription.personName }}
              <div class="mb-4" style="white-space: pre">
                {{ subscription.description }}
              </div>
              <div class="mb-4" style="white-space: pre">
                Nuvarande period:<br>
                {{ formatDate(subscription.oldStartDate, 'Y-MM') }} –
                {{ formatDate(subscription.oldEndDate, 'Y-MM') }}
              </div>
              <div class="mb-4" style="white-space: pre">
                {{ subscription._id }}
              </div>
            </v-card-text>
            <v-divider/>
            <v-card-text class="mt-2">
              <v-layout row>
                <v-flex xs12>
                  <v-select
                    :items="filterPrices(subscription.article)"
                    v-model="subscription.articleId"
                    label="Välj ny prenumerationstyp"
                    no-data-text="Inga prenumerationstyper hittades"
                    item-value="_id"
                    item-text="description"
                    dense
                    required
                    hint="Det nyaste priset med samma beskrivning väljs automatiskt"
                    persistent-hint
                    @change="(price) => setArticle(subscription, filterPrices(subscription.article), price)"
                  >
                    <template v-slot:item="data">
                      {{ `${data.item.description} ${data.item.price} kr` }}
                    </template>
                  </v-select>
                </v-flex>
                <v-flex xs12>
                  <v-autocomplete
                    v-model="subscription.payerId"
                    :items="personsList"
                    item-value="_id"
                    placeholder="Betalare"
                    label="Betalare"
                    :filter="getList"
                    clearable
                  >
                    <template v-slot:selection="data">
                      <template v-if="data.item.type === 1">
                        <v-list-item-content>{{
                            `${data.item.id} ${data.item.lastname} ${data.item.firstname}, ${data.item.postal_address}`
                          }}
                        </v-list-item-content>
                      </template>
                      <template v-if="data.item.type === 2">
                        <v-list-item-content>{{
                            `${data.item.id} ${data.item.lastname}, ${data.item.postal_address}`
                          }}
                        </v-list-item-content>
                      </template>
                    </template>
                    <template v-slot:item="data">
                      <template v-if="data.item.type === 1">
                        <v-list-item-content>{{
                            `${data.item.id} ${data.item.lastname} ${data.item.firstname}, ${data.item.postal_address}`
                          }}
                        </v-list-item-content>
                      </template>
                      <template v-if="data.item.type === 2">
                        <v-list-item-content>{{
                            `${data.item.id} ${data.item.lastname}, ${data.item.postal_address}`
                          }}
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-flex>

                <v-flex xs12 md6>
                  <DatePickerMenu
                    class="mt"
                    v-model="subscription.startDate"
                    label="Prenumerationsstart"
                    hint="(alltid den första)"
                    returnFormat="YYYY-MM-DDT00:00:00.000Z"
                    displayFormat="YYYY-MM"
                    @input="date => setEndDate(date, subscription)"
                  />
                </v-flex>
                <v-flex xs12 md6>
                  <DatePickerMenu
                    class="mt"
                    v-model="subscription.endDate"
                    label="Prenumerationsslut"
                    hint="(alltid den siste)"
                    returnFormat="YYYY-MM-DDT23:59:59.999Z"
                    displayFormat="YYYY-MM"
                  />
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                :disabled="
                  !subscription.personId ||
                    !subscription.payerId
                "
                @click="renewSubscription(subscription, index)"
              >
                Förnya prenumeration
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>

      <v-bottom-navigation
        fixed
        hide-on-scroll
        horizontal
        scroll-target="#hide-on-scroll-example"
      >
        <confirm-button
          :small="true"
          :plain="true"
          :text="true"
          :disabled="!subscriptions.length"
          :messages="[
            `Förnya alla prenumerationer`,
            'Bekräfta förnyelse',
            '✔',
          ]"
          @confirmation-success="renewAllSubscriptions"
        >
        </confirm-button>
      </v-bottom-navigation>
    </v-container>
  </v-sheet>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import moment from 'moment'
import DatePickerMenu from '@/components/DatePickerMenu'
import ConfirmButton from '@/components/ConfirmButton'
import {models} from 'feathers-vuex'

import _ from 'lodash'

// TODO db.collection update query
/*
db.getCollection('subscriptions').find({startDate: ISODate("2022-02-28T00:00:00.000Z")}).forEach( function(x) {
      x.startDate = ISODate("2022-02-01T00:00:00.000Z")
      db.getCollection("subscriptions").save(x);
   });
 */
export default {
  components: {DatePickerMenu, ConfirmButton},
  data: () => ({
    subscriptionsLength: 0,
    subscriptionLimit: 50,
    subscriptionEndDate: '',
    search: '',
    loading: false,
    isMobile: false,
    active: true,
    articles: [],
    prices: [],
    invoiceRowsList: [],
    invoicesList: [],
    subscriptions: [],
    subscriptionsList: [],
    subscriptionCount: 0,
    subscriptionRange: 'active',
    subscriptionType: [1, 2],
    footerProps: {
      options: {
        itemsPerPage: 20,
        sortBy: ['firstname'],
        sortDesc: [false],
      },
      pagination: {
        rowsPerPage: 20,
        sortBy: 'lastname',
        ascending: true,
      },
      itemsPerPageOptions: [
        5,
        10,
        15,
        20,
        25,
        30,
        40,
        {text: 'Alla', value: -1},
      ],
      itemsPerPageText: 'Personer per sida',
    },
    subscriptionsQuery: {
      query: {},
    },
    pricesQuery: {
      query: {},
      paginate: true,
    },
    personsQuery: {
      query: {
        active: true,
        type: {
          $in: [1, 2],
        },
      },
      paginate: true,
    },
  }),
  async created() {
    this.subscriptionEndDate = moment().format('YYYY-MM-DD')

    this.clearSubscriptions()

    await this.fetchArticles()
    await this.fetchPrices()

    this.clearPersons()
    await this.findPersons({
      query: {
        active: true,
      },
    })

    const {Subscription} = this.$FeathersVuex.api
    // or
    this.subscriptionCount = await Subscription.count({
      query: {priority: 'critical'},
    })

    // await this.fetchSubscriptions()

    // const today = moment().startOf('year');
    // "2018-12-05T00:00:00.00
    /*


    */
    /* const persons = this.persons
    await this.findInvoiceRows({
      payerId: { $in: persons.map(p => p._id) }
    }) */
  },
  mounted() {
    /*
    db.getCollection('subscriptions').find({startDate: ISODate('2022-01-01T23:59:59.999+00:00')}).forEach(function(doc){
        db.subscriptions.update({_id: doc._id},{$set:{"startDate": ISODate('2022-01-01T00:00:00.000+00:00')}});
    })
     */
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
    this.subscriptionsList = []
  },
  methods: {
    ...mapActions('subscriptions', {createSubscription: 'create'}),
    ...mapActions('invoicerows', {findInvoiceRows: 'find'}),
    ...mapActions('persons', {findPersons: 'find'}),
    ...mapMutations('persons', {
      clearPersons: 'clearAll', // lets you do `this.clearTodos()` inside the component
    }),
    ...mapMutations('subscriptions', {
      clearSubscriptions: 'clearAll', // lets you do `this.clearTodos()` inside the component
    }),
    ...mapActions('prices', {findPrices: 'find'}),
    ...mapMutations('prices', {
      clearPrices: 'clearAll', // lets you do `this.clearTodos()` inside the component
    }),
    ...mapActions('articles', {findArticles: 'find'}),
    ...mapMutations('articles', {
      clearArticles: 'clearAll', // lets you do `this.clearTodos()` inside the component
    }),
    setArticle(subscription, prices, price) {
      subscription.price = prices.find(p => p._id === price).price
    },
    getPayDate(id) {
      if(id < this.billectFirstInvoiceNumber) {
        const invoice = this.invoicesList.find(i => i.id === id)
        if(invoice?.payDate)
          return moment(invoice.payDate).format('Y-MM-DD')
        else
          return 'Nej'
      }
      else {
        const invoice = this.getBillectaInvoice(id)
        if(!invoice) return false
        return (
          !invoice.CurrentAmount.Value &&
          invoice.ClosedDate &&
          invoice.Stage == 'Completed' ? 'Ja' : 'Nej'
        )
      }

      return 'Nej'
    },
    isInvoicePayed(id) {
      if(id < this.billectFirstInvoiceNumber) {
        const invoice = this.invoicesList.find(i => i.id === id)
        if(invoice && invoice.payDate)
          return true

        return false
      }

      const invoice = this.getBillectaInvoice(id)
      if(!invoice) return false
      return (
        !invoice.CurrentAmount.Value &&
        invoice.ClosedDate &&
        invoice.Stage == 'Completed'
      )

      // subscription.invoice && !subscription.invoice.payDate
    },
    getBillectaInvoice(id) {
      const billectaInvoices = this.billectaInvoices;

      if(!billectaInvoices.length)
        return null

      const invoice = billectaInvoices.find(i => i.InvoiceNumber === id + '')

      if(invoice) {
        return invoice
      }

      return null

    },
    async getAllOpenInvoices() {
      try {
        await this.$store.dispatch('billecta_invoice/getAllOpenInvoices', {
          id: this.mainCreditorPublicId,
          from: moment(this.subscriptionEndDate).add(-1, 'Y').format('Y-MM-DD'),
          to:  moment(this.subscriptionEndDate).format('Y-MM-DD'),
          sortingfield: 'InvoiceNumber',
          asc: true,
        })
      } catch (error) {
        this.showErrorMessage(error)
      }
    },
    async getAllClosednvoices() {
      try {
        await this.$store.dispatch('billecta_invoice/getAllClosedInvoices', {
          id: this.mainCreditorPublicId,
          from: moment(this.subscriptionEndDate).add(-1, 'Y').format('Y-MM-DD'),
          to:  moment(this.subscriptionEndDate).format('Y-MM-DD'),
          sortingfield: 'InvoiceNumber',
          asc: true,
        })
      } catch (error) {
        this.showErrorMessage(error)
      }
    },
    async renewAllSubscriptions() {
      for await (const sub of this.subscriptions) {
        if (sub.personId && sub.payerId) await this.renewSubscription(sub)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async renewSubscription(subscription = {}, index = -1) {
      let newSub = {
        ...subscription,
        payDate: '',
        renewed: false,
        renewedId: subscription._id,
        status: 'active',
      }
      delete newSub._id
      delete newSub.createdAt
      delete newSub.updatedAt
      delete newSub.invoiceRowId
      delete newSub.description

      let person = newSub.person;
      if (person) {
        if (person.type === 1)
          person = `${person.id} ${person.lastname} ${person.firstname}, ${person.postal_address}`
        if (person.type === 2)
          person = `${person.id} ${person.lastname}, ${person.postal_address}`
      }

      delete newSub.payer;
      delete newSub.person;
      // delete newSub.personId;
      delete newSub.personName;
      delete newSub.payerName;
      // delete newSub.payerId;
      delete newSub.payedDatum;
      delete newSub.betaltDatum;
      delete newSub.id
      //5fbd553b44a0f62c686a5302

      // console.log(newSub)
      //subscription.renewed = true

      try {
        const newSubscription = new models.api.Subscription(newSub)
        await newSubscription.save()

        const oldSubscription = new models.api.Subscription({
          _id: subscription._id,
          renewed: true,
        })
        await oldSubscription.save()


        this.$store.commit('showMessage', {
          position: 'bottom',
          timeout: 2000,
          text: `Prenumeration förnyad för ${person}`,
          visible: true,
        })
      } catch (e) {
        console.log(e)
      }

      this.subscriptionsList = this.subscriptionsList.filter(
        sub => sub._id !== subscription._id
      )

      this.subscriptions = this.subscriptions.filter(
        sub => sub._id !== subscription._id
      )

      // console.log(subscription, newSub, index)
    },
    getList(item, queryText) {
      const search = queryText
        .toLowerCase()
        .split(' ')
        .filter(o => o)
      let count = []
      Object.keys(item).forEach(key => {
        if (typeof item[key] === 'string' || typeof item[key] === 'number') {
          const string = '' + item[key]
          const index = search.findIndex(
            s =>
              string
                .trim()
                .toLowerCase()
                .indexOf(s) > -1
          )

          if (index > -1 && !count.includes(index)) {
            count.push(index)
          }
        }
      })
      return count.length === search.length
    },
    setEndDate(date, subscription) {
      const endDate = moment
        .utc(date)
        .add(1, 'y')
        .add(-1, 'd')
        .format('YYYY-MM-DDT23:59:59.999Z')
      subscription.endDate = endDate
    },
    onResize() {
      this.isMobile = this.$vuetify.breakpoint.smAndDown
    },

    async fetchArticles() {
      const {Article} = models.api

      const data = await Article.find()

      this.articles = data
    },
    async fetchPrices() {
      const {Price} = models.api

      const data = await Price.find()

      this.prices = data.sort(
        (a, b) => new Date(b.pricePeriodStart) - new Date(a.pricePeriodStart)
      )
    },
    filterPrices(articleId) {
      return this.groupPriceByDate(
        this.pricesList.filter(price => price.articleId === articleId),
        'description',
        'pricePeriodStart'
      )
    },
    setDate() {
      // console.log(date)
      //this.subscriptionEndDate = moment().utc(date).format('YYYY-MM-DD')
    },
    async fetchSubscriptions(pricesIds) {
      this.loading = true
      const date = moment
        .utc(this.subscriptionEndDate)
        .endOf('month')
        .endOf('day')

      //console.log(moment().utcOffset())

      await this.getAllOpenInvoices()
      await this.getAllClosednvoices()



      this.subscriptionsQuery.query = {
        $limit: this.subscriptionLimit || 100,
        endDate: new Date(date),
        articleId: {$in: pricesIds},
        renewed: false,
        renewAuto: true,
        invoiceRowId: {$ne: ''},
        /* "$or": {
          renewedId: { "$ne": 0 },
          renewed_Id: { "$ne": '' },
        } */
      }

      this.subscriptionsList = []

      this.$store.commit('showMessage', {
        position: 'bottom',
        timeout: 22000,
        text: 'Hämtar underlag för nya prenumerationer',
        visible: true,
      })

      const {Subscription, Invoice, Invoicerow} = models.api

      await this.findPersons(this.personsQuery)

      let subscriptions = await Subscription.find(this.subscriptionsQuery)

      console.log('subscriptions', subscriptions)

      /*this.$store.commit('showMessage', {
        position: 'bottom',
        timeout: 22000,
        text: `${subscriptions.length} prenumerationer hämtades`,
        visible: true,
      })*/

      if (true) {
        const invoiceRowsQuery = {
          query: {
            _id: {
              $in: subscriptions
                .filter(sub => sub.invoiceRowId)
                .map(sub => sub.invoiceRowId),
            },
          },
        }

        const invoiceRows = await Invoicerow.find(invoiceRowsQuery)
        this.invoiceRowsList = invoiceRows

        console.log('invoiceRows', invoiceRows)

        const invoiceQuery = {
          query: {
            /*payDate: { $ne: null },
            canceled: '',*/
            _id: {$in: invoiceRows.map(sub => sub.invoiceId)},
          },
        }
        const invoices = await Invoice.find(invoiceQuery)
        this.invoicesList = invoices

        if (false) {
          const invoiceQuery = {
            query: {
              payDate: {$ne: null},
              canceled: '',
              _id: {$in: invoiceRows.map(sub => sub.invoiceId)},
            },
          }
          const invoices = await Invoice.find(invoiceQuery)
          console.log('invoices', invoices)
          const invoiceIds = invoices.map(i => i._id)
          let invoiceRowIds = invoiceRows
            .filter(i => invoiceIds.includes(i.invoiceId))
            .map(row => row._id)

          this.subscriptionsQuery.query.invoiceRowId = {
            $in: invoiceRowIds,
          }

          const query = {
            $limit: this.subscriptionLimit || 100,
            invoiceRowId: {
              $in: invoiceRowIds,
            }
          }

          console.log(query)

          subscriptions = await Subscription.find({
            query,
          })
        }
      }

      //console.log(subscriptions)

      this.subscriptionsList = subscriptions.map(sub => {
        return {
          ...sub,
          article: this.pricesList.find(p => p._id === sub.articleId).articleId,
        }
      })

      this.$store.commit('showMessage', {
        position: 'bottom',
        timeout: 3500,
        text: `${this.subscriptions.length} prenumeration${
          this.subscriptions.length !== 1 ? 'er' : ''
        } inläst${this.subscriptions.length !== 1 ? 'a' : ''}`,
        visible: true,
      })

      this.subscriptionsLength = this.subscriptions.length

      this.loading = false

      this.$store.commit('showMessage', {
        visible: false,
      })

      this.generateSubscriptionData()
    },
    generateSubscriptionData () {
      if (!this.personsList.length) return []

      let subs = this.subscriptionsList
        .map((item, index) => {
          const person = this.personsList.find(p => p._id === item.personId)
          let personName
          if (person) {
            if (person.type === 1)
              personName = `${person.id} ${person.lastname} ${person.firstname}, ${person.postal_address}`
            if (person.type === 2)
              personName = `${person.id} ${person.lastname}, ${person.postal_address}`
          } else {
            personName = 'Personen hittades inte'
            item.personId = ''
          }

          const price = this.pricesList.find(p => p._id === item.articleId)
          const article = this.articlesList.find(p => p._id === price.articleId)

          const description = `${article.name}: ${price.description}`

          let articleId = ''
          //get the newest prices for this article
          const prices = this.filterPrices(article._id)
          if (prices.length) {
            articleId = prices.find(p => p.description === price.description)
            if (articleId) articleId = articleId._id
            else articleId = ''
          }

          // console.log(prices, price)

          const payer = this.personsList.find(p => p._id === item.payerId)
          let payerName
          if (payer) {
            if (payer.type === 1)
              payerName = `${payer.id} ${payer.lastname} ${payer.firstname}, ${payer.postal_address}`
            if (payer.type === 2)
              payerName = `${payer.id} ${payer.lastname}, ${payer.postal_address}`
          } else {
            item.payerId === ''
          }

          if (item.personId === item.payerId) payerName = '-'

          let billectaInvoice = null;
          const _invoiceRow = this.invoiceRowsList.find(i => i._id === item.invoiceRowId)
          if(_invoiceRow) {
            const _invoice = this.invoicesList.find(i => i._id === _invoiceRow.invoiceId)
            if(_invoice) {
              // console.log(_invoice)
              billectaInvoice = this.billectaInvoices.find(b => b.InvoiceNumber === _invoice.id + '')

            }
          }


          // let active = true;
          if (!payerName) payerName = 'Personen hittades inte'

          const startDate = moment
            .utc(item.endDate)
            .add(1, 'd')
            .format('Y-MM-DDTHH:mm:ss.SSSZ')
          const endDate = moment
            .utc(startDate)
            .add(1, 'y')
            .add(-1, 'd')
            .format('Y-MM-DDTHH:mm:ss.SSSZ')

          let invoice = null

          const invoiceRow = this.invoiceRowsList.find(o => o._id === item.invoiceRowId)
          if (invoiceRow)
            invoice = this.invoicesList.find(o => o._id === invoiceRow.invoiceId)

          return {
            ...item,
            oldStartDate: item.startDate,
            oldEndDate: item.endDate,
            index,
            articleId,
            personName,
            description,
            articleName: `${article.name} ${price.description}`,
            payerName,
            startDate,
            endDate,
            billectaInvoice,
            payer,
            person,
            invoice,
          }
        })
        .filter(sub => sub.personId)

      subs = _.orderBy(
        subs,
        ['person.type', 'person.lastname'],
        ['desc', 'asc']
      )

      this.subscriptions = subs
    }
  },
  computed: {
    //...mapGetters('subscriptions', { subscriptionsLength: 'count' }),
    ...mapState('subscriptions', {loadingSubscriptions: 'isFindPending'}),
    ...mapGetters('persons', {personsList: 'list'}),
    ...mapState('persons', {loadingPersons: 'isFindPending'}),
    ...mapState('articles', {loadingArticles: 'isFindPending'}),
    ...mapGetters('articles', {articlesList: 'list'}),
    ...mapState('prices', {loadingPrices: 'isFindPending'}),
    ...mapGetters('prices', {pricesList: 'list'}),
    ...mapGetters({
      openInvoices: 'billecta_invoice/openInvoices',
      closedInvoices: 'billecta_invoice/closedInvoices',
      mainCreditorPublicId: 'billecta_creditor/mainCreditorPublicId',
      billectFirstInvoiceNumber: 'billecta_invoice/billectFirstInvoiceNumber',
    }),
    billectaInvoices() {
      return [...this.closedInvoices, ...this.openInvoices]
    },

    pricesByDate() {
      if (!this.pricesList.length) return null

      const key = 'pricePeriodStart'
      const result = this.pricesList.reduce(function (r, a) {
        const k = moment.utc(a[key]).format('YYYY-MM-DD')
        r[k] = r[k] || []
        r[k].push(a)
        return r
      }, Object.create(null))

      return result
    },
    pricesByDateArray() {
      if (!this.pricesByDate) return []

      return Object.entries(this.pricesByDate)
        .map(([key, value]) => {
          return {
            key,
            items: value,
          }
        })
        .sort((a, b) => new Date(b.key) - new Date(a.key))
    },
    currentPricesByArray() {
      if (!this.pricesByDate || !this.subscriptionEndDate) return []

      //reverse sort newest date to oldest
      let array = [...this.pricesByDateArray] //.sort((a,b) => new Date(a.key) - new Date(b.key))

      array = array.find(
        date =>
          new Date(date.key).toJSON().slice(0, 10) <=
          new Date().toJSON().slice(0, 10)
      )

      return (array && array.items) || []
    },
    currentPricesFullYear() {
      if (!this.pricesList.length) return []

      return this.pricesList.filter(price => price.wholeYear)
    },
    currentPricesFullYearIds() {
      return this.currentPricesFullYear.map(price => price._id)
    },
    currentPricesNonFullYear() {
      if (!this.pricesList.length) return []

      return this.pricesList.filter(price => !price.wholeYear)
    },
    currentPricesNonFullYearIds() {
      return this.currentPricesNonFullYear.map(price => price._id)
    },
    persons() {
      return this.personsList
    },
    headers() {
      return [
        {
          text: 'Prenumerant',
          align: 'start',
          sortable: false,
          value: 'personName',
        },
        {text: 'Produkt', value: 'articleId'},
        {text: 'Betalare', value: 'payerName'},
        {text: 'Börjar', value: 'startDateYMD'},
        {text: 'Slutar', value: 'endDateYMD'},
        {text: '', value: 'blank'},
      ]
    },
  },
  watch: {
    active(active) {
      this.personsQuery.query.active = active
      this.clearPersons()
      this.findPersons(this.personsQuery)
    },
    async subscriptionEndDate() {
    },
    async subscriptions() {
      // or
      this.subscriptionsLength = this.subscriptions.length
    },
    subscriptionRange() {
      // this.personsQuery.query.active = type
      this.clearPersons()
      this.findPersons(this.personsQuery)
    },
    subscriptionType(type) {
      this.personsQuery.query.type = {
        $in: type,
      }
      this.clearPersons()
      this.findPersons(this.personsQuery)
    },
  },
}
</script>

<style lang="scss" scoped>
.text--white {
  color: white !important;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .mobile table.v-table tr {
    max-width: 100%;
    position: relative;
    display: block;
  }

  .mobile table.v-table tr:nth-child(odd) {
    border-left: 6px solid deeppink;
  }

  .mobile table.v-table tr:nth-child(even) {
    border-left: 6px solid cyan;
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr.mobile td {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #f5f5f5;
    height: auto;
    padding: 10px;
  }

  tr.mobile .mobile-cell[data-label]:before {
    content: attr(data-label);
    padding-right: 0.5em;
    text-align: left;
    display: block;
    color: #999;
  }
}
</style>
