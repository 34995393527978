import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '../../feathers-client'

class Invoice extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'Invoice'
  // Define default properties here
  static instanceDefaults() {
    return {
      billecta_id: '',
      payerId: '',
      amount: '',
      dispatchDate: '',
      dueDate: '',
      payDate: '',
    }
  }
}
const servicePath = 'invoices'
const servicePlugin = makeServicePlugin({
  Model: Invoice,
  service: feathersClient.service(servicePath),
  servicePath,
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
})

export default servicePlugin
